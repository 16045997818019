import { useQuery } from '@tanstack/vue-query'
import { queryKeys } from '~/queryClient'
import financialBff from '~/services-v2/financial-bff/financial-bff'

export default function useOpenableSteps(id: Ref<string>) {
  const queryKey = queryKeys.FINANCING.STEPS_STATUS(id.value)

  const queryFn = async () => {
    const res = await financialBff('query')({
      financing: [{ id: id.value }, {
        openable_steps: {
          registration: true,
          documentation: true,
          hardware: true,
          contract: true,
          receiptModel: true,
        },
      }],
    })

    return res.financing
  }

  return useQuery({
    queryFn,
    queryKey,
    retry: 3,
    retryDelay: 2000, // 1 second
    staleTime: 1000 * 10, // 10 seconds
    refetchInterval: 1000 * 60, // 1 minute
  })
}
