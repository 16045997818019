import { useMutation } from '@tanstack/vue-query'
import queryClient, { queryKeys } from '~/queryClient'
import http from '~/services-v2/financial-bff/financial-bff.http'

interface UploadResponse {
  data: {
    customer_document_upload: {
      docstorage_id: string
    }[]
  }
}

export function useUploadFiles(projectId: Ref<string>) {
  const mutationFn = async (documents: File[]) => {
    // TODO: Refactor to abstract this query
    const query = `#graphql
      mutation Mutation($documents: [Upload!]!) {
        customer_document_upload(documents: $documents) {
          docstorage_id
        }
      }
    `

    const formData = new FormData()

    const operations = {
      query,
      variables: {
        documents: null,
      },
    }

    const map = documents.reduce((acc, _, index) => {
      acc[index] = [`variables.documents`]
      return acc
    }, {})

    formData.append('operations', JSON.stringify(operations))
    formData.append('map', JSON.stringify(map))

    documents.forEach((document, index) => {
      formData.append(`${index}`, document)
    })

    const res = await http.post<UploadResponse>('/graphql', formData)

    return res.data.data.customer_document_upload
  }

  return useMutation({
    mutationFn,
    mutationKey: queryKeys.REGISTRATION.UPDATE.FILE_UPLOAD(projectId.value),
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: queryKeys.REGISTRATION.UPDATE.FILE_UPLOAD(projectId.value),
      })
    },
    retry: 2,
    retryDelay: 2000,
  })
}
