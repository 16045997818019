import { useQuery } from '@tanstack/vue-query'
import { isAxiosError } from 'axios'
import { queryKeys } from '~/queryClient'
import { getPaymentModel } from '~/services-v2/payment-model-api/payment-model-api'

export default function usePaymentModel(projectId: Ref<string>) {
  const queryKey = ref(queryKeys.PAYMENT_MODEL.DATA(projectId.value))

  const queryFn = async () => {
    try {
      return await getPaymentModel(projectId.value)
    }
    catch (error) {
      if (isAxiosError(error)) {
        throw error.status
      }
      throw error
    }
  }

  return useQuery({
    queryFn,
    queryKey,
    staleTime: 1000 * 60 * 1,

    retry: 3,
    retryDelay: 1000 * 2,
  })
}

export type UsePaymentModelData = ReturnType<typeof usePaymentModel>['data']['value']
