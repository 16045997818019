import { useMutation } from '@tanstack/vue-query'
import { queryKeys } from '~/queryClient'
import { uploadInstallationHardware } from '~/services-v2/installation-api/installation-api'

export default function useInstallationHardwareUploadFile() {
  const mutationFn = async (body: { installationId: string, files: FormData, comment: string }) => {
    const { installationId, files, comment } = body
    const response = await uploadInstallationHardware(installationId, files, comment)
    return response
  }

  return useMutation({
    mutationFn,
    mutationKey: queryKeys.INSTALLATIONS.UPLOAD_FILE,
    retry: 2,
    retryDelay: 1000,
  })
}
