<script setup lang="ts">
import type { ReceiptModel } from '~/utils/receipt-model/ReceiptModel'

// props
const {
  paymentModelData,
  isTransferAfterInstallation,
  hasCombo,
  percentageDefault,
} = defineProps<{
  paymentModelData: ReceiptModel
  isTransferAfterInstallation: boolean
  hasCombo: boolean
  percentageDefault: number
}>()

// emits
const emit = defineEmits<{
  (e: 'close'): void
}>()
const { t } = useI18n()

function titleModal() {
  return isTransferAfterInstallation
    ? t('receiptModel.onflex.title')
    : t(
        hasCombo
          ? 'receiptModel.modalInstallments.titleWithCombo'
          : 'receiptModel.modalInstallments.titleWithoutCombo',
      )
}

const firstValue = computed(() =>
  paymentModelData.transfers.length ? paymentModelData.transfers[0].percent : percentageDefault,
)
const secondValue = computed(() =>
  paymentModelData.transfers.length ? paymentModelData.transfers[1].percent : percentageDefault,
)
</script>

<template>
  <div>
    <SolModal
      id="payment-model-modal"
      :title="titleModal()"
      :is-open="true"
      action-primary-text="Fechar"
      :size="{ desktop: 'medium', mobile: 'full' }"
      @close="emit('close')"
      @action:primary="emit('close')"
    >
      <template v-if="isTransferAfterInstallation">
        <div>
          <div class="mt-4">
            {{ t('receiptModel.onflex.topic') }}
          </div>
          <ul class="pl-8">
            <li class="list-disc">
              {{ t('receiptModel.onflex.firstTopic') }}
            </li>
            <li class="list-disc">
              {{ t('receiptModel.onflex.secondTopic') }}
            </li>
          </ul>
        </div>
      </template>
      <template v-else>
        <div>
          <p>
            {{ t('receiptModel.modalInstallments.principalText', {
              firstValue,
              secondValue,
            }) }}
            <ul class="pl-8">
              <li class="list-disc">
                {{ t('receiptModel.modalInstallments.firstTopic') }}
              </li>
              <li class="list-disc">
                {{ t('receiptModel.modalInstallments.secondTopic') }}
              </li>
            </ul>
          </p>
        </div>
      </template>
    </SolModal>
  </div>
</template>

<style lang="scss" scoped>

</style>
