<script setup lang="ts">
import type { Form } from './SupplierInformation.schema'
import type { UsePaymentModelData } from '~/store-v2/payment-model/usePaymentModel'
import { useToast } from '@solfacil/girassol'
import IconPlus from '~icons/material-symbols/add'
import IconDownload from '~icons/material-symbols/download'
import IMaterialSymbolsInfoOutline from '~icons/material-symbols/info-outline'
import usePaymentModelSendBillingLetter from '~/store-v2/payment-model/usePaymentModelSendBillingLetter'
import useSuppliers from '~/store-v2/payment-model/useSuppliers'

// props
const { form, paymentModel, disableField } = defineProps<{
  form: Form
  paymentModel: UsePaymentModelData
  disableField: boolean
}>()

// initial base hooks
const { t } = useI18n()
const { createErrorToast, createSuccessToast } = useToast()

// initial variables & computeds
const supplierOptions = ref<{ name: string, value: number }[]>([])
const hasMoreThanOneEmail = computed(() => {
  return (form?.values?.supplier_email?.length ?? 0) > 1
})
const emailLoading = ref(false)
const emailSuccess = ref(false)

// fields
const [hardwareSupplierModel, hardwareSupplierProps] = form.defineField('hardware_supplier')
const [invoiceValueModel, invoiceValueProps] = form.defineField('equipment_value')

// domain hooks
const { data: suppliersData } = useSuppliers()

const { mutateAsync: mutateSendBillingLetter } = usePaymentModelSendBillingLetter()

// functions
function isMobileDevice() {
  return window.innerWidth < 768
}

function addEmailField() {
  const lastIndex = (form.values.supplier_email || []).length - 1
  const lastItem = form.values.supplier_email?.[lastIndex]
  if (lastItem === '' || lastItem === undefined || lastItem === '') {
    return
  }

  emailSuccess.value = false
  form.setFieldValue('supplier_email', [...form.values.supplier_email || [], ''])
}

function removeEmailField(index: number) {
  const updatedEmails = form.values.supplier_email?.filter((_, i) => (i !== index)) || []
  form.setFieldValue('supplier_email', updatedEmails)
}

function isEmptyHardwareSupplier() {
  const isEmpty = form.values.hardware_supplier?.value === 0
    || form.values.hardware_supplier?.value === undefined

  if (isEmpty) {
    form.setFieldError('hardware_supplier', 'Selecione um fornecedor')
    return true
  }
  return false
}

function checkEmailsErrors() {
  return form.values.supplier_email?.every((_, index) =>
    form.errors.value[`supplier_email.${index}`],
  ) ?? true
}

function hasEmptyEmails() {
  return form.values.supplier_email?.every(email => email === '') ?? false
}

const disabledSendEmailButton = computed(() => {
  return !emailSuccess.value && !emailLoading.value && !disableField
})

function downloadBillingLetter() {
  const billingLetterLink = paymentModel?.supplier?.billing_letter_link
  if (!billingLetterLink)
    return
  window.open(billingLetterLink, '_blank')
}

// Send
async function sendEmail() {
  try {
    emailLoading.value = true
    const { valid } = await form.validate()
    if (!valid)
      return

    if (!checkEmailsErrors() && !hasEmptyEmails() && isEmptyHardwareSupplier())
      return

    await mutateSendBillingLetter({
      project_id: paymentModel?.project_id as string,
      supplier_id: form.values.hardware_supplier?.value as number,
      supplier_email: form.values.supplier_email as string[],
      equipment_value: form.values.equipment_value as number,
    })

    createSuccessToast({
      title: t('receipt-model.send-billing-letter.success.title'),
      description: t('receipt-model.send-billing-letter.success.description'),
    })

    emailSuccess.value = true
  }
  catch (err) {
    console.error(err)
    createErrorToast({
      title: t('receipt-model.send-billing-letter.error.title'),
      description: t('receipt-model.send-billing-letter.error.description'),
    })
    form.setFieldError(`supplier_email.${0}`, 'Informe um email válido')
  }
  finally {
    emailLoading.value = false
  }
}

// watchers
watch(() => paymentModel, (_paymentModel) => {
  if (!_paymentModel)
    return
  const { supplier, equipment_value } = _paymentModel
  form.setValues({
    equipment_value: equipment_value || 0,
  }, false)

  if (supplier?.emails && supplier?.emails.length > 0) {
    emailSuccess.value = true
    supplier.emails.forEach((email, i) => {
      form.setFieldValue(`supplier_email.${i}`, email)
    })
  }
  else {
    form.setFieldValue(`supplier_email.${0}`, '')
  }
}, { immediate: true })

watch(() => suppliersData.value, (_suppliersData) => {
  if (!_suppliersData)
    return

  const mapped = _suppliersData.map(supplier => ({
    name: supplier.company_name,
    value: supplier.id,
  }))

  supplierOptions.value.push(...mapped)

  if (!paymentModel?.supplier)
    return

  const filterSupplier = supplierOptions.value.find((supplierOpt) => {
    return supplierOpt.value === paymentModel?.supplier?.id
  })
  form.setValues({
    hardware_supplier: filterSupplier,
  }, false)
}, { immediate: true })
</script>

<template>
  <div>
    <span class="font-highlight pb-2 text-neutral-low-pure text-[16px] md:system:text-[20px]">
      {{ t('customer.supplier_information') }}
    </span>
    <form>
      <div class="flex gap-4 mt-4">
        <SolSelect
          id="hardwareSupplier"
          v-model:selected="hardwareSupplierModel"
          v-bind="hardwareSupplierProps"
          name="hardwareSupplier"
          :disabled="disableField"
          :label="t('customer.supplier_equipment')"
          :options="supplierOptions"
          class="w-full md:system:w-1/2"
          :error="form.errors.value.hardware_supplier"
        />
        <InputMoneyInput
          id="invoiceValue"
          v-model="invoiceValueModel"
          v-bind="invoiceValueProps"
          class="w-full md:system:w-1/2"
          name="invoiceValue"
          size="medium"
          required
          :disabled="disableField"
          :error="form.errors.value.equipment_value"
          :label="t('customer.equipment_value')"
          :placeholder="t('customer.equipment_value_placeholder')"
        />
      </div>

      <div class="mt-4 grid grid-cols-1">
        <div class="mb-quark flex">
          <strong class="label text-brand-primary-pure text-[14px]">
            {{ t('customer.emails_to_send') }}
          </strong>
          <SimulationTooltip
            :position="isMobileDevice() ? 'bottom' : 'right'"
            :text="t('customer.emails_tooltip')"
          >
            <IMaterialSymbolsInfoOutline class="ml-1.5" />
          </SimulationTooltip>
        </div>
        <div class="flex flex-row items-start gap-4">
          <div class="flex flex-col w-1/2">
            <div v-for="i in form?.values?.supplier_email?.length" :key="i" class="mb-2">
              <SolInputText
                id="supplierEmail"
                :value="form.values.supplier_email?.[i - 1]"
                name="supplierEmail"
                :is-success="emailSuccess"
                :is-loading="emailLoading"
                :disabled="disableField"
                :placeholder="t('customer.emails_to_send_placeholder')"
                :error="form.errors.value[`supplier_email.${i - 1}`]"
                @input="(e) => {
                  form.setFieldValue(`supplier_email.${i - 1}`, e.target?.value!)
                  emailSuccess = false
                }"
                @blur="form.setFieldTouched(`supplier_email.${i - 1}`, true)"
              />
              <SolLink
                v-show="hasMoreThanOneEmail && disabledSendEmailButton"
                class="text-brand-primary-pure mt-1"
                :disabled="disableField"
                @click="removeEmailField(i - 1)"
              >
                {{ t('form.button.remove-email') }}
              </SolLink>
            </div>
          </div>
          <SolButton
            v-if="!disableField"
            id="send-email-button"
            size="medium"
            variant="secondary"
            :disabled="!disabledSendEmailButton || JSON.stringify(form.values.supplier_email) === JSON.stringify([''])"
            @click="sendEmail()"
          >
            {{ t('form.button.send-email') }}
          </SolButton>
          <SolButton
            v-else
            id="send-email-button"
            size="medium"
            variant="secondary"
            @click="downloadBillingLetter()"
          >
            <template #icon>
              <IconDownload />
            </template>
            {{ t('billing_letter.download-title') }}
          </SolButton>
        </div>

        <SolLink
          v-show="(form?.values?.supplier_email?.length ?? 0) < 3 && disabledSendEmailButton"
          class="text-brand-primary-pure mt-2"
          @click="addEmailField()"
        >
          <template #icon>
            <IconPlus />
          </template>
          {{ t('form.button.new-email') }}
        </SolLink>
      </div>
    </form>
  </div>
</template>
