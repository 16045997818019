/* eslint-disable regexp/no-super-linear-backtracking */
/* eslint-disable regexp/no-obscure-range */
/* eslint-disable regexp/no-unused-capturing-group */
import type { PartialDeep } from 'type-fest'
import type { FormContext } from 'vee-validate'
import type { UseRegistrationData } from '~/store-v2/registration/useRegistration'
import * as z from 'zod'
import { RegistrationDocumentModel, RegistrationDocumentTypes } from '~/services-v2/financial-bff/__generated__/zeus'
import date from '~/utils/date'
import { convertToISODate, onlyNumbers } from '~/utils/string'
import { validateCPF } from '~/utils/validateCPF'

// Função para validar números de telefone brasileiros
function validatePhoneNumber(phone: string) {
  const phoneRegex = /^(\(\d{2}\) 9 \d{4}-\d{4}|\d{11})$/
  return phoneRegex.test(phone)
}

export function getSchema(t: any, validateCpf: boolean) {
  const base = z.object({
    full_name:
      z.string({ message: t('form.required') })
        .min(1, { message: t('form.required') })
        .refine(value => /^[a-zA-ZÀ-ÿ]+[-'s]?[a-zA-ZÀ-ÿ ]+$/.test(value), {
          message: 'O nome não pode conter números',
        })
        .refine(value => /^([a-zA-ZÀ-ÿ]+\s)+[a-zA-ZÀ-ÿ]*\S$/.test(value), {
          message: 'Escreva o seu nome e sobrenome',
        }),
    cpf: !validateCpf
      ? z.string().optional()
      : z.string({ message: t('form.required') })
          .refine(validateCPF, { message: 'Digite um CPF válido' })
          .transform(onlyNumbers),
    phone: z.string()
      .min(1, t('form.required'))
      .max(16, t('form.invalid_phone'))
      .refine(validatePhoneNumber, { message: 'Digite um número de telefone válido' }),
    email: z.string({ message: t('form.required') })
      .min(1, t('form.required'))
      .email({ message: t('form.invalid_email') }),
    birthdate: z.string({ message: t('form.required') })
      .min(1, t('form.required'))
      .transform(convertToISODate)
      .refine(value => date(value).isValid(), t('form.required'))
      .refine(value => date(value).isAfter(date().subtract(80, 'years')), 'Necessário ter menos de 80 anos')
      .refine(value => date(value).isBefore(date().subtract(16, 'years')), 'Necessário ter mais de 16 anos'),
  })

  return z.discriminatedUnion('document_type', [
    base.extend({
      document_type: z.literal('rg'),
      document_front: z.string({ message: t('form.required') }),
      document_back: z.string({ message: t('form.required') }),
    }),
    base.extend({
      document_type: z.literal('cnh'),
      document_front: z.string({ message: t('form.required') }),
      document_back: z.string({ message: t('form.required') }),
    }),
    base.extend({
      document_type: z.literal('cnh_digital'),
      document_digital: z.string({ message: t('form.required') }),
    }),
  ])
}

export type FormSchema = z.infer<ReturnType<typeof getSchema>>
export type Form = FormContext<PartialDeep<FormSchema>>
export type Field = keyof PartialDeep<z.infer<ReturnType<typeof getSchema>>> | 'document_front' | 'document_back' | 'document_digital'

export type DocumentFields = 'document_front' | 'document_back' | 'document_digital'

export function getInitialValues(): Partial<FormSchema> {
  return {
    document_type: 'rg',
    full_name: '',
    cpf: '',
    email: '',
    phone: '',
    birthdate: '',
  }
}

export function extractDocumentEntries(form: Form) {
  return Object.entries(form.values)
    .map(([key, value]) => /document_front|document_back|document_digital/i.test(key) ? ({ type: key, docstorage_id: value }) : null)
    .map((doc) => {
      if (form.values.document_type === RegistrationDocumentModel.cnh) {
        if (doc?.type === 'document_front') {
          return { type: RegistrationDocumentTypes.driver_license_front, docstorage_id: doc?.docstorage_id }
        }
        if (doc?.type === 'document_back') {
          return { type: RegistrationDocumentTypes.driver_license_back, docstorage_id: doc?.docstorage_id }
        }
      }

      if (form.values.document_type === RegistrationDocumentModel.rg) {
        if (doc?.type === 'document_front') {
          return { type: RegistrationDocumentTypes.identity_document_front, docstorage_id: doc?.docstorage_id }
        }
        if (doc?.type === 'document_back') {
          return { type: RegistrationDocumentTypes.identity_document_back, docstorage_id: doc?.docstorage_id }
        }
      }

      if (form.values.document_type === RegistrationDocumentModel.cnh_digital) {
        if (doc?.type === 'document_digital') {
          return { type: RegistrationDocumentTypes.digital_driver_license, docstorage_id: doc?.docstorage_id }
        }
      }
      return null
    })
    .filter(doc => doc !== null)
}

type AnalysisError = NonNullable<UseRegistrationData>['errors'][number]

export function extractErrorsByField(errors: AnalysisError[] | undefined, kind: string): Record<Field, AnalysisError> {
  const mappings: Record<string, Field> = {
    'document.driver_license_front': 'document_front',
    'document.driver_license_back': 'document_back',
    'document.digital_driver_license': 'document_digital',
    'document.identity_document_front': 'document_front',
    'document.identity_document_back': 'document_back',
    'documents.driver_license_front': 'document_front',
    'documents.driver_license_back': 'document_back',
    'documents.digital_driver_license': 'document_digital',
    'documents.identity_document_front': 'document_front',
    'documents.identity_document_back': 'document_back',
    'fullname': 'full_name',
    'cpf': 'cpf',
    'contact.email': 'email',
    'contact.phone': 'phone',
  } as const

  const mappingsWithKind = Object.entries(mappings).reduce((acc, [key, value]) => {
    acc[key] = value
    acc[`${kind}.${key}`] = value
    return acc
  }, {} as Record<string, Field>)

  return (errors || [])
    .filter(error => !!error)
    .reduce((acc, error) => {
      const field = mappingsWithKind[error.field]
      if (field) {
        acc[field] = error
      }
      return acc
    }, {} as Record<Field, AnalysisError>)
}
