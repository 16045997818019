<script setup lang="ts">
import type { StageFormalization, TagStep } from '~/ui-blocks/Formalization/Formalization.types'
import type { HardwareCombosModel } from '~/utils/installation/Installation'
import IconLock from '~icons/material-symbols/lock'
import { STATUSES_MAPPING } from '~/services-v2/financial-bff/financial-bff.consts'
import useFinancingV2 from '~/store-v2/financings/useFinancing'
import useOpenableSteps from '~/store-v2/financings/useOpenableSteps'
import { Formalization } from '~/types/enum'
import { GeneralStatuses, type ProposalStatus } from '~/utils/financing-flow/Financing'

const props = defineProps<{
  position: number
  stage: StageFormalization
  stageReproved: string
  statusProject: ProposalStatus
  formData?: HardwareCombosModel
  refetch: () => Promise<void>
}>()

const { t } = useI18n()
const route = useRoute()
const projectId = computed(() => String(route.params.id))
const { data: financing } = useFinancingV2(projectId)

const {
  data: dataStepsStatus,
  refetch: refetchDataStepsStatus,
} = useOpenableSteps(projectId)

const hasSelectedHardware = ref(false)
const hasSelectedSystemInfo = ref(false)
const blocked = ref(true)

const tag = computed(() => {
  const statusMapping = STATUSES_MAPPING[props.statusProject.equipment]

  return {
    id: props.statusProject.biometry_and_contract,
    text: t(statusMapping.textKey),
    variant: 'fill',
    size: 'medium',
    type: statusMapping.color,
  } as TagStep
})

watch(
  tag,
  () => {
    refetchDataStepsStatus()
  },
  { immediate: true },
)

onMounted(async () => {
  checkStageReproved()
  hasSelectedHardware.value = (props.formData?.modules?.length ?? 0) > 0 && ((props.formData?.inverters?.length ?? 0) > 0 || (props.formData?.microinverters?.length ?? 0) > 0)
  hasSelectedSystemInfo.value = Boolean(props.formData?.distributor && props.formData.phase)
})

function checkStageReproved() {
  if (props.stageReproved === '')
    blocked.value = false
  else
    blocked.value = props.stageReproved !== Formalization.EQUIPMENT
}

function openCollapse() {
  const hasSelected = !(hasSelectedHardware.value && hasSelectedSystemInfo.value)
  const isEquipmentStage = props.stage === Formalization.EQUIPMENT
  const isHardwareApproved = financing?.value?.section_statuses?.proposal?.equipment === GeneralStatuses.APPROVED
  const openHardware = dataStepsStatus.value?.openable_steps?.hardware && !dataStepsStatus.value?.openable_steps?.contract
  const canOpenThisStep = !blocked.value && openHardware

  const isSelectedAndNotBlocked = hasSelected && isEquipmentStage && canOpenThisStep
  const isHardwareApprovedAndNotBlocked = isHardwareApproved && isEquipmentStage && canOpenThisStep

  return isSelectedAndNotBlocked || isHardwareApprovedAndNotBlocked
}
</script>

<template>
  <CustomerElementAccordion
    :id="`accordion_${Formalization.EQUIPMENT}`"
    :title="t('customer.project_data_title')"
    :position="String(position)"
    :tag="blocked || !dataStepsStatus?.openable_steps?.hardware ? undefined : tag"
    :blocked="blocked || !dataStepsStatus?.openable_steps?.hardware"
    :open-collapse="openCollapse()"
  >
    <template #icon>
      <IconLock v-if="blocked || !dataStepsStatus?.openable_steps?.hardware" />
    </template>

    <div>
      <p class="mt-2">
        {{ t('customer.project_data_subtitle') }}
      </p>
    </div>

    <div v-if="props.formData">
      <CustomerFormsHardwareForm
        :hardware-information="props.formData"
        :refetch-form="props.refetch"
      />
    </div>
    <div v-else class="my-4 card-container flex flex-col justify-between w-full align-middle py-lg text-center items-center">
      <img src="/images/not-found.png" class="not-found">
      <h1 class="fonts-heading-h1">
        Ops, algo deu errado
      </h1>
      <p class="fonts-subtitle-medium">
        Ocorreu algum problema e não conseguimos trazer os dados dos seus equipamentos.
      </p>
    </div>
  </CustomerElementAccordion>
</template>

<style lang="scss" scoped>
.form-project-data {
  @apply mt-sm mb-0;
}
</style>
