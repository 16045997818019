<script setup lang="ts">
import { useToast } from '@solfacil/girassol'
import { useRoute } from 'vue-router'
import CancelStep from '~/components/shared/CancelStep.vue'
import { FinancingStatus } from '~/services-v2/financial-bff/__generated__/zeus'
import useFinancing from '~/store-v2/financings/useFinancing'
import useRegistration from '~/store-v2/registration/useRegistration'

const emit = defineEmits<{
  (e: 'close'): void
}>()
const route = useRoute()
const toast = useToast()
const { t } = useI18n()

// computed
const id = computed(() => route.params.id as string)

const {
  data: financing,
} = useFinancing(id)

const {
  data: registration,
} = useRegistration(id)

// state
const timeLeft = ref(-1)
const progress = ref(-1)

// methods
useIntervalFn(() => {
  if (timeLeft.value > 0) {
    timeLeft.value -= 1
    progress.value = 100 - (timeLeft.value / 60) * 100
  }
}, 1000)

function formatTime(time: number) {
  const minutes = Math.floor(time / 60)
  const seconds = time % 60
  return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`
}

function handleReproved() {
  toast.createErrorToast({
    title: t('toast.error.reproved.title'),
    description: t('toast.error.reproved.description'),
  })
  emit('close')
}

function handleAproved() {
  toast.createSuccessToast({
    title: t('toast.success.send.title'),
    description: t('toast.success.send.description'),
  })
  emit('close')
}

function handleUnderAnalysis() {
  toast.createInformativeToast({
    title: t('toast.informative.send.under_analisys.title'),
    description: t('toast.informative.send.under_analisys.description'),
  })
  emit('close')
}

// lifecycle
onMounted(() => {
  timeLeft.value = 60
})

// watches
watch(timeLeft, (value) => {
  if (value === 0) {
    handleUnderAnalysis()
  }
})

watch(financing, (value) => {
  const status = value?.section_statuses.proposal?.customer_data
  if (status === FinancingStatus.reproved) {
    handleReproved()
  }

  if (status === FinancingStatus.approved) {
    handleAproved()
  }

  if (status === FinancingStatus.to_review) {
    emit('close')
  }
})
</script>

<template>
  <div v-if="financing && registration">
    <SolModal
      id="modal-documentation-under-analysis"
      is-open="true"
      title="Documentação em análise"
      :size="{ desktop: 'medium', mobile: 'full' }"
      @close="emit('close')"
    >
      <div class="flex flex-col items-center gap-6">
        <div class="flex flex-col items-center gap-3 w-full">
          <div class="loading-container">
            <svg class="loading-circle" viewBox="0 0 36 36">
              <defs>
                <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
                  <stop offset="0%" style="stop-color:#BFFF00;stop-opacity:1" />
                  <stop offset="100%" style="stop-color:#00B569;stop-opacity:1" />
                </linearGradient>
              </defs>
              <path
                class="circle-bg"
                d="M18 2.0845
                 a 15.9155 15.9155 0 0 1 0 31.831
                 a 15.9155 15.9155 0 0 1 0 -31.831"
              />
              <path
                class="circle"
                :style="{ strokeDasharray: `${progress}, 100`, stroke: 'url(#gradient)' }"
                d="M18 2.0845
                 a 15.9155 15.9155 0 0 1 0 31.831
                 a 15.9155 15.9155 0 0 1 0 -31.831"
              />
            </svg>
            <div class="loading-text">
              {{ formatTime(timeLeft) }}
            </div>
          </div>
        </div>
        <div class="flex flex-col items-center gap-2 text-center ">
          <span class="fonts-body-x-large-bold text-neutral-low-light">
            Obrigada pelo envio da documentação!
          </span>
          <span class="fonts-body-large-regular text-neutral-low-light">
            Por favor, aguarde a análise para seguir para próxima etapa.
          </span>
        </div>
      </div>

      <template #action-secondary>
        <CancelStep
          :status-step="registration?.status"
          step-to-cancel="registration"
          :project-id="id"
          @step-canceled="emit('close')"
        />
      </template>
    </SolModal>
  </div>
</template>

<style lang="scss" scoped>
.loading-container {
  position: relative;
  width: 200px;
  height: 200px;

  .loading-circle {
    width: 100%;
    height: 100%;

    .circle-bg {
      fill: none;
      stroke: #eee;
      stroke-width: 3.8;
    }

    .circle {
      fill: none;
      stroke-width: 3.8;
      stroke-linecap: butt; /* Bordas retas */
      animation: dash 2.5s ease-in-out infinite;
    }
  }

  .loading-text {
    @apply fonts-body-medium-bold text-neutral-low-light;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 32px;
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 0, 100;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 100, 0;
    stroke-dashoffset: 0;
  }
  70% {
    stroke-dasharray: 100, 0;
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dasharray: 0, 100;
    stroke-dashoffset: -100;
  }
}
</style>
