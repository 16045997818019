import { useQuery } from '@tanstack/vue-query'
import { queryKeys } from '~/queryClient'
import { getCombosHardware } from '~/services-v2/installation-api/installation-api'

export default function useCombosHardware(projectId: Ref<string>, systemType: string | null) {
  const queryFn = async () => {
    return await getCombosHardware(projectId.value, systemType)
  }

  return useQuery({
    queryFn,
    queryKey: queryKeys.INSTALLATIONS.COMBOS_HARDWARE(projectId),
    retry: 3,
    staleTime: 1000 * 60 * 1,
  })
}
