import type { FormContext } from 'vee-validate'
import type { UseRegistrationData } from '~/store-v2/registration/useRegistration'
import * as z from 'zod'
import { validateCPF } from '~/utils/validateCPF'

export function getSchema(t: any) {
  const base = z.object({
    is_under_customer_name: z.boolean().default(true),
    electricity_bill_document: z.string({ message: t('form.required') }),
    additional_document: z.string().optional().default(''),
    comment: z.string().optional().default(''),
  })
  return z.discriminatedUnion('is_under_customer_name', [
    base.extend({
      is_under_customer_name: z.literal(true),
      holder_cpf: z.string().optional(),
      employment_proof_document: z.string().optional(),
    }),
    base.extend({
      is_under_customer_name: z.literal(false),
      holder_cpf: z.string({ message: t('form.required') }).refine(validateCPF, { message: 'Digite um CPF válido' }),
      employment_proof_document: z.string({ message: t('form.required') }),
    }),
  ])
}

export type FormSchema = z.infer<ReturnType<typeof getSchema>>
export type Form = FormContext<Partial<FormSchema>>
export type Field = keyof(FormSchema)

export function getInitialValues(): Partial<FormSchema> {
  return {
    is_under_customer_name: true,
  }
}

export function extractDocumentEntries(form: Form) {
  return Object.entries(form.values)
    .map(([key, value]) => /document/i.test(key) ? ({ type: key.replace('_document', ''), docstorage_id: value }) : null)
    .filter(doc => doc !== null)
}

type AnalysisError = NonNullable<UseRegistrationData>['errors'][number]

export function extractErrorsByField(errors: AnalysisError[] | undefined): Record<Field, AnalysisError> {
  const mappings: Record<string, Field> = {
    'project.electricity_bill.holder_cpf': 'holder_cpf',
    'project.documents.electricity_bill': 'electricity_bill_document',
    'project.documents.additional': 'additional_document',
    'project.documents.employment_proof': 'employment_proof_document',
    'project.comment': 'comment',
    'project.document.electricity_bill': 'electricity_bill_document',
    'project.document.additional': 'additional_document',
    'project.document.employment_proof': 'employment_proof_document',
  } as const

  return (errors || [])
    .filter(error => !!error)
    .reduce((acc, error) => {
      const field = mappings[error.field]
      if (field) {
        acc[field] = error
      }
      return acc
    }, {} as Record<Field, AnalysisError>)
}
