import __pages_import_0__ from "/src/pages/simulation/new/waiting.vue";
import __pages_import_1__ from "/src/pages/simulation/new/index.vue";
import __pages_import_2__ from "/src/pages/customer/solfacil/index.vue";
import __pages_import_3__ from "/src/pages/company/solfacil/index.vue";
import __pages_import_4__ from "/src/pages/bank-statement/index.vue";
import __pages_import_5__ from "/src/pages/index.vue";
import __pages_import_6__ from "/src/pages/[...all].vue";
import __pages_import_7__ from "/src/pages/company/[id].vue";
import __pages_import_8__ from "/src/pages/customer/[id].vue";
import __pages_import_9__ from "/src/pages/installation/[id].vue";
import __pages_import_10__ from "/src/pages/simulation/bnpl/[id].vue";
import __pages_import_11__ from "/src/pages/simulation/details/[id].vue";
import __pages_import_12__ from "/src/pages/simulation/result/[id].vue";
import __pages_import_13__ from "/src/pages/simulation/revision/[id].vue";

const routes = [{"name":"simulation-new-waiting","path":"/simulation/new/waiting","component":__pages_import_0__,"props":true,"meta":{"layout":"simulation"}},{"name":"simulation-new","path":"/simulation/new","component":__pages_import_1__,"props":true,"meta":{"layout":"simulation"}},{"name":"customer-solfacil","path":"/customer/solfacil","component":__pages_import_2__,"props":true},{"name":"company-solfacil","path":"/company/solfacil","component":__pages_import_3__,"props":true},{"name":"bank-statement","path":"/bank-statement","component":__pages_import_4__,"props":true,"meta":{"layout":"simulation"}},{"name":"index","path":"/","component":__pages_import_5__,"props":true,"meta":{"layout":"simulation"}},{"name":"all","path":"/:all(.*)*","component":__pages_import_6__,"props":true,"meta":{"layout":404}},{"name":"company-id","path":"/company/:id","component":__pages_import_7__,"props":true,"meta":{"layout":"client-data"}},{"name":"customer-id","path":"/customer/:id","component":__pages_import_8__,"props":true,"meta":{"layout":"client-data"}},{"name":"installation-id","path":"/installation/:id","component":__pages_import_9__,"props":true,"meta":{"layout":"simulation"}},{"name":"simulation-bnpl-id","path":"/simulation/bnpl/:id","component":__pages_import_10__,"props":true,"meta":{"layout":"simulation"}},{"name":"simulation-details-id","path":"/simulation/details/:id","component":__pages_import_11__,"props":true,"meta":{"layout":"simulation"}},{"name":"simulation-result-id","path":"/simulation/result/:id","component":__pages_import_12__,"props":true,"meta":{"layout":"simulation"}},{"name":"simulation-revision-id","path":"/simulation/revision/:id","component":__pages_import_13__,"props":true,"meta":{"layout":"simulation"}}];

export default routes;