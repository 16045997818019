<script setup lang="ts">
import type { FinancingsList, UseFinancingsParams } from '~/store-v2/financings/useFinancings'
import IconPlus from '~icons/material-symbols/add'
import ListProjects from '~/components/ListProjects.vue'
import useAuth from '~/store-v2/auth/useAuth'
import useFinancings, { FinancingSteps } from '~/store-v2/financings/useFinancings'

const impersonateStore = useImpersonateStore()

const { t } = useI18n()
const router = useRouter()
const { track } = useMixpanel()

const params = ref<UseFinancingsParams>({
  page: 1,
  page_size: 10,
  order_by: 'updated_at:desc',
  status: [],
  steps: [FinancingSteps.simulation, FinancingSteps.proposal, FinancingSteps.installation, FinancingSteps.concluded, FinancingSteps.expired],
  query: '',
  partner_id: '',
  is_combo: '',
})

const {
  data: financings,
  isLoading: isLoadingFinancings,
  error: errorFinancings,
} = useFinancings(params)

const {
  data: auth,
} = useAuth()

function setParams(value: UseFinancingsParams) {
  params.value = {
    ...params.value,
    ...value,
  }
}

function newSimulation() {
  track('financing-list_button_nova-simulacao', { trigger: 'Clique no botão de nova simulação na listagem de financiamentos' })
  router.push('/simulation/new')
}

watch(() => impersonateStore.partnerId, () => {
  params.value = {
    ...params.value,
    partner_id: impersonateStore.partnerId,
  }

  setParams(params.value)
})
</script>

<template>
  <div class="container-list">
    <div class="page-heading">
      <div>
        <h2 class="fonts-heading-h2">
          {{ t('home.title') }}
        </h2>
        <p class="fonts-subtitle-small text-neutral-low-light">
          {{ t('home.subtitle') }}
        </p>
      </div>

      <SolButton v-if="auth?.person.partner?.permission.financing" id="new-simulation" @click="newSimulation">
        <template #icon-left>
          <IconPlus />
        </template>
        {{ t('home.newSimulation') }}
      </SolButton>
    </div>

    <SharedViewPartnerAlert />

    <ListProjects
      :loading="isLoadingFinancings"
      :list="financings as unknown as FinancingsList"
      :error-financings="errorFinancings"
      :params="params"
      @params="setParams"
    />
  </div>
</template>

<style scoped lang="scss">
.container-list {
  @apply py-2xs px-4xs bg-neutral-high-pure;

  @screen md:system {
    --header-height: 97px;
    @apply py-2xs px-12 overflow-auto;
    height: calc(100vh - var(--header-height));
  }

  .page-heading {
    @apply flex flex-col gap-4 pb-2xs;

    @screen md:system {
      @apply flex-row items-center justify-between py-2xs;
    }
  }
}
</style>

<route lang="yaml">
meta:
  layout: simulation
</route>
