<script setup lang="ts">
import type { Item, Product } from '~/utils/navigation/Sidebar'

import { SolSidebar } from '@solfacil/girassol'
import IconMoney from '~icons/girassol/attach-money'
import IconDashboard from '~icons/girassol/dashboard'
import IconPayments from '~icons/girassol/payments'
import IconUser from '~icons/material-symbols/shield-person-outline'

const props = defineProps<{
  isOpenSidebar: boolean
}>()
const router = useRouter()
const openSidebar = ref(props.isOpenSidebar)
const isActiveMenu = (routeMenu: string) => router.currentRoute.value.name === routeMenu

const product = ref({
  id: 'financing',
  icon: IconMoney,
  title: 'Financiamento',
  action: () => router.push('/'),
}) as Ref<Product>

const items = computed<Item[]>(() => [
  {
    icon: IconDashboard,
    name: 'Início',
    isActive: isActiveMenu('index'),
    action: () => router.push('/'),
  },
  {
    icon: IconPayments,
    name: 'Extrato Solfácil',
    isActive: isActiveMenu('bank-statement'),
    action: () => router.push('/bank-statement'),
  },
  {
    icon: IconUser,
    name: 'Gestão de usuários',
    isActive: false,
    action: () => window.open('https://fintech.solfacil.com.br/usuarios'),
  },
])

watch(() => props.isOpenSidebar, () => openSidebar.value = props.isOpenSidebar)
</script>

<template>
  <div>
    <SolSidebar
      :items="items"
      :product="product"
      :is-open-mobile-sidebar="openSidebar"
      @click-menu="openSidebar = !openSidebar"
    />
  </div>
</template>
