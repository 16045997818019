import type { SimulatorService } from './services/simulator-v2/simulator.types'
import type { UseBankStatementParams } from './store-v2/bank-statement/useBankStatement'
import type { UseFinancingsParams } from './store-v2/financings/useFinancings'
import type { UsePartnersParams } from './store-v2/partners/usePartners'
import { QueryClient } from '@tanstack/vue-query'
import { filterAndFormatParams } from './utils/objects'

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 2,
      retryDelay: 1000,
      staleTime: 1000 * 60 * 3, // 3 minutes
      gcTime: 1000 * 60 * 5, // 5 minutes
    },
  },
})

export const queryKeys = {
  AUTH: {
    LOGIN: ['AUTH', 'LOGIN'],
    LOGOUT: ['AUTH', 'LOGOUT'],
    STATUS: ['AUTH', 'STATUS'],
  },
  SF_PLUS: {
    STATUS: ['SF_PLUS', 'STATUS'],
  },
  FINANCING: {
    DETAILS: (id: string) => ['FINANCING', id],
    LIST: (params: UseFinancingsParams) => ['FINANCING', 'LIST', ...filterAndFormatParams(params)],
    STEPS_STATUS: (id: string) => ['STEPS_STATUS', id],
  },
  PARTNERS: {
    LIST: (params: UsePartnersParams) => ['PARTNERS', 'LIST', ...filterAndFormatParams(params)],
  },
  BANK_STATEMENT: {
    LIST: (params: UseBankStatementParams) => ['BANK_STATEMENT', 'LIST', ...filterAndFormatParams(params)],
  },
  ADDRESS: {
    BY_CEP: (cep: Ref<string | undefined>) => ['ADDRESS', 'BY_CEP', cep.value],
  },
  CONTRACT_BIOMETRY: {
    DATA: (projectId: string) => ['CONTRACT_BIOMETRY', 'DATA', projectId],
    SEND: ['CONTRACT_BIOMETRY', 'SEND'],
    RESEND: ['CONTRACT_BIOMETRY', 'RESEND'],
    CANCEL: ['CONTRACT_BIOMETRY', 'CANCEL'],
  },
  REGISTRATION: {
    DATA: (projectId: string) => ['REGISTRATION', 'DATA', projectId],
    COMPANY: (id: string) => ['REGISTRATION', 'COMPANY', id],
    CUSTOMER: (id: string) => ['REGISTRATION', 'CUSTOMER', id],
    UPDATE: {
      FILE_UPLOAD: (projectId: string) => ['REGISTRATION', 'UPDATE', 'FILE_UPLOAD', projectId],
      DATA: (projectId: string) => ['REGISTRATION', 'UPDATE', 'DATA', projectId],
    },
    REGISTRATION_CONTACT_CHANGE: ['REGISTRATION', 'REGISTRATION_CONTACT_CHANGE'],
    FORM_ANALYSIS_CANCEL: [
      'REGISTRATION',
      'FORM_ANALYSIS_CANCEL',
    ],
  },
  // projects
  PROJECTS: (params: SimulatorService.ProjectsRequest) => ['PROJECTS', ...filterAndFormatParams(params)],
  PROJECT: {
    LIST: (params: SimulatorService.ProjectsRequest) => ['PROJECT', 'LIST', ...filterAndFormatParams(params)],
    DETAILS: (id: string) => ['PROJECT', 'DETAILS', id],
    PROPOSAL_CHANGES: (id: string) => ['PROJECT', 'PROPOSAL_CHANGES', id],
    PROPOSAL_UPDATE: (id: string) => ['PROJECT', 'PROPOSAL_UPDATE', id],
    CONTRACT_SIGNED: (id: string) => ['PROJECT', 'CONTRACT_SIGNED', id],
  },
  OPPORTUNITIES: (params: SimulatorService.GetOpportunitiesRequest) => ['OPPORTUNITIES', ...filterAndFormatParams(params)],

  ANALYSIS_BILL: ['ANALYSIS_BILL'],
  FINANCING_TYPES: ['FINANCING_TYPES'],

  BNPL_SUMMARY: (id: string) => ['BNPL_SUMMARY', id],
  BNPL_INSTALLMENTS_MUTATION: (projectID: string) => ['BNPL_INSTALLMENTS', projectID],
  BNPL_INSTALLMENT: (id: string) => ['BNPL_INSTALLMENT', id],

  LEGACY_FINANCING: (projectId: string) => ['LEGACY_FINANCING', projectId],

  INSTALLATIONS: {
    DETAILS: (projectId: Ref<string>) => ['INSTALLATIONS', 'DETAILS', projectId.value],
    SAVE_HARDWARE: (projectId: Ref<string>) => ['INSTALLATIONS', 'SAVE_HARDWARE', projectId.value],
    COMBOS_HARDWARE: (projectId: Ref<string>) => ['INSTALLATIONS', 'COMBOS_HARDWARE', projectId.value],
    UPLOAD_FILE: ['INSTALLATIONS', 'UPLOAD_FILE'],
  },

  HARDWARE: {
    FORM: (projectId: string) => ['HARDWARE', 'FORM', projectId],
    DRAFT: ['HARDWARE', 'DRAFT'],
    VALIDATION: ['HARDWARE', 'VALIDATION'],
    MIRROR_STATUS: (projectId: string, skip: boolean) => ['HARDWARE', 'MIRROR_STATUS', projectId, skip],
    MIRROR_UPLOAD: ['HARDWARE', 'MIRROR_UPLOAD'],
    SAVE_MIRROR: (projectId: string) => ['HARDWARE', 'SAVE_MIRROR', projectId],
  },

  SIMULATION: {
    GET_SHOPPING_CART_ID: (projectId: string) => ['SIMULATION', 'GET_SHOPPING_CART_ID', projectId],
    PUT_SHOPPING_CART_ID: ['SIMULATION', 'PUT_SHOPPING_CART_ID'],
    PDF_DOWNLOAD: (projectId: string) => ['SIMULATION', 'PDF_DOWNLOAD', projectId],
  },
  PAYMENT_MODEL: {
    DATA: (projectId: string) => ['PAYMENT_MODEL', 'DATA', projectId],
    SUPPLIERS: ['PAYMENT_MODEL', 'SUPPLIERS'],
    UPLOAD_FILE: ['PAYMENT_MODEL', 'UPLOAD_FILE'],
    DELETE_FILE: ['PAYMENT_MODEL', 'DELETE_FILE'],
    SEND_BILLING_LETTER: ['PAYMENT_MODEL', 'SEND_BILLING_LETTER'],
    UPDATE: ['PAYMENT_MODEL', 'UPDATE'],
  },
}

export default queryClient
