<!-- eslint-disable unused-imports/no-unused-vars -->
<script setup lang="ts">
import { SolEmptyState } from '@solfacil/girassol'
import env from '~/env' // <-- new import
import Solfacil from '~/pages/company/solfacil/index.vue'
import useFinancing from '~/store-v2/financings/useFinancing'
import useHardwareForm from '~/store-v2/hardware/useHardwareForm'
import useProject from '~/store-v2/projects/useProject'
import useRegistration from '~/store-v2/registration/useRegistration'
import SimulationResumeWidget from '~/ui-blocks/SimulationResumeWidget/SimulationResumeWidget.vue'
import { getErrorMessage } from '~/utils/errors'

// base hooks
const { track } = useMixpanel()
const route = useRoute()
const router = useRouter()

// initial variables and computed
const projectId = computed(() => route.params.id as string)

// domain hooks
const {
  data: project,
  isLoading: isLoadingProject,
  error: errorProject,
  isError: isErrorProject,
  refetch: refetchProject,
} = useProject(projectId)

const {
  data: financing,
  isLoading: isLoadingFinancing,
  isError: isErrorFinancing,
  error: errorFinancing,
  refetch: refetchFinancing,
} = useFinancing(projectId)

const {
  data: companyRegistration,
  isError: isErrorCompanyRegistration,
  isLoading: isLoadingCompanyRegistration,
  refetch: refetchCompanyRegistration,
  error: errorCompanyRegistration,
} = useRegistration(projectId)

const {
  data: hardwareForm,
  refetch: refetchHardwareForm,
  isLoading: isLoadingHardwareForm,
  error: errorHardwareForm,
} = useHardwareForm(projectId)

// functions
async function refetchAll() {
  await refetchCompanyRegistration()
  await refetchHardwareForm()
  await refetchProject()
  await refetchFinancing()
}

function navigateToLegacyProject(id: string | number) {
  window.location.href = `${env.VITE_URL_LEGACY}/financiamento/etapas/${id}`
}

function navigateToLegacyProjectList() {
  window.location.href = `${env.VITE_URL_LEGACY}/financiamentos`
}

// And add the following watch:
watch([project, financing], ([newProjectState, newFinancingState]) => {
  if (newProjectState && newFinancingState && newProjectState?.new_journey === false) {
    setTimeout(() => {
      if (newFinancingState.legacy_id) {
        navigateToLegacyProject(newFinancingState?.legacy_id)
      }
      else {
        navigateToLegacyProjectList()
      }
    }, 2000)
  }
}, { immediate: true })

watch(companyRegistration, (newState) => {
  if (newState?.person_type === 'pf') {
    router.push(`/customer/${projectId.value}`)
  }
}, { immediate: true })

// derived states
const registerProvider = computed(() => 'solfacil')
const isOnflexPartner = computed(() => project?.value?.is_onflex_partner || false)
const hasEnergyBillAnalysis = computed(() => project?.value?.has_energy_bill_analysis || false)

const isLoading = computed(() => isLoadingCompanyRegistration.value || isLoadingProject.value || isLoadingHardwareForm.value)
const isError = computed(() => isErrorCompanyRegistration.value || isErrorProject.value)

const error = computed(() => getErrorMessage([errorHardwareForm.value, errorProject.value, errorCompanyRegistration.value]))

// lifecycle
onMounted(async () => {
  track('customer_data_page_view', { trigger: 'Ver a página Dados do cliente' })
})
</script>

<template>
  <!-- loading state -->
  <div v-if="isLoading" class="container">
    <div class="my-4 card-container flex flex-col justify-between w-full">
      <span class="loader-project" />
    </div>
  </div>

  <div v-else>
    <!-- error state -->
    <template v-if="isError">
      <SharedErrorsCheckAccess :error-message="error" />
    </template>

    <!-- success state -->
    <template v-else>
      <!-- legacy project -->
      <div v-if="project?.new_journey === false" class="justify-center items-center flex flex-col bg-fff text-center h-[80vh]">
        <SolEmptyState
          id="legacy-project-lock"
          title="Todos os projetos iniciados na antiga plataforma serão concluídos na mesma. "
          subtitle="Aguarde enquanto você está sendo redirecionado para antiga plataforma. Caso não seja redirecionado automaticamente, clique no botão abaixo."
          variant="empty-list"
          class="!max-w-full !md:system:max-w-[750px]"
        />
        <SolButton id="redirect" class="mt-4" @click="() => navigateToLegacyProject(financing?.legacy_id!)">
          Seguir para antiga plataforma
        </SolButton>
      </div>
      <template v-else>
        <div v-if="companyRegistration && registerProvider && hardwareForm">
          <Solfacil
            :registration="companyRegistration"
            :company-form="companyRegistration"
            :project-name="project?.project_name || ''"
            :provider="registerProvider"
            status="aproved"
            :trigger-refetch="refetchAll"
            :is-onflex-partner="isOnflexPartner"
            :has-energy-bill-analysis="hasEnergyBillAnalysis"
            :hardware-form="{ response: hardwareForm, refetch: refetchHardwareForm }"
          />
        </div>
      </template>
    </template>
  </div>

  <!-- Floating Widget -->
  <SimulationResumeWidget />
</template>

<style lang="scss">
.container {
  @apply flex flex-col items-center px-4 pb-6 mt-6;

  @screen md:system {
    @apply p-12 mt-0;
  }
}

.loader-project {
  @apply h-96 rounded-lg;
  @apply top-0 right-0 bottom-0 left-0 z-50;
  background: rgba(#fff, 0.9) url(https://cdn.solfacil.com.br/assets/img/loading-for-legacy.gif) no-repeat center center;
}
</style>

<route lang="yaml">
meta:
  layout: client-data
</route>
