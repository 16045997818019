import type { Ref } from 'vue'
import { useQuery } from '@tanstack/vue-query'
import { queryKeys } from '~/queryClient'
import { getCustomerBiometry } from '~/services-v2/contract-formalization-api/contract-formalization-api'

export default function useCustomerBiometry(projectId: Ref<string>, enabled: Ref<boolean>) {
  const queryKey = computed(() => queryKeys.CONTRACT_BIOMETRY.DATA(projectId.value))

  const queryFn = async () => {
    return getCustomerBiometry(projectId.value)
  }

  return useQuery({
    queryFn,
    queryKey: queryKey.value,
    retry: 3,
    retryDelay: 1000,
    staleTime: 10 * 1000, // 10 seconds
    refetchInterval: 10 * 1000, // 10 seconds
    enabled,
  })
}

export type UseCustomerBiometryData = ReturnType<typeof useCustomerBiometry>['data']['value']
