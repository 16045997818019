// Note: Constants for the application

export const ACCESS_TOKEN_STORAGE_KEY = 'accessToken'
export const REFRESH_TOKEN_STORAGE_KEY = 'refreshToken'
export const HTTP_STATUS_BAD_REQUEST = 400
export const UPLOADED_FILES_KEY = 'uploaded_files'

// i18n
export const I18N_SINGULAR = 1
export const I18N_PLURAL = 0
