import { useMutation } from '@tanstack/vue-query'
import { queryKeys } from '~/queryClient'
import { saveMirror } from '~/services-v2/installation-api/installation-api'

export function usePostMirror(projectId: string) {
  const mutationFn = async (mirror: File) => {
    return await saveMirror({ projectId, mirror })
  }

  return useMutation({
    mutationFn,
    mutationKey: queryKeys.HARDWARE.SAVE_MIRROR(projectId),
    retry: 2,
    retryDelay: 1000,
  })
}
