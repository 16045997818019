<script setup lang="ts">
import IconAddPerson from '~icons/material-symbols/person-add-outline'
import { useCpfValidation } from '~/composables/simulation/useCpfValidation'
import { useIncludeGuarantor } from '~/composables/simulation/useIncludeGuarantor'

const props = defineProps<{
  refetchProject: () => void
  setFalseLoadingGuarantor: () => void
}>()
const emit = defineEmits<{
  (event: 'update:is-loading', value: boolean): void
}>()
const { t } = useI18n()
const { value: cpfModel, errorMessage, setErrors, resetField, validate } = useCpfValidation(t)
const { includeGuarantor, isPending } = useIncludeGuarantor(props.refetchProject, props.setFalseLoadingGuarantor, emit)
</script>

<template>
  <div class="flex gap-4">
    <SolInputText
      id="include-guarantor"
      v-model="cpfModel"
      name="cpfGuarantor"
      class="w-full"
      :mask="{ preset: 'CPF' }"
      :placeholder="t('form.guarantor.placeholder')"
      :disabled="isPending"
      autocomplete="off"
      :error="errorMessage"
    />
    <SolButton
      id="customer_confirm"
      class="!w-[302px] lg:system:!w-[250px] box-border !py-4 !px-6 flex !h-[48px]"
      :loading="isPending"
      size="large"
      :disabled="isPending"
      @click="includeGuarantor(cpfModel, resetField, setErrors, validate)"
    >
      <div class="flex items-center gap-2">
        <IconAddPerson class="h-5 w-[21px]" />
        <span class="fonts-body-medium-bold">{{ t('form.guarantor.button') }}</span>
      </div>
    </SolButton>
  </div>
</template>
