import { useQuery } from '@tanstack/vue-query'
import { queryKeys } from '~/queryClient'
import financialBff from '~/services-v2/financial-bff/financial-bff'

export default function useGetShoppingCartId(projectId: string) {
  const queryKey = queryKeys.SIMULATION.GET_SHOPPING_CART_ID(projectId)

  const queryFn = async () => {
    const response = await financialBff('query')({
      simulation_project_shopping_cart_id_get: [
        { projectId },
        true,
      ],
    })

    return response.simulation_project_shopping_cart_id_get
  }

  return useQuery({
    queryKey,
    queryFn,
    staleTime: 1000 * 60 * 5,
  })
}
