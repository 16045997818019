<script setup lang="ts">
import type { Form } from './Invoice.schema'
import type { GeneralStatuses } from '~/utils/financing-flow/Financing'
import type { Invoice, InvoicesKindEnum, ReceiptModel, ResponseUpload } from '~/utils/receipt-model/ReceiptModel'
import { useToast } from '@solfacil/girassol'
import IconCheck from '~icons/material-symbols/check-circle-outline'
import usePaymentModelDeleteFile from '~/store-v2/payment-model/usePaymentModelDeleteFile'
import usePaymentModelUploadFile from '~/store-v2/payment-model/usePaymentModelUploadFile'
import download from '~/utils/download'

interface Options {
  hasFinancingCommissionToReceive: boolean
  isSeparateInvoices: boolean
  isSingleInvoice: boolean
  hasCorrectionLetter: boolean
}

// props
const props = defineProps<{
  form: Form
  data: ReceiptModel
  invoiceSelected: string
  combo: boolean
  options: Options
  disableField: boolean
  paymentStatusFlow: GeneralStatuses
  equipmentSelected: string | null
}>()

// emits
const emit = defineEmits(['updateErrors'])
// initial base hooks
const { t } = useI18n()
const router = useRouter()
const { createErrorToast } = useToast()

// initial variables and computed
const showModalToPreviewFile = ref(false)
const documentUrl = ref('')
const mimeTypeFile = ref('')
const fileName = ref('')

const loadingStates = ref({
  commission: false,
  products: false,
  service_product: false,
  correction_letter: false,
})

type Documents = Record<InvoicesKindEnum, ResponseUpload | undefined>

const currentDocuments = reactive<Documents>({
  commission: undefined,
  products: undefined,
  service_product: undefined,
  correction_letter: undefined,
})

const showCorrectionField = computed(() => {
  if (props.options.hasCorrectionLetter || currentDocuments.correction_letter)
    return true

  return false
})

// fields
const [productsModel, productsProps] = props.form.defineField('products')
const [serviceProductModel, serviceProductProps] = props.form.defineField('service_product')
const [commissionModel, commissionProps] = props.form.defineField('commission')
const [correctionLetterModel, correctionLetterProps] = props.form.defineField('correction_letter')

function transformDocumentResponse(document: Invoice | undefined) {
  if (!document)
    return

  return {
    invoice_id: document.invoice_id,
    filename: document.document_filename || '',
    mimetype: document.document_mimetype || '',
    url: document.document_url || '',
    url_inline: document.document_url_inline || '',
    status: document.invoice_status,
    reason: document.invoice_reason,
  }
}

// function
function createFormData(documents, type: InvoicesKindEnum) {
  const formData = new FormData()
  formData.append('project_id', String(router.currentRoute.value.params.id))
  formData.append('kind', type)
  formData.append('file', documents.files[0], documents.files[0].name)
  return formData
}

const {
  mutateAsync: mutatePaymentModelUploadFile,
} = usePaymentModelUploadFile()

const { mutateAsync: mutatePaymentModelDeleteFile } = usePaymentModelDeleteFile()

async function uploadInvoice(event: any, type: InvoicesKindEnum) {
  loadingStates.value[type] = true

  const formData = createFormData(event.target, type)

  try {
    const response = await mutatePaymentModelUploadFile(formData)
    if (response)
      currentDocuments[type] = response as unknown as ResponseUpload
  }
  catch (error) {
    console.error(error)
    createErrorToast(t('receiptModel.alert.upload_not_sent'))
  }
  finally {
    loadingStates.value[type] = false
  }
}

async function deleteByResponse(document: ResponseUpload, invoice: InvoicesKindEnum) {
  loadingStates.value[invoice] = true
  try {
    const response = await mutatePaymentModelDeleteFile(document.invoice_id)
    if (response) {
      currentDocuments[invoice] = undefined
      props.form.setFieldValue(invoice as InvoicesKindEnum, null)
    }
  }
  catch (error) {
    console.error(error)
    createErrorToast('Arquivo não deletado, tente novamente!')
  }
  finally {
    loadingStates.value[invoice] = false
  }
}

function previewFile(document: Invoice | string) {
  if (typeof document === 'string') {
    documentUrl.value = document
    mimeTypeFile.value = 'application/pdf'
    fileName.value = ''
  }
  else {
    documentUrl.value = (document.document_mimetype === 'application/pdf' ? document.document_url_inline : document.document_url) || ''
    mimeTypeFile.value = document.document_mimetype || ''
    fileName.value = document.document_filename || ''
  }

  showModalToPreviewFile.value = true
}

async function handleDownloadUploadedFile(document: ResponseUpload | undefined) {
  if (!document) {
    return
  }

  download.downloadFile(document.url)
}

function getReasonDeniedErrorMessage(document: ResponseUpload) {
  if (document?.status === 'denied') {
    return document.reason || t('defaultDenialReason')
  }
  return ''
}

// watchers
watch(() => props.data, (_paymentModel) => {
  const { status, invoice } = _paymentModel
  const commission = invoice?.find(invoice => invoice.invoice_kind === 'commission')
  const products = invoice?.find(invoice => invoice.invoice_kind === 'products')
  const service_product = invoice?.find(invoice => invoice.invoice_kind === 'service_product')
  const correction_letter = invoice?.find(invoice => invoice.invoice_kind === 'correction_letter')
  if (invoice) {
    if (commission) {
      props.form.setFieldValue('commission', commission.document_filename, false)
      currentDocuments.commission = transformDocumentResponse(commission)
    }
    if (products) {
      props.form.setFieldValue('products', products.document_filename, false)
      currentDocuments.products = transformDocumentResponse(products)
    }
    if (service_product) {
      props.form.setFieldValue('service_product', service_product.document_filename, false)
      currentDocuments.service_product = transformDocumentResponse(service_product)
    }
    if (correction_letter) {
      props.form.setFieldValue('correction_letter', correction_letter.document_filename, false)
      currentDocuments.correction_letter = transformDocumentResponse(correction_letter)
    }
  }

  if (status === 'to_review' && !currentDocuments.correction_letter) {
    props.form.setFieldError('correction_letter', 'Adicione a carta de correção para para ajustar seu projeto')
    props.form.setFieldTouched('correction_letter', true)
  }
}, { immediate: true })

watch(() => props.form.errors.value, (newErrors) => {
  emit('updateErrors', newErrors)
})

const invoiceActionsMenu = computed(() => {
  const invoice = props.data.invoice || []
  const defaultActions = ['download', 'preview']

  const invoiceMap = {
    products: [...defaultActions],
    service_product: [...defaultActions],
    commission: [...defaultActions],
    correction_letter: [...defaultActions],
  }

  const shouldAllowDelete = ['to_review', 'in_progress'].includes(props.paymentStatusFlow)

  invoice.forEach(({ invoice_kind, invoice_status }) => {
    if (shouldAllowDelete && invoice_status === 'denied' && invoice_kind in invoiceMap) {
      invoiceMap[invoice_kind] = ['download', 'preview', 'delete']
    }
  })

  return invoiceMap
})
</script>

<template>
  <div class="flex flex-col items-start gap-1 mt-4 mb-4xs">
    <span class="text-neutral-low-dark fonts-heading-h4 text-[20px] md:system:text-[24px]">
      {{ t('customer.invoice',
           { count: options.hasFinancingCommissionToReceive && options.isSeparateInvoices ? 1 : 0 })
      }}
    </span>
    <div class="arrow-text">
      <span class="fonts-body-small-regular text-brand-primary-light">
        {{ t('receipt-model.invoice.description-invoices-files') }}
      </span>
    </div>
    <div class="arrow-text">
      <span class="fonts-body-small-regular text-brand-primary-light">
        {{ t('receipt-model.invoice.description-days') }}
      </span>
    </div>
  </div>
  <div>
    <form class="grid grid-cols-1 md:site:grid-cols-2 gap-4 md:site:gap-8">
      <div v-if="options.isSeparateInvoices">
        <SolFileUpload
          v-if="!currentDocuments.products"
          id="products"
          v-model="productsModel"
          name="products"
          v-bind="productsProps"
          accept=".pdf"
          :multiple="false"
          :loading="loadingStates.products"
          :placeholder="t('formDocument.placeholder')"
          :label="t('form.hardware_invoice')"
          :disabled="disableField"
          :error="form.errors.value.products"
          :class="[{ 'pointer-events-none opacity-60': disableField }]"
          @change="uploadInvoice($event, 'products')"
        />

        <div v-else>
          <SolFileUpload
            id="products"
            name="products"
            :file-name="currentDocuments.products?.filename"
            :download-src="currentDocuments.products?.url"
            :download-menu="invoiceActionsMenu.products"
            accept=".pdf"
            :multiple="false"
            :loading="loadingStates.products"
            :placeholder="t('formDocument.placeholder')"
            :label="t('form.hardware_invoice')"
            :class="[
              { 'error-upload': currentDocuments.products.status === 'denied' },
            ]"
            :error="getReasonDeniedErrorMessage(currentDocuments.products)"
            @menu:download="() => handleDownloadUploadedFile(currentDocuments?.products)"
            @menu:delete="deleteByResponse(currentDocuments.products, 'products')"
            @menu:preview="previewFile(currentDocuments.products?.url_inline)"
            @change="uploadInvoice($event, 'products')"
          />
          <div v-if="currentDocuments.products.status === 'approved'" class="flex items-center gap-1 mt-1">
            <span class="text-feedback-positive-pure text-micro">{{ t('form.payment_model.invoice_approved') }}</span>
            <IconCheck class="text-feedback-positive-pure" />
          </div>
        </div>
      </div>

      <div v-if="options.isSingleInvoice || equipmentSelected === 'container'">
        <SolFileUpload
          v-if="!currentDocuments.service_product"
          id="service_product"
          v-model="serviceProductModel"
          name="service_product"
          v-bind="serviceProductProps"
          accept=".pdf"
          :loading="loadingStates.service_product"
          :multiple="false"
          :placeholder="t('formDocument.placeholder')"
          :label="t('form.service_invoice')"
          :disabled="disableField"
          :class="[{ 'pointer-events-none opacity-60': disableField }]"
          :error="form.errors.value.service_product"
          @change="uploadInvoice($event, 'service_product')"
        />

        <div v-else>
          <SolFileUpload
            id="service_product"
            name="service_product"
            :file-name="currentDocuments.service_product?.filename"
            :download-src="currentDocuments.service_product?.url"
            :download-menu="invoiceActionsMenu.service_product"
            accept=".pdf"
            :multiple="false"
            :loading="loadingStates.service_product"
            :placeholder="t('formDocument.placeholder')"
            :label="t('form.service_invoice')"
            :class="[
              { 'error-upload': currentDocuments.service_product.status === 'denied' },
            ]"
            :error="getReasonDeniedErrorMessage(currentDocuments.service_product)"
            @menu:download="() => handleDownloadUploadedFile(currentDocuments?.service_product)"
            @menu:delete="deleteByResponse(currentDocuments.service_product, 'service_product')"
            @menu:preview="previewFile(currentDocuments.service_product?.url_inline)"
            @change="uploadInvoice($event, 'service_product')"
          />
          <div v-if="currentDocuments.service_product.status === 'approved'" class="flex items-center gap-1 mt-1">
            <span class="text-feedback-positive-pure text-micro">{{ t('form.payment_model.invoice_approved') }}</span>
            <IconCheck class="text-feedback-positive-pure" />
          </div>
        </div>
      </div>

      <div v-if="options.hasFinancingCommissionToReceive">
        <SolFileUpload
          v-if="!currentDocuments.commission"
          id="commission"
          v-model="commissionModel"
          name="commission"
          v-bind="commissionProps"
          accept=".pdf"
          :loading="loadingStates.commission"
          :multiple="false"
          :placeholder="t('formDocument.placeholder')"
          :label="t('form.commission_invoice')"
          :disabled="disableField"
          :class="[{ 'pointer-events-none opacity-60': disableField }]"
          :error="form.errors.value.commission"
          @change="uploadInvoice($event, 'commission')"
        />

        <div v-else>
          <SolFileUpload
            id="commission"
            name="commission"
            :file-name="currentDocuments.commission?.filename"
            :download-src="currentDocuments.commission?.url"
            :download-menu="invoiceActionsMenu.commission"
            accept=".pdf"
            :loading="loadingStates.commission"
            :multiple="false"
            :placeholder="t('formDocument.placeholder')"
            :label="t('form.commission_invoice')"
            :class="[
              { 'error-upload': currentDocuments.commission.status === 'denied' },
            ]"
            :error="getReasonDeniedErrorMessage(currentDocuments.commission)"
            @menu:download="() => handleDownloadUploadedFile(currentDocuments?.commission)"
            @menu:delete="deleteByResponse(currentDocuments.commission, 'commission')"
            @menu:preview="previewFile(currentDocuments.commission?.url_inline)"
            @change="uploadInvoice($event, 'commission')"
          />
          <div v-if="currentDocuments.commission.status === 'approved'" class="flex items-center gap-1 mt-1">
            <span class="text-feedback-positive-pure text-micro">{{ t('form.payment_model.invoice_approved') }}</span>
            <IconCheck class="text-feedback-positive-pure" />
          </div>
        </div>
      </div>

      <div v-show="showCorrectionField">
        <SolFileUpload
          v-if="!currentDocuments.correction_letter"
          id="correction_letter"
          v-model="correctionLetterModel"
          name="correction_letter"
          v-bind="correctionLetterProps"
          accept=".pdf"
          :loading="loadingStates.correction_letter"
          :multiple="false"
          :helper-text="t('form.correnction_letter.helper_text')"
          :placeholder="t('formDocument.placeholder')"
          :label="t('form.correction_letter')"
          :disabled="disableField"
          :class="[{ 'pointer-events-none opacity-60': disableField }]"
          :error="form.errors.value.correction_letter"
          @change="uploadInvoice($event, 'correction_letter')"
        />

        <div v-else>
          <SolFileUpload
            id="correction_letter"
            name="correction_letter"
            :file-name="currentDocuments?.correction_letter?.filename"
            :download-src="currentDocuments?.correction_letter?.url"
            :download-menu="invoiceActionsMenu.correction_letter"
            accept=".pdf"
            :loading="loadingStates.correction_letter"
            :multiple="false"
            :helper-text="t('form.correnction_letter.helper_text')"
            :placeholder="t('formDocument.placeholder')"
            :label="t('form.correction_letter')"
            :class="[
              { 'error-upload': currentDocuments.correction_letter.status === 'denied' },
            ]"
            :error="getReasonDeniedErrorMessage(currentDocuments.correction_letter)"
            @menu:download="() => handleDownloadUploadedFile(currentDocuments?.correction_letter)"
            @menu:delete="deleteByResponse(currentDocuments.correction_letter, 'correction_letter')"
            @menu:preview="previewFile(currentDocuments.correction_letter?.url_inline)"
            @change="uploadInvoice($event, 'correction_letter')"
          />
          <div v-if="currentDocuments.correction_letter.status === 'approved'" class="flex items-center gap-1 mt-1">
            <span class="text-feedback-positive-pure text-micro">{{ t('form.payment_model.correction_letter_approved') }}</span>
            <IconCheck class="text-feedback-positive-pure" />
          </div>
        </div>
      </div>
    </form>
  </div>

  <SolModal
    id="modal-preview-file"
    :is-open="showModalToPreviewFile"
    title=""
    :size="{ desktop: 'extra-large', mobile: 'full' }"
    @close="showModalToPreviewFile = false"
  >
    <div v-if="mimeTypeFile === 'application/pdf'">
      <iframe
        :src="documentUrl"
        width="100%"
        height="500px"
        :type="mimeTypeFile"
        frameborder="0"
      />
    </div>
    <div
      v-else
      class="flex justify-center w-full"
    >
      <img :src="documentUrl" :alt="fileName">
    </div>
  </SolModal>
</template>

<style lang="scss" scoped>
.receipt-model .method_receipt {
  @apply my-2xs p-4xs;
  @apply border-a-sm border-neutral-low-light rounded-md;

  md:system {
    @apply my-xs p-2xs;
  }
}

.error-upload {
  :deep(.sol-download) {
    @apply border-1 border-feedback-negative-pure;
  }
}

.arrow-text::before {
  @apply text-brand-primary-light fonts-body-small-regular text-[14px];
  content: '➔';
}
</style>
