import type { StatusMap } from './financial-bff.types'

export const STATUSES_MAPPING: StatusMap = {
  in_progress: {
    textKey: 'global.statuses.in_progress',
    color: 'warning',
  },
  under_analysis: {
    textKey: 'global.statuses.under_analysis',
    color: 'informative',
  },
  approved: {
    textKey: 'global.statuses.approved',
    color: 'positive',
  },
  to_review: {
    textKey: 'global.statuses.to_review',
    color: 'warning',
  },
  waiting_customer: {
    textKey: 'global.statuses.waiting_customer',
    color: 'warning',
  },
  reproved: {
    textKey: 'global.statuses.reproved',
    color: 'negative',
  },
  expired: {
    textKey: 'global.statuses.expired',
    color: 'neutral',
  },
  canceled: {
    textKey: 'global.statuses.canceled',
    color: 'negative',
  },
}
