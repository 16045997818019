app:
  name: Financiamentos
  next: Próximo
  advance: Avançar
  advance_and_update: Atualizar e avançar
  share: Compartilhar
  whatsapp: WhatsApp
  back: Voltar
  send: Enviar
  save: Salvar
  cancel: Cancelar
  cancel_analysis: Cancelar análise
  alert:
    partner:
      title: 'Visualizando projetos como: | Visualizando projeto como: '
      statement_title: 'Visualizando extrato como:'
      description: >-
        Aqui você encontra os projetos do parceiro acima. Para ver todos os
        projetos clique no botão ao lado. | Para voltar a visualização de admin,
        clique no botão ao lado.
      statement_description: Aqui você encontra as últimas transferências do parceiro acima.
      textButton: Visualização de Admin
resumeWidget:
  simulationSummary: Resumo da simulação
  summary: Resumo
  close: Fechar
  gracePeriod: Carência
  insurance: Seguros
  combo: Combo Fácil
  ampera: Ampera
  month: Mês | Meses
  financedValue: Valor Financiado
  downPayment: Entrada
  totalProjectValue: Valor total do projeto
  cet: CET
  interestRate: Taxa de juros
  installment: Parcela
  financing: Financiamento
  interestRateTooltip: |
    A taxa de juros pode sofrer
    alterações de acordo com as
    condições selecionadas
    na proposta
  cetTooltip: |
    O Custo Efetivo Total (CET) pode
    sofrer alterações de acordo
    com o IOF diário
  opportunities:
    title: Seus ganhos
    commission: Comissão
    insuranceCommission: Comissão do Seguro
  editSimulation: Editar Simulação
  viewSimulation: Visualizar Simulação
header:
  welcome: Bem-vindo
  exit: Sair
  project:
    title: Financiamento
    admin: Administrador
    int_light: Integrador Light
    expired: Expirado
    steps:
      new: Nova simulação
      result: Resultado
      details: Parcelas
      simulation: Simulação
      formalization: Proposta
      installation: Instalação
      contraction: Contratação
  bnpl:
    steps:
      simulation: Simulação
      formalization: Proposta
      external: Externo
    status:
      IN_PROGRESS:
        type: warning
        text: Em andamento
      EXPIRED:
        type: neutral
        text: Expirado
      DONE:
        type: positive
        text: Concluído
  impersonate:
    modal:
      title: Visualizar como parceiro
      subtitle: Busque pelo Nome ou CNPJ do parceiro que deseja visualizar
      input_label: Nome do parceiro ou CNPJ
      button: Visualizar
      empty_state:
        title: Nenhum parceiro encontrado
        description: >-
          Não foi possível encontrar o parceiro que você está buscando.
          Verifique se o nome ou CNPJ está correto e tente novamente.
    error:
      title: Erro ao visualizar como parceiro
      description: >-
        Não foi possível visualizar como parceiro. Verifique se o parceiro foi
        selecionado e tente novamente.
home:
  title: Início
  subtitle: Todos os seus projetos em um só lugar
  newSimulation: Nova simulação
  banner:
    title: Nova plataforma de formalização!
    subtitle: Após concluir a simulação, formalize mais rápido nessa nova experiência
simulation:
  next: Ver parcelas
  load_again: Carregar novamente
  founded: Simulações encontradas
  golist: Ir para a lista
  list: Simulações
  subtitles: Acesse suas simulações salvas
  new: Simulação
  return_formalization: Ir para proposta
  project_type: Linhas de financiamento
  cpf_use_terms:
    client: Eu, o cliente e o devedor solidário, se aplicável, concordamos com os
    terms: Termos de Uso
    with: ', com o'
    terms_authorization: Termo de Autorização de Consulta ao SCR
    and_with: e com a
    privacy_policy: Política de Privacidade
    solfacil: >-
      da Solfácil. O Cliente e o devedor solidário, se aplicável, autorizam a
      consulta de seus dados pessoais junto aos Órgãos de Proteção ao Crédito.
  continue_with_value: Seguir com valor aprovado
  financing_options: Opções de financiamento
  details:
    info_title: Dados da simulação somente para visualização.
    info_description: Após a assinatura do contrato não será mais possível editar a simulação.
  conclude: Concluir simulação
  info_risk:
    RS_title: O cliente foi aprovado na simulação
    RS_description_conditions: 'Fique atento às condições:'
    RS_description_grace: Carência obrigatória de 6 meses.
    RS_description_eligible_area: >-
      Localização em área elegível. A Solfácil sabe o quanto é importante estar
      presente em todas as regiões e está comprometida a fazer isso de um jeito
      eficiente e responsável.
    necessary_install_pending_project_title: O cliente foi aprovado na simulação
    necessary_install_pending_project_description: >-
      Atenção! Projeto aprovado em crédito, mas para que possa formalizar esse
      novo projeto, é necessário a comprovação da instalação de outros projetos
      ja formalizados
  modal:
    title: Nova plataforma de proposta!
    altImg: Tela de financiamento
    info:
      proposal:
        title: Mande sua proposta em até 2 minutos!
        description: Preencha tudo de uma vez e aguarde nossa análise.
      projects:
        title: Faça seus projetos quando e onde quiser!
        description: 'Celular, computador ou tablet: você escolhe como e onde.'
      payments:
        title: Pagamentos em tempo real
        description: Após aprovação, pagamentos 24h por dia, 7 dias na semana.
    button:
      old: Seguir com a plataforma antiga
      new: Seguir com a nova plataforma!
  bannerComboFacil:
    title: Kit da Loja + Financiamento = Combo Fácil
    description: Condições e benefícios exclusivos para você e seu cliente
  result:
    data:
      title: Dados da simulação
      name: Nome do projeto
      type:
        cpf: CPF
        cnpj: CNPJ
      client: do cliente
      value: Valor total do projeto
      bill: Conta de luz
      systemPower: Potência do sistema (kWp)
      down_payment: Entrada
      system_type: Tipo sistema
    financing:
      title: Financiamento
      subtitle: Prazos e parcelamento flexíveis para o seu cliente
      pre_approved_value: Valor pré-aprovado
      max_installments: Prazo pré-aprovado
      to: até
      with_interest: Com juros
      max_grace_period: Carência pré-aprovada
      months: meses
      down_payment: Entrada (para você)
      project_value: Valor financiado solicitado
      kit: Aquisição Kit
      store: Loja Solfácil ou externo
    bnpl:
      banner:
        title: Conheça o | A nova opção de pagamento para seus projetos
        emphasis: Parcelado Solfácil
        description: >-
          Compre o Kit na Loja Solfácil, e receba seu serviço à vista. Seu
          cliente paga em até 9x, e em condição promocional de lançamento, em
          até 3x, não há taxas e tarifas
      title: Parcelado Solfácil
      subtitle: >-
        Adquira seu kit solar na Loja Solfácil, e ofereça essa nova condição
        como mais uma possibilidade de fechar negócios!
      subtitleTwo: >-
        Estamos trabalhando em uma nova opção de pagamento para você e seu
        cliente que em breve você vai poder contar!
      new: Novidade!
      will_be_pre_approved: Confira as condições que seriam pré-aprovadas
      pre_approved_value: Valor pré-aprovado
      approved_with_restrictions: Aprovado, com restrições
      max_installments: Parcelado em até
      to: até
      max_grace_period: Carência pré-aprovada
      without_interest: Sem juros
      without_period: Sem carência
      down_payment: Entrada (cliente para Solfácil)
      of: de
      project_value: Parcelas de até
      kit: Aquisição Kit
      store: Loja Solfácil
      customerRejected:
        title: Cliente reprovado
        description:
          title: Existem duas ações que você pode tomar
          actions:
            - >-
              1. Consulte os órgãos de proteção ao crédito e veja se esse CPF ou
              CNPJ está regular.
            - >-
              2. Consulte este CPF ou CNPJ novamente daqui 30 dias. Esse prazo é
              necessário porque pode ser que ocorram alterações nas situações
              dos mesmos.
        altImg: Imagem representando o cliente sendo reprovado
      customerRejectedGuarantor:
        title: Cliente reprovado
        description:
          title: Existem duas ações que você pode tomar
          actions:
            - >-
              1. Consulte os órgãos de proteção de crédito e veja se este CPF ou
              CNPJ está regular.
            - 2. Refaça a análise e adicione outro avalista.
      alerts:
        responsibility:
          title: Atenção!
          description: >-
            Sabe o cliente que, mesmo sem crédito, você parcela, assumindo o
            risco? Aqui é  semelhante, confira condições.
          action: Saiba mais
        restrictions:
          title: Cliente pré-aprovado com restrições
          description: >-
            Para esse cliente, temos duas opções para seguir com o
            financiamento. Selecione no quadro abaixo.
      shortly:
        title: Parcelado Solfácil
        flag: Em breve
        altImg: Calendário de Período de pagamento
        description: >-
          Em breve uma nova maneira de viabilizar seus projetos junto à Solfácil
          com o parcelado. Clique abaixo para registrar seu interesse e conheça
          às condições.
        textButton: Tenho interesse
      messages:
        noResults:
          title: Ops, algo deu errado
          description: >-
            Ocorreu algum problema e não conseguimos trazer o resultado da sua
            simulação. Tente novamente mais tarde
          textButton: Tente novamente
        analyzingSimulation:
          title: Analisando sua simulação
          description: >-
            Em breve vamos concluir a análise e você será notificado via e-mail.
            Você também pode acompanhar o status dela na listagem de simulações.
          textListButton: Ir para a lista
          textNewSimulationButton: Nova simulação
      modals:
        important:
          title: Importante!
          description: >-
            Você será responsabilizado caso o cliente fique inadimplente. A
            Solfácil poderá solicitar que você pague integralmente os valores do
            parcelamento, bem como poderá inserir os dados da sua empresa nos
            Órgãos de Proteção ao Crédito.
          textButton: Fechar
        attention:
          title: Atenção!
          description: >-
            Você será responsabilizado caso o cliente fique inadimplente. A
            Solfácil poderá solicitar que você pague integralmente os valores do
            parcelamento, bem como poderá inserir os dados da sua empresa nos
            Órgãos de Proteção ao Crédito.
          textCheckbox: Estou ciente
          textCancelButton: Cancelar
        interest:
          title: Agradecemos seu interesse!
          altImgOption1: Imagem representando as melhores condições
          option1: As melhores condições
          altImgOption2: Imagem representando parcelado Solfácil
          option2: De 3 à 9 vezes!
          altImgOption3: Imagem representando kits adquiridos pela Loja Solfácil
          option3: Kits adquiridos pela Loja Solfácil
          description: >-
            Parcelado Solfácil é a nova condição de pagamentos que a Solfácil
            oferece para você e seus clientes. Comprando o Kit na Loja Solfácil,
            você tem mais essa opção para fechar negócio!
          textButton: Fechar
      buttons:
        textFollowFinancing: Seguir financiamento
        textFollowInstallment: Seguir parcelamento
        textInterest: Tenho interesse
  bnpl:
    solfacilInstallment: Parcelado Solfácil
    identity: id
    installment_title: Parcela | Parcelas
    conditions_installment: >-
      Confira nossas opções a que melhor se encaixa no perfil do seu cliente. É
      possível simular o valor do kit do projeto, e defini-lo na etapa seguinte,
      de proposta.
    project_value: Valor do projeto
    due_date: Vencimento
    invoice_due_date: Vencimento da fatura
    kit_value: Valor do kit
    kit_value_helper: Atualizado após a seleção do kit
    number_order: N° do pedido
    service_value: Valor do serviço
    service_value_helper: Valor do projeto menos kit e taxa
    installment_amount: Valor da parcela
    initial_amount: Entrada
    receive_amount: Receberá
    installments:
      customer_pays:
        title: Parcela com o *serviço fixo*
        description: >-
          Você repassa o valor da taxa para seu cliente, aumentando o valor do
          projeto.
      customer_not_pays:
        title: Parcela com o *projeto fixo*
        description: Você arca com o valor da taxa, mantendo o valor do projeto.
    banner:
      title: Projetos parcelados
      description: >-
        Encontre seus projetos em andamento do nosso novo modelo de pagamento
        clicando no botão ao lado.
      tooltip: >-
        Agora além de financiamento, você pode ofertar ao seu cliente a nova
        linha "Parcelado Solfácil". Essa opção pode estar disponível na tela de
        "Resultado da simulação".
      button: Ver projetos
      black:
        title: Novo Crédito "Parcelado Solfácil"
        description: Até 9 parcelas, com condições exclusivas!
        textButton: Saiba mais
    modal:
      title: 'Novidade: Parcelado Solfácil'
      altImgOption1: Imagem representando as melhores condições
      option1: As melhores condições
      altImgOption2: Imagem representando parcelado Solfácil
      option2: De 3 à 9 vezes!
      altImgOption3: Imagem representando kits adquiridos pela Loja Solfácil
      option3: Kits adquiridos pela Loja Solfácil
      description: >-
        Parcelado Solfácil é a nova condição de pagamentos que a Solfácil
        oferece para você e seus clientes. Comprando o Kit na Loja Solfácil,
        você tem mais essa opção para fechar negócio! Disponível no resultado da
        simulação.
      textButton: Fechar
    id:
      summary:
        title:
          summary: Resumo
          service: Serviço fixo
        installment:
          title: Parcela
          prohibited: Entrada
          cet: CET
          am: a.m.
        enterprise:
          title: Para sua empresa
          total: Valor total do projeto
          kit: Kit da Loja
          tax: Taxa de antecipação
          value: Valor a receber
        client:
          title: Para seu cliente
          total: Valor total do projeto
          prohibited: Entrada
          installments: Parcelas
          of: de
  notFound:
    title: Nenhuma simulação encontrada
    subtitle: >-
      Busque por outro Nome, CPF, ou CNPJ para encontrar a simulação que está
      buscando. Caso esteja correto, faça uma nova simulação, clicando no botão
      abaixo.
  errorProject:
    title: Erro ao carregar a lista
    subtitle: >-
      Infelizmente não conseguimos exibir a sua lista de simulações nesse
      momento. O que deseja fazer?
  status_filter:
    done: Aprovado
    pending: Em processamento
    reproved: Reprovado
  ampera:
    description: Otimize seu pós-venda com o monitoramento e tenha desconto nas parcelas.
    textLink: Mais detalhes
    lack_of_stock:
      text1: >-
        Por se tratar de um segundo financiamento, não é necessário um novo
        Ampera para esta instalação.
  newPage:
    title: Tipo de cliente
    description: Defina o perfil do seu cliente para esse projeto
    empty:
      title: Erro ao carregar tipos de financiamentos
      subtitle: >-
        Ocorreu um erro ao tentar carregar os tipos de financiamentos(PF e PJ).
        Tente novamente mais tarde.
    pf:
      title: Pessoa Física (PF)
      description: Linha de crédito para residências
      financing:
        title: Financiamento de até {value}
        description: Em até {maxFinancingInstallments} parcelas - Seguros - {period}
      bnpl:
        title: Parcelado Solfácil de até {value}
        description: Em até {maxBnplInstallments} parcelas - {period}
    pj:
      title: Pessoa Jurídica (PJ)
      description: Linha de crédito para empresas
      financing:
        title: Financiamento de até {value}
        description: Em até {maxFinancingInstallments} parcelas - {period}
      bnpl:
        description: Em breve Parcelado Solfácil
    client_data:
      title: Dados do projeto
      description: Condições especiais de crédito para o seu cliente em poucos segundos.
      form:
        document_pf:
          title: CPF do cliente*
        document_pj:
          title: CNPJ do cliente*
          majoritary:
            title: CPF do sócio majoritário*
        value:
          title: Valor total do projeto
          placeholder: Digite o valor do projeto
        down_payment:
          title: Entrada
          placeholder: Digite o valor da entrada
        bill_value:
          title: Valor da conta de luz*
          placeholder: Digite o valor da conta
        power:
          title: Potência do sistema*
          placeholder: Digite o valor da potência
          system_power_error_422: >-
            Aumente o valor do projeto ou diminua a potência para continuar
    form:
      system_type:
        label: Tipo de sistema
        placeholder: Selecione o tipo de sistema
        with_battery: Com bateria
        without_battery: Sem bateria
revision:
  title: Revisão da simulação
  subtitle: >-
    Revise e altere todos os dados da simulação, inclusive valores, desde que
    estejam dentro das nossas políticas.
  subtitle_complement: Caso contrário, será necessário realizar uma nova simulação.
buttons:
  back: Voltar
  discart: Descartar alteração
  edit_save: Salvar edição
  go_financing: Ir para financiamentos
  go_new_page: Refazer análise
loading:
  messages: >-
    Estamos quase lá, Captando energia solar para alimentar nossos cálculos, Os
    raios solares estão chegando, Carregando com a força do sol
list:
  days: '- | {count} dia | {count} dias'
installments:
  banner:
    title: 'Combo Fácil:'
    start: Compre o kit na
    link: Loja Solfácil
    end: >-
      com este financiamento, tenha parcelas mais baratas para seu cliente e
      ganhe muito mais pontos Solfácil Mais.
  with_combo: Parcelas com Combo Fácil
  with_combo_description: >-
    A parcela mais barata para o seu cliente. Kit deve ser comprado na Loja
    Solfácil.
  with_combo_comission: Parcelas com Combo Fácil e comissão
  with_combo_comission_description: >-
    Melhor custo benefício para você e seu cliente. Kit deve ser comprado na
    Loja Solfácil.
  without_combo: Parcelas sem Combo Fácil
  without_combo_description: >-
    Opção mais cara para seu o cliente. Kit pode ser comprado fora da Loja
    Solfácil.
form:
  payment_model:
    invoice_approved: Nota fiscal aprovada
    correction_letter_approved: Carta de correção aprovada
  alert_required_fields: Por favor, preencha todos os campos obrigatórios.
  required: Campo obrigatório
  file_required: É necessário anexar um arquivo.
  invalid_file_type: |
    Tipo de arquivo inválido. Tipos de arquivo aceitos: {types}
  invalid_document: Documento deve ser válido
  past_date_required: Idade inferior ao mínimo permitido.
  invalid_phone: Número deve ser válido
  invalid_email: E-mail deve ser válido
  invalid_emails: Insira um email válido. Se houver mais de um, separe-os com vírgulas.
  repeated_document: O CPF não pode ser igual ao do cliente.
  name: Nome Completo
  cpf: CPF
  cpf_duplicate: O CPF informado já está sendo utilizado por outro cadastro.
  cnpj: CNPJ
  cepNotFound: CEP não encontrado
  cepInfo: >-
    O CEP precisa ser o mesmo utilizado para entregas dos Correios, mesmo que
    conste outro na conta de luz. Caso a dúvida persiste, clique no botão abaixo
    "Problemas com o CEP?".
  informativeCepText: Problemas com o CEP?
  validatedCep: CEP validado com sucesso.
  phone: Celular
  email: E-mail
  validatedEmail: E-mail validado com sucesso.
  nationality: Nacionalidade
  birthdate: Data de Nascimento
  occupation: Profissão
  gender: Gênero
  mother_name: Nome da mãe
  energy_concessionaire: Concessionária
  installation_phase: Fase do local da instalação
  inverter: Marca e modelo do inversor
  battery: Marca e modelo bateria
  battery_quantity: Quantidade de baterias
  module_quantity: Quantidade de módulos
  inverter_quantity: Quantidade de inversores
  modules: Marca e modelo dos módulos
  microinversor_quantity: Quantidade de microinversores
  microinversors: Marca e modelo dos microinversores
  button:
    add_inverter: Adicionar inversor
    add_battery: Adicionar bateria
    new-email: Adicionar outro email
    remove-email: Remover e-mail
    send-email: Enviar e-mails
  cash: À vista
  installments: Parcelado
  hardware_invoice: Nota fiscal do equipamento
  service_invoice: Nota fiscal cheia do projeto emitida pela sua empresa
  commission_invoice: Nota Fiscal de comissão do financiamento
  error: Cadastro não realizado, tente novamente em instantes.
  cepModal:
    title: Problemas com o CEP?
    titleButton: Fechar
    informative:
      title: >-
        Caso tenha problemas com o CEP, assista ao vídeo ou siga os passos
        abaixo:
      item1: '1º Acesse ao site Buscar CEP dos correios:'
      item2: >-
        2º No primeiro campo, escreva o nome da rua, cidade e Unidade Federal
        (abreviado). No segundo campo não é necessário fazer alterações.
      item3: 3º Preencha o código exibido na imagem, e clique no botão “Buscar”.
      item4: >-
        4º Na tela seguinte vai aparecer o CEP que precisa ser informado na
        plataforma.
      item5: >-
        5º Se as dúvidas persistirem, assista o vídeo acima para mais
        informações
  cancelAnalysis:
    confirm: Confirmar cancelamento
    back: Voltar
    title: Cancelar a análise?
    message: >-
      Ao cancelar, você poderá editar os dados desta etapa. Mas, ao reenviar,
      sua análise voltará para o fim da fila e poderá demorar mais.
    successToast: Análise cancelada com sucesso.
    errorToastDescription: Não foi possível cancelar a análise.
  toast:
    success: Cadastro realizado com sucesso!
  optional_placeholder: Opcional
  invalidCep: CEP Inválido
  correction_letter: Carta de correção
  non_negative: O valor não pode ser negativo
  email-invalid: E-mail inválido
  email-required: E-mail é obrigatório
  guarantor:
    button: Incluir avalista
    placeholder: CPF avalista
    valid_cpf: Digite um CPF válido
    error_toast_default: Erro ao incluir avalista. Tente novamente.
    error_toast_guarantor_same_document: CPF do avalista não pode ser o mesmo do cliente
  cpf_required: CPF é obrigatório
  correnction_letter:
    helper_text: Adicione a carta de correção para para ajustar seu projeto
  max: Máximo de {max} caracteres
  invalid_cep: CEP inválido
company:
  information: Dados da empresa
  name: Razão social
  name_placeholder: Insira a razão social
  phone: Telefone da empresa
  phone_placeholder: (00) 0 0000-0000
  email: E-mail da empresa
  email_placeholder: Insira o e-mail empresarial
  official_document: Documento de oficialização da empresa
  document: CNPJ
  document_placeholder: 00.000.000/0000-00
  energy_bill: Conta de luz
  energy_bill_subtitle: >-
    Iremos solicitar o envio da conta de luz na etapa de documentação. Se não
    estiver no nome do cliente, basta indicar o CPF do titular.
  energy_bill_is_the_same_of_client: A conta de luz está no nome do seu cliente?
  majority_section: Documentação do sócio majoritário
  majority_subtitles: >-
    Sócio majoritário é aquele que possui a maior participação dentro do quadro
    societário da empresa. Essa pessoa se enquadra como devedor solidário.
  majority_is_the_same_of_the_legal: Sócio majoritário, também é o representante legal?
  has_more_than_one_legal_representative: Na empresa, existe apenas um representante legal?
  legal_representant_section: Dados do representante legal
  legal_representant_subtitle: >-
    Responsável por desempenhar todas as funções administrativas da empresa
    respondendo legalmente pelo CNPJ e outras ações gerenciais devidamente
    indicadas no contrato social.
  second_legal_representant_section: Dados do segundo representante legal
  alertDocumentTitle: >-
    Notamos que o endereço na conta de luz, está diferente do endereço de
    instalação
  alertDocumentMessage: >-
    Revise os campos abaixo com o local de instalação para confirmar, ou anexe
    uma nova conta de luz do local da instalação.
  alertRepresentantLegalMissingTitle: >-
    Notamos que o cliente PJ precisa preencher informações sobre representante
    legal
  alertRepresentantLegalMissingMessage: Confira e confirme abaixo os dados do representante legal
  biometry:
    modal_sending:
      title: Confirme as informações de contato do cliente
      helper_text:
        edit_suggestion:
          first_line: 'Lembre-se: você possui'
          second_line: '{count} tentativas'
          last_line: para alterar os endereços de envio da biometria
        no_changes:
          first_line: 'Lembre-se: você'
          second_line: não possui mais tentativas
          last_line: para alterar os endereços de envio da biometria
        new_analysis_warning: >-
          Ao trocar os dados do seus clientes, o projeto passará por uma nova
          análise
      send: Enviar biometria e contrato
      confirm_edit: Confirmar alteração
      edit: Editar dados
      cancel: Cancelar
      form:
        majority:
          email: E-mail do sócio majoritário
          phone: Celular do sócio majoritário
        legal:
          email: E-mail do representante legal
          phone: Celular do representante legal
        legal_1:
          email: E-mail do 1º representante legal
          phone: Celular do 1º representante legal
        legal_2:
          email: E-mail do 2º representante legal
          phone: Celular do 2º representante legal
        required: Campo obrigatório
        email_error: Informe um e-mail válido
        phone_error: Informe um telefone celular válido
        submit: Confirmar edição
customer:
  new: Proposta
  confirm: Confirmar dados
  client_data: Dados do cliente
  client_data_subtitle: Responda os campos abaixo com os dados do seu cliente
  use_terms: >-
    Eu e o cliente concordamos com os Termos de Uso, com o Termo de Autorização
    de Consulta ao SCR e com a Política de Privacidade da Solfácil. O Cliente
    autoriza a consulta de seus dados pessoais junto aos Órgãos de Proteção ao
    Crédito.
  confirm_modal: >-
    Não é possível fazer alterações nos dados do cliente futuramente. Uma vez
    confirmado, caso seja necessário fazer alterações um novo projeto deverá ser
    criado.
  attention: Atenção
  document_data_title: Documentação
  document_data_subtitle: >-
    Anexe abaixo os seguintes documentos do seu cliente. De preferência para
    arquivos em .pdf.
  address_data_title: Endereço residencial
  address_data_subtitle: Preencha igual está na conta de luz
  project_data_title: Equipamentos
  project_data_subtitle: Adicione as informações técnicas e o equipamento.
  receipt_model_title: Modelo de recebimento
  receipt_model_subtitle_eligible: >-
    Escolha a forma como você vai receber o valor do seu projeto e anexe a(s)
    nota(s) fiscal(is). Se necessário.
  receipt_model_subtitle_not_eligible: >-
    Confira o seu modelo de recebimento e anexe a(s) nota(s) fiscal(is). Se
    necessário.
  method_receipt: Forma de recebimento
  in_cash_subtitle_with_combo: >-
    Todo valor, descontado o kit, à vista para você após a aprovação do(s)
    pedido(s) vinculado(s) ao projeto.
  in_cash_subtitle_without_combo: >-
    Todo valor à vista para você após aprovação do contrato e nota(s)
    fiscal(is).
  in_installment_subtitle: >-
    Primeiro pagamento após aprovação do contrato e nota(s) fiscal(is), segundo
    pagamento após validação da instalação. | O valor dos equipamentos será pago
    à vista para o fornecedor. Metade do valor restante financiado será pago
    após aprovação do contrato e nota(s) fiscal(is), a outra metade após
    validação da instalação.
  in_installment_without_service_invoice_subtitle: >-
    Primeiro pagamento após aprovação do contrato, segundo pagamento após a
    validação da instalação.
  invoice: Notas fiscais |  Nota fiscal
  invoice_subtitle: >-
    O envio das notas pode ser feito após a assinatura do contrato. Depois de
    assinado você vai ter 10 dias para submeter as notas, caso contrário sua
    empresa ficará bloqueada para novos projetos. Dê preferência para arquivos
    no formato .pdf. | O envio da nota pode ser feito após a assinatura do
    contrato. Depois de assinado você vai ter 10 dias para submeter as notas,
    caso contrário sua empresa ficará bloqueada para novos projetos. Dê
    preferência para arquivos no formato .pdf.
  transfer_summary: Resumo da transferência
  financed_value: Valor financiado
  kit_value: Valor do kit (Combo Fácil)
  waiting_kit_value: Aguardando pedido (Combo Fácil)
  opened: Em aberto
  equipment_value: Valor do kit (Pago ao fornecedor)
  transfer_waiting: Aguardando informações das transferências
  transfer_after_contract_approval: Transferência total após aprovação de documentação
  transfer_after_project_approval: Transferência após aprovação do projeto
  transfer_after_validation_installation: Transferência após validação da instalação
  transfer_after_project_formalization: Transferência após aprovação da nota fiscal
  transfers_after_project_formalization_supplier: >-
    Transferência de {percent}% referente ao valor do kit após formalização do
    projeto
  transfer_after_project_formalization_commission_insurance: Transferência de comissão de seguro após a aprovação do contrato
  transfer_after_project_formalization_commission_financing: Transferência de comissão de financiamento após a aprovação do contrato
  total_transfer_to_registered_account: Total a ser transferido para conta cadastrada
  after_contract_approval_value_will_be_transferred: Após a aprovação do contrato o valor será transferido para sua conta.
  after_installation_value_transfer: >-
    Após a aprovação da instalação o valor bloqueado será transferido para sua
    conta.
  commission_transfer_after_nf_approval: >-
    O valor da comissão será transferido após a aprovação da NF de comissão. O
    valor bloqueado será transferido após a validação da instalação.
  value_transfer_after_validation_installation: O valor bloqueado será transferido após a validação da instalação.
  financing_commission: Comissão financiamento
  insurance_commission: Comissão seguros
  service_products: Nota fiscal cheia do projeto emitida pela sua empresa
  alertDocumentTitle: >-
    Notamos que o endereço na conta de luz, está diferente do endereço de
    instalação
  alertDocumentMessage: >-
    Revise os campos abaixo com o local de instalação para confirmar, ou anexe
    uma nova conta de luz do local da instalação.
  alertInstallment: >-
    Você receberá o pagamento em duas parcelas: após aprovação do contrato e
    após aprovação da instalação. Ainda somos responsáveis pelo pagamento do kit
    do seu projeto, por isso pedimos dados do fornecedor.
  method_receipt_seleted_in_cash: >-
    Neste cenário a Solfácil paga à vista: 100% do dinheiro para você e você
    paga o fornecedor.
  method_receipt_seleted_installment: >-
    Neste cenário a Solfácil paga 60% do valor do projeto na formalização. Os
    40% restantes serão pagos na conclsão da instalação.
  separate_invoices_subtitle: >-
    Nota de equipamentos emitida pelo fornecedor (nota de serviço não é
    necessária nesse caso)
  second_separate_invoices_subtitle: Neste cenário a Solfácil paga o fornecedor. O restante é enviado para você.
  supplier_information: Informações do fornecedor
  supplier_equipment: Fornecedor do equipamento
  invoice_value: Valor da nota fiscal do kit
  invoice_value_placeholder: Digite o valor da nosta fiscal do kit
  emails_to_send: E-mail para envio da carta de faturamento
  emails_to_send_placeholder: Digite o e-mail para envio da carta de faturamento
  emails_tooltip: |-
    Para você ter acesso à nota fiscal de venda
    desses equipamentos,precisamos enviar
    uma carta de faturamento para o seu fornecedor.
    Para isso, preencha abaixo
    o e-mail do seu contato neste fornecedor
  titleUnableContinuefinancing: Não conseguimos seguir com esse financiamento
  descriptionUnableContinuefinancing: >-
    Alguns dados do cliente podem precisar de ajustes. Por favor, verifique se o
    contato e o endereço pertencem ao cliente, e não ao vendedor ou a um
    parente. Após essa confirmação, crie um novo projeto e envie para análise.
    Lembramos que, ainda assim, o sistema pode reprovar automaticamente a
    proposta em alguns casos conforme nossa política interna.
  endButtons:
    backSimulation: Voltar para simulação
    nextInstallation: Ir para instalação
  header:
    title: Projeto
    steps:
      simulation: Simulação
      formalization: Proposta
      installation: Instalação
    tags:
      pending: Em andamento
      pendency: Pendenciado
      kit_pending: Kit pendente
      under_analysis: Em análise
      submitted: Enviado
      send_contract: Enviar contrato
      sent: Pendente cliente
      reproved: Reprovado
      canceled: Cancelado
      approved: Aprovado
      expired: Expirado
    message:
      error: Ocorreu um erro ao obter os status da proposta.
  biometry:
    send_success: Biometria enviada com sucesso
    cancel_success: O envio da biometria foi cancelado
    title: Biometria e contrato
    subtitle: >-
      Informe seu cliente como é o processo da realização da biometria e
      assinatura do contrato do financiamento.
    steps:
      send_contract: Envio da biometria e do contrato
      biometry: Biometria
      contract: Contrato
      approval: Aprovação do contrato
    description:
      send_biometry_contract:
        days: >-
          Após você clicar em "Enviar", o seu cliente vai receber um e-mail com
          o link para realizar a biometria facial e depois outro para assinar o
          contrato eletronicamente. O cliente terá {days} dias para completar
          cada uma das duas etapas. Se não, o link vai expirar e um novo e-mail
          será enviado automaticamente;
        minutes: >-
          Caso o seu cliente não tenha recebido o e-mail da biometria ou do
          contrato, é possível reenviar {minutes} minutos após o primeiro envio;
        sign: >-
          Para assinar o contrato é necessário informar um Token (código de
          confirmação) recebido pelo celular. Selecione abaixo a melhor forma
          para o seu cliente receber o Token.
        radio:
          label: Forma de recebimento do Token
          sms: SMS
          whatsapp: WhatsApp
      biometry: >-
        O seu cliente vai receber um e-mail com as instruções para realizar a
        biometria facial. São aceitas 5 tentativas para fazê-la com sucesso,
        caso contrário o projeto é reprovado.
      contract: >-
        Após realizada a biometria, seu cliente vai receber outro e-mail, para
        assinar o contrato. Peça para que ele revise as condições antes de
        assinar. Durante essa etapa, será solicitado o preenchimento do Token
        (enviado conforme selecionado no campo acima). Depois de assinado, o
        contrato é enviado ao e-mail do cliente.
      approval: Etapa de confirmação e aprovação do time Solfácil
    alert:
      under_analysis:
        title: Contrato e biometria realizados pelo cliente e está em análise
        text: >-
          Nosso time está analisando a documentação e em breve traremos a
          resposta.
      pending:
        title: Problema no resultado da biometria
        text: Um novo e-mail já foi enviado ao cliente para uma nova tentativa.
      reproved_biometry:
        title: Cliente reprovado na biometria ou assinatura do contrato
        subtitle: >-
          Seu cliente foi reprovado nessa etapa devido a inconsistências e por
          isso esse projeto não pode ser formalizado. Esse tipo de reprova é
          irreversível.
      reproved:
        title: Não é possível seguir com este financiamento
        subtitle: O mesmo cliente já tem um projeto formalizado com a Solfácil.
      pendencies: >-
        Regularize sua situação conosco até {date} para seguir com a
        formalização desse projeto.
    status:
      sent_message: Enviado
      waiting: Aguardando
      in_progress: Em andamento
      pending: Enviar contrato
      pendency: Pendenciado
      sent: Pendente cliente
      under_analysis: Em análise
      reproved: Reprovado
      approved: Aprovado
      accomplished: Realizada
      signed: Assinado
    footer:
      policy: Acessar política de cancelamento
      resend: Reenviar
      cancel: Cancelar envio
      contract: Baixar contrato
      insurance: Baixar apólice
    modal_cancel:
      title: Deseja cancelar envio do contrato e biometria?
      description: >-
        Cancelar o envio da biometria e do contrato podem atrasar a formalização
        desse cliente. Caso a biometria já tenha sido realizada, será necessário
        que o cliente faça novamente.
      confirm: Cancelar envio
      cancel: Fechar
    catch:
      data_error_title: Biometria indisponível no momento
      data_error: >-
        Atenção! Ocorreu um erro ao obter os dados da biometria. Tente
        novamente.
      send_error: >-
        Atenção! Ocorreu um erro ao enviar o contrato e a biometria. Tente
        novamente.
      resend_error: >-
        Atenção! Ocorreu um erro ao reenviar o contrato e a biometria. Tente
        novamente.
      cancel_error: >-
        Atenção! Ocorreu um erro ao cancelar o contrato e a biometria. Tente
        novamente.
      update_error: >-
        Devido a nossas políticas internas, não é possível seguir com a
        alteração.
      know_errors:
        BIOMETRY_ALREADY_DONE: Não foi possível atualizar pois a biometria já foi realizada
        FORMALIZATION_COMPLETED: Não foi possível atualizar pois a formalização já foi finalizada
        INVALID_STATUS: Não foi possível atualizar pois o status é inválido
        MAX_TRIES: Não foi possível atualizar pois o limite de tentativas foi atingido
        MATRIX_RESTRICTIONS: >-
          Devido a nossas políticas internas, não é possível seguir com a
          alteração
        SAME_EMAIL_PHONE: Não foi possível atualizar pois o e-mail e o telefone são os mesmos
        LIMIT_CHANGES_EXCEEDED: Quantidade de tentativas excedida
      has_been_cancelled: O contrato foi cancelado, aguarde {minutes} minuto(s) para cancelar novamente
    update_success: Os dados para contato foram alterados com sucesso
    modal_sending:
      title: Confirme as informações de contato do cliente
      helper_text:
        edit_suggestion:
          first_line: 'Lembre-se: você possui'
          second_line: '{count} tentativas'
          last_line: para alterar os endereços de envio da biometria
        no_changes:
          first_line: 'Lembre-se: você'
          second_line: não possui mais tentativas
          last_line: para alterar os endereços de envio da biometria
        new_analysis_warning: >-
          Ao trocar os dados do seus clientes, o projeto passará por uma nova
          análise
      send: Enviar biometria e contrato
      confirm_edit: Confirmar alteração
      edit: Editar dados
      cancel: Cancelar
      form:
        required: Campo obrigatório
        email: E-mail do cliente
        email_error: Informe um e-mail válido
        phone: Celular do cliente
        phone_error: Informe um telefone celular válido
        submit: Confirmar edição
        email_guarantor: E-mail do avalista
        phone_guarantor: Celular do avalista
  bannerSolfacilPlus:
    titlePrimary: Parabéns pela formalização! Você ganhou
    titleSecondary: pontos no Solfácil Mais
  canceled:
    title: Projeto cancelado
    description: >-
      O cliente solicitou o cancelamento deste projeto e a transferência de
      valores não será realizada. Se já foi feita, ela será estornada. Em caso
      de Combo Fácil, o pedido do kit também será cancelado.
  guarantor_section_title: Insira os dados pessoais do(a) avalista
  customer_section_title: Insira os dados pessoais do(a) cliente
solfacilPoints:
  label: Pontos Solfácil+
  phrases:
    one: 'Que tal adicionar em seu financiamento:'
    two: Vamos melhorar o projeto? Que tal adicionar
    three: Bom projeto! Que tal adicionar {string}?
    four: Formalizar esse projeto vai te trazer muitos benefícios! Muito bem!
toastKitSelected:
  title: Kit selecionado
  description: >-
    Caso disponível no momento da formalização, o kit será faturado
    automaticamente para sua comodidade.
company_form_document:
  label_document_majority_and_legal_representants: >-
    RG ou CNH do sócio majoritário e representante(s) legal(is) (caso
    aplicável)*
  label_energy_bill: Conta de luz do local da instalação*
  label_document_company: Documentos de oficialização da empresa*
  label_additional_document: Documentos adicionais
  label_subtitle_document_majority_and_legal_representants: >-
    Aceitamos: RG ou CNH ou CNH Digital + QR Code ou CTPS física ou carteira de
    classe ativa com foto. Documentos devem conter o número do CPF.
  label_subtitle_energy_bill: >-
    Agilize sua análise! Dê preferência para arquivos em .pdf do site da
    concessionária. Envie a conta completa, em nome da pessoa física ou jurídica
    com até 90 dias de emissão.
  label_subtitle_document_company: >-
    Aceitamos um desses documentos: declaração de microempreendedor individual
    (MEI), contrato social, requerimento de empresário ou, na ausência destes,
    certidão emitida pela Junta Comercial.
  label_subtitle_additional_document: >-
    Se o local de instalação não for o endereço do CNPJ ou da residência do
    representante legal ou coobrigado, será necessário enviar um comprovante de
    residência no nome de uma dessas três entidades do local de instalação.
  additional_comments: Comentários adicionais
  placeholder: Arraste o arquivo aqui ou clique para upload
  placeholder_additional_comments: Opcional
formDocument:
  labelDocument: RG ou CNH*
  labelDocumentSol: RG ou CNH do cliente*
  labelElectricityBill: Conta de luz do local da instalação*
  labelAdditionalDocument: Documentos adicionais
  labelSubtitleDocument: >-
    Aceitamos: RG ou CNH ou CNH Digital + QR Code ou CTPS física ou carteira de
    classe ativa com foto. Documentos devem conter o número do CPF.
  labelSubtitleElectricityBill: >-
    Agilize sua análise! De preferência para arquivos em .pdf do site da
    concessionária. Envie a conta completa, em nome da pessoa física ou jurídica
    de até 90 dias de emissão.
  labelSubtitleAdditionalDocument: >-
    Não obrigatório. Exemplo: comprovante de vínculo entre o cliente financiador
    e o titular da conta de luz.
  additionalComments: Comentários adicionais
  placeholder: Arraste o arquivo aqui ou clique para upload
  placeholderAdditionalComments: Opcional
  helperText: Somente arquivos .jpg com tamanho máximo de arquivo de 500kb
  errorMessage: Inisira um novo documento
  errorMessageInstallationAddressMatchesBill: >-
    Conta de luz não bate com local de instalação. Envie novamente o documento
    de conta de luz e caso necessário atualize o endereço de instalação em
    'Dados do cliente'.
  errorMessageInstallationDocumentMatchesBill: Documento não bate com CPF registrado.
  errorMessageInstallationDocumentMatches: CPF não bate com documento registrado
  errorMessageInstallationAddressAndDocumentMatchesBill: >-
    Conta de luz não bate com local de instalação e com o documento informado.
    Envie novamente o documento de conta de luz, caso necessário atualize o
    endereço de instalação e o documento em 'Dados do cliente'.
  errorMessageIdentityMatchesDocument: Documento não bate com CPF registrado, faça o upload do arquivo novamente.
  messageTitleAwaitingCustomerData: Aguardando Dados do cliente
  messageTextAwaitingCustomerData: >-
    Para finalizar a análise dos documentos abaixo, por favor faça o
    preenchimento da etapa de "Dados do cliente".
  alertError: Inconsistência na análise da documentação
  responseError: >-
    Erro ao enviar o arquivo, verifique o tipo do arquivo ou o tamanho e tente
    novamente.
  responseErrorDelete: Erro ao deletar o arquivo, por favor tente novamente.
  responseSuccessDelete: Arquivo deletado com sucesso.
formAddress:
  countryside: Endereço está em Zona Rural?
  yesCountryside: Sim
  noCountryside: Não
  cep: CEP*
  installationStreet: Rua da instalação*
  street: Rua*
  street_placeholder: Rua, alameda, avenida...
  number: Número*
  complement: Complemento
  complementPlaceholder: Apt, bloco...
  neighborhood: Bairro*
  neighborhood_placeholder: Nome do bairro
  state: Estado*
  city: Cidade*
  city_placeholder: Nome do município
  local: Local de residência do cliente, é o mesmo local da instalação?
  yesLocal: Local de residência, é o mesmo do local da instalação
  noLocal: Local de residência e instalação não são os mesmo
  titleInstallationLocation: Complete as informações do local de instalação
  addressInstallation: Endereço do local de instalação
subscriptionEnergy:
  title: Seu cliente ainda pode ter energia solar!
  subtitle: >-
    Indique seu cliente para ter energia solar por assinatura com até 20% de
    desconto na conta de luz! E ganhe uma comissão caso ele aceite!
  recommend_customer: Indicar cliente
  alt_img: Imagem Energia Solar por Assinatura
  title_img: Energia Solar por Assinatura
electricityBill:
  yesMessage: Sim
  noMessage: Não
  title: Conta de luz
  description: >-
    Iremos solicitar o envio da conta de luz na etapa de documentação. Se não
    estiver no nome do cliente, basta indicar o CPF do titular.
  question: A conta de luz está no nome do seu cliente?
  placeholder: Informe o CPF que consta na conta
  errorMessageInstallationDocumentMatchesBill: CPF não bate com documento registrado
  errorMessageInstallationDocumentMatchesBillCompany: CNPJ não bate com documento registrado
  errorMessageCompany: Documentos da empresa não batem com o registrado
emptyStateCustomer:
  title: Sem informações disponíveis
  subtitle: Não há informações sobre os dados do cliente.
installationPage:
  messageNewInstallation: Instalação
  barInstallation:
    waiting:
      type: informative
      text: Em andamento
    processing:
      type: warning
      text: Em análise
    error:
      type: negative
      text: Pendenciado
    success:
      type: positive
      text: Instalado
  helpButton: Dúvidas na ativação
  manualButton: Seguir manualmente
  amperaExperienceButton: Experiência Ampera
  waiting:
    img:
      name: ampera.svg
      alt: Imagem do equipamento ampera
      title: Ampera
    title: Aguardando ativação automática do Ampera
    subtitle: >-
      Caso faça mais de 15 minutos que você ativou o Ampera e ele ainda não foi
      ativado, clique no botão abaixo para adicionar fotos (ou vídeos) e
      comprovar a instalação
  waitingMultiples:
    img:
      name: ampera.svg
      alt: Imagem do equipamento ampera
      title: Ampera
    title: >-
      Ao concluir a instalação, adicione fotos ou vídeos do seu projeto nos
      campos abaixo
    subtitle: >-
      Com o Ampera ativado após a instalação você não precisa passar por essa
      etapa. Porém como você ainda não tem 3 projetos instalados na Solfácil,
      precisamos que você anexe alguns arquivos para que possamos validar a
      instalação do seu projeto.
  waitingAmperaActivated:
    img:
      name: ampera.svg
      alt: Imagem do equipamento ampera
      title: Ampera
    title: Seu Ampera já foi ativado, você já quase concluiu seu projeto
    subtitle: >-
      Precisamos de fotos ou vídeos para validar esta instalação, mas após a
      terceira instalação, os projetos seguintes com Ampera, você pula essa
      etapa. O Ampera valida a instalação para você!
  waitingWihtoutAmpera:
    img:
      name: installation.svg
      alt: Imagem representando instalção
      title: Instalação
    title: >-
      Ao concluir a instalação, adicione fotos ou vídeos do seu projeto nos
      campos abaixo
    subtitle: >-
      Cansado de subir fotos pra comprovar instalação? Com Ampera em seus
      projetos, além de contar com monitoramento inteligente das suas usinas,
      com a ativação do Ampera, você não precisar subir mais nada para comprovar
      sua instalação!
  waitingWihtoutAmperaMultiples:
    img:
      name: installation.svg
      alt: Imagem representando instalção
      title: Instalação
    title: >-
      Ao concluir a instalação, adicione fotos ou vídeos do seu projeto nos
      campos abaixo
    subtitle: >-
      Com o Ampera ativado após a instalação, você pula essa etapa! Porém, como
      você ainda não tem 3 projetos na Solfácil, envie alguns arquivos para
      validar sua instalação e desbloquear todo o potencial do Ampera!
  processing:
    img:
      name: processing.svg
      alt: Imagem representando algo em processamento
      title: >-
        Estamos analisando se está tudo certo com o Ampera, em até 24h úteis
        confirmaremos ou não a instalação.
    title: Obrigado por informar a conclusão da instalação!
    subtitle: >-
      Estamos analisando se está tudo certo com o Ampera, em até 24h úteis
      confirmaremos ou não a instalação.
  error:
    img:
      name: error.svg
      alt: Imagem representando que ocorreu um erro
      title: Por favor revise as informações abaixo.
    title: Encontramos um problema para seguir com a conclusão da instalação
    subtitle: Por favor revise as informações abaixo.
  success:
    img:
      name: success.svg
      alt: Imagem representando que houve sucesso
      title: Concluído
    title: Instalação validada com sucesso!
    subtitle: Concluído em {date} às {hour}
  titleAddFiles: Adicione fotos e vídeos da instalação
  descriptionAddFiles: São aceitos diversos formatos com tamanho máximo de 20Mb.
  descriptionAvoidFailures: >-
    Evite reprovas! Caso a marca do inversor tenha sido alterada, indique o
    modelo/potência nos comentários adicionais. São aceitos diversos formatos
    com tamanho máximo de 20Mb.
  form:
    placeholderUpload: Arraste o arquivo aqui ou clique para upload
    installationField:
      label: Do telhado ou local da instalação
    invertersField:
      label: Contendo inversores e proteções CC/CA
    installedField:
      label: Do Ampera instalado
    inverterLabels:
      label: Foto de todas as etiquetas de inversores (com número de série)
    additionalComments: Comentários adicionais
    batteryInstalled: Foto das baterias instaladas
    sendButton: Enviar para análise
    downloadFiles: Baixar arquivos
  footer:
    formalization: Ir para proposta
    ampera: Monitorar Ampera
  trackAmpera: Rastrear Ampera
emptyStateInstallation:
  title: Sem informações disponíveis
  subtitle: Não há informações sobre a instalação.
bannerSolfacilPlus:
  img:
    alt: Imagem representando uma conquista.
    title: Parabéns! Você ganhou pontos extras no Solfácil Mais
  title: Parabéns! Você ganhou pontos extras no Solfácil Mais
  subtitle: Quanto mais projetos fizer com a Solfácil, maior seu nível e benefícios!
receiptModel:
  infoSolfacil:
    title: Informações para emissão da nota fiscal de comissão
    subtitle: >-
      Favor emitir e anexar uma nota fiscal para a Solfácil referente ao
      pagamento da sua comissão.
    labelValue: Valor da nota
    labelCnpj: CNPJ
    labelCorporateReason: Razão social
    labelMunicipalRegistration: Inscrição Municipal (IM)
    labelMainActivity: Atividade principal (CNAE)
    labelAddress: Endereço
    labelEmail: E-mail
  alert:
    title: >-
      Para ter acesso ao Modelo de recebimento primeiro você precisa escolher um
      kit do Combo Fácil
    text: >-
      Volte a “Dados técnicos e equipamentos” para selecionar um kit da vitrine
      ou acesse a Loja compre um kit e opte pela opção de pagamento
      "Financiamento Solfácil”. Desta forma você conseguirá seguir com o Modelo
      de recebimento.
    upload_not_sent: Arquivo não enviado, tente novamente!
  alertInstallments:
    title: Modelo de recebimento em dois pagamentos ({firstValue}% e {secondValue}%)
    text: >-
      Após a aprovação do contrato, você receberá {firstValue}% do valor do
      projeto, descontados o valor do kit. Concluída a aprovação instalação os
      restantes {secondValue}%.
  modalInstallments:
    titleWithCombo: Pagamento parcelado com compra de kit pela Solfácil
    titleWithoutCombo: Pagamento parcelado
    principalText: >-
      Para o seu perfil de parceiro, nossa política de pagamento é {firstValue}%
      após aprovação do contrato e {secondValue}% após a aprovação da
      instalação. Você poderá receber todo valor à vista
    firstTopic: Quando tiver três projetos instalados com a Solfácil;
    secondTopic: Se tiver no máximo 1 projeto atrasado com mais de 120 dias de instalação.
  onflex:
    title: Modelo de recebimento 100% após a instalação
    subtitle: >-
      Todo o valor do financiamento, menos o valor do kit selecionado, será
      transferido após a validação da instalação deste projeto.
    topic: 'Para evoluir sua situação cadastral você pode:'
    firstTopic: Solicitar uma reavaliação com o Atendimento Solfácil
    secondTopic: Aguardar a próxima reavaliação automática trimestral
    twoInvoices: em duas notas
  error:
    title: Erro ao carregar modelo de recebimento!
    subtitle: Ocorreu um erro ao tentar carregar o modelo de recebimento.
  response:
    error:
      title: Modelo de recebimento não enviado!
      description: Ocorreu um erro ao tentar enviar o modelo de recebimento.
      mirror-title: Espelho não enviado!
      mirror-description: Ocorreu um erro ao tentar enviar o espelho.
    success:
      title: Modelo de recebimento enviado!
      description: O modelo de recebimento foi enviado para análise.
    error_upload:
      title: Informações incompletas
      description: >-
        Verifique se todos os campos foram preenchidos corretamente para enviar
        para análise.
    equipment_value_error: O valor do equipamento não pode ser maior que o valor do financiamento.
    email_error: >-
      Ocorreu um erro ao tentar atualizar o projeto, verifique se os e-mails
      estão corretos.
  tags:
    inAnalysis: Em análise
    approved: Aprovado
    denied: Reprovado
    inProgress: Em andamento
    pendingChange: NF Pendente
    selected: Selecionado
    transferInProgress: Transferência em andamento
project_data:
  status:
    pending: Pendenciado
    selected: Selecionado
    in_progress: Em andamento
    kit_pending: Kit pendente
    approved: Aprovado
  alerts:
    kit_out:
      title: O kit que você selecionou na simulação não está mais disponível
      text: >-
        Vamos precisar que você selecione um novo kit dentro dos disponíveis na
        loja.
    invalid_kit:
      title: >-
        O kit que você selecionou está abaixo ou acima das necessidades do
        projeto do seu cliente
      text: >-
        Já filtramos os kits para aqueles que atendam a sua necessidade e do
        cliente.
    mirror:
      in_analisys: >-
        Estamos analisando o espelho do pedido, este processo leva alguns
        instantes.
      info_payment_method_store: >-
        Compre pela Loja Solfácil e selecione o método de pagamento
        “Financiamento Solfácil”. Depois de comprar, adicione o espelho do
        pedido no campo abaixo.
      info_payment_method_store_button: Ir para a Loja
    mirror_uploaded:
      text: Kit solar selecionado via espelho do pedido
      action_text: Baixar espelho do pedido
    project_power: Lembre-se que a potência estimada para este projeto é de
  heading:
    kit: Kit da Loja
  notifications:
    mirror_uploaded: >-
      Estamos analisando o espelho do pedido, este processo leva alguns
      instantes.
    mirror_upload_error: Erro ao enviar espelho da Loja. Revise o PDF adicionado.
    mirror_upload_success: Espelho do pedido da Loja anexado com sucesso.
    mirror_uploaded_reproved: Reveja o espelho do pedido.
  errors:
    failed_to_save_kit_draft: Não foi possível realizar a ação. Por favor, tente novamente
    string_overload_max: >-
      Potência excessiva no(s) inversor(es) selecionado(s). Diminua a quantidade
      ou opte por modelos de menor potência.
    string_overload_min: >-
      Potência insuficiente no(s) inversor(es) selecionado(s). Adicione mais um
      inversor ou escolha modelos de potência superior.
    microinverter_overload_max: >-
      Potência excessiva nos microinversores selecionados. Aumente a quantidade
      ou opte por modelos de menor potência.
    microinverter_overload_min: >-
      Potência insuficiente nos microinversores selecionados. Diminua a
      quantidade de microinversores ao projeto ou escolha um modelo de potência
      superior.
    modules_overload: >-
      Os módulos selecionados parecem não se adequar ao especificado no projeto.
      Por favor revise a quantidade ou potência  dos módulos.
    modules_price_per_watt: >-
      Os módulos selecionados parecem não se adequar ao especificado no projeto.
      Por favor revise a quantidade ou potência dos módulos.
  form:
    mirror_upload: Adicione o espelho do pedido
    mirror: Via espelho do pedido (Comprar na Loja)
    showcase: Via vitrine da Loja (Mais rápido)
    container: Via saldo da loja
    inverter_type: Tipo de inversor
    combo_option:
      label: Selecione como prosseguir
financing:
  filters:
    title: 'Filtrar projetos por:'
    status: Status
    steps: Etapas
    clear: Limpar filtro | Limpar filtros
  title: Financiamentos
  subtitle: >-
    Todos as formalizações e instalações da nova plataforma reunidas em um só
    lugar
  alert:
    title: Aqui você encontra apenas financiamentos criados na nova plataforma
    subtitle: >-
      Para acessar os financiamentos da plataforma antiga, clique no botão à
      direita.
    action: Ver lista antiga
    bill:
      title: Não conseguimos seguir com esse financiamento
      description: >-
        Infelizmente, ainda não conseguimos atender este(a) cliente. Contudo,
        uma nova tentativa poderá ser realizada daqui a 30 dias.
  banner:
    title: Parcelas mais baixas para você e seu cliente
    subtitle: Suba de nível mais rápido no Solfácil Mais e tenha sempre o melhor preço
  button:
    know_more: Saiba mais
  boxSearch:
    placeholder: Buscar por Nome, CPF/CNPJ ou ID
    button: Buscar
  emptyList:
    title: Sem projetos em andamento na nova plataforma
    subtitle: Inicie uma nova simulação.
    button_list: Ver lista de simulações
  notFound:
    title: Nenhum projeto encontrado
    subtitle: >-
      Busque por outro Nome, CPF, CNPJ ou ID do projeto para encontrar o projeto
      que está buscando. Caso esteja correto, você pode buscar o projeto lista
      antiga.
  sort_by:
    label: 'Ordenar por:'
    updated_at_desc: Últimas atualizações
    created_at_desc: Mais recentes
    created_at_asc: Mais antigos
    expires_at_asc: Expiração
  no_data: Sem dados aqui
  list:
    results_found: '{count} financiamento encontrado | {count} financiamentos encontrados'
    days_info: '- | {count} dia | {count} dias'
    headers:
      id: ID
      customer_name: Nome do projeto
      financed_value: Valor financiado
      expires_at: Expira em
      installing_days: Instalando há
      installed_days: Instalado em
      step: Etapa
    status:
      pending: Em andamento
      expired: Expirado
      reproved: Reprovado
      approved: Aprovado
      under_analysis: Em análise
      pendency: Pendenciado
      send_contract: Enviar contrato
      pending_customer: Pendente cliente
      pending_receipt: NF pendente
      concluded: Concluído
      cancelled: Cancelado
      kit_pending: Kit pendente
      canceled: Cancelado
    tags:
      paid_percentage: Pago {percentage}%
    step:
      simulation: Simulação
      proposal: Proposta
      installation: Instalação
      concluded: Concluído
      expired: Expirado
      canceled: Cancelado
    error:
      fetching:
        title: Não conseguimos carregar os financiamentos
        description: Tente novamente mais tarde.
  ErrorEmptyState:
    title: Ops, algo deu errado
    subtitle: >-
      Ocorreu algum problema e não conseguimos trazer o resultado. Tente
      novamente mais tarde
unauthorized:
  title: Sem autorização de acesso
  description: >-
    Você não tem permissão para acessar essa página. Se você achar que isso é um
    erro, consulte o administrador da sua conta.
notFound:
  title: Página não encontrada
  description: >-
    A página que você está procurando não existe. Verifique o endereço digitado,
    ou volte para a listagem ou crie uma nova simulação.
unknonwError:
  title: Sem informações disponíveis
  description: >-
    Algo deu errado, tente novamente mais tarde. Se você achar que isso é um
    erro, consulte o administrador da sua conta.
noResults:
  title: Ops, algo deu errado
  description: >-
    Ocorreu algum problema e não conseguimos trazer o resultado da sua
    simulação. Tente novamente mais tarde
emptyStateRegister:
  title: Sem informações disponíveis
  subtitle: >-
    Ocorreu um problema ao obter informações sobre o financiamento. Tente
    recarregar a página.
formalization:
  upload:
    maximum_size:
      text: Tamanho máximo de
      10mb: 10MB
    accepted_files:
      text: por arquivo
      files: (JPEG, JPG, PNG e PDF)
    cpf_attention:
      text: Atenção!
      description: Documentos devem conter o número do CPF
  client:
    title: Insira as informações e documentos do seu cliente solicitados abaixo
    cpf:
      label: CPF do(a) cliente
      placeholder: 000.000.000-00
      label_guarantor: CPF do(a) avalista
    name:
      label: Nome Completo do(a) cliente
      placeholder: Preencha o nome completo do cliente
      label_guarantor: Nome Completo do(a) avalista
      placeholder_guarantor: Preencha o nome completo do avalista
    address:
      label: Endereço do(a) cliente
    birthdate:
      label: Data de Nascimento do cliente
      placeholder: 00/00/0000
      label_guarantor: Data de Nascimento do avalista
    phone:
      label: Celular do(a) cliente
      placeholder: (00) 00000-0000
      label_guarantor: Celular do(a) avalista
    email:
      label: E-mail do(a) cliente
      placeholder: Insira o email do cliente
      label_guarantor: E-mail do(a) avalista
      placeholder_guarantor: Insira o email do avalista
  majority:
    address: Endereço residencial sócio majoritário
  customer_documents:
    documents: Documento
    document:
      example:
        link: Exemplo de envio
        title: Exemplo de como deve ser enviado
        image:
          front:
            alt: Exemplo de RG frente
            title: Frente do documento
          back:
            alt: Exemplo de RG verso
            title: Verso do documento
      type_front: Frente do RG* |  Frente da CNH*
      type_back: Verso do RG* |  Verso da CNH*
    digital_document: CNH Digital
  address:
    cep:
      label: CEP
      placeholder: 00000-000
    number:
      placeholder: '1234'
    installation:
      label: Endereço do local de instalação
    guarantor:
      label: Endereço do avalista
  legal_representant:
    documents:
      title: >-
        Documentação do representante legal | Documentação do 1º representante
        legal
      secundary: Documentação do 2º representante legal
    address:
      title: >-
        Endereço residencial representante legal | Endereço residencial 1º
        representante legal
      secundary: Endereço residencial 2º representante legal
  electricity_bill:
    title: Conta de luz do local da instalação
    info_bill: >-
      Envie a conta completa, em nome da pessoa física ou jurídica de até 90
      dias de emissão.
    info_files: >-
      Agilize sua análise! De preferência para arquivos em .pdf do site da
      concessionária. Tamanho máximo de 10MB por arquivo (JPEG, JPG, PNG e PDF)
    form:
      electricity_bill_customer_name: A conta de luz está no nome do seu cliente?
      proof_link_document: Comprovante de vínculo
      document_account_holder:
        label: CPF do titular da conta
        placeholder: Insira o CPF do titular da conta
    what_is_proof_of_link: O que é comprovante de vínculo?
    add_observations_and_comments: Adicionar observações e/ou comentários
    modal_what_is_bond:
      description:
        part_1: Aceitamos documentações que comprovem
        part_2: vínculo de parentesco
        part_3: com o titular da conta de luz ou
        part_4: vínculo com o local de instalação
        part_5: >-
          Confira abaixo os documentos que podem ser anexados que comprovam
          vínculo:
      proof_of: Comprovante de
      kinship: parentesco
      location: localização
      marriage_certificate: Certidão de casamento/união estável com o titular (1º grau)
      document_of_holder: CNH/RG do titular (com CPF)
      purchase_and_sale_agreement: Contrato de Compra e Venda (devidamente registrado no Cartório de Notas)
      iptu: IPTU
      property_registration: Matrícula do Imóvel',
      ir_declaration: Declaração de IR (somente se o bem estiver vinculado ao imóvel)
      okay: Está bem, entendi!
price:
  modal:
    title: Atualização de preços e taxas
    primary: Entendi e quero continuar
    secondary: Não quero editar simulação
    body: >-
      Nossas taxas e preços sofreram alterações desde a última simulação que
      você realizou pra este projeto
    subbody: >-
      Sua simulação só será atualizada se você confirmar as edições realizadas.
      Caso contrário, você seguirá com o mesmo valor inicialmente registrado.
price-change:
  modal:
    title: Atenção!
    primary: Confirmar edição
    secondary: Cancelar
    body: >-
      Esta alteração irá atualizar os valores da sua simulação, descartando a
      condição anterior. Deseja continuar?
    primary-loading: Atualizando...
global:
  statuses:
    in_progress: Em andamento
    under_analysis: Em análise
    approved: Aprovado
    to_review: Revisar
    waiting_customer: Aguardando cliente
    reproved: Reprovado
    expired: Expirado
    canceled: Cancelado
toast:
  approved:
    documentation:
      title: Documentação aprovada
      description: >-
        Analisamos a documentação enviada e está tudo correto! Siga para próxima
        etapa.
  incomplete:
    documentation:
      title: Documentação incompleta
      description: Verifique se todos os campos estão preenchidos para envio da análise.
  sended:
    documentation:
      title: Documentação enviada
      description: Documentação enviada com sucesso!
  error:
    upload:
      title: Erro ao enviar arquivo
      description: >-
        Ocorreu um erro ao enviar o arquivo, verifique o tipo do arquivo ou o
        tamanho e tente novamente.
    send:
      title: Erro ao enviar
      description: >-
        Ocorreu um erro ao enviar o formulário. Verifique os campos e tente
        novamente.
    reproved:
      description: >-
        A documentação enviada não cumpre os requisitos exigidos. Faça uma nova
        simulação ou entre em contato conosco.
      title: Documentação reprovada
  success:
    send:
      title: Documentação aprovada!
      description: >-
        Analisamos a documentação enviada e está tudo correto! Siga para a etapa
        de equipamentos.
  informative:
    send:
      under_analisys:
        title: Documentação em análise com especialista
        description: >-
          Nosso especialista está analisando a documentação enviada e retornará
          em até 24h.
lack-bank:
  modal:
    title:
      bank: Falta de dados bancários
      qi-tech: Falta de abertura de conta QI Tech
    button: Entrar em contato
    description_1: Para darmos continuidade à análise do seu projeto,
    description_2: é necessário atualizar os dados bancários da sua conta de recebimento
    description_3: >-
      , garantindo que ela esteja ativa e com a mesma titularidade do seu CNPJ.
      Para ajustar essas informações, entre em contato através:
    contact:
      description_1: Mande uma mensagem para seu Gerente de Contas via
      whatsapp: WhatsApp (11) 91238-6374
      description_2: Ligue para o
      phone: 0800 887 1548
      description_3: para falar com a Central de Atendimento
payment-model:
  modal-confirmation:
    send: Estou ciente e quero continuar
    cancel: Voltar para edição
    title: Atenção! Confira as opções selecionadas
    description-1: >-
      Após o envio das notas fiscais para análise, as opções selecionadas no
      Modelo de Recebimento
    description-2: >-
      Não deixe de conferir se está selecionada a melhor forma de recebimento
      para sua empresa! Você deseja continuar?
    description-alert: não poderão ser editadas
receipt-model:
  send-billing-letter:
    error:
      title: >-
        Carta de faturamento não enviada, favor verifique se os email estão
        corretos.
      description: Verifique os email selecionados e tente novamente.
    success:
      title: Carta de faturamento enviada!
      description: A carta de faturamento foi enviada para os emails adicionados.
  invoice:
    description-days: >-
      Você possui 10 dias a partir da data de assinatura do contrato para
      submeter as notas fiscais, caso contrário sua empresa ficará bloqueada
      para novos projetos.
    description-invoices-files: >-
      Agilize sua análise! De preferência para arquivos em .pdf. Tamanho máximo
      de 10MB por arquivo (JPEG, JPG, PNG e PDF).
result:
  tag:
    guarantor:
      pending: Necessita avalista
      approved: Avalista aprovado
      partially_approved: Aprovado parcial
      total_approved: Aprovado
billing_letter:
  download-title: Download da carta de faturamento
defaultDenialReason: Valide se o arquivo esta correto
