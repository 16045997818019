/* eslint-disable no-console */
import z from 'zod'

const envSchema = z.object({
  // app
  VITE_APP_VERSION: z.string().default('development'),
  // sentry
  VITE_SENTRY_DSN: z.string().optional(),
  // launch darkly
  VITE_LAUNCH_DARKLY_CLIENT_ID: z.string(),
  VITE_LAUNCH_DARKLY_PROJECT_KEY: z.string(),
  // Keycloak
  VITE_KEYCLOAK_API_URL: z.string(),
  VITE_KEYCLOAK_CLIENT_ID: z.string(),
  VITE_KEYCLOAK_REALM: z.string(),
  // APIs
  VITE_URL_LEGACY: z.string(),
  VITE_PERSON_API_URL: z.string(),
  VITE_SOLFACILPLUS_API_URL: z.string(),
  VITE_CORE_API_URL: z.string(),
  VITE_FINANCIAL_BFF_API_URL: z.string(),
  VITE_PAYMENT_BFF_API_URL: z.string(),
  VITE_INSTALLATION_RISK_API_URL: z.string(),
  VITE_CUSTOMER_REGISTER_API_URL: z.string(),
  VITE_SIMULATOR_SERVICE_URL_DIRECT: z.string(),
  VITE_SIMULATOR_SERVICE_URL: z.string(),
})

const env = envSchema.parse(import.meta.env)

if (import.meta.env.MODE === 'development')
  console.log(env)

export default env

declare global {
  export interface ImportMetaEnv extends z.infer<typeof envSchema> {}
}
