import type { UseFinancingData } from '~/store-v2/financings/useFinancing'
import type { ReceiptModel } from '~/utils/receipt-model/ReceiptModel'
import { useMutation } from '@tanstack/vue-query'
import queryClient, { queryKeys } from '~/queryClient'
import { postPaymentModel } from '~/services-v2/payment-model-api/payment-model-api'

interface Params {
  project_id: string
  issuance_model: string
  receipt_model: string
  supplier_id: number
  supplier_email?: string[]
  equipment_value: number
  equipment_origin: string
  shopping_cart_id: string
}

export function useUpdatePaymentModel(projectId: Ref<string>) {
  const mutationFn = async (inputs: Params) => {
    const response = await postPaymentModel(inputs)
    return response
  }

  return useMutation({
    mutationFn,
    mutationKey: queryKeys.PAYMENT_MODEL.UPDATE,
    retry: 2,
    retryDelay: 1000,
    onSuccess: () => {
      // Update the financing details in the query cache
      // to reflect the new payment model status
      queryClient.setQueryData<UseFinancingData>(queryKeys.FINANCING.DETAILS(projectId.value), (oldData) => {
        if (!oldData)
          return oldData

        return {
          ...oldData,
          section_statuses: {
            ...oldData.section_statuses,
            proposal: {
              ...oldData.section_statuses.proposal,
              payment_model: 'under_analysis',
            },
          },
        }
      })

      queryClient.setQueryData<ReceiptModel>(queryKeys.PAYMENT_MODEL.DATA(projectId.value), (oldData) => {
        if (!oldData)
          return oldData

        return {
          ...oldData,
          status: 'under_analysis',
        }
      })

      queryClient.refetchQueries({ queryKey: queryKeys.PAYMENT_MODEL.DATA(projectId.value) })
      queryClient.refetchQueries({ queryKey: queryKeys.HARDWARE.FORM(projectId.value) })
    },
  })
}
