import { useQuery } from '@tanstack/vue-query'
import { queryKeys } from '~/queryClient'
import financialBff from '~/services-v2/financial-bff/financial-bff'

export default function useProject(projectId: Ref<string>) {
  const queryKey = queryKeys.PROJECT.DETAILS(projectId.value)

  const queryFn = async () => {
    const res = await financialBff('query')({
      project: [{ projectId: projectId.value }, {
        id: true,
        project_value: true,
        complete: true,
        project_name: true,
        installments: true,
        down_payment: true,
        partner_id: true,
        person_type: true,
        document: true,
        system_power: true,
        gained_points: true,
        has_energy_bill_analysis: true,
        is_onflex_partner: true,
        new_journey: true,
        original_installment_value: true,
        insurance_commission: true,
        selected_installments: {
          grace_period: true,
          installment_value: true,
          installments: true,
          interest_rate: true,
          cet_percentage: true,
          financing_value: true,
          down_payment: true,
          commission_raw: true,
          aggregated_addons: {
            id: true,
            product_slug: true,
            type: true,
            installment_price: true,
            applied: true,
          },
          addons: {
            id: true,
            product_slug: true,
            type: true,
            installment_price: true,
            applied: true,
          },
        },
        representative_document: true,
        grace_period: true,
        commission: true,
        risk_class: true,
        max_installments: true,
        max_grace_period: true,
        pre_approved_value: true,
        selected_installment: true,
        products_applieds: true,
        user_id: true,
        created_at: true,
        credit_analysis_status: true,
        project_status: true,
        is_formalized: true,
        addons: {
          id: true,
          product_name: true,
          product_external_id: true,
          simulation_id: true,
          type: true,
          product_slug: true,
          applied: true,
          partner_commission: true,
          installment_price: true,
          total_price: true,
          engine_simulation_id: true,
          monthly_interest_rate: true,
          addon_type: true,
        },
        risk_info: {
          title: true,
          description: true,
          todo: true,
          reason: true,
          decision: true,
        },
        guarantor: {
          id: true,
        },
      }],
    })

    return res.project!
  }

  return useQuery({
    queryFn,
    queryKey,
    retry: 3,
    retryDelay: 500,
  })
}
