import type { HardwareBodySave } from '~/utils/installation/Installation'
import { useMutation } from '@tanstack/vue-query'
import { queryKeys } from '~/queryClient'
import financialBff from '~/services-v2/financial-bff/financial-bff'

export default function useHardwareValidation() {
  const mutationKey = queryKeys.HARDWARE.VALIDATION

  const mutationFn = async (input: HardwareBodySave) => {
    const response = await financialBff('mutation')({
      validateHardwareForm: [
        {
          hardwareFormInput: {
            project_id: input.project_id,
            distributor_id: input.distributor_id ?? 0,
            phase_id: input.phase_id ?? 0,
            is_combo: input.is_combo,
            combo_price: input.combo_price,
            inverter_type: input.inverter_type,
            modules: input.modules?.map(module => ({
              id: module.id,
              quantity: module.quantity,
              power: module.power ? Number.parseFloat(module.power) : null,
            })) || [],
            inverters: input.inverters?.map(inverter => ({
              id: inverter.id,
              quantity: inverter.quantity,
              power: inverter.power ? Number.parseFloat(inverter.power) : null,
            })) || [],
            batteries: input.batteries?.map(battery => ({
              id: battery.id,
              quantity: battery.quantity,
              power: battery.power ? Number.parseFloat(battery.power) : null,
            })) || [],
            combo_metadata: null,
          },
        },
        {
          is_valid: true,
          hardware: {
            inverters: {
              overload: {
                is_valid: true,
                is_under_expected: true,
              },
            },
            modules: {
              price_per_watt: {
                is_valid: true,
                is_under_expected: true,
              },
            },
          },
        },
      ],
    })

    return response.validateHardwareForm
  }

  return useMutation({
    mutationKey,
    mutationFn,
  })
}
