<script setup lang="ts">
const emit = defineEmits<{
  (e: 'send'): void
  (e: 'close'): void
}>()
const { t } = useI18n()
</script>

<template>
  <div>
    <SolModal
      id="payment-model-confirmation-modal"
      :title="t('payment-model.modal-confirmation.title')"
      :is-open="true"
      :size="{ desktop: 'medium', mobile: 'full' }"
      :action-primary-text="t('payment-model.modal-confirmation.send')"
      :action-secondary-text="t('payment-model.modal-confirmation.cancel')"
      @action:primary="emit('send')"
      @close="emit('close')"
      @action:secondary="emit('close')"
    >
      <div class="flex flex-col justify-center items-center gap-6">
        <img src="/icons/confirmation-icon-modal.svg" alt="Confirmação">
        <span class="fonts-body-medium-regular text-neutral-low-light text-center">
          {{ t('payment-model.modal-confirmation.description-1') }}
          <b>{{ t('payment-model.modal-confirmation.description-alert') }}</b>.
          {{ t('payment-model.modal-confirmation.description-2') }}
        </span>
      </div>
    </SolModal>
  </div>
</template>

<style lang="scss" scoped>
.loading-container {
  position: relative;
  width: 200px;
  height: 200px;

  .loading-circle {
    width: 100%;
    height: 100%;

    .circle-bg {
      fill: none;
      stroke: #eee;
      stroke-width: 3.8;
    }

    .circle {
      fill: none;
      stroke-width: 3.8;
      stroke-linecap: butt; /* Bordas retas */
      animation: dash 2.5s ease-in-out infinite;
    }
  }

  .loading-text {
    @apply fonts-body-medium-bold text-neutral-low-light;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 32px;
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 0, 100;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 100, 0;
    stroke-dashoffset: 0;
  }
  70% {
    stroke-dasharray: 100, 0;
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dasharray: 0, 100;
    stroke-dashoffset: -100;
  }
}
</style>
