<script setup lang="ts">
import { useToast } from '@solfacil/girassol'
import useCustomerDocumentFormAnalysisCancel from '../../store-v2/registration/useCustomerDocumentFormAnalysisCancel'

const props = defineProps({
  stepToCancel: {
    type: String,
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  projectId: {
    type: String,
    required: true,
  },
})
const emit = defineEmits<{
  (e: 'stepCanceled'): void
}>()

const { track } = useMixpanel()

const { t } = useI18n()
const toggleModalCancel = ref(false)
const toast = useToast()

const {
  mutateAsync: analysisCancel,
  isPending: isPendingCancel,
} = useCustomerDocumentFormAnalysisCancel(toRef(props.projectId))

async function cancelAnalysis() {
  track('customer_data_modal_confirm-cancel', { trigger: 'Clique no botão no mocal de cancelar análise' })

  try {
    await analysisCancel(props.stepToCancel)

    toast.createSuccessToast({
      title: '',
      description: t('form.cancelAnalysis.successToast'),
    })

    emit('stepCanceled')
  }
  catch (error) {
    console.error(error)
    toast.createErrorToast({
      description: t('form.cancelAnalysis.errorToastDescription'),
    })
  }
  finally {
    toggleModalCancel.value = false
  }
}

function openModalCancel() {
  track('customer_data_button_cancel-analysis', { trigger: 'Clique no botão cancelar análise' })
  toggleModalCancel.value = true
}
</script>

<template>
  <SolButton
    :id="`cancel_analysis-${props.stepToCancel}`"
    class="w-full md:system:w-auto order-2 md:system:order-1"
    variant="secondary"
    size="large"
    :disabled="props.disabled"
    @click="openModalCancel"
  >
    {{ t('app.cancel_analysis') }}
  </SolButton>

  <SolModal
    :id="`modal-${props.stepToCancel}`"
    :is-open="toggleModalCancel"
    :title="t('form.cancelAnalysis.title')"
    :action-secondary-text="t('form.cancelAnalysis.back')"
    :size="{
      desktop: 'small',
      mobile: 'bottom-sheet',
    }"
    @close="toggleModalCancel = false"
    @action:secondary="toggleModalCancel = false"
  >
    <template #action-primary>
      <SolButton
        id="confirm-cancel-analysis"
        size="medium"
        :loading="isPendingCancel"
        :disabled="isPendingCancel"
        @click="cancelAnalysis"
      >
        {{ t('form.cancelAnalysis.confirm') }}
      </SolButton>
    </template>
    <div>
      {{ t('form.cancelAnalysis.message') }}
    </div>
  </SolModal>
</template>
