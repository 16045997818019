import { useQuery } from '@tanstack/vue-query'
import { queryKeys } from '~/queryClient'
import financialBff from '~/services-v2/financial-bff/financial-bff'

export default function useProjectContractSigned(id: Ref<string>) {
  const queryKey = queryKeys.PROJECT.CONTRACT_SIGNED(id.value)

  const queryFn = async () => {
    const res = await financialBff('query')({
      project: [{ projectId: id.value }, {
        is_contract_signed: true,
      }],
    })

    return res.project?.is_contract_signed
  }

  return useQuery({
    queryFn,
    queryKey,
    staleTime: 1000 * 30, // 30 seconds
    refetchInterval: 1000 * 30, // 30 seconds
  })
}
