export function isStageReproved(value: Record<string, string | undefined>): string {
  Object.entries(value).forEach(([key, status]) => {
    if (status === 'reproved')
      return key
  })
  return ''
}

export function isFinancingStatusFinal(status: string): boolean {
  const statusList = ['canceled', 'reproved', 'expired']
  return statusList.includes(status)
}
