function _hj(method: string, ...args: any[]) {
  if (window.hj) {
    window.hj('consent', true)
    window.hj(method, ...args)
  }
  else {
    console.warn('Hotjar is not available')
  }
}

export default {
  identify: (id: string | number, properties: Record<string, any>) => {
    // request use cookie consent before identifying the user
    _hj('identify', id, properties)
  },
  track: (event: string, properties: Record<string, any> | string) => {
    _hj('event', event, properties)
  },
  pageView: (url: string) => {
    _hj('vpv', url)
  },
  setUserProperties: (properties: Record<string, any>) => {
    _hj('setUserProperties', properties)
  },
}
