import { useToast } from '@solfacil/girassol'
import useGuarantor from '~/store-v2/simulation/useGuarantor'

export function useIncludeGuarantor(refetchProject: () => void, setFalseLoadingGuarantor: () => void, emit: (event: 'update:is-loading', value: boolean) => void) {
  const { t } = useI18n()
  const route = useRoute()
  const { createErrorToast } = useToast()
  const { mutateAsync: mutateGuarantor, isPending } = useGuarantor()
  const formatMessageError = {
    GUARANTOR_ALREADY_EXISTS: t('form.guarantor.error_toast_default'),
    GUARANTOR_SAME_DOCUMENT: t('form.guarantor.error_toast_guarantor_same_document'),
    GUARANTOR_NOT_ELIGIBLE: t('form.guarantor.error_toast_default'),
    DEFAULT: t('form.guarantor.error_toast_default'),
  }

  async function includeGuarantor(cpf: string, resetField: () => void, setErrors: (error: string) => void, validate: () => Promise<{ valid: boolean }>) {
    const { valid } = await validate()
    if (!valid)
      return

    setErrors('')

    if (!cpf) {
      setErrors(t('form.cpf_required'))
      return
    }

    const regexOnlyNumbers = /\D/g
    const cleanCpf = cpf.replace(regexOnlyNumbers, '')

    try {
      emit('update:is-loading', true)
      await mutateGuarantor({ project_id: String(route.params.id), document: cleanCpf })
      resetField()
      refetchProject()
    }
    catch (error: any) {
      createErrorToast(formatMessageError[error?.response?.data?.detail?.error_key || formatMessageError.DEFAULT] || t('form.guarantor.error_toast_default'))
    }
    finally {
      setFalseLoadingGuarantor()
    }
  }

  return { includeGuarantor, isPending }
}
