import type { UseRegistrationData } from '~/store-v2/registration/useRegistration'
import type {
  Error,
  ErrorPayload,
  TagStep,
} from '~/ui-blocks/Formalization/Formalization.types'
import { STATUSES_MAPPING } from '~/services-v2/financial-bff/financial-bff.consts'

export const documentOptions = [
  { value: 'rg', name: 'RG (Identidade)' },
  { value: 'cnh', name: 'CNH' },
  { value: 'cnh_digital', name: 'CNH digital' },
]

const expectedErrors: { field: string }[] = [
  // generic
  { field: 'generic.matrizaf.reproved' },
  { field: 'generic.qi_tech_account' },
  { field: 'generic.bank_account' },
  // customer
  { field: 'customer.document.driver_license_front' },
  { field: 'customer.document.driver_license_back' },
  { field: 'customer.document.digital_driver_license' },
  { field: 'customer.document.identity_document_front' },
  { field: 'customer.document.identity_document_back' },
  { field: 'customer.contact.email' },
  { field: 'customer.contact.phone' },
  { field: 'customer.fullname' },
  { field: 'customer.birthdate' },
  { field: 'customer.address.zipcode' },
  // guarantor
  { field: 'guarantor.document.driver_license_front' },
  { field: 'guarantor.document.driver_license_back' },
  { field: 'guarantor.document.digital_driver_license' },
  { field: 'guarantor.document.identity_document_front' },
  { field: 'guarantor.document.identity_document_back' },
  { field: 'guarantor.contact.email' },
  { field: 'guarantor.contact.phone' },
  { field: 'guarantor.fullname' },
  { field: 'guarantor.birthdate' },
  { field: 'guarantor.address.zipcode' },
  // company
  { field: 'company.contact.email' },
  { field: 'company.contact.phone' },
  { field: 'company.document.company_official_document' },
  { field: 'company.address.zipcode' },
  { field: 'company.company_name' },
  // majority_partner
  { field: 'majority_partner.document.identity' },
  { field: 'majority_partner.document.driver_license_front' },
  { field: 'majority_partner.document.driver_license_back' },
  { field: 'majority_partner.document.digital_driver_license' },
  { field: 'majority_partner.document.identity_document_front' },
  { field: 'majority_partner.document.identity_document_back' },
  { field: 'majority_partner.contact.email' },
  { field: 'majority_partner.contact.phone' },
  { field: 'majority_partner.fullname' },
  { field: 'majority_partner.birthdate' },
  { field: 'majority_partner.address.zipcode' },
  // legal_representative_1
  { field: 'legal_representative_1.document.identity' },
  { field: 'legal_representative_1.document.driver_license_front' },
  { field: 'legal_representative_1.document.driver_license_back' },
  { field: 'legal_representative_1.document.digital_driver_license' },
  { field: 'legal_representative_1.document.identity_document_front' },
  { field: 'legal_representative_1.document.identity_document_back' },
  { field: 'legal_representative_1.contact.email' },
  { field: 'legal_representative_1.contact.phone' },
  { field: 'legal_representative_1.fullname' },
  { field: 'legal_representative_1.cpf' },
  { field: 'legal_representative_1.birthdate' },
  { field: 'legal_representative_1.address.zipcode' },
  // legal_representative_2
  { field: 'legal_representative_2.document.identity' },
  { field: 'legal_representative_2.document.driver_license_front' },
  { field: 'legal_representative_2.document.driver_license_back' },
  { field: 'legal_representative_2.document.digital_driver_license' },
  { field: 'legal_representative_2.document.identity_document_front' },
  { field: 'legal_representative_2.document.identity_document_back' },
  { field: 'legal_representative_2.contact.email' },
  { field: 'legal_representative_2.contact.phone' },
  { field: 'legal_representative_2.fullname' },
  { field: 'legal_representative_2.cpf' },
  { field: 'legal_representative_2.birthdate' },
  { field: 'legal_representative_2.address.zipcode' },
  // project
  { field: 'project.document.employment_proof' },
  { field: 'project.document.electricity_bill' },
  { field: 'project.document.additional' },
  { field: 'project.electricity_bill.is_under_customer_name' },
  { field: 'project.electricity_bill.holder_cpf' },
  { field: 'project.address.zipcode' },
  { field: 'project.generic.reproved' },
]

export function getUnexpectedError(backendErrors: Omit<Error, 'type'>[] | undefined): Omit<Error, 'type'> | null {
  const handledErrors: Record<string, Omit<Error, 'type'>> = {}
  let firstUnexpectedError: Omit<Error, 'type'> | null = null

  backendErrors?.forEach((backendError) => {
    const expectedError = expectedErrors.find(error => error.field === backendError.field)

    if (expectedError) {
      handledErrors[backendError.field] = {
        field: expectedError.field,
        title: backendError.title,
        description: backendError.description,
      }
    }
    else {
      if (!firstUnexpectedError) {
        firstUnexpectedError = backendError
      }
    }
  })

  return firstUnexpectedError
}

export function getErrorPath(errors: Omit<Error, 'type'>[] | undefined, errorPath: string[]) {
  if (!errors || errorPath.length === 0)
    return undefined

  return errors.find((err) => {
    return errorPath.some(path => err.field.startsWith(path))
  })
}

export function fieldNumberShouldBeValidated(field: number) {
  return field > 0
}

export function getStatusTag(t, statusKey: string | undefined): TagStep {
  if (!statusKey || !STATUSES_MAPPING[statusKey]) {
    return {
      id: 'unknown',
      text: t('unknown'),
      variant: 'outline',
      size: 'medium',
      type: 'neutral',
    }
  }
  const status = STATUSES_MAPPING[statusKey]

  return {
    id: statusKey,
    text: t(status.textKey),
    variant: 'fill',
    size: 'medium',
    type: status.color,
  }
}

export function categorizeErrorsPF(errors: ErrorPayload[]) {
  const categorizedErrors = {
    customer: [] as Error[],
    customerAddress: [] as Error[],
    project: [] as Error[],
    projectAddress: [] as Error[],
    guarantor: [] as Error[],
    guarantorAddress: [] as Error[],
  }

  errors.forEach(({ message, field, ...rest }) => {
    const error = { ...rest, title: message, description: '', field }

    switch (true) {
      case field.startsWith('customer.address'):
        categorizedErrors.customerAddress.push(error)
        break
      case field.startsWith('project.address'):
        categorizedErrors.projectAddress.push(error)
        break
      case field.startsWith('guarantor.address'):
        categorizedErrors.projectAddress.push(error)
        break
      case field.startsWith('customer'):
        categorizedErrors.customer.push(error)
        break
      case field.startsWith('project'):
        categorizedErrors.project.push(error)
        break
      case field.startsWith('guarantor'):
        categorizedErrors.project.push(error)
        break
      default:
        break
    }
  })

  return categorizedErrors
}

export function showErrorsFieldsPF(
  errors: Record<string, Error[]>,
  customerDocumentationForm: any,
  guarantorDocumentationForm: any,
  residencialAddressForm: any,
  electricityBillForm: any,
  installationAddressForm: any,
  guarantorAddressForm: any,
) {
  const formMap = {
    customer: customerDocumentationForm,
    guarantor: guarantorDocumentationForm,
    customerAddress: residencialAddressForm,
    project: electricityBillForm,
    projectAddress: installationAddressForm,
    guarantorAddress: guarantorAddressForm,
  }

  const fieldPrefixes = [
    'project.address.',
    'customer.address.',
    'guarantor.address.',
    'customer.contact.',
    'guarantor.contact.',
    'project.',
    'customer.',
    'guarantor.',
  ]
  const fieldRegex = new RegExp(`^(${fieldPrefixes.join('|')})`)

  Object.entries(errors).forEach(([key, errorList]) => {
    const form = formMap[key]
    errorList.forEach(({ field, title }) => {
      const fieldName = field.replace(fieldRegex, '')
      form.setFieldError(fieldName, title)
    })
  })
}

export function categorizeErrorsPJ(errors: ErrorPayload[]) {
  const categorizedErrors = {
    company: [] as Error[],
    majority: [] as Error[],
    majorityAddress: [] as Error[],
    Rep1: [] as Error[],
    Rep1Address: [] as Error[],
    Rep2: [] as Error[],
    Rep2Address: [] as Error[],
    project: [] as Error[],
    projectAddress: [] as Error[],
  }

  errors.forEach(({ message, field, ...rest }) => {
    const error = { ...rest, title: message, description: '', field }

    switch (true) {
      case field.startsWith('project.address'):
        categorizedErrors.projectAddress.push(error)
        break
      case field.startsWith('majority_partner.address'):
        categorizedErrors.majorityAddress.push(error)
        break
      case field.startsWith('legal_representative_one.address'):
        categorizedErrors.Rep1Address.push(error)
        break
      case field.startsWith('legal_representative_two.address'):
        categorizedErrors.Rep2Address.push(error)
        break
      case field.startsWith('project'):
        categorizedErrors.project.push(error)
        break
      case field.startsWith('company.majority_partner'):
        categorizedErrors.majority.push({ ...error, field: field.replace('company.majority_partner.', '') })
        break
      case field.startsWith('company'):
        categorizedErrors.company.push(error)
        break
      case field.startsWith('majority_partner'):
        categorizedErrors.majority.push(error)
        break
      case field.startsWith('legal_representative_one'):
        categorizedErrors.Rep1.push(error)
        break
      case field.startsWith('legal_representative_two'):
        categorizedErrors.Rep2.push(error)
        break
      default:
        break
    }
  })

  return categorizedErrors
}

export function showErrorsFieldsPJ(
  errors: Record<string, Error[]>,
  companyForm: any,
  majorityPartnerDataForm: any,
  majorityPartnerAddressForm: any,
  legalRepresentative1DataForm: any,
  legalRepresentative1AddressForm: any,
  legalRepresentative2DataForm: any,
  legalRepresentative2AddressForm: any,
  electricityBillForm: any,
  projectAddressForm: any,
) {
  const formMap = {
    company: companyForm,
    majority: majorityPartnerDataForm,
    majorityAddress: majorityPartnerAddressForm,
    Rep1: legalRepresentative1DataForm,
    Rep1Address: legalRepresentative1AddressForm,
    Rep2: legalRepresentative2DataForm,
    Rep2Address: legalRepresentative2AddressForm,
    project: electricityBillForm,
    projectAddress: projectAddressForm,
  }

  const fieldPrefixes = [
    'project.address.',
    'company.address.',
    'company.contact.',
    'majority_partner.address.',
    'majority_partner.contact.',
    'legal_representative_one.address.',
    'legal_representative_one.contact.',
    'legal_representative_two.address.',
    'legal_representative_two.contact.',
    'project.',
    'company.',
    'majority_partner.',
    'legal_representative_one.',
    'legal_representative_two.',
  ]
  const fieldRegex = new RegExp(`^(${fieldPrefixes.join('|')})`)

  Object.entries(errors).forEach(([key, errorList]) => {
    const form = formMap[key]
    errorList.forEach(({ field, title }) => {
      const fieldName = field.replace(fieldRegex, '')
      form.setFieldError(fieldName, title)
    })
  })
}

export function extractGloballErrors(registration: UseRegistrationData) {
  const err = getErrorPath(registration?.errors, [
    'generic.qi_tech_account',
    'generic.bank_account',
    'project.generic.reproved',
    'generic.matrizaf.reproved',
    'project.generic.to_review',
    'generic.to_review',
  ])

  if (!err)
    return null

  const alertMessages = {
    'generic.qi_tech_account': {
      title: 'Ausência de dados Qi Tech',
      description: 'Atualize seu cadastro com os dados bancários para prosseguir com o financiamento.',
      type: 'warning',
      showModal: true,
      lackType: 'qi-tech',
    },
    'generic.bank_account': {
      title: 'Ausência de dados bancários',
      description: 'Atualize seu cadastro com os dados bancários para prosseguir com o financiamento.',
      type: 'warning',
      showModal: true,
      lackType: 'bank',
    },
    'project.generic.reproved': {
      title: err.title,
      description: err.description,
      showModal: false,
      type: 'error',
      lackType: 'reproved',
    },
    'project.generic.to_review': {
      title: err.title,
      description: err.description,
      showModal: false,
      type: 'warning',
      lackType: 'to_review',
    },
    'generic.matrizaf.reproved': {
      title: 'Não conseguimos seguir com esse financiamento',
      description: `A proposta foi reprovada na etapa de validação de dados devido a inconsistências nas informações apresentadas, conforme previsto em nossa política interna.`,
      showModal: false,
      type: 'error',
      lackType: 'reproved',
    },
    'generic.to_review': {
      title: err.title,
      description: err.description,
      showModal: false,
      type: 'warning',
      lackType: 'to_review',
    },
  } as const

  const alert = alertMessages[err.field as keyof typeof alertMessages]

  if (!alert) {
    if (err.field.startsWith('generic')) {
      return {
        title: err.title,
        description: err.description,
        type: 'warning',
        field: err.field,
        showModal: false,
        lackType: 'generic',
      }
    }
    else {
      return null
    }
  }

  return {
    title: alert.title,
    description: alert.description,
    type: alert.type,
    field: err.field,
    showModal: alert.showModal,
    lackType: alert.lackType,
  }
}
