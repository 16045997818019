<script setup lang="ts">
import type { StageFormalization } from '~/ui-blocks/Formalization/Formalization.types'
import type { ProposalStatus } from '~/utils/financing-flow/Financing'
import { useToast } from '@solfacil/girassol'
import { toTypedSchema } from '@vee-validate/zod'
import { match } from 'ts-pattern'
import { useForm } from 'vee-validate'
import IconLock from '~icons/material-symbols/lock'
import IconVisibilityOff from '~icons/material-symbols/visibility-off-outline'
import IconVisibility from '~icons/material-symbols/visibility-outline'
import ToggleSwitch from '~/components/shared/ToggleSwitch.vue'
import queryClient, { queryKeys } from '~/queryClient'
import hotjar from '~/services-v2/hotjar/hotjar'
import SimulatorService from '~/services/simulator/Simulator'
import useFinancingV2 from '~/store-v2/financings/useFinancing'
import { FinancingStatus } from '~/store-v2/financings/useFinancings'
import useOpenableSteps from '~/store-v2/financings/useOpenableSteps'
import useHardwareForm from '~/store-v2/hardware/useHardwareForm'
import useMirrorStatus from '~/store-v2/hardware/useMirrorStatus'
import { usePostMirror } from '~/store-v2/hardware/usePostMirrorStatus'
import usePaymentModel from '~/store-v2/payment-model/usePaymentModel'
import { useUpdatePaymentModel } from '~/store-v2/payment-model/useUpdatePaymentModel'
import useGetShoppingCartId from '~/store-v2/simulation/useGetShoppingCartId'
import { Formalization } from '~/types/enum'
import ConfirmationModal from '~/ui-blocks/Formalization/blocks/paymentModel/ConfirmationModal.vue'
import InvoicesForm from '~/ui-blocks/Formalization/blocks/paymentModel/Invoice/Invoice.form.vue'
import * as InvoiceSchema from '~/ui-blocks/Formalization/blocks/paymentModel/Invoice/Invoice.schema'
import PaymentModelModal from '~/ui-blocks/Formalization/blocks/paymentModel/PaymentModelModal.vue'
import SupplierInformationForm from '~/ui-blocks/Formalization/blocks/paymentModel/supplierInformation/SupplierInformation.form.vue'
import * as SupplierSchema from '~/ui-blocks/Formalization/blocks/paymentModel/supplierInformation/SupplierInformation.schema'
import { getErrorMessage } from '~/utils/errors'
import { GeneralStatuses } from '~/utils/financing-flow/Financing'
import { HardwareMirrorStatus } from '~/utils/installation/Installation'
import { getStatusTag } from '../../../ui-blocks/Formalization/Formalization.utils'

// props
const props = defineProps<{
  position: number
  stage: StageFormalization
  stageReproved: string
  statusProject: ProposalStatus
  isOnflexPartner: boolean
}>()

// initial base hooks
const projectShoppingCartId: any = ref('')
const { track } = useMixpanel()
const { t } = useI18n()
const route = useRoute()
const router = useRouter()
const projectId = computed(() => String(route.params.id))
const { createErrorToast, createSuccessToast } = useToast()
const simulatorService = new SimulatorService()
// initial variables
const methodReceiptSelected = ref('')
const methodInvoiceSelected = ref('')
const submitForm = ref(false)
const loadingButtonSendFiles = ref(false)
const blocked = ref(true)
const equipmentOrigin = ref<string | null>(null)
const comboSelected = ref(false)
const alertError = ref({
  title: '',
  description: '',
  type: '',
})
const alertSuccess = ref({
  title: '',
  description: '',
  type: '',
})
const showCommissionInvoiceInfo = ref(false)
const paymentModelModal = ref(false)
const confirmationModal = ref(false)
const firstPercentToTransfer = ref(0)
const secondPercentToTransfer = ref(0)
const PERCENTAGE_DEFAULT = 50
const hasSuppliers = ref(false)
const mirrorFile = ref<File[] | null>(null)
const showMirrorFileError = ref(false)
const showcaseSelected = ref(false)
const skipMirrorPolling = ref(true)
const shoppingCartIdSelected = ref('')

const mirrorIsAttached = ref(false)
// domain hooks

const { data: financing } = useFinancingV2(projectId)

const {
  data: dataStepsStatus,
  refetch: refetchDataStepsStatus,
} = useOpenableSteps(projectId)

const {
  data: paymentModelData,
  isLoading: isLoadingPaymentModel,
  error: errorPaymentModel,
  refetch: refetchPaymentModel,
} = usePaymentModel(projectId)

const {
  data: shoppingCartId,
  isLoading: isLoadingShoppingCardId,
} = useGetShoppingCartId(projectId.value)

const {
  refetch: refetchHardwareForm,
  isLoading: isLoadingHardwareForm,
} = useHardwareForm(projectId)

const {
  mutateAsync: mutatePostMirror,
} = usePostMirror(projectId.value)

const {
  mutateAsync: mutateUpdatePaymentModel,
} = useUpdatePaymentModel(projectId)

const { data: dataMirrorStatus } = useMirrorStatus(projectId, skipMirrorPolling)

// INITIAL FORMS
const financingValue = computed(() => paymentModelData.value?.financing_value ?? 0)

const supplierForm = useForm({
  initialValues: SupplierSchema.getInitialValues(),
  validationSchema: computed (() => toTypedSchema(SupplierSchema.getSchema(t, financingValue.value))),
  validateOnMount: false,
})

const isSingleInvoice = computed(() => {
  return !paymentModelData.value?.has_combo && methodInvoiceSelected.value === 'single_invoice'
})

const hasFinancingCommissionToReceive = computed(() => {
  if (!paymentModelData.value?.commissions?.financing?.value)
    return false
  return paymentModelData.value?.commissions?.financing?.value > 0
})

const isSeparateInvoices = computed(() => {
  return !paymentModelData.value?.has_combo && methodInvoiceSelected.value === 'separate_invoices'
})

const invoiceForm = useForm({
  initialValues: InvoiceSchema.getInitialValues(),
  validationSchema: computed(() => toTypedSchema(InvoiceSchema.getSchema({
    isSingleInvoice: isSingleInvoice.value || equipmentOrigin.value === 'container',
    t,
    isSeparateInvoices: isSeparateInvoices.value,
    hasFinancingCommissionToReceive: hasFinancingCommissionToReceive.value,
  }))),
  validateOnMount: false,
})

// computed
const isFinancingDisabled = computed(() => {
  if (!financing.value) {
    return false
  }

  return [
    FinancingStatus.approved,
    FinancingStatus.reproved,
    FinancingStatus.expired,
    FinancingStatus.canceled,
  ].includes(financing.value.status)
})
const isLoading = computed(
  () => isLoadingPaymentModel.value || isLoadingShoppingCardId.value || isLoadingHardwareForm.value,
)

const showTag = computed(() => {
  return blocked.value
    || !(dataStepsStatus.value?.openable_steps?.receiptModel)
    ? undefined
    : getStatusTag(t, props.statusProject.payment_model)
})

const isTransferAfterInstallation = computed(
  () => paymentModelData.value?.roles?.in_cash_at_installation,
)

const isTransferAfterFormalization = computed(
  () => paymentModelData.value?.roles?.in_cash_at_formalization,
)

const invoicesToShow = computed(() => {
  return !paymentModelData.value?.has_combo || paymentModelData.value?.commissions?.financing?.value > 0 || (paymentModelData.value?.is_container_balance_available && equipmentOrigin.value === 'container')
})

const invoiceValueInput = computed(() => (supplierForm.values.equipment_value || paymentModelData.value?.equipment_value) ?? 0)

const fieldIsDisabledBasedStatus = computed(() => {
  return isFinancingDisabled.value || getStatusProjectToDisabledFields(props.statusProject.payment_model)
})

const hasCombo = computed(() => paymentModelData.value ? paymentModelData.value.has_combo : false)

const isEligibleUserToChoosePayment = computed(() => {
  return paymentModelData.value?.roles?.in_cash && paymentModelData.value.roles?.instalments
})

const isEligibleUserToChooseInvoices = computed(() => {
  return paymentModelData.value?.roles?.invoices?.separate_invoices && paymentModelData.value.roles?.invoices?.single_invoice
})

const inCash = computed(() => {
  return methodReceiptSelected.value === 'in_cash'
})

const hasCorrectionLetter = computed(() => {
  return props.statusProject.payment_model === 'to_review'
})

const getOptions = computed(() => {
  return {
    isSingleInvoice: isSingleInvoice.value,
    isSeparateInvoices: isSeparateInvoices.value,
    hasCorrectionLetter: hasCorrectionLetter.value,
    hasFinancingCommissionToReceive: hasFinancingCommissionToReceive.value,
  }
})

const isContainer = computed(() => {
  return paymentModelData.value?.is_container_balance_available
})

// function

function getStatusProjectToDisabledFields(status) {
  return ['approved', 'reproved', 'under_analysis', 'awaiting_analisys_equipment'].includes(status)
}

function onSelectedInCashOrInstallment(type: string) {
  methodReceiptSelected.value = type
  track('formalizing_receive-model_button-group_payment', { trigger: 'Clique no button group de à vista/parcelado', choice: type })
  submitForm.value = false
}

function onInvoiceSelected(type: string) {
  methodInvoiceSelected.value = type
  track('formalizing_receive-model_button-group_invoice', { trigger: 'Clique no button group de uma nota/duas notas', choice: type })
}

function openModal() {
  paymentModelModal.value = true
  track('formalizing_receive-model_alert_more-info', { trigger: 'Clique no button no alert de Saiba Mais referente a pagamentos parcelados obrigatório' })
}

function transferPartner(_paymentModelData) {
  const transfers = _paymentModelData.transfers
  if (transfers) {
    transfers.some(transfer => transfer.status !== 'waiting_send_payment')

    const partnerTransfers = transfers
      .filter(transfer => transfer.recipient === 'partner' && transfer.category === 'financing')
      .map(transfer => transfer.percent)

    if (partnerTransfers.length > 0)
      firstPercentToTransfer.value = partnerTransfers[0]

    if (partnerTransfers.length > 1)
      secondPercentToTransfer.value = partnerTransfers[1]
  }
}

function formattedTitle() {
  return isTransferAfterInstallation.value
    ? t('receiptModel.onflex.title')
    : t('receiptModel.alertInstallments.title', {
        firstValue: firstPercentToTransfer.value || PERCENTAGE_DEFAULT,
        secondValue: secondPercentToTransfer.value || PERCENTAGE_DEFAULT,
      })
}

function formattedText() {
  return isTransferAfterInstallation.value
    ? t('receiptModel.onflex.subtitle')
    : t('receiptModel.alertInstallments.text', {
        firstValue: firstPercentToTransfer.value || PERCENTAGE_DEFAULT,
        secondValue: secondPercentToTransfer.value || PERCENTAGE_DEFAULT,
      })
}

async function postReceiptModel(payload) {
  try {
    await mutateUpdatePaymentModel(payload)
    track('formalizing_receive-model_button_next', { trigger: 'Clique no botão Próximo em Modelo de Recebimento', payload })
    createSuccessToast({
      title: t('receiptModel.response.success.title'),
      description: t('receiptModel.response.success.description'),
    })

    queryClient.refetchQueries({ queryKey: queryKeys.PAYMENT_MODEL.DATA(projectId.value) })
  }
  catch (error) {
    console.error(error)

    createErrorToast({
      title: t('receiptModel.response.error.title'),
      description: t('receiptModel.response.error.description'),
    })
  }
  finally {
    submitForm.value = false
    loadingButtonSendFiles.value = false
  }
}

function handleOnKitOrigin(value: string) {
  equipmentOrigin.value = match(value)
    .with('mirror', () => 'mirror')
    .with('showcase', () => 'vitrine')
    .otherwise(() => paymentModelData?.value?.is_container_balance_available ? 'container' : null)
}

function handleMirrorSubmit(value) {
  mirrorFile.value = value
  showMirrorFileError.value = false
}

function handleShowcaseSelected(value: boolean) {
  showcaseSelected.value = value
}

function handleFileAttached(value) {
  mirrorIsAttached.value = value
}

function handleShoppingCartId(value) {
  shoppingCartIdSelected.value = value
}

const resolveEquipmentOrigin = computed(() => {
  const isContainer = paymentModelData.value?.is_container_balance_available

  let origin = isContainer ? 'container' : equipmentOrigin.value

  if (isContainer && equipmentOrigin.value === 'mirror' && !mirrorFile.value) {
    origin = 'container'
  }
  else if (isContainer && equipmentOrigin.value === 'vitrine' && !showcaseSelected.value) {
    origin = 'container'
  }
  else {
    origin = equipmentOrigin.value
  }

  return origin
})

function createPayload(issuanceModel: string, receiptModel: string, supplierId: number | null = null, equipmentValue: number | null = null) {
  return {
    project_id: String(router.currentRoute.value.params.id),
    issuance_model: issuanceModel,
    receipt_model: receiptModel,
    supplier_id: supplierId,
    equipment_value: equipmentValue,
    equipment_origin: resolveEquipmentOrigin.value,
    shopping_cart_id: equipmentOrigin.value === 'vitrine' ? shoppingCartId.value || shoppingCartIdSelected.value || projectShoppingCartId.value : null,
  }
}

async function finalizeSendFilesProcess() {
  scrollIntoViewSupplier()
  submitForm.value = false
  loadingButtonSendFiles.value = false
  await refetchDataStepsStatus()
  await refetchPaymentModel()
}

function initSendFilesProcess() {
  submitForm.value = true
  loadingButtonSendFiles.value = true
}

async function sendFilesWhenOnflex() {
  if (!paymentModelData.value) {
    return
  }

  if (hasCombo.value) {
    const payload = createPayload(paymentModelData?.value?.issuance_model, 'service_invoice')
    await postReceiptModel(payload)
  }
  else {
    const _hardwareSupplier = typeof supplierForm.values.hardware_supplier === 'string' ? null : supplierForm.values.hardware_supplier
    const payload = createPayload(
      paymentModelData?.value?.issuance_model,
      paymentModelData?.value?.receipt_model,
      _hardwareSupplier?.value ?? null,
      invoiceValueInput.value,
    )
    await postReceiptModel(payload)
  }

  finalizeSendFilesProcess()
}
async function pollingMirrorStatus() {
  skipMirrorPolling.value = false
  const interval = setInterval(async () => {
    if (dataMirrorStatus.value === HardwareMirrorStatus.APPROVED) {
      clearInterval(interval)

      await refetchHardwareForm()

      await refetchDataStepsStatus()
      await refetchPaymentModel()
      skipMirrorPolling.value = true
    }
  }, 15000) // 15 seconds
}

async function submitMirror() {
  if (!paymentModelData.value)
    return

  try {
    if (mirrorFile.value === null && !mirrorIsAttached.value) {
      showMirrorFileError.value = true
      createErrorToast({
        title: t('toast.incomplete.documentation.title'),
        description: t('toast.incomplete.documentation.description'),
        actionText: '',
      })
      finalizeSendFilesProcess()
      return
    }
    if (mirrorFile.value === null && mirrorIsAttached.value) {
      return true
    }

    if (mirrorFile.value) {
      if (mirrorFile.value && mirrorFile.value.length > 0) {
        const resp = await mutatePostMirror(mirrorFile.value[0])

        if (resp.url) {
          await refetchHardwareForm()
          pollingMirrorStatus()
        }
      }
      return true
    }
  }
  catch (error) {
    console.error(error)
    createErrorToast({
      title: t('receiptModel.response.error.mirror-title'),
      description: t('receiptModel.response.error.mirror-description'),
    })
  }
}

async function validateAllForms(): Promise<boolean> {
  const forms = [invoiceForm, supplierForm]

  if (methodInvoiceSelected.value !== 'separate_invoices') {
    forms.splice(1, 1)
  }

  const results = await Promise.all(forms.map(form => form.validate()))
  const isValid = results.every(result => result.valid)

  return isValid
}

async function handleSendClick() {
  const isValid = await validateAllForms()

  if (!isValid) {
    createErrorToast({
      title: t('toast.incomplete.documentation.title'),
      description: t('toast.incomplete.documentation.description'),
      actionText: '',
    })

    return
  }

  confirmationModal.value = true
}

async function sendFiles() {
  if (!paymentModelData.value)
    return

  confirmationModal.value = false
  const isValid = await validateAllForms()

  if (!isValid) {
    createErrorToast({
      title: t('toast.incomplete.documentation.title'),
      description: t('toast.incomplete.documentation.description'),
      actionText: '',
    })

    return
  }

  hotjar.track('clicked_btn_enviar_MR', 'Clique no botão Enviar em Modelo de Recebimento no modal de confirmação')

  initSendFilesProcess()

  if (hasCombo.value && equipmentOrigin.value === 'mirror') {
    const resp = await submitMirror()
    if (resp === undefined || resp === null) {
      finalizeSendFilesProcess()
      return
    }
  }

  // Handle transfers after installation
  if (isTransferAfterInstallation.value) {
    await sendFilesWhenOnflex()
    finalizeSendFilesProcess()
    return
  }

  let issuanceModel
  let receiptModel
  let equipmentValue: number | null = null

  const supplierVal
    = typeof supplierForm.values.hardware_supplier === 'string'
      ? null
      : supplierForm.values.hardware_supplier

  if (hasCombo.value) {
    issuanceModel = isEligibleUserToChoosePayment.value
      ? methodReceiptSelected.value
      : paymentModelData.value.issuance_model
    receiptModel = isEligibleUserToChooseInvoices.value
      ? methodInvoiceSelected.value
      : equipmentOrigin.value === 'container' ? 'single_invoice' : paymentModelData.value.receipt_model
  }
  else {
    issuanceModel = methodReceiptSelected.value
    receiptModel = methodInvoiceSelected.value

    if (isEligibleUserToChooseInvoices.value && methodInvoiceSelected.value === 'separate_invoices') {
      equipmentValue = invoiceValueInput.value
    }
    else if (!isEligibleUserToChooseInvoices.value) {
      equipmentValue = invoiceValueInput.value
    }
  }

  const payload
    = issuanceModel && receiptModel
      ? createPayload(
          issuanceModel,
          receiptModel,
          supplierVal?.value ?? null,
          equipmentValue,
        )
      : null

  if (payload) {
    await postReceiptModel(payload)
  }
  else {
    submitForm.value = false
    loadingButtonSendFiles.value = false
  }

  finalizeSendFilesProcess()
}

function scrollIntoViewSupplier() {
  const infoSupplierElement = document.querySelector('#infoSupplier')
  if (infoSupplierElement)
    infoSupplierElement.scrollIntoView({ behavior: 'smooth' })
}

function checkStageReproved() {
  const isContractBlocked = props.statusProject.biometry_and_contract !== GeneralStatuses.APPROVED

  if (props.stageReproved === '' && !isContractBlocked)
    blocked.value = false
  else
    blocked.value = (props.stageReproved !== Formalization.PAYMENT_MODEL || isContractBlocked)
}

function handleOnComboSelected(value: { selected: boolean }) {
  comboSelected.value = value.selected
}

function showAlertGlobalStatus(status) {
  if (['approved'].includes(status)) {
    alertSuccess.value.title = 'Documentação aprovada! Acompanhe o resumo da transferência para identificar seus pagamentos'
    alertSuccess.value.description = ' A documentação enviada foi aprovada por nossos especialistas. Agora, não deixe de acompanhar o resumo da transferência abaixo. Nele você acompanha o andamento do seu projeto e pagamentos realizados pela Solfácil.'
    alertSuccess.value.type = 'success'
  }

  if (['to_review', 'pending_change'].includes(status)) {
    alertError.value.title = 'Nota fiscal com informações divergentes do projeto'
    alertError.value.description = 'Verifique se as informações de descrição de kit, endereço, alienação e comprovantes estão preenchidas corretamente na nota fiscal enviada. Caso necessário, anexe uma carta de correção com as informações ajustadas de acordo com o projeto. '
    alertError.value.type = 'warning'
  }
}

function setToggleSwitchs(_paymentModelData) {
  methodReceiptSelected.value = _paymentModelData.issuance_model
  methodInvoiceSelected.value = _paymentModelData.receipt_model
}

async function getShoppingCartId() {
  try {
    const response = await simulatorService.get_simulation_by_shopping_cart_id(String(router.currentRoute.value.params.id))
    projectShoppingCartId.value = response.data
  }
  catch (error) {
    console.error(error)
    projectShoppingCartId.value = ''
  }
}

// watchers

watch(() => props.statusProject, async () => {
  await refetchDataStepsStatus()
  if (dataStepsStatus.value?.openable_steps?.contract) {
    blocked.value = false
  }

  await refetchPaymentModel()
  checkStageReproved()

  if (props.statusProject.payment_model === 'approved') {
    alertSuccess.value.title = 'Documentação aprovada! Acompanhe o resumo da transferência para identificar seus pagamentos'
    alertSuccess.value.description = 'A documentação enviada foi aprovada por nossos especialistas. Agora, não deixe de acompanhar o resumo da transferência abaixo. Nele você acompanha o andamento do seu projeto e pagamentos realizados pela Solfácil.'
    alertSuccess.value.type = 'success'
  }

  if (props.statusProject.payment_model === 'to_review') {
    alertError.value.title = 'Nota fiscal com informações divergentes do projeto'
    alertError.value.description = 'Verifique se as informações de descrição de kit, endereço, alienação e comprovantes estão preenchidas corretamente na nota fiscal enviada. Caso necessário, anexe uma carta de correção com as informações ajustadas de acordo com o projeto. '
    alertError.value.type = 'warning'
  }
}, { immediate: true })

watch(() => paymentModelData.value, (_paymentModelData) => {
  if (!_paymentModelData)
    return

  setToggleSwitchs(_paymentModelData)
  transferPartner(_paymentModelData)

  showAlertGlobalStatus(_paymentModelData.status)

  hasSuppliers.value = _paymentModelData.receipt_model === 'separate_invoices'
}, { immediate: true })

const hasShowcaseSelected = computed(() => {
  return !!(equipmentOrigin.value === 'vitrine' && (shoppingCartId.value || projectShoppingCartId.value || showcaseSelected.value || shoppingCartIdSelected.value))
})

const isComboValidationRequired = computed(() => {
  if (!paymentModelData.value?.has_combo) {
    return false
  }
  const conditions = [
    { origin: 'container', check: paymentModelData.value?.is_container_balance_available },
    { origin: 'mirror', check: paymentModelData.value?.has_combo && mirrorIsAttached.value },
    { origin: 'vitrine', check: paymentModelData.value?.has_combo && hasShowcaseSelected.value },
  ]

  return !conditions.some(({ origin, check }) => equipmentOrigin.value === origin && check)
})

const disabledSendButton = computed(() => {
  return fieldIsDisabledBasedStatus.value || isComboValidationRequired.value
})

const orderIdValue = computed(() => {
  return paymentModelData.value?.order_id
})

onMounted(async () => {
  await getShoppingCartId()
})
</script>

<template>
  <CustomerElementAccordion
    :id="`accordion_${Formalization.PAYMENT_MODEL}`"
    :title="t('customer.receipt_model_title')"
    :position="String(position)"
    :tag="showTag"
    :blocked="blocked || !dataStepsStatus?.openable_steps?.receiptModel"
    :open-collapse="dataStepsStatus?.openable_steps?.receiptModel || (!blocked && stage === Formalization.PAYMENT_MODEL)"
    class="-mt-6"
  >
    <template #icon>
      <IconLock v-if="blocked || !dataStepsStatus?.openable_steps?.receiptModel" />
    </template>
    <template v-if="isLoading">
      <div class="container">
        <div class="my-4 card-container flex flex-col justify-between w-full">
          <span class="loader-project" />
        </div>
      </div>
    </template>
    <template v-else-if="errorPaymentModel">
      <template v-if="getErrorMessage(errorPaymentModel) === '404'">
        <div class="flex justify-center">
          <SolEmptyState
            id="empty-list"
            :title="t('receiptModel.error.title')"
            :subtitle="t('receiptModel.error.subtitle')"
            variant="error404"
          >
            <template #action>
              <div class="flex gap-2 inline-flex show-md">
                <SolButton
                  id="btn-new-simulation"
                  size="medium"
                  @click="refetchPaymentModel"
                >
                  <template #icon-left>
                    <IconPlus />
                  </template>
                  Clique para recarregar
                </SolButton>
              </div>
            </template>
          </SolEmptyState>
        </div>
      </template>
      <template v-else-if="getErrorMessage(errorPaymentModel) === '422'">
        <div class="flex justify-center">
          <SolEmptyState
            id="empty-list"
            title="Não foi possível carregar o modelo de recebimento"
            subtitle="Ocorreu um imprevisto e não foi possível carregar o modelo de recebimento. Por favor, tente novamente."
            variant="no-content"
            class="w-full"
          >
            <template #action>
              <div class="flex gap-2 inline-flex show-md">
                <SolButton
                  id="btn-new-simulation"
                  size="medium"
                  @click="refetchPaymentModel"
                >
                  <template #icon-left>
                    <IconPlus />
                  </template>
                  Tente novamente
                </SolButton>
              </div>
            </template>
          </SolEmptyState>
        </div>
      </template>
    </template>
    <template v-else-if="paymentModelData">
      <div class="step-receipt">
        <div class="receipt-model">
          <!-- Title -->
          <div class="mb-sm">
            <p class="mt-2">
              {{ isEligibleUserToChoosePayment
                ? t('customer.receipt_model_subtitle_eligible')
                : t('customer.receipt_model_subtitle_not_eligible') }}
            </p>
          </div>

          <!-- ALERTS -->

          <!-- GLOBAL STATUS ALERTS -->

          <template v-if="alertError.title || alertSuccess.title">
            <SolAlert
              v-if="alertError.title && invoicesToShow && props.statusProject.payment_model === 'to_review'"
              :id="`${statusProject.payment_model}-alert-error`"
              class="mb-4"
              :feedback="alertError.type"
              :title="alertError.title"
            >
              {{ alertError.description }}
            </SolAlert>
            <SolAlert
              v-if="alertSuccess.title"
              :id="`${statusProject.payment_model}-alert-success`"
              class="mb-4"
              :feedback="alertSuccess.type"
              :title="alertSuccess.title"
            >
              {{ alertSuccess.description }}
            </SolAlert>
          </template>
          <!-- TRANSFERS ALERTS -->
          <template v-else>
            <template v-if="!isEligibleUserToChoosePayment">
              <template v-if="isTransferAfterInstallation">
                <SolAlert
                  id="informative-feedback-onboarding-flex"
                  class="mb-2xs"
                  :title="formattedTitle()"
                  feedback="informative"
                  action="Saiba mais"
                  @click="openModal"
                >
                  {{ formattedText() }}
                </SolAlert>
              </template>
              <template v-else-if="isTransferAfterFormalization">
                <SolAlert
                  id="informative-feedback-onboarding-flex"
                  class="mb-2xs"
                  title="Modelo de recebimento 100% após aprovação da documentação"
                  feedback="informative"
                >
                  {{ t('customer.after_contract_approval_value_will_be_transferred') }}
                </SolAlert>
              </template>
              <template v-else>
                <SolAlert
                  v-if="paymentModelData?.roles?.invoices?.separate_invoices"
                  id="informative-feedback-receipt-model"
                  class="mb-2xs"
                  :title="`${formattedTitle()} ${t('receiptModel.onflex.twoInvoices')}`"
                  feedback="informative"
                >
                  {{ t('customer.alertInstallment') }}
                </SolAlert>

                <SolAlert
                  v-else
                  id="informative-feedback-receipt-model"
                  class="mb-2xs"
                  :title="formattedTitle()"
                  feedback="informative"
                  action="Saiba mais"
                  @click="openModal"
                >
                  {{ formattedText() }}
                </SolAlert>
              </template>
            </template>
          </template>

          <div class="method_receipt">
            <div v-if="isEligibleUserToChoosePayment || isEligibleUserToChooseInvoices" class="title">
              <h3 class="fonts-heading-h3 pb-4xs">
                {{ t('customer.method_receipt') }}
              </h3>
            </div>
            <template v-if="isEligibleUserToChoosePayment">
              <!-- TOGGLE IN CASH | INSTALLMENT -->
              <div class="switch flex  md:system:pb-2">
                <ToggleSwitch
                  :disabled="fieldIsDisabledBasedStatus"
                  :active="methodReceiptSelected"
                  :first-option="{ title: 'À vista', key: 'in_cash' }"
                  :second-option="{ title: 'Faseado', key: 'installment' }"
                  @selected="onSelectedInCashOrInstallment"
                />
              </div>
              <!-- TEXT IN CASH | INSTALLMENT -->
              <div class="subtitle-installment">
                <template v-if="inCash">
                  <span class="fonts-body-small-regular text-brand-primary-dark">
                    {{ hasCombo ? t('customer.in_cash_subtitle_with_combo') : t('customer.in_cash_subtitle_without_combo') }}
                  </span>
                </template>
                <template v-else>
                  <span
                    v-if="paymentModelData?.roles.invoices.commission"
                    class="fonts-body-small-regular text-brand-primary-dark"
                  >
                    {{ t('customer.in_installment_subtitle', { count: hasCombo ? 1 : 0 }) }}
                  </span>
                  <span v-else class="fonts-body-small-regular text-brand-primary-dark">
                    {{ t('customer.in_installment_without_service_invoice_subtitle') }}
                  </span>
                </template>
              </div>
            </template>
            <template v-if="isEligibleUserToChooseInvoices">
              <div class="switch flex py-nano md:system:pt-4xs md:system:pb-2">
                <!-- TOGGLE SINGLE INVOICE | SEPARATE INVOICE -->
                <ToggleSwitch
                  :disabled="fieldIsDisabledBasedStatus"
                  :active="methodInvoiceSelected"
                  :first-option="{ title: 'Uma nota', key: 'single_invoice' }"
                  :second-option="{ title: 'Duas notas', key: 'separate_invoices' }"
                  @selected="onInvoiceSelected"
                />
              </div>
              <!-- TEXT SINGLE INVOICE | SEPARATE INVOICE -->
              <template v-if="methodInvoiceSelected === 'single_invoice'">
                <div class="subtitle-installment mb-6">
                  <span class="fonts-body-small-regular text-brand-primary-dark">
                    {{ t('customer.service_products') }}
                  </span>
                  <span
                    v-if="methodReceiptSelected === 'in_cash'"
                    class="fonts-body-small-regular text-neutral-low-light block pt-2"
                  >
                    {{ t('customer.method_receipt_seleted_in_cash') }}
                  </span>
                  <span v-else class="fonts-body-small-regular text-neutral-low-light block pt-2">
                    {{ t('customer.method_receipt_seleted_installment') }}
                  </span>
                </div>
              </template>
              <template v-if="methodInvoiceSelected === 'separate_invoices'">
                <div class="subtitle-installment mb-6">
                  <span class="fonts-body-small-regular text-brand-primary-dark">
                    {{ t('customer.separate_invoices_subtitle') }}
                  </span>
                  <span class="fonts-body-small-regular text-neutral-low-light block pt-2">
                    {{ t('customer.second_separate_invoices_subtitle') }}
                  </span>
                </div>
              </template>
            </template>

            <div v-show="methodInvoiceSelected === 'separate_invoices'" id="infoSupplier" class="pt-8 pb-8">
              <SupplierInformationForm
                :form="supplierForm"
                :payment-model="paymentModelData"
                :disable-field="fieldIsDisabledBasedStatus"
              />
            </div>

            <!-- EQUIPMENT -->
            <FormalizationReiceiptModelFormsCombo
              v-if="hasCombo"
              :project-id="projectId"
              :project-status="props.statusProject.payment_model"
              :disabled="fieldIsDisabledBasedStatus || loadingButtonSendFiles"
              :shopping-cart-id="shoppingCartId || projectShoppingCartId"
              :order-id="orderIdValue"
              :error="showMirrorFileError"
              :is-container="paymentModelData.is_container_balance_available"
              :equipment-origin="paymentModelData.equipment_origin !== null ? String(paymentModelData.equipment_origin) : null"
              :available-container="isContainer"
              :payment-status-flow="statusProject.payment_model"
              @on-kit-origin="handleOnKitOrigin"
              @on-combo-selected="handleOnComboSelected"
              @on-showcase-selected="handleShowcaseSelected"
              @mirror-file="handleMirrorSubmit"
              @has-file-attached="handleFileAttached"
              @on-shopping-cart-id="handleShoppingCartId"
            />

            <div v-show="invoicesToShow">
              <InvoicesForm
                :form="invoiceForm"
                :data="paymentModelData"
                :invoice-selected="methodInvoiceSelected"
                :disable-field="fieldIsDisabledBasedStatus || loadingButtonSendFiles"
                :combo="hasCombo"
                :options="getOptions"
                :payment-status-flow="statusProject.payment_model"
                :equipment-selected="equipmentOrigin"
              />
            </div>

            <!-- COMMISSION INFO -->
            <div v-if="hasFinancingCommissionToReceive">
              <SolLink
                id="show-commission-invoice-info"
                class="mt-4"
                size="medium"
                @click="showCommissionInvoiceInfo = !showCommissionInvoiceInfo"
              >
                <template v-if="!showCommissionInvoiceInfo" #icon>
                  <IconVisibility />
                </template>
                <template v-else #icon>
                  <IconVisibilityOff />
                </template>
                {{ showCommissionInvoiceInfo ? 'Ocultar as instruções para emissão da NF de comissão' : 'Visualizar as instruções para emissão da NF de comissão' }}
              </SolLink>

              <CustomerElementCommissionInvoiceInfo
                v-if="showCommissionInvoiceInfo"
                :solfacil-data="paymentModelData.solfacil_info"
                :commission-value="paymentModelData.commissions.total.value"
              />
            </div>
          </div>

          <!-- TRANSFERNS -->
          <CustomerElementSummary
            v-if="paymentModelData"
            :data="paymentModelData"
            :combo="hasCombo"
            :is-onboarding-flex="isTransferAfterInstallation ?? false"
            :status-flow="props.statusProject.payment_model"
          />
        </div>

        <div>
          <SolDivider thickness="x-small" orientation="horizontal" class="mt-8 mb-6" />

          <div class="flex justify-end">
            <SolButton
              id="address_confirm"
              size="large"
              :loading="loadingButtonSendFiles"
              :disabled="disabledSendButton"
              @click="handleSendClick()"
            >
              {{ t('app.send') }}
            </SolButton>
          </div>
        </div>
      </div>
    </template>
  </CustomerElementAccordion>

  <PaymentModelModal
    v-if="paymentModelModal && paymentModelData"
    :payment-model-data="paymentModelData"
    :percentage-default="PERCENTAGE_DEFAULT"
    :is-transfer-after-installation="isTransferAfterInstallation ?? false"
    :has-combo="hasCombo"
    @close="() => { paymentModelModal = false }"
  />

  <ConfirmationModal
    v-if="confirmationModal"
    @send="sendFiles()"
    @close="() => { confirmationModal = false }"
  />
</template>

<style lang="scss">
.step-receipt {
  .receipt-model .method_receipt {
    @apply my-2xs p-2xs gap-2xs;
    @apply border-a-sm border-neutral-low-light rounded-md;

    md:system {
      @apply my-xs p-2xs;
    }
  }

  .receipt-model .title label {
    @apply font-highlight text-neutral-low-pure;
  }

  .method_receipt .subtitle {
    @apply leading-3 mt-quark mb-2xs;
  }

  .sol-button-core.-secondary {
    min-width: fit-content !important;
  }

  .sol-alert-core.-informative {
    @apply flex flex-col md:site:flex-row gap-4 md:site:gap-0;
  }

  .subtitle-installment {
    @apply leading-3 mt-quark;
  }
}

.sol-upload {
  @apply p-5;
}

.sol-select-core>.select-button-toggle {
  @apply h-auto;
}

.sol-input-currency-core>.container-input {
  @apply ml-1;
}
</style>
