import type { Ref } from 'vue'
import type { SimulatorApi } from '~/services-v2/simulator-api/simulator-api.types'
import { useMutation } from '@tanstack/vue-query'
import { queryKeys } from '~/queryClient'
import simulatorApi from '~/services-v2/simulator-api/simulator-api'

export default function useDownloadSimulationPdf(projectId: Ref<string>) {
  const mutationKey = queryKeys.SIMULATION.PDF_DOWNLOAD(projectId.value)

  const mutationFn = async (params: SimulatorApi.GetSimulationPdfRequest) => {
    const pdfBlob = await simulatorApi.getSimulationPdf(projectId.value, params)
    const blob = new Blob([pdfBlob], { type: 'application/pdf' })

    const blobUrl = window.URL.createObjectURL(blob)
    // create a link element
    const a = document.createElement('a')

    a.href = blobUrl
    a.download = `simulation-${projectId.value}.pdf`
    // append to the document body
    document.body.appendChild(a)
    // trigger the download by simulating click
    a.click()
    // cleanup and remove the link
    a.remove()
    window.URL.revokeObjectURL(blobUrl)

    return pdfBlob
  }

  const query = useMutation({
    mutationFn,
    mutationKey,
  })

  return query
}
