<script setup lang="ts">
import type { RegistrationUpdateByBiometryForm } from '~/store-v2/registration/useRegistrationContactChange'
import type { StageFormalization, TagStep } from '~/ui-blocks/Formalization/Formalization.types'
import type { BiometryAlertEnum, BiometryAlertStatus } from '~/utils/customer-register/Biometry'
import { SolAlert, SolButton, SolDivider, SolInputText, useToast } from '@solfacil/girassol'
import { toTypedSchema } from '@vee-validate/zod'
import { useForm } from 'vee-validate'
import IconDownload from '~icons/material-symbols/download'
import IconLock from '~icons/material-symbols/lock'
import IconLetter from '~icons/material-symbols/mail-outline'
import IconOpenInNew from '~icons/material-symbols/open-in-new'
import IconPhone from '~icons/material-symbols/smartphone'
import IconWarning from '~icons/material-symbols/warning-outline'
import { usePhoneMask } from '~/composables/mask'
import { STATUSES_MAPPING } from '~/services-v2/financial-bff/financial-bff.consts'
import useCustomerBiometry from '~/store-v2/contract-formalization/useCustomerBiometry'
import useCustomerBiometryCancel from '~/store-v2/contract-formalization/useCustomerBiometryCancel'
import useCustomerBiometryResend from '~/store-v2/contract-formalization/useCustomerBiometryResend'
import useCustomerBiometrySend from '~/store-v2/contract-formalization/useCustomerBiometrySend'
import useFinancing from '~/store-v2/financings/useFinancing'
import useRegistration from '~/store-v2/registration/useRegistration'
import { useRegistrationContactChange } from '~/store-v2/registration/useRegistrationContactChange'
import { Formalization } from '~/types/enum'

import { GeneralStatuses, type ProposalStatus } from '~/utils/financing-flow/Financing'
import { convertToBrPhone } from '~/utils/string'
import { getSchema } from './biometry.schema'
import BiometryTimeline from './BiometryTimeline.vue'

const props = defineProps<{
  position: number
  statusProject: ProposalStatus
  stage: StageFormalization
  stageReproved: string
}>()

const emit = defineEmits<{
  (e: 'statusBiometry', v: ProposalStatus): void
  (e: 'alertBiometry', v: BiometryAlertStatus): void
}>()

const route = useRoute()
const projectId = computed(() => String(route.params.id))

const { t } = useI18n()
const { track } = useMixpanel()
const { createErrorToast } = useToast()
const toast = useToast()

// constants
const helpcenterUrl = 'https://helpcentersolfacil.zendesk.com/hc/pt-br/articles/15977819037467-Como-cancelo-meu-financiamento'

// states
const methodToReceiveBiometry = ref('whatsapp')
const showBiometrySending = ref(false)
const editBiometryContact = ref(false)

const alert = ref(null) as Ref<string | null>
const showModalCancel = ref(false)
const status = ref({} as ProposalStatus)
const tag = computed(() => {
  const statusMapping = STATUSES_MAPPING[props.statusProject.biometry_and_contract]

  if (!statusMapping)
    return

  return {
    id: props.statusProject.biometry_and_contract,
    text: t(statusMapping.textKey),
    variant: 'fill',
    size: 'medium',
    type: statusMapping.color,
  } as TagStep
})

// queries and mutations
const {
  data: financing,
  isLoading: isLoadingFinancing,
  refetch: refetchFinancing,
} = useFinancing(projectId)

const {
  data: registration,
  isLoading: isLoadingRegistration,
} = useRegistration(projectId)

const {
  mutateAsync: updateRegistrationContactChange,
  isPending: isUpdateCustomerPending,
} = useRegistrationContactChange(projectId.value)

// biometrics queries and mutations
const isApprovedReprovedCanceledOrExpired = computed(() => [
  GeneralStatuses.APPROVED,
  GeneralStatuses.REPROVED,
  GeneralStatuses.CANCELED,
  GeneralStatuses.EXPIRED,
].includes(financing.value?.section_statuses.proposal?.biometry_and_contract as GeneralStatuses))

const isCanceledOrExpired = computed(() => [
  GeneralStatuses.CANCELED,
  GeneralStatuses.EXPIRED,
].includes(financing.value?.section_statuses.proposal?.biometry_and_contract as GeneralStatuses))

const isApproved = computed(() => financing.value?.section_statuses.proposal?.biometry_and_contract === GeneralStatuses.APPROVED)
const isEquipamentApproved = computed(() => financing.value?.section_statuses.proposal?.equipment === GeneralStatuses.APPROVED)
const enablePooling = computed(() => isEquipamentApproved.value && !isApprovedReprovedCanceledOrExpired.value)

const {
  data: customerBiometrics,
  isError: isErrorCustomerBiometrics,
  isLoading: isLoadingCustomerBiometrics,
  refetch: refetchCustomerBiometrics,
} = useCustomerBiometry(projectId, enablePooling)

const {
  mutateAsync: mutateCustomerBiometrySend,
  isPending: isPendingSend,
} = useCustomerBiometrySend(projectId)

const {
  mutateAsync: mutateCustomerBiometryResend,
  isPending: isPendingResend,
} = useCustomerBiometryResend(projectId)

const {
  mutateAsync: mutateCustomerBiometryCancel,
  isPending: isPendingCancel,
} = useCustomerBiometryCancel(projectId)

const showSendButton = computed(() => {
  if (!customerBiometrics.value || !financing.value)
    return false
  return ['waiting_to_start', 'in_progress'].includes(customerBiometrics.value.status)
})

const showCancelButton = computed(() => {
  if (!customerBiometrics.value || !financing.value)
    return false
  return customerBiometrics.value.cancel === true
})

const showResendButton = computed(() => {
  if (!customerBiometrics.value || !financing.value)
    return false
  return (financing.value.section_statuses.proposal?.biometry_and_contract === GeneralStatuses.REPROVED)
    || customerBiometrics.value.biometry.status === 'in_progress'
})

const isLoading = computed(() => isLoadingRegistration.value || isLoadingFinancing.value || isLoadingCustomerBiometrics.value)

const validationSchema = computed(() => toTypedSchema(getSchema(t, registration.value)!))

const updateForm = useForm({
  validationSchema: validationSchema.value,
  keepValuesOnUnmount: false,
})

const legals = computed(() => registration.value?.company?.legal_representatives.filter(legal => legal.cpf !== registration.value?.company?.majority_partner.cpf))
const firstLegalRepresentant = computed(() => legals.value && legals.value[0])
const secondLegalRepresentant = computed(() => legals.value && legals.value[1])

const hasFirstLegalRepresentant = computed(() => legals.value && !!legals.value[0]?.cpf)
const hasSecondLegalRepresentant = computed(() => legals.value && !!legals.value[1]?.cpf)

const disableUpdateContactButton = computed(() => Object.keys(updateForm.errors.value).length > 0)

const noChangesToEdit = computed(() => !(registration.value?.remaining_changes_after_confirmation !== 0))

const openCollapse = computed(() => {
  const canOpenThisStep = financing?.value?.openable_steps?.contract && !financing?.value?.openable_steps?.receiptModel
  return ((props.stage === Formalization.BIOMETRY_AND_CONTRACT) || canOpenThisStep)
})

const EnumAlerts: BiometryAlertEnum = {
  under_analysis: {
    title: t('customer.biometry.alert.under_analysis.title'),
    text: t('customer.biometry.alert.under_analysis.text'),
    feedback: 'warning',
  },
  pending: {
    title: t('customer.biometry.alert.pending.title'),
    text: t('customer.biometry.alert.pending.text'),
    feedback: 'informative',
  },
}

async function createCompanyPayload(): Promise<RegistrationUpdateByBiometryForm> {
  const { values } = await updateForm.validate()

  if (!values || !('majority_partner_person_form_phone' in values))
    throw new Error('Invalid values')

  const majorityPartnerIsLegalRepresentative = registration.value?.company?.majority_partner_is_legal_representative
  const hasFirstLegalRepresentant = !!registration.value?.company?.legal_representatives[0] && !majorityPartnerIsLegalRepresentative
  const hasSecondLegalRepresentant = !!registration.value?.company?.legal_representatives[1]

  return {
    project_id: projectId.value,
    majority_partner: {
      email: values.majority_partner_person_form_email!,
      phone: values.majority_partner_person_form_phone!,
    },
    legal_representative_1: hasFirstLegalRepresentant
      ? {
          email: values.first_legal_representant_email!,
          phone: values.first_legal_representant_phone!,
        }
      : null,
    legal_representative_2: hasSecondLegalRepresentant
      ? {
          email: values.second_legal_representant_email!,
          phone: values.second_legal_representant_phone!,
        }
      : null,
  }
}

onMounted(() => {
  if (!enablePooling.value) {
    refetchCustomerBiometrics()
  }
})

async function createCustomerPayload(): Promise<RegistrationUpdateByBiometryForm> {
  const { values } = await updateForm.validate()

  if (!values || !('phone' in values))
    throw new Error('Invalid values')

  const hasGuarantor = !!registration.value?.guarantor

  return {
    project_id: projectId.value,
    customer: {
      email: values.email!,
      phone: values.phone!,
    },
    guarantor: hasGuarantor
      ? {
          email: values.emailGuarantor!,
          phone: values.phoneGuarantor!,
        }
      : null,
  }
}

async function handleSendBiometry() {
  showBiometrySending.value = false
  track('formalizing_contract_button_send-contract', { trigger: 'Clique no botão de enviar contrato' })

  try {
    const sendBiometryResponse = await mutateCustomerBiometrySend(methodToReceiveBiometry.value)

    if (sendBiometryResponse) {
      toast.createSuccessToast({
        description: t('customer.biometry.send_success'),
      })
    }
    else {
      handleBiometrySendError()
    }
  }
  catch (error) {
    console.error(error)
    handleBiometrySendError()
  }
}

function handleBiometrySendError() {
  toast.createErrorToast({
    description: t('customer.biometry.catch.send_error'),
  })
}

function openModalToSendBiometry() {
  showBiometrySending.value = true
  editBiometryContact.value = false
}

async function handleBiometryContactUpdate() {
  try {
    const payload = registration.value?.person_type === 'pf' ? await createCustomerPayload() : await createCompanyPayload()

    await updateRegistrationContactChange(payload)
    openModalToSendBiometry()

    toast.createSuccessToast({
      description: t('customer.biometry.update_success'),
    })
  }
  catch (error: unknown) {
    closeBiometryModal()
    toast.createErrorToast({
      description: t(`customer.biometry.catch.know_errors.${error}`, t('customer.biometry.catch.update_error')),
    })
  }
}

async function handleResend() {
  track('formalizing_contract_button_resend-contract', { trigger: 'Clique no botão de reenviar contrato' })

  try {
    await mutateCustomerBiometryResend()
  }
  catch (error) {
    console.error(error)
    createErrorToast(t('customer.biometry.catch.resend_error'))
  }
}

async function handleCancel() {
  track('formalizing_contract_button_cancel-contract', { trigger: 'Clique no botão de cancelar envio do contrato' })
  showModalCancel.value = false

  try {
    const responseCancelBiometry = await mutateCustomerBiometryCancel()
    const STATUS_CODE_204_NO_CONTENT = Object.keys(responseCancelBiometry).length === 0

    if (STATUS_CODE_204_NO_CONTENT) {
      status.value.biometry_and_contract = GeneralStatuses.IN_PROGRESS
      emit('statusBiometry', status.value)

      toast.createSuccessToast({
        description: t('customer.biometry.cancel_success'),
      })
    }
    else {
      createErrorToast(t('customer.biometry.catch.cancel_error'))
    }
  }
  catch (error: any) {
    const partialMessageHasBeenCancelled = 'Contract has been cancelled, wait'
    const minutes = error?.response?.data?.detail.match(/\d+/)[0]

    const errorMessage = error?.response?.data?.detail?.includes(partialMessageHasBeenCancelled)
      ? t('customer.biometry.catch.has_been_cancelled', { minutes })
      : t('customer.biometry.catch.cancel_error')

    createErrorToast(errorMessage)
  }
}

function closeBiometryModal() {
  showBiometrySending.value = false
  editBiometryContact.value = false
  updateForm.resetForm()
}

function handleUrlClick(url: string) {
  window.open(url, '_blank')
}

function handleCancelEditing() {
  editBiometryContact.value = false
  updateForm.resetForm()
  refetchFinancing()
}

function handleEdit() {
  editBiometryContact.value = true

  if (registration.value?.person_type === 'pf') {
    updateForm.setValues({
      email: registration.value?.customer?.contact?.email,
      phone: registration.value?.customer?.contact?.phone,
      emailGuarantor: registration.value?.guarantor?.contact?.email,
      phoneGuarantor: registration.value?.guarantor?.contact?.phone,
    }, true)
  }

  if (registration.value?.person_type === 'pj') {
    const legals = registration.value?.company?.legal_representatives.filter(legal => legal.cpf !== registration.value?.company?.majority_partner.cpf)
    updateForm.setValues({
      majority_partner_person_form_email: registration.value?.company?.majority_partner?.contact?.email,
      majority_partner_person_form_phone: registration.value?.company?.majority_partner?.contact?.phone,
    }, true)

    if (legals && legals[0]) {
      updateForm.setValues({
        first_legal_representant_email: legals[0]?.contact?.email,
        first_legal_representant_phone: legals[0]?.contact?.phone,
      }, true)
    }

    if (legals && legals[1]) {
      updateForm.setValues({
        second_legal_representant_email: legals[1]?.contact?.email,
        second_legal_representant_phone: legals[1]?.contact?.phone,
      }, true)
    }
  }
}

function handleSend() {
  const openModalToEditData = customerBiometrics.value?.biometry.status !== 'in_progress'
  openModalToEditData ? showBiometrySending.value = true : handleSendBiometry()
}

function handlePhoneDDI(phone?: string | null) {
  if (!phone)
    return ''

  if ([12, 13].includes(phone.length) && phone.startsWith('55'))
    return phone.replace('55', '')

  return phone
}

// fields
const [biometryEmailModel, biometryEmailProps] = updateForm.defineField('email')
const [biometryPhoneModel, biometryPhoneProps] = updateForm.defineField('phone')
const [biometryEmailGuarantorModel, biometryEmailGuarantorProps] = updateForm.defineField('emailGuarantor')
const [biometryPhoneGuarantorModel, biometryPhoneGuarantorProps] = updateForm.defineField('phoneGuarantor')

const [majorityPartnerEmailModel, majorityPartnerEmailProps] = updateForm.defineField('majority_partner_person_form_email')
const [majorityPartnerPhoneModel, majorityPartnerPhoneProps] = updateForm.defineField('majority_partner_person_form_phone')
const [firstLegalRepresentantEmailModel, firstLegalRepresentantEmailProps] = updateForm.defineField('first_legal_representant_email')
const [firstLegalRepresentantPhoneModel, firstLegalRepresentantPhoneProps] = updateForm.defineField('first_legal_representant_phone')
const [secondLegalRepresentantEmailModel, secondLegalRepresentantEmailProps] = updateForm.defineField('second_legal_representant_email')
const [secondLegalRepresentantPhoneModel, secondLegalRepresentantPhoneProps] = updateForm.defineField('second_legal_representant_phone')
</script>

<template class="customer-biometry">
  <CustomerElementAccordion
    :id="`accordion_${Formalization.BIOMETRY_AND_CONTRACT}`"
    :title="t('customer.biometry.title')" :position="String(position)"
    :tag="!financing?.openable_steps?.contract ? undefined : tag" :blocked="!financing?.openable_steps?.contract"
    :open-collapse="openCollapse" class="mb-2 lg:system:mb-8"
  >
    <template #icon>
      <IconLock v-if="!financing?.openable_steps?.contract" />
    </template>

    <!-- loading state -->
    <template v-if="isLoading">
      <div class="container">
        <div class="my-4 card-container flex flex-col justify-between w-full">
          <span class="loader-project" />
        </div>
      </div>
    </template>

    <!-- success state -->
    <template v-if="customerBiometrics && financing?.openable_steps?.contract">
      <!-- subtitle -->
      <div class="mt-2">
        <p>{{ t('customer.biometry.subtitle') }}</p>
      </div>

      <SolAlert
        v-if="alert" id="alert-biometry" :title="EnumAlerts[alert]?.title" :text="EnumAlerts[alert]?.text"
        :feedback="EnumAlerts[alert]?.feedback" class="mt-7"
      />

      <template v-if="isErrorCustomerBiometrics">
        <div class="w-full flex justify-center">
          <SolEmptyState
            id="empty-state" :subtitle="t('customer.biometry.catch.data_error')"
            :title="t('customer.biometry.catch.data_error_title')"
            variant="no-content"
          />
        </div>
      </template>
      <template v-else>
        <!-- timeline -->
        <BiometryTimeline
          :data="customerBiometrics"
          :disabled="isCanceledOrExpired"
          class="mt-8 -mb-8"
          @method-receipt="(value) => methodToReceiveBiometry = value"
        />

        <SolDivider thickness="x-small" orientation="horizontal" class="my-7" />

        <!-- footer -->
        <div
          class="flex flex-col md:system:flex-row flex-col-reverse justify-between"
          :class="{ 'pointer-events-none opacity-60': isCanceledOrExpired }"
        >
          <!-- link -->
          <a :href="helpcenterUrl" target="_blank" class="flex items-center gap-2 text-brand-secondary-dark">
            <span>{{ t('customer.biometry.footer.policy') }}</span>
            <IconOpenInNew />
          </a>

          <!-- buttons -->
          <div class="flex flex-col md:system:flex-row  flex-col-reverse justify-end gap-3 mb-7 md:system:mb-0">
            <SolButton
              v-if="showCancelButton"
              id="customer_cancel" size="large" variant="tertiary"
              class="w-full whitespace-nowrap"
              :loading="isPendingCancel"
              :disabled="isApprovedReprovedCanceledOrExpired"
              @click="showModalCancel = true"
            >
              {{ t('customer.biometry.footer.cancel') }}
            </SolButton>

            <SolButton
              v-if="showResendButton" id="customer_resend" size="large" variant="secondary"
              class="w-full hidden" :loading="isPendingResend" :disabled="isApprovedReprovedCanceledOrExpired"
              @click="handleResend"
            >
              {{ t('customer.biometry.footer.resend') }}
            </SolButton>

            <SolButton
              v-if="showSendButton" id="customer_send" size="large" class="w-full" :loading="isPendingSend"
              :disabled="isApprovedReprovedCanceledOrExpired" @click="handleSend"
            >
              <!-- "status": "waiting_to_start", -->
              {{ t('app.send') }}
            </SolButton>

            <SolButton
              v-if="isApproved && customerBiometrics?.download?.insurance"
              id="customer_download_insurance"
              size="large"
              variant="secondary"
              class="w-full"
              :disabled="isCanceledOrExpired"
              @click="handleUrlClick(customerBiometrics.download.insurance)"
            >
              <div class="flex items-center gap-3 whitespace-nowrap">
                <IconDownload class="text-sm" />
                <span>{{ t('customer.biometry.footer.insurance') }}</span>
              </div>
            </SolButton>

            <SolButton
              v-if="isApproved && customerBiometrics?.download?.contract"
              id="customer_download_contract"
              size="large"
              class="w-full"
              :disabled="isCanceledOrExpired"
              @click="handleUrlClick(customerBiometrics.download.contract)"
            >
              <div class="flex items-center gap-3 whitespace-nowrap">
                <IconDownload class="text-sm" />
                <span>{{ t('customer.biometry.footer.contract') }}</span>
              </div>
            </SolButton>
          </div>
        </div>
      </template>
    </template>
  </CustomerElementAccordion>

  <SolModal
    id="sending-biometry-confirm" :is-open="showBiometrySending"
    :title="t('customer.biometry.modal_sending.title')"
    :action-primary-text="editBiometryContact ? t('customer.biometry.modal_sending.form.submit') : t('customer.biometry.modal_sending.send')"
    :action-secondary-text="editBiometryContact ? t('customer.biometry.modal_sending.cancel') : t('customer.biometry.modal_sending.edit')"
    :size="{ mobile: 'bottom-sheet' }" @close="closeBiometryModal"
  >
    <template #action-primary>
      <SolButton
        v-if="editBiometryContact"
        id="customer_send"
        size="medium"
        :loading="isUpdateCustomerPending"
        :disabled="disableUpdateContactButton"
        @click="handleBiometryContactUpdate"
      >
        {{ t('customer.biometry.modal_sending.form.submit') }}
      </SolButton>
      <SolButton
        v-else id="customer_send" size="medium" :loading="isPendingSend" :disabled="isPendingSend"
        @click="handleSendBiometry"
      >
        {{ t('customer.biometry.modal_sending.send') }}
      </SolButton>
    </template>

    <template #action-secondary>
      <SolButton
        v-if="editBiometryContact" id="customer_send" size="medium" variant="secondary"
        :disabled="isUpdateCustomerPending" @click="handleCancelEditing"
      >
        {{ t('customer.biometry.modal_sending.cancel') }}
      </SolButton>
      <SolButton
        v-else id="customer_send" size="medium" variant="secondary" :disabled="noChangesToEdit"
        @click="handleEdit()"
      >
        {{ t('customer.biometry.modal_sending.edit') }}
      </SolButton>
    </template>

    <div v-if="editBiometryContact">
      <p class="mb-3 text-neutral-low-medium">
        {{ t('customer.biometry.modal_sending.helper_text.new_analysis_warning') }}
      </p>

      <!-- person type PF -->
      <form v-if="registration?.person_type === 'pf'">
        <!-- Customer -->
        <div class="grid grid-cols-2 gap-0 sm:system:gap-4">
          <SolInputText
            id="email" v-model="biometryEmailModel" class="mb-3" name="email" v-bind="biometryEmailProps"
            :label="t('customer.biometry.modal_sending.form.email')" :error="updateForm.errors.value.email"
          />

          <SolInputText
            id="phone" v-model="biometryPhoneModel" class="mb-3" name="phone" v-bind="biometryPhoneProps"
            :mask="{ preset: 'MobilePhoneDDD' }" :label="t('customer.biometry.modal_sending.form.phone')"
            :error="updateForm.errors.value.phone"
          />
        </div>

        <!-- Guarantor -->
        <div v-if="registration?.guarantor" class="grid grid-cols-2 gap-0 sm:system:gap-4">
          <SolInputText
            id="email-guarantor" v-model="biometryEmailGuarantorModel" class="mb-3" name="emailGuarantor"
            v-bind="biometryEmailGuarantorProps" :label="t('customer.biometry.modal_sending.form.email_guarantor')"
            :error="updateForm.errors.value.emailGuarantor"
          />

          <SolInputText
            id="phone-guarantor" v-model="biometryPhoneGuarantorModel" class="mb-3" name="phoneGuarantor"
            v-bind="biometryPhoneGuarantorProps" :mask="{ preset: 'MobilePhoneDDD' }"
            :label="t('customer.biometry.modal_sending.form.phone_guarantor')"
            :error="updateForm.errors.value.phoneGuarantor"
          />
        </div>
      </form>

      <!-- person type PJ -->
      <form v-if="registration?.person_type === 'pj'">
        <div class="grid grid-cols-12 gap-0 sm:system:gap-4">
          <SolInputText
            id="majority_partner_person_form_email" v-model="majorityPartnerEmailModel"
            class="col-span-12 sm:system:col-span-6 mb-3" name="majority_partner_person_form_email"
            v-bind="majorityPartnerEmailProps" :label="t('company.biometry.modal_sending.form.majority.email')"
            :error="updateForm.errors.value.majority_partner_person_form_email"
          />

          <SolInputText
            id="majority_partner_person_form_phone" v-model="majorityPartnerPhoneModel"
            class="col-span-12 sm:system:col-span-6 mb-3" name="majority_partner_person_form_phone"
            :mask="{ preset: 'MobilePhoneDDD' }" :label="t('company.biometry.modal_sending.form.majority.phone')"
            v-bind="majorityPartnerPhoneProps" :error="updateForm.errors.value.majority_partner_person_form_phone"
          />
        </div>

        <div v-if="hasFirstLegalRepresentant" class="grid grid-cols-12 gap-0 sm:system:gap-4">
          <SolInputText
            id="first_legal_representant_email" v-model="firstLegalRepresentantEmailModel"
            class="col-span-12 sm:system:col-span-6 mb-3" name="first_legal_representant_email"
            v-bind="firstLegalRepresentantEmailProps" :label="t('company.biometry.modal_sending.form.legal_1.email')"
            :error="updateForm.errors.value.first_legal_representant_email"
          />

          <SolInputText
            id="first_legal_representant_phone" v-model="firstLegalRepresentantPhoneModel"
            class="col-span-12 sm:system:col-span-6 mb-3"
            name="first_legal_representant_phone"
            :mask="{ preset: 'MobilePhoneDDD' }"
            v-bind="firstLegalRepresentantPhoneProps"
            :label="secondLegalRepresentant ? t('company.biometry.modal_sending.form.legal_1.phone') : t('company.biometry.modal_sending.form.legal.phone')"
            :error="updateForm.errors.value.first_legal_representant_phone"
          />
        </div>

        <div v-if="hasSecondLegalRepresentant" class="grid grid-cols-12 gap-0 sm:system:gap-4">
          <SolInputText
            id="second_legal_representant_email" v-model="secondLegalRepresentantEmailModel"
            class="col-span-12 sm:system:col-span-6 mb-3" name="second_legal_representant_email"
            v-bind="secondLegalRepresentantEmailProps" :label="t('company.biometry.modal_sending.form.legal_2.email')"
            :error="updateForm.errors.value.second_legal_representant_email"
          />

          <SolInputText
            id="second_legal_representant_phone" v-model="secondLegalRepresentantPhoneModel"
            class="col-span-12 sm:system:col-span-6 mb-3" name="second_legal_representant_phone"
            v-bind="secondLegalRepresentantPhoneProps" :mask="{ preset: 'MobilePhoneDDD' }"
            :label="t('company.biometry.modal_sending.form.legal_2.phone')"
            :error="updateForm.errors.value.second_legal_representant_phone"
          />
        </div>
      </form>
    </div>
    <div v-else>
      <!-- Customer -->
      <template v-if="registration?.person_type === 'pf'">
        <div class="customer-data-highlight">
          <div>
            <p class="label-text">
              {{ t('customer.biometry.modal_sending.form.email') }}
            </p>
            <div class="flex gap-1 items-center">
              <IconLetter />
              <p>{{ registration?.customer?.contact?.email }}</p>
            </div>
          </div>

          <div>
            <p class="label-text">
              {{ t('customer.biometry.modal_sending.form.phone') }}
            </p>
            <div class="flex gap-1 items-center">
              <IconPhone />
              <p>{{ usePhoneMask().maskPhone(handlePhoneDDI(registration?.customer?.contact?.phone)) }}</p>
            </div>
          </div>
        </div>

        <!-- Guarantor -->
        <div v-if="registration?.guarantor" class="customer-data-highlight mt-3">
          <div>
            <p class="label-text">
              {{ t('customer.biometry.modal_sending.form.email_guarantor') }}
            </p>
            <div class="flex gap-1 items-center">
              <IconLetter />
              <p>{{ registration?.guarantor?.contact?.email }}</p>
            </div>
          </div>

          <div>
            <p class="label-text">
              {{ t('customer.biometry.modal_sending.form.phone_guarantor') }}
            </p>
            <div class="flex gap-1 items-center">
              <IconPhone />
              <p>{{ usePhoneMask().maskPhone(registration?.guarantor?.contact?.phone || '') }}</p>
            </div>
          </div>
        </div>
      </template>

      <template v-if="registration?.person_type === 'pj'">
        <div class="customer-data-highlight">
          <div>
            <p class="label-text">
              {{ t('company.biometry.modal_sending.form.majority.email') }}
            </p>
            <div class="flex gap-1 items-center">
              <IconLetter />
              <p>{{ registration?.company?.majority_partner.contact?.email }}</p>
            </div>
          </div>

          <div>
            <p class="label-text">
              {{ t('company.biometry.modal_sending.form.majority.phone') }}
            </p>
            <div class="flex gap-1 items-center">
              <IconPhone />
              <p>{{ convertToBrPhone(registration.company?.majority_partner.contact?.phone) }}</p>
            </div>
          </div>
        </div>

        <div v-if="hasFirstLegalRepresentant" class="customer-data-highlight mt-3">
          <div>
            <p class="label-text">
              {{ secondLegalRepresentant ? t('company.biometry.modal_sending.form.legal_1.email') : t('company.biometry.modal_sending.form.legal.email') }}
            </p>
            <div class="flex gap-1 items-center">
              <IconLetter />
              <p>{{ firstLegalRepresentant?.contact?.email }}</p>
            </div>
          </div>

          <div>
            <p class="label-text">
              {{ secondLegalRepresentant ? t('company.biometry.modal_sending.form.legal_1.phone') : t('company.biometry.modal_sending.form.legal.phone') }}
            </p>
            <div class="flex gap-1 items-center">
              <IconPhone />
              <p>{{ convertToBrPhone(firstLegalRepresentant?.contact?.phone) }}</p>
            </div>
          </div>
        </div>

        <div v-if="hasSecondLegalRepresentant" class="customer-data-highlight mt-3">
          <div>
            <p class="label-text">
              {{ t('company.biometry.modal_sending.form.legal_2.email') }}
            </p>
            <div class="flex gap-1 items-center">
              <IconLetter />
              <p>{{ secondLegalRepresentant?.contact?.email }}</p>
            </div>
          </div>

          <div>
            <p class="label-text">
              {{ t('company.biometry.modal_sending.form.legal_2.phone') }}
            </p>
            <div class="flex gap-1 items-center">
              <IconPhone />
              <p>{{ convertToBrPhone(secondLegalRepresentant?.contact?.phone) }}</p>
            </div>
          </div>
        </div>
      </template>

      <div class="flex items-center gap-1 mt-3">
        <div>
          <IconWarning />
        </div>
        <p />
        <template v-if="registration && (registration.remaining_changes_after_confirmation || 0) > 0">
          <p class="fonts-body-small-regular">
            {{ t('customer.biometry.modal_sending.helper_text.edit_suggestion.first_line') }}
            <strong>
              {{
                t('customer.biometry.modal_sending.helper_text.edit_suggestion.second_line',
                  { count: registration?.remaining_changes_after_confirmation })
              }}
            </strong>
            {{ t('customer.biometry.modal_sending.helper_text.edit_suggestion.last_line') }}
          </p>
        </template>
        <template v-else>
          <p class="fonts-body-small-regular">
            {{ t('customer.biometry.modal_sending.helper_text.no_changes.first_line') }}
            <strong>
              {{ t('customer.biometry.modal_sending.helper_text.no_changes.second_line') }}
            </strong>
            {{ t('customer.biometry.modal_sending.helper_text.no_changes.last_line') }}
          </p>
        </template>
      </div>
    </div>
  </SolModal>

  <SolModal
    id="modal" :is-open="showModalCancel" :title="t('customer.biometry.modal_cancel.title')"
    :action-primary-text="t('customer.biometry.modal_cancel.confirm')"
    :action-secondary-text="t('customer.biometry.modal_cancel.cancel')"
    :size="{ desktop: 'medium', mobile: 'bottom-sheet' }" @close="showModalCancel = false"
    @action:primary="handleCancel" @action:secondary="showModalCancel = false"
  >
    <div>{{ t('customer.biometry.modal_cancel.description') }}</div>
  </SolModal>
</template>

<style lang="scss" scoped>
.customer-data-highlight {
  @apply p-3;

  @apply grid grid-cols-1 gap-2;

  @apply bg-neutral-high-light rounded-md;

  @screen md:system {
    @apply grid-cols-2;
  }

  & .label-text {
    @apply fonts-body-small-regular text-neutral-low-light;
  }
}

.customer-biometry {
  :deep(.sol-overlay-core) {
    @apply z-10;
  }
}
</style>
