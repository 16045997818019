import type { FormContext } from 'vee-validate'
import type { UseRegistrationData } from '~/store-v2/registration/useRegistration'
import * as z from 'zod'
import { onlyNumbers } from '~/utils/string'

export function getSchema(t: any) {
  return z.object({
    cnpj: z.string(),
    company_name: z.string({ message: t('form.required') })
      .min(1, { message: t('form.required') }),
    email: z.string({ message: t('form.required') })
      .min(1, t('form.required'))
      .email({ message: t('form.invalid_email') }),
    phone: z.string({ message: t('form.required') })
      .transform(onlyNumbers)
      .refine(v => v.length === 10 || v.length === 11, t('form.invalid_phone')),
    company_official_document: z.string({ message: t('form.required') }),
  })
}

export type FormSchema = z.infer<ReturnType<typeof getSchema>>
export type Form = FormContext<Partial<FormSchema>>
export type Field = keyof(FormSchema)

export function getInitialValues(): Partial<FormSchema> {
  return {
    cnpj: '',
    company_name: '',
    email: '',
    phone: '',
    company_official_document: undefined,
  }
}

export function extractDocumentEntries(form: Form) {
  return Object.entries(form.values)
    .map(([key, value]) => key.includes('document') ? ({ type: key, docstorage_id: value }) : null)
    .filter(doc => doc !== null)
}

type AnalysisError = NonNullable<UseRegistrationData>['errors'][number]

export function extractErrorsByField(errors: AnalysisError[] | undefined): Record<Field, AnalysisError> {
  const mappings: Record<string, Field> = {
    'company.company_name': 'company_name',
    'company.contact.phone': 'phone',
    'company.contact.email': 'email',
    'company.document.company_official_document': 'company_official_document',
    'company.documents.company_official_document': 'company_official_document',
  } as const

  return (errors || [])
    .filter(error => !!error)
    .reduce((acc, error) => {
      const field = mappings[error.field]
      if (field) {
        acc[field] = error
      }
      return acc
    }, {} as Record<Field, AnalysisError>)
}
