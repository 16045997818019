import type { BiometryResponse } from '~/utils/customer-register/Biometry'
import http from '~/services-v2/contract-formalization-api/contract-formalization-api.http'

export async function getCustomerBiometry(projectId: string) {
  const response = await http.get<BiometryResponse>(`/v1/formalizations/info/${projectId}`)
  return response.data
}

export async function sendCustomerBiometry(projectId: string, method_receipt: string) {
  const response = await http.post<BiometryResponse>('/v1/formalizations/', {
    project_id: projectId,
    contract_token_receipt_method: method_receipt,
  })
  return response.data
}

export async function resendCustomerBiometry(projectId: string) {
  const response = await http.post<BiometryResponse>(`/v1/formalizations/resend/${projectId}`)
  return response.data
}

export async function cancelCustomerBiometry(projectId: string) {
  const response = await http.post<BiometryResponse>(`/v1/formalizations/cancel/${projectId}`)
  return response.data
}
