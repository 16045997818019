import { useField } from 'vee-validate'
import * as zod from 'zod'
import { validateCPF } from '~/utils/validateCPF'

export function useCpfValidation(t: (key: string) => string) {
  const cpfSchema = zod.string({ message: t('form.required') })
    .min(1, t('form.required'))
    .refine(val => validateCPF(val), { message: t('form.guarantor.valid_cpf') })

  function validateCpf(value: string) {
    const result = cpfSchema.safeParse(value)
    return result.success ? true : result.error.errors[0].message
  }

  return useField('cpfGuarantor', validateCpf)
}
