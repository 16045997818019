<script setup lang="ts">
import IconDownload from '~icons/girassol/download'
import checkIcon from '~icons/material-symbols/check-circle-outline-rounded'
import lockIcon from '~icons/material-symbols/lock-outline'
import useFinancingV2 from '~/store-v2/financings/useFinancing'
import download from '~/utils/download'

const props = defineProps<{
  data: any
  commisionTrasnfers: any
  statusFlow: string
}>()

const { t } = useI18n()
const { formatMoney } = useMoney()
const config = import.meta.env
const route = useRoute()
const id = computed(() => route.params.id as string)
const { data: financing } = useFinancingV2(id)
const hasTransfers = props.commisionTrasnfers.length > 0

function getLink(item) {
  const paymentMap = {
    commission_financing: 7,
    commission_insurance: 8,
  }
  const payment_id = computed(() => {
    return paymentMap[item.category]
  })
  const url = `${config.VITE_PAYMENT_MODEL_TRANSACTIONS_API_URL}/transfer-receipt?financing_id=${financing.value?.id}&payment_type_id=${payment_id.value}`
  download.downloadFile(url)
}

const showButtonToDownloadLink = false
</script>

<template>
  <template v-if="hasTransfers">
    <div class="mt-micro">
      <div v-for="item in props.commisionTrasnfers" :key="item.id" class="mb-nano">
        <div v-if="item.value > 0" class="flex flex-col md:system:flex-row md:system:justify-between">
          <span class="block break-words md:system:flex md:system:w-1/2">
            {{ item.category.includes('commission_financing') ? t('customer.financing_commission') : t('customer.insurance_commission') }}
          </span>
          <div class="flex flex-col-reverse md:site:flex-row md:site:items-center md:site:justify-end">
            <SolButton
              v-if="showButtonToDownloadLink"
              :id="`${item.category}-transaction-download`"
              variant="tertiary"
              size="medium"
              @click="getLink(item)"
            >
              <template #icon:left>
                <IconDownload />
              </template>

              Baixar comprovante
            </SolButton>
            <span
              class="md:system:pt-0 pt-quark flex md:system:ml-nano"
              :class="`${item.status === 'paid' ? 'fonts-body-large-bold text-brand-secondary-pure' : 'text-neutral-low-light'}`"
            >
              <checkIcon v-if="item.status === 'paid'" class="mr-quark" />
              <lockIcon v-else class="mr-quark" />
              {{ formatMoney(item.value) }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </template>
  <template v-else>
    <div class="mt-micro">
      <template v-if="props.data.financing.value > 0">
        <div class="flex flex-col md:system:flex-row md:system:justify-between">
          <span class="block break-words md:system:flex md:system:w-1/2">
            {{ t('customer.financing_commission') }}:
          </span>
          <span class="flex md:system:pt-0 pt-quark">
            <lockIcon class="mr-quark" />
            {{ formatMoney(props.data.financing.value) }}
          </span>
        </div>
      </template>
      <template v-if="props.data.insurance.value > 0">
        <div class="flex flex-col md:system:flex-row md:system:justify-between">
          <span class="block break-words md:system:flex md:system:w-1/2">
            {{ t('customer.insurance_commission') }}:
          </span>
          <span class="flex md:system:pt-0 pt-quark">
            <lockIcon class="mr-quark" />
            {{ formatMoney(props.data.insurance.value) }}
          </span>
        </div>
      </template>
    </div>
  </template>
</template>

<style scoped lang="scss">
:deep(.sol-button-core.-medium){
  @apply  px-0 py-4xs;
  @screen md:site {
    @apply p-0;
    height: auto;
  }
}
</style>
