<script setup lang="ts">
import type { FinancingStatus } from '~/services-v2/financial-bff/financial-bff.types'
import type { UseHardwareFormData } from '~/store-v2/hardware/useHardwareForm'
import type { UseRegistrationData } from '~/store-v2/registration/useRegistration'
import type { Error, StageFormalization } from '~/ui-blocks/Formalization/Formalization.types'
import type { BiometryAlertStatus } from '~/utils/customer-register/Biometry'
import { defineComponent } from 'vue'
import CompanyProjectData from '~/components/company/solfacil/ProjectData.vue'
import FormalizationReiceiptModel from '~/components/formalization/reiceipt-model/index.vue'
import { isStageReproved } from '~/services-v2/financial-bff/financial-bff.utils'
import useFinancingV2 from '~/store-v2/financings/useFinancing'
import useRegistration from '~/store-v2/registration/useRegistration'
import RegistrationForms from '~/ui-blocks/Formalization/1.RegistrationPJ.vue'
import LackBankDetails from '~/ui-blocks/Formalization/blocks/LackBankDetails.vue'
import { extractGloballErrors } from '~/ui-blocks/Formalization/Formalization.utils'
import FormalizationBiometry from '~/ui-blocks/FormalizationBiometry/FormalizationBiometry.vue'
import date from '~/utils/date'
import { GeneralStatuses } from '~/utils/financing-flow/Financing'

const props = defineProps<{
  registration: UseRegistrationData
  triggerRefetch: () => Promise<void>
  isOnflexPartner: boolean
  projectName: string
  hardwareForm: {
    response?: UseHardwareFormData
    refetch: () => Promise<any>
  }
  hasEnergyBillAnalysis: boolean
}>()

defineComponent({
  components: {
    RegistrationForms,
    FormalizationBiometry,
  },
})

const { t } = useI18n()
const route = useRoute()
const router = useRouter()

const id = computed(() => route.params.id as string)
const showAlertAgainAnalysisBill = ref(false)
const showModalLackBankDetails = ref(false)
const statusDocument = ref('' as string)

const biometryAlert = ref({
  pendencies: null,
  reproved: false,
  biometryStatus: '',
} as unknown as BiometryAlertStatus)

const stage = ref('') as unknown as Ref<StageFormalization>

const {
  isLoading: isLoadingStatus,
  refetch: refetchStatus,
  data: financingDetails,
} = useFinancingV2(id)

const {
  data: registration,
} = useRegistration(id)

const hasAlert = props.registration?.status === 'reproved'
  || statusDocument.value === 'failed'
  || biometryAlert.value?.reproved
  || biometryAlert.value?.pendencies?.items?.length
  || financingDetails.value?.status === 'reproved'
  || financingDetails.value?.status === 'canceled'

const stageReproved = computed(() => {
  return financingDetails.value && financingDetails.value.section_statuses.proposal
    ? isStageReproved(financingDetails.value.section_statuses.proposal)
    : ''
})

const componentStage = ref({
  registration: {
    id: 'registration',
    name: 'RegistrationForms',
    component: RegistrationForms,
    props: {
      registration: props.registration,
      triggerRefetch: props.triggerRefetch,
      statusDocument,
    },
  },
  hardware: {
    id: 'hardware',
    name: 'CompanyProjectData',
    component: CompanyProjectData,
    props: {
      projectName: props.projectName,
    },
  },
  receipt_model: {
    id: 'receipt_model',
    name: 'FormalizationReiceiptModel',
    component: FormalizationReiceiptModel,
    props: {
      isOnflexPartner: props.isOnflexPartner,
    },
  },
  contract: {
    id: 'contract',
    name: 'FormalizationBiometry',
    component: FormalizationBiometry,
    props: {},
  },
})

const components = ref([
  componentStage.value.registration,
  componentStage.value.hardware,
  componentStage.value.contract,
  componentStage.value.receipt_model,
])

function stageOpenCollapse(value: StageFormalization) {
  stage.value = value

  setTimeout(() => {
    const element = document.getElementById(`accordion_${value}`)
    if (element)
      element.scrollIntoView({ behavior: 'smooth' })
  }, 500)
}

function redirectPage(value: string) {
  const routes = {
    simulation: `/simulation/details/${id.value}`,
    installation: `/installation/${id.value}`,
    concluded: `/installation/${id.value}`,
  }

  if (routes[value])
    router.push(routes[value])
}

function openWhatsApp() {
  const whatsappLink = 'https://wa.me/5511912386374?text=Ol%C3%A1!%20Preciso%20de%20ajuda%20para%20atualizar%20meus%20dados%20banc%C3%A1rios%20na%20plataforma.'
  window.open(whatsappLink, '_blank')
}

const disabledGoToInstallation = computed(() => {
  if (['installation', 'concluded'].includes(financingDetails?.value?.step ?? ''))
    return false
  return true
})

const alert = computed(() => {
  if (['approved', 'canceled', 'expired'].includes(financingDetails.value?.status ?? ''))
    return null
  return extractGloballErrors(registration.value)
})

const shouldGoToWhatsApp = computed(() => ['generic.qi_tech_account', 'generic.bank_account'].includes(alert.value?.field ?? ''))

const statusToNavigationHeader = computed(() => financingDetails.value as FinancingStatus)

const underAnalysisAlert = computed(() => {
  if (financingDetails.value?.section_statuses.proposal?.customer_data === GeneralStatuses.APPROVED) {
    return null
  }

  if (financingDetails.value?.section_statuses.proposal?.customer_data === GeneralStatuses.UNDER_ANALYSIS) {
    return {
      title: 'Seu projeto passará por uma análise manual feita por um de nossos especialistas',
      description: 'Leituras manuais podem acontecer por diversos fatores como legibilidade e confirmação de dados. Logo você será avisado do resultado!',
      type: 'informative',
      field: 'under_analysis',
    } as Error
  }

  return null
})

watch(alert, (value) => {
  if (value) {
    showModalLackBankDetails.value = value.showModal
  }
}, { immediate: true })

watch(() => props.hardwareForm.response, async () => {
  let index = 0 as number
  const item = 'hardware'

  components.value.forEach((component, key) => {
    if (component.id === item)
      index = key
  })

  components.value = components.value.filter(component => component.id !== item)

  await nextTick()
  components.value.splice(index, 0, componentStage.value[item])
})

watch(() => props.registration, async () => {
  componentStage.value.registration.props.registration = props.registration
})

watch(() => props.hardwareForm.response, async () => {
  let index = 0 as number
  const item = 'hardware'

  await components.value.forEach((component, key) => {
    if (component.id === item)
      index = key
  })

  components.value = components.value.filter(component => component.id !== item)

  await nextTick()
  components.value.splice(index, 0, componentStage.value[item])
})
</script>

<template>
  <div v-if="!isLoadingStatus">
    <NavigationHeaderBar
      stage="proposal"
      :title="props.projectName"
      :project-status="statusToNavigationHeader"
      @stage="stageOpenCollapse"
    />

    <div v-if="components" class="informations">
      <SharedViewPartnerAlert />

      <template v-if="alert">
        <SolAlert
          id="feedback"
          class="my-4"
          :feedback="alert.type"
          :title="alert.title"
          :action="shouldGoToWhatsApp ? 'Atualizar cadastro' : ''"
          @click="shouldGoToWhatsApp ? openWhatsApp() : () => {}"
        >
          {{ alert.description }}
        </SolAlert>
      </template>

      <template v-if="underAnalysisAlert">
        <SolAlert
          id="feedback"
          class="my-4"
          :feedback="underAnalysisAlert.type"
          :title="underAnalysisAlert.title"
        >
          {{ underAnalysisAlert.description }}
        </SolAlert>
      </template>

      <SolAlert
        v-if="showAlertAgainAnalysisBill || financingDetails?.reason === 'analyze_energy'"
        id="alert-analysis-bill-pj"
        :title="t('financing.alert.bill.title')"
        feedback="error"
        class="my-4"
      >
        {{ t('financing.alert.bill.description') }}
      </SolAlert>

      <div v-if="hasAlert" class="my-4 mb-8">
        <template v-if="financingDetails?.status === 'canceled' && financingDetails?.reason !== 'analyze_energy'">
          <SolAlert
            id="informative-feedback-canceled"
            :title="t('customer.canceled.title')"
            feedback="error"
            class="my-4"
          >
            {{ t('customer.canceled.description') }}
          </SolAlert>
        </template>

        <template v-if="financingDetails?.status === 'reproved' && financingDetails?.reason !== 'analyze_energy'">
          <template v-if="biometryAlert?.reproved">
            <SolAlert
              v-if="biometryAlert?.biometryStatus === 'reproved'"
              id="reproved-feedback-biometry"
              :title="t('customer.biometry.alert.reproved_biometry.title')"
              feedback="error"
              class="my-4"
            >
              {{ t('customer.biometry.alert.reproved_biometry.subtitle') }}
            </SolAlert>
            <solAlert
              v-else
              id="reproved-feedback-contract"
              :title="t('customer.biometry.alert.reproved.title')"
              feedback="error"
              class="my-4"
            >
              {{ t('customer.biometry.alert.reproved.subtitle') }}
            </solAlert>
          </template>

          <div v-for="(item, index) in biometryAlert?.pendencies?.items" :key="index">
            <SolAlert
              id="pendencies-feedback-biometry"
              class="my-4"
              :title="item.message"
              feedback="warning"
            >
              {{ t('customer.biometry.alert.pendencies',
                   { date: date(biometryAlert?.pendencies?.expired_at).format("DD/MM/YYYY") })
              }}
            </SolAlert>
          </div>
        </template>
      </div>

      <component
        :is="component.component"
        v-for="(component, index) in components"
        :key="index"
        :position="index + 1"
        client-status="approved"
        :status-project="financingDetails?.section_statuses?.proposal as any"
        :trigger-refetch="props.triggerRefetch"
        :stage="stage"
        :stage-reproved="stageReproved"
        :form-data="props.hardwareForm.response"
        :refetch="props.hardwareForm.refetch"
        v-bind="component.props"
        @status-biometry="refetchStatus"
        @alert-biometry="value => biometryAlert = value as BiometryAlertStatus"
        @client-status-document-step="value => statusDocument = value as string"
        @show-alert-again-analysis-bill="showAlertAgainAnalysisBill = true"
      />
    </div>

    <div class="mx-4 md:system:mx-12">
      <SolDivider thickness="x-small" orientation="horizontal" class="my-5 mt-0 md:system:my-0" />

      <div class="flex flex-col md:system:flex-row flex-col-reverse justify-between gap-5 my-4 md:system:my-12">
        <SolButton
          id="customer-back-simulation"
          size="large"
          class="w-full md:system:w-auto"
          variant="secondary"
          @click="redirectPage('simulation')"
        >
          {{ t('customer.endButtons.backSimulation') }}
        </SolButton>

        <SolButton
          id="customer-next-installation"
          size="large"
          class="w-full md:system:w-auto"
          :disabled="disabledGoToInstallation"
          @click="redirectPage('installation')"
        >
          {{ t('customer.endButtons.nextInstallation') }}
        </SolButton>
      </div>
    </div>
  </div>
  <LackBankDetails
    :show="showModalLackBankDetails"
    :type="alert?.lackType ?? ''"
    @close="showModalLackBankDetails = false"
  />
</template>

<style lang="scss" scoped>
.informations {
  @apply p-4 md:site:px-12 md:site:pt-8;
}
</style>
