import type { UseFinancingData } from '../financings/useFinancing'
import type { HardwareBodySave } from '~/utils/installation/Installation'
import { useMutation } from '@tanstack/vue-query'
import queryClient, { queryKeys } from '~/queryClient'
import { saveHardware } from '~/services-v2/installation-api/installation-api'

export default function useSaveHardware(projectId) {
  const mutationFn = async (hardwareBodySave: HardwareBodySave) => {
    const response = await saveHardware(hardwareBodySave)
    return response
  }

  return useMutation({
    mutationFn,
    mutationKey: queryKeys.INSTALLATIONS.SAVE_HARDWARE(projectId),
    retry: 2,
    retryDelay: 1000,
    onSuccess: () => {
      // Update the financing details in the query cache
      // to reflect the new payment model status
      queryClient.setQueryData<UseFinancingData>(queryKeys.FINANCING.DETAILS(projectId.value), (oldData) => {
        if (!oldData)
          return oldData

        return {
          ...oldData,
          section_statuses: {
            ...oldData.section_statuses,
            proposal: {
              ...oldData.section_statuses.proposal,
              equipment: 'under_analysis',
            },
          },
        }
      })

      queryClient.refetchQueries({ queryKey: queryKeys.INSTALLATIONS.DETAILS(projectId) })
    },
  })
}
