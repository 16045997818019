<script setup lang="ts">
import accountBalanceIcon from '~icons/material-symbols/account-balance-outline'
import checkIcon from '~icons/material-symbols/check-circle-outline-rounded'
import lockIcon from '~icons/material-symbols/lock-outline'

const props = defineProps<{
  transfers: any
  transfersPaid: number
  transfersPending: number
  transferAfterInstallation: boolean
  statusFlow: string
}>()
const { t } = useI18n()
const { formatMoney } = useMoney()
const hasTransfers = props.transfers.length > 0 || false

function getIconComponent(status) {
  const statusIcons = {
    paid: checkIcon,
    not_paid: lockIcon,
  }

  return statusIcons[status] || null
}

function getClass(status) {
  const statusClasses = {
    paid: 'fonts-body-large-bold text-brand-secondary-pure',
    not_paid: 'text-neutral-low-light',
  }

  return statusClasses[status] || ''
}

// Total a ser transferido para conta cadastrada
const partnerTransfers = props.transfers.filter(transfer => transfer.recipient === 'partner')
const totalTransferToPartner = partnerTransfers.reduce((acc, item) => acc + item.value, 0)

// Transferência total após aprovação de documentação
const afterProjectFormalizationTransfers = props.transfers.filter(
  transfer => transfer.kind === 'after_project_formalization',
)

const parternAfterProjectFormalizationTransfers = afterProjectFormalizationTransfers.filter(
  transfer => transfer.recipient === 'partner',
)
const totalAfterProjectFormalization = parternAfterProjectFormalizationTransfers.reduce(
  (acc, item) => acc + item.value,
  0,
)

// Transferência total após instalação

const afterValidationInstallationTransfers = props.transfers.filter(
  transfer => transfer.kind === 'after_validation_installation',
)
const totalAfterValidationInstallation = afterValidationInstallationTransfers.reduce(
  (acc, item) => acc + item.value,
  0,
)

// se todas transferencias em afterProjectFormalizationTransfers tiverem status paid, então o status é paid
const statusAfterProjectFormalization = computed(() => {
  if (afterProjectFormalizationTransfers.length === 0)
    return 'waiting_send_payment'
  const allPaid = afterProjectFormalizationTransfers.every(item => item.status === 'paid')
  return allPaid ? 'paid' : 'not_paid'
},
)

// se todas transferencias em afterValidationInstallationTransfers tiverem status paid, então o status é paid
const statusAfterValidationInstallation = computed(() => {
  if (afterValidationInstallationTransfers.length === 0)
    return 'waiting_send_payment'
  const allPaid = afterValidationInstallationTransfers.every(item => item.status === 'paid')
  return allPaid ? 'paid' : 'not_paid'
},
)
</script>

<template>
  <div class="pt-2xs">
    <div class="pt-nano flex align-top">
      <div class="flex items-center">
        <accountBalanceIcon class="hidden md:site:flex md:site:icon-size-large" />
      </div>
      <template v-if="!hasTransfers">
        <div class="flex flex-col w-full">
          <div class="flex flex-col md:system:flex-row md:site:justify-between md:site:align-center md:site:gap-4 w-full md:site:ml-nano">
            <div class="flex flex-col md:site:w-4/5">
              <span class="fonts-body-large-bold text-brand-primary-dark block break-words md:system:flex md:system:w-1/2">
                {{ t('customer.total_transfer_to_registered_account') }}
              </span>
              <span class="block break-words pt-quark md:system:flex md:system:w-1/2">
                Em breve as informações estarão disponíveis
              </span>
            </div>
            <span class="fonts-body-large-bold text-brand-primary-dark pt-quark md:site:pt-0">
              {{ t('customer.opened') }}
            </span>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="flex flex-col w-full">
          <div v-if="totalTransferToPartner > 0" class="pb-micro md:site:pb-quark flex flex-col md:system:flex-row md:site:justify-between md:site:align-center md:site:gap-4 w-full md:site:ml-nano">
            <div class="flex flex-col md:site:w-4/5">
              <span class="fonts-body-large-bold text-brand-primary-dark block break-words md:system:flex fonts-body-x-large-bold">
                {{ t('customer.total_transfer_to_registered_account') }}
              </span>
            </div>
            <span class="fonts-body-large-bold text-brand-primary-dark pt-quark md:site:pt-0">
              {{ formatMoney(totalTransferToPartner) }}
            </span>
          </div>
          <div
            v-if="totalAfterProjectFormalization > 0"
            class="pt-quark pb-quark flex flex-col
          md:system:flex-row md:site:justify-between
          md:site:items-center md:site:gap-4 w-full
          md:site:ml-nano"
          >
            <div class="flex flex-col">
              <span class="block break-words md:system:flex">
                {{ t('customer.transfer_after_contract_approval') }}
              </span>
            </div>
            <div class="flex flex-col md:site:flex-row   md:site:items-center md:site:justify-end">
              <span
                class="md:system:pt-0 pt-quark flex md:system:ml-nano"
                :class="`${getClass(statusAfterProjectFormalization)}`"
              >
                <component :is="getIconComponent(statusAfterProjectFormalization)" class="mr-quark" />
                {{ formatMoney(totalAfterProjectFormalization) }}
              </span>
            </div>
          </div>
          <div
            v-if="totalAfterValidationInstallation > 0"
            class="pt-0 pb-micro flex flex-col
          md:system:flex-row md:site:justify-between
          md:site:items-center md:site:gap-4 w-full
          md:site:ml-nano"
          >
            <div class="flex flex-col">
              <span class="block break-words md:system:flex">
                {{ t('customer.transfer_after_validation_installation') }}
              </span>
            </div>
            <div class="flex flex-col md:site:flex-row   md:site:items-center md:site:justify-end">
              <span
                class="md:system:pt-0 pt-quark flex md:system:ml-nano"
                :class="`${getClass(statusAfterValidationInstallation)}`"
              >
                <component :is="getIconComponent(statusAfterValidationInstallation)" class="mr-quark" />
                {{ formatMoney(totalAfterValidationInstallation) }}
              </span>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
