import type { BiometryResponse } from '~/utils/customer-register/Biometry'
import { useMutation } from '@tanstack/vue-query'
import { queryClient, queryKeys } from '~/queryClient'
import { resendCustomerBiometry } from '~/services-v2/contract-formalization-api/contract-formalization-api'

export default function useCustomerBiometryResend(projectId: Ref<string>) {
  const mutationFn = async () => {
    return resendCustomerBiometry(projectId.value)
  }

  return useMutation({
    mutationFn,
    mutationKey: queryKeys.CONTRACT_BIOMETRY.RESEND,
    retry: 3,
    retryDelay: 1000,
    onSuccess: () => {
      queryClient.setQueryData<BiometryResponse>(queryKeys.CONTRACT_BIOMETRY.DATA(projectId.value), (oldData) => {
        if (!oldData)
          return oldData

        return {
          ...oldData,
          approval: 'waiting',
          biometry: { ...oldData.biometry, status: 'in_progress' },
          contract: { ...oldData.contract, status: 'waiting' },
          status: 'biometric_started',
        }
      })

      queryClient.invalidateQueries({
        queryKey: queryKeys.FINANCING.DETAILS(String(projectId)),
      })
    },
  })
}
