import type { fields, ModelTypes } from './__generated__/zeus'
import KnowError from '~/exceptions/KnownError'
import { Thunder } from './__generated__/zeus'
import http from './financial-bff.http'

interface GraphQLRes {
  data: Record<string, any> | null
  errors: Error[] | null
}

const financialBff = Thunder(async (query, variables) => {
  const res = await http.post<GraphQLRes>('/graphql', {
    query,
    variables,
  })

  if (res.data.errors) {
    throw new KnowError(res.data.errors[0].message)
  }
  return res.data.data
})

export type Partner = ModelTypes['CorePartner']

export type Models = ModelTypes
export { fields }

export default financialBff
