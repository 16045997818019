export enum Journey {
  OLD = 0,
  MIXED = 1,
  NEW = 2,
}

export enum Formalization {
  CUSTOMER_DATA = 'customer_data',
  PAYMENT_MODEL = 'payment_model',
  EQUIPMENT = 'equipment',
  BIOMETRY_AND_CONTRACT = 'biometry_and_contract',
}
