<script setup lang="ts">
withDefaults(defineProps<{
  show: boolean
}>(), {
  show: false,
})

const emit = defineEmits<{
  (e: 'close'): void
}>()

const { t } = useI18n()

const proofLinkText = [
  {
    type: t('formalization.electricity_bill.modal_what_is_bond.kinship'),
    list: [
      t('formalization.electricity_bill.modal_what_is_bond.marriage_certificate'),
      t('formalization.electricity_bill.modal_what_is_bond.document_of_holder'),
    ],
  },
  {
    type: t('formalization.electricity_bill.modal_what_is_bond.location'),
    list: [
      t('formalization.electricity_bill.modal_what_is_bond.purchase_and_sale_agreement'),
      t('formalization.electricity_bill.modal_what_is_bond.iptu'),
      t('formalization.electricity_bill.modal_what_is_bond.property_registration'),
      t('formalization.electricity_bill.modal_what_is_bond.ir_declaration'),
    ],
  },
]
</script>

<template>
  <div>
    <SolModal
      id="modal-preview-file"
      :is-open="show"
      :title="t('formalization.electricity_bill.what_is_proof_of_link')"
      :size="{ desktop: 'medium', mobile: 'full' }"
      class="overflow-auto"
      :action-primary-text="t('formalization.electricity_bill.modal_what_is_bond.okay')"
      @close="emit('close')"
      @action:primary="emit('close')"
    >
      <div class="flex flex-col items-start gap-6">
        <span class="fonts-body-large-regular text-brand-primary-dark">
          {{ t('formalization.electricity_bill.modal_what_is_bond.description.part_1') }}
          <span class="fonts-body-large-bold ">
            {{ t('formalization.electricity_bill.modal_what_is_bond.description.part_2') }}
          </span>
          {{ t('formalization.electricity_bill.modal_what_is_bond.description.part_3') }}
          <span class="fonts-body-large-bold">
            {{ t('formalization.electricity_bill.modal_what_is_bond.description.part_4') }}
          </span>.
          {{ t('formalization.electricity_bill.modal_what_is_bond.description.part_5') }}
        </span>

        <div
          class="flex flex-col md:system:flex-row justify-center items-stretch  gap-4"
        >
          <div
            v-for="proofLink in proofLinkText"
            :key="proofLink.type"
            class="
            flex flex-col items-center gap-4 flex-1 p-4
            bg-brand-secondary-light rounded-lg
            bg-opacity-intense
          "
          >
            <div class="text-center">
              <p class=" fonts-body-x-large-regular text-brand-primary-dark">
                {{ t('formalization.electricity_bill.modal_what_is_bond.proof_of') }}
              </p>
              <p class="fonts-body-x-large-bold text-brand-secondary-pure">
                {{ proofLink.type }}
              </p>
            </div>
            <SolDivider thickness="x-small" />
            <ul class="list-disc list-inside fonts-body-medium-regular">
              <li v-for="(item, index) in proofLink.list" :key="index">
                {{ item }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </SolModal>
  </div>
</template>

<style scoped>

</style>
