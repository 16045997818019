export function parseObjectToSearchParams(obj: Record<any, any>) {
  const searchParams = new URLSearchParams()

  Object.entries(obj).forEach(([key, value]) => {
    if (value === undefined || value === null)
      searchParams.delete(key)
    else searchParams.set(key, value.toString())
  })

  return searchParams
}
export function parseSearchParamsToObject(searchParams: URLSearchParams) {
  const obj: Record<string, any> = {}

  for (const [key, value] of searchParams.entries()) {
    if (value === undefined || value === null)
      continue
    obj[key] = value
  }

  return obj
}
