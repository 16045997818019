app:
  name: Financing
  next: Next
  advance: Advance
  advance_and_update: Update and advance
  share: Share
  whatsapp: WhatsApp
  send: Send
  save: Save
  cancel: Cancel
  cancel_analysis: Cancel analysis
  alert:
    partner:
      title: 'Viewing projects as: | Viewing project as: '
      statement_title: 'Viewing statement as: '
      textButton: Admin View
      description: >-
        Here you will find projects of just the partner above. To see all
        projects click on the button on the side. | To return to the admin view,
        click on the button on the side.
      statement_description: Here you will find the statement of just the partner above.
  back: Go back
header:
  welcome: Welcome
  exit: Exit
  project:
    title: Financing
    steps:
      formalization: Proposal
      simulation: Simulation
      installation: Installation
    expired: Expired
  bnpl:
    steps:
      simulation: Simulation
      formalization: Proposal
      external: External
    status:
      IN_PROGRESS:
        type: warning
        text: In progress
      EXPIRED:
        type: neutral
        text: Expired
      DONE:
        type: positive
        text: Done
  impersonate:
    modal:
      title: View as a partner
      subtitle: Search by the Name or CNPJ of the partner you want to view
      input_label: Partner name or CNPJ
      button: View
      empty_state:
        title: Partner not found
        description: >-
          It seems that the partner you are looking for does not exist. Please
          check the data and try again.
    error:
      title: Error to view partner
      description: >-
        It was not possible to view the partner. Please check the data and try
        again.
home:
  title: Home
  subtitle: All your projects in one place
  newSimulation: New simulation
  banner:
    title: New formalization platform!
    subtitle: After completing the simulation, formalize faster in this new experience
resumeWidget:
  simulationSummary: Simulation summary
  summary: Summary
  close: Close
  gracePeriod: Grace period
  month: |-
    month |
    months
  financedValue: Financed value
  downPayment: Down payment
  totalProjectValue: Total project value
  cet: CET
  interestRate: Interest rate
  installment: Installment
  financing: Financing
  opportunities:
    title: Your gains
    commission: Commission
    insuranceCommission: Insurance Commission
simulation:
  next: View installments
  load_again: Load again
  founded: Simulations found
  golist: Go to list
  list: Simulations
  subtitles: Access your saved simulations
  new: New simulation
  project_type: Financing lines
  cpf_use_terms: >-
    I declare that my client has authorized the consultation of their CPF or
    CNPJ in credit protection agencies.
  continue_with_value: Continue with approved amount
  financing_options: Financing options
  details:
    info_title: Simulation data for visualization purposes only.
    info_description: >-
      You will be able to review your simulation and change data during the
      contract signing stage.
  conclude: Conclude simulation
  info_risk:
    necessary_install_pending_project_title: The client was approved in the simulation
    necessary_install_pending_project_description: >-
      Attention! Project approved for credit, but in order to formalize this new
      project, it is necessary to prove the installation of other projects
      already formalized
  bnpl:
    solfacilInstallment: Solfacil Installment
    identity: id
    installment_title: |-
      Installment |
      Installments
    conditions_installment: >-
      Check out our options for the one that best fits your client's profile. It
      is possible to simulate the value of the project kit, and define it in the
      next stage, proposal.
    project_value: Project value
    due_date: Maturity
    invoice_due_date: Invoice due date
    kit_value_helper: Updated after kit selection
    kit_value: Kit value
    number_order: N° order
    service_value_helper: Project value minus kit and fee
    service_value: Service value
    installment_amount: Installment value
    initial_amount: Prohibited
    receive_amount: Will receive
    customer_pays_fee: Installment with fixed service
    store_pays_fee: Installment with fixed project
    customer_pays_fee_description: You pass on the fee to your client, increasing the value of the project.
    store_pays_fee_description: You pay the fee, maintaining the value of the project.
    id:
      summary:
        title:
          summary: Summary
          service: Fixed service
        installment:
          title: Installment
          prohibited: Prohibited
          cet: CET
          am: a.m.
        enterprise:
          title: For your company
          total: Total project value
          kit: Store Kit
          tax: Advance fee
          value: Amount to receive
        client:
          title: For your client
          total: Total project value
          prohibited: Prohibited
          installments: Installments
          of: of
  modal:
    title: New proposal platform!
    altImg: Funding Screen
    info:
      proposal:
        title: Send your proposal in up to 2 minutes!
        description: Fill out everything at once and wait for our analysis.
      projects:
        title: Make your projects whenever and wherever you want!
        description: 'Cell phone, computer or tablet: you choose how and where.'
      payments:
        title: Real-time payments
        description: After approval, payments 24 hours a day, 7 days a week.
    button:
      old: Continue with the old platform
      new: Continue with the new platform!
  notFound:
    title: Simulation not found
    subtitle: >-
      It seems that the simulation you are looking for does not exist. Please
      check the data and try again.
  errorProject:
    title: Error to load list
    subtitle: >-
      It seems that there was an error loading the list of simulations. Please
      try again later.
  status_filter:
    done: Approved
    pending: Pending
    reproved: Reproved
  result:
    bnpl:
      modals:
        important:
          description: >-
            You will be held responsible if the customer defaults. Solfácil may
            request that you pay the installment amounts in full, and may also
            enter your company's details in the Credit Protection Bodies.
      alerts:
        responsibility:
          description: >-
            Does the customer know that, even without credit, you pay in
            installments, assuming the risk? It's similar here, check
            conditions.
      will_be_pre_approved: Check the conditions that would be pre-approved
      approved_with_restrictions: Approved, with restrictions
      pre_approved_value: Pre-approved amount
      max_installments: Installments in up to
      buttons:
        textFollowInstallment: Installment
      customerRejectedGuarantor:
        title: REPROVED CUSTOMER
        description:
          title: There are two actions that you can take
          actions[0]: >-
            1. Consult credit protection agencies and see if this CPF or CNPJ is
            regular.
          actions[1]: 2. Refares the analysis and add another guarantor.
    financing:
      down_payment: Input (for you)
    data:
      down_payment: Prohibited
      systemPower: System Power (KWp)
  return_formalization: Go to proposal
  ampera:
    lack_of_stock:
      text1: >-
        Since this is a second financing, a new Ampera is not required for this
        installation.
    textLink: More details
    description: >-
      Optimize your after-sales with monitoring and receive discounts on
      installments.
  newPage:
    title: Customer Type
    description: Define your customer's profile for this project
    pf:
      title: Individual (PF)
      description: Credit line for residences
      financing:
        title: Financing up to {value}
        description: >-
          In up to {maxFinancingInstallments} installments - Insurance -
          {period}
      bnpl:
        title: Solfácil Installments up to {value}
        description: In up to {maxBnplInstallments} installments - {period}
    pj:
      title: Legal Entity (PJ)
      description: Credit line for companies
      financing:
        title: Financing up to {value}
        description: >-
          In up to {maxFinancingInstallments} installments - Insurance -
          {period}
      bnpl:
        description: Solfácil Installments coming soon
    client_data:
      title: Project data
      description: Special credit conditions for your customer in a few seconds.
      form:
        document_pf:
          title: Customer CPF*
        document_pj:
          title: Customer CNPJ*
          majoritary:
            title: CPF of the majority shareholder*
        value:
          title: Total project value*
          placeholder: Enter the project value
        down_payment:
          title: Prohibited
          placeholder: Enter the entry value
        power:
          title: System power*
          placeholder: Enter the power value
          system_power_error_422: |-
            The cost per watt (R$/Wp) must be within internal policies.
            Increase project value or decrease power to continue
    empty:
      title: Error when loading financing types
      subtitle: >-
        An error occurred when trying to load the types of financing (PF and
        PJ).

        Please try again later.
revision:
  title: Simulation review
  subtitle: >-
    Review and change all simulation data, including values, as long as they
    comply with our policies.
  subtitle_complement: Otherwise, it will be necessary to perform a new simulation.
buttons:
  back: Back
  discart: Discard change
  edit_save: Save changes
  go_financing: Go to financing
  go_new_page: Redo analysis
loading:
  messages: >-
    We're almost there, harnessing solar energy to power our calculations.
    Sunbeams are arriving, charging with the strength of the sun.
list:
  days: '- | {count} day | {count} days'
installments:
  with_combo: Installments with Easy Combo
  with_combo_description: >-
    The cheapest installment for your customer. The kit must be purchased at the
    Solfácil Store.
  with_combo_comission: Installments with Easy Combo and commission
  with_combo_comission_description: >-
    Best value for money for you and your client. The kit must be purchased at
    the Solfácil Store.
  without_combo: Installments without Easy Combo
  without_combo_description: >-
    Most expensive option for your client. The kit can be purchased outside the
    Solfácil Store.
form:
  alert_required_fields: Please fill in all required fields.
  file_required: File submission required.
  invalid_file_type: |
    Invalid file type. Accepted file types are: {types}
  required: Required field
  past_date_required: Past date required
  cpf_duplicate: The CPF provided is already being used by another registration.
  error: Registration not completed, try again shortly.
  informativeCepText: Problems with the zip code?
  cepModal:
    title: Problems with the zip code?
    titleButton: Close
    informative:
      title: >-
        If you have problems with the CEP, watch the video or follow the steps
        below:
      item1: '1st Access the website Search postal code:'
      item2: >-
        2nd In the first field, write the name of the street, city and Federal
        Unit (abbreviated).

        In the second field it is not necessary to make changes.
      item3: 3rd Fill in the code shown in the image, and click the “Search” button.
      item4: >-
        4th On the next screen, the zip code that needs to be entered on the
        platform will appear.
      item5: 5th If your doubts persist, watch the video above for more information
  cepInfo: >-
    The zip code must be the same as that used for postal deliveries, even if a
    different one appears on the electricity bill.

    If the question persists, click on the button below "Problems with the zip
    code?".
  cancelAnalysis:
    confirm: Confirm cancellation
    back: Back
    title: Cancel the analysis?
    message: |-
      You are about to cancel the analysis of the information you entered.
      This will interrupt the process and a new submission will be required.
      Are you sure you want to continue?
    successToast: Analysis canceled successfully.
    errorToastDescription: Unable to cancel analysis.
  toast:
    success: Registration completed successfully!
  repeated_document: The CPF cannot be the same as the customer's.
  optional_placeholder: Optional
  correction_letter: Correction letter
  button:
    new-email: Add another email
    remove-email: Remove email
    send-email: Send emails
  non_negative: The value cannot be negative
  email:
    required: ''
    invalid: ''
  email-invalid: Invalid Email
  email-required: E-mail is mandatory
  service_invoice: Invoice full of the project issued by your company
  guarantor:
    placeholder: CPF EVALIST
    button: Include guarantor
    valid_cpf: Enter a valid CPF
    error_toast_default: |-
      Error when including a guarantor.
      Try again.
    error_toast_guarantor_same_document: CPF of the guarantor cannot be the same as the customer
  cpf_required: CPF is mandatory
  correnction_letter:
    helper_text: Add the correction letter to adjust your project
  max: Maximum of {max} characters
  invalid_cep: Invalid zip code
  payment_model:
    correction_letter_approved: Correction letter approved
    invoice_approved: Invoice approved
company:
  energy_bill_subtitle: |-
    We will request the electricity bill to be sent at the documentation stage.
    If it is not in the customer's name, simply indicate the holder's CPF.
  biometry:
    modal_sending:
      title: Confirm the customer's contact information
      helper_text:
        edit_suggestion:
          first_line: 'Don''t forget: You have'
          second_line: '{count} tries'
          last_line: to update the customer's data.
        no_changes:
          first_line: 'Don''t forget: You'
          second_line: don't have any changes
          last_line: to update the customer's data.
        new_analysis_warning: >-
          By changing your customers' data, the project will undergo a new
          analysis
      send: Send biometrics and contract
      confirm_edit: Confirm change
      edit: Edit data
      cancel: Cancel
      form:
        majority:
          email: Majority shareholder email
          phone: Majority shareholder's cell phone
        legal:
          email: Legal representative's email
          phone: Legal representative's cell phone
        legal_1:
          email: 1st legal representative's email
          phone: Cell phone of the 1st legal representative
        legal_2:
          email: 2nd legal representative's email
          phone: Cell phone of the 2nd legal representative
        required: Required field
        email_error: Please enter a valid email
        phone_error: Please enter a valid mobile phone number
        submit: Confirm edit
customer:
  header:
    message:
      error: An error occurred while obtaining the proposal status.
    steps:
      simulation: Simulation
      installation: Installation
      formalization: Proposal
    tags:
      expired: Expired
      canceled: Canceled
      reproved: Failed
      approved: Approved
      pendency: Pending
      pending: In progress
      under_analysis: Under review
      send_contract: Send contract
      sent: Pending customer
      submitted: Sent
  transfer_waiting: Waiting for transfers
  canceled:
    title: Project canceled
    description: >-
      The client requested the cancellation of this project and the transfer of
      values will not be carried out. If it has already been done, it will be
      reversed. In the case of Combo Fácil, the kit order will also be
      cancelled.
  new: Proposal
  receipt_model_title: Receipt Model
  receipt_model_subtitle_eligible: >-
    Choose how you will receive the value of your project and attach the
    invoice(s).
  receipt_model_subtitle_not_eligible: Check your receipt model and attach the invoice(s).
  descriptionUnableContinuefinancing: >-
    Some customer data may need adjustment.

    Please check that the contact and address belong to the customer, not the
    seller or a relative.

    After this confirmation, create a new project and send it for analysis.

    Please note that, even so, the system may automatically disapprove the
    proposal in some cases, in accordance with our internal policy.
  document_data_subtitle: >-
    Please attach the following documents from your client below. Preferably in
    .pdf format.
  biometry:
    send_success: Biometry sent successfully
    cancel_success: The sent biometry canceled successfully
    catch:
      data_error_title: Biometry data error
      has_been_cancelled: Contract has been cancelled, wait {minutes} minute(s) to cancel again"
    alert:
      reproved_biometry:
        title: Customer biometry not approved
        subtitle: >-
          The customer failed this stage due to inconsistencies and This project
          cannot be formalized. This type of reproach is irreversible.
      reproved:
        title: Its not possible follow with the financing
        subtitle: The customer already has a project in the formalization process.
    update_error: >-
      Due to our internal policies, it is not possible to proceed with the
      change.
    know_errors:
      BIOMETRY_ALREADY_DONE: >-
        It was not possible to update because the biometrics have already been
        done
      FORMALIZATION_COMPLETED: >-
        It was not possible to update because the formalization has already been
        completed
      INVALID_STATUS: It was not possible to update because the status is invalid
      MAX_TRIES: >-
        It was not possible to update because the maximum number of attempts has
        been reached
      MATRIX_RESTRICTIONS: >-
        Due to our internal policies, it is not possible to proceed with the
        change.
      SAME_EMAIL_PHONE: >-
        It was not possible to update because the email and phone number are the
        same
    update_success: Contact details have been changed successfully
    modal_sending:
      title: Confirm the customer's contact information
      helper_text:
        edit_suggestion:
          first_line: 'Don''t forget: You have'
          second_line: '{count} tries'
          last_line: to update the customer's data.
        no_changes:
          first_line: 'Don''t forget: You'
          second_line: don't have any changes
          last_line: to update the customer's data.
        new_analysis_warning: >-
          By changing your customers' data, the project will undergo a new
          analysis
      send: Send biometrics and contract
      confirm_edit: Confirm change
      edit: Edit data
      cancel: Cancel
      form:
        required: Required field
        email: Customer's email
        email_error: Please enter a valid email
        phone: Customer's mobile phone
        phone_error: Please enter a valid mobile phone number
        submit: Confirm edit
        email_guarantor: Email of the guarantor
  guarantor_section_title: Enter the personal data of the guarantor
  customer_section_title: Enter the personal data of the client
  separate_invoices_subtitle: >-
    Equipment Note issued by the supplier (service note is not required in this
    case)
company_form_document:
  label_document_majority_and_legal_representants: >-
    RG or CNH of the majority shareholder and legal representative(s) (if
    applicable)*
  label_energy_bill: Electricity bill for the installation site*
  label_document_company: Company officialization documents*
  label_additional_document: Additional documents
  label_subtitle_document_majority_and_legal_representants: >-
    We accept: RG or CNH or CNH Digital + QR Code or physical CTPS or active
    class card with photo. Documents must contain the CPF number.
  label_subtitle_energy_bill: >-
    Speed up your analysis! Give preference to .pdf files from the utility
    company's website. Send the complete bill, in the name of the individual or
    legal entity, issued within 90 days.
  label_subtitle_document_company: >-
    We accept one of these documents: declaration of individual
    microentrepreneur (MEI), articles of association, application for business
    or, in the absence of these, a certificate issued by the Commercial Board.
  label_subtitle_additional_document: >-
    If the place of establishment is not the address of the CNPJ or the
    residence of the legal representative or co-obligor, it will be necessary to
    send proof of residence in the name of one of these three entities of the
    place of establishment.
  additional_comments: Additional comments
  placeholder: Drag file here or click to upload
  placeholder_additional_comments: Optional
formDocument:
  labelDocumentSol: RG or CNH of client*
  labelElectricityBill: Electricity bill for the installation site*
  labelAdditionalDocument: Additional documents
  labelSubtitleDocument: >-
    We accept: RG or CNH or CNH Digital + QR Code or physical CTPS or active
    class card with photo. Documents must contain the CPF number.
  labelSubtitleElectricityBill: >-
    Speed up your analysis! Preferably .pdf files from the dealership website.
    Send the complete bill, in the name of the individual or legal entity, up to
    90 days from the date of issue.
  labelSubtitleAdditionalDocument: >-
    Not mandatory. Example: proof of connection between the financing client and
    the electricity bill holder.
  additionalComments: Additional comments
  placeholder: Drag file here or click to upload
  placeholderAdditionalComments: Optional
  responseErrorDelete: Error deleting file, please try again.
  responseSuccessDelete: File deleted successfully.
formAddress:
  countryside: Endereço está em Zona Rural?
  yesCountryside: Sim
  noCountryside: Não
  cep: CEP*
  installationStreet: Rua da instalação*
  street: Rua*
  street_placeholder: Rua, alameda, avenida...
  number: Número*
  complement: Complemento
  complementPlaceholder: Apt, bloco...
  neighborhood: Bairro*
  neighborhood_placeholder: Nome do bairro
  state: Estado*
  city: Cidade*
  city_placeholder: Nome do bairro
  local: Local de residência do cliente, é o mesmo local da instalação?
  yesLocal: Local de residência, é o mesmo do local da instalação
  noLocal: Local de residência e instalação não são os mesmo
  titleInstallationLocation: Complete as informações do local de instalação
  addressInstallation: Endereço do local de instalação
solfacilPoints:
  label: Pontos Solfácil Mais
  phrases:
    one: 'How about adding to your financing:'
    two: Let's do the project better? How about adding
    three: Good project! How about adding {string}? Very good!
    four: Formalizing this project will bring you many benefits! Very good!
toastKitSelected:
  title: Selected Kit
  description: >-
    If available at the time of formalization, the kit will be invoiced
    automatically for your convenience.
receiptModel:
  tags:
    inAnalysis: In analysis
    approved: Approved
    denied: Rejected
    inProgress: In progress
    pendingChange: Pending invoice
    selected: Selected
    transferInProgress: Transfer in progress
  onflex:
    title: 100% receipt model after installation
    twoInvoices: in two notes
  alertInstallments:
    title: Receipt model in two payments ({firstValue}% and {secondValue}%)
  response:
    error: >-
      An error occurred while trying to update the receipt model. Please try
      again later.
    equipment_value_error: Equipment value must be greater than to financing amounts
    email_error: >-
      An error occurred while trying to update the email. Please try again
      later.
subscriptionEnergy:
  title: Your customer can still have solar energy!
  subtitle: >-
    Refer your customer to have solar energy by subscription with up to 20% off
    on the electricity bill! And earn a commission if they accept!
  recommend_customer: Refer customer
  alt_img: Image Subscription Solar Energy
  title_img: Subscription Solar Energy
project_data:
  status:
    pending: Pending
    selected: Selected
    in_progress: In progress
  errors:
    string_overload_max: >-
      Excessive power in the selected inverter(s). Reduce the quantity or choose
      lower power models.
    string_overload_min: >-
      Insufficient power in the selected inverter(s). Add another inverter or
      choose higher power models.
    microinverter_overload_max: >-
      Excessive power in the selected microinverters. Increase the quantity or
      choose lower power models.
    microinverter_overload_min: >-
      Insufficient power in the selected microinverters. Reduce the quantity of
      microinverters in the project or choose a higher power model.
    modules_overload: >-
      The selected modules do not seem to fit the project specifications. Please
      review the quantity or power of the modules.
financing:
  emptyList:
    title: No projects found
    subtitle: It seems that you have not yet created any projects.
    button_list: New project
  boxSearch:
    placeholder: Search by Name, CPF/CNPJ or ID
  filters:
    clear: |-
      Clear filter |
      Clear filters
    title: 'Filter projects by:'
    steps: Steps
    status: Status
  no_data: No data found
  notFound:
    subtitle: >-
      Search for another Name, CPF, CNPJ or project ID to find the project you
      are looking for.

      If it is correct, you can search for the project in the old list.
  list:
    step:
      canceled: Canceled
      concluded: Completed
      expired: Expired
      proposal: Proposal
      installation: Installation
      simulation: Simulation
    status:
      canceled: Canceled
      expired: Expired
      pending: In progress
      waiting_customer: Waiting for customer
      reproved: Failed
      under_analysis: Under review
      to_review: To review
      approved: Approved
  alert:
    title: Here you will only find loans created on the new platform
    subtitle: To access financing from the old platform, click the button on the right.
    action: View old list
    bill:
      description: |-
        Unfortunately, we are still unable to serve this customer.
        However, a new attempt may be made in 30 days.
      title: We were unable to continue with this financing
  ErrorEmptyState:
    title: Oops, something went wrong
    subtitle: |-
      There was a problem and we were unable to bring the result.
      Try again later
installationPage:
  footer:
    formalization: Go to proposal
  trackAmpera: Track Ampera
unauthorized:
  title: Unauthorized
  description: >-
    You do not have permission to access this page. If you believe this is an
    error, please contact the system administrator.
notFound:
  title: Page not found
  description: >-
    The page you are looking for does not exist. If you believe this is an
    error, please contact the system administrator.
unknonwError:
  title: Without information's
  description: >-
    An error occurred while processing your request. If you believe this is an
    error, please contact the system administrator.
noResults:
  title: No results found
  description: >-
    We couldn't find any results for your search. Try again with different
    terms.
emptyStateCustomer:
  title: No information available
emptyStateRegister:
  subtitle: |-
    There was a problem obtaining financing information.
    Try reloading the page.
formalization:
  upload:
    maximum_size:
      text: Tamanho máximo de
      10mb: 10MB
    accepted_files:
      text: por arquivo
      files: (JPEG, JPG, PNG e PDF)
    cpf_attention:
      text: Atenção!
      description: Documentos devem conter o número do CPF
  client:
    cpf: CPF do(a) cliente
    name:
      label: Nome Completo do(a) cliente
      placeholder: Preencha o nome completo do cliente
      placeholder_guarantor: Fill in the full name of the guarantor
      label_guarantor: Full name of the guarantor
    birthdate: Data de Nascimento do cliente
    phone: Celular do(a) cliente
    email:
      label: E-mail do(a) cliente
      placeholder: Insira o email do cliente
      label_guarantor: Email from the guarantor
      placeholder_guarantor: Enter the Email of the guarantor
  customer_documents:
    document:
      example:
        title: Exemplo de como deve ser enviado
        image:
          front:
            alt: Exemplo de RG frente
            title: Frente do documento
          back:
            alt: Exemplo de RG verso
            title: Verso do documento
  electricity_bill:
    title: Conta de luz do local da instalação
    info_bill: >-
      Envie a conta completa, em nome da pessoa física ou jurídica de até 90
      dias de emissão.
    info_files: >-
      Agilize sua análise! De preferência para arquivos em .pdf do site da
      concessionária. Tamanho máximo de 10MB por arquivo (JPEG, JPG, PNG e PDF)
    form:
      electricity_bill_customer_name: A conta de luz está no nome do seu cliente?
      proof_link_document: Comprovante de vínculo
      document_account_holder:
        label: CPF do titular da conta
        placeholder: Insira o CPF do titular da conta
    what_is_proof_of_link: O que é comprovante de vínculo?
    add_observations_and_comments: Adicionar observações e/ou comentários
    modal_what_is_bond:
      description:
        part_1: Aceitamos documentações que comprovem
        part_2: vínculo de parentesco
        part_3: com o titular da conta de luz ou
        part_4: vínculo com o local de instalação
        part_5: >-
          Confira abaixo os documentos que podem ser anexados que comprovam
          vínculo:
      proof_of: Comprovante de
      kinship: parentesco
      location: localização
      marriage_certificate: Certidão de casamento/união estável com o titular (1º grau)
      document_of_holder: CNH/RG do titular (com CPF)
      purchase_and_sale_agreement: Contrato de Compra e Venda (devidamente registrado no Cartório de Notas)
      iptu: IPTU
      property_registration: Matrícula do Imóvel',
      ir_declaration: Declaração de IR (somente se o bem estiver vinculado ao imóvel)
      okay: Está bem, entendi!
  address:
    guarantor:
      label: Address of the guarantor
price:
  modal:
    title: Price and Fees Update
    secondary: I don't want to edit simulation
    primary: I understood and I want to continue
    body: >-
      Our rates and prices have changed since the last simulation you have made
      for this project
    subbody: >-
      Your simulation will only be updated if you confirm the editions
      performed. Otherwise, you will follow the same amount initially recorded.
global:
  statuses:
    in_progress: In progress
    under_analysis: Under analysis
    approved: Approved
    to_review: To review
    waiting_customer: Waiting for customer
    reproved: Reproved
    expired: Expired
    canceled: Canceled
toast:
  approved:
    documentation:
      title: Approved documentation
      description: >-
        We have analyzed the submitted documentation and everything is correct!
        Proceed to the next step.
  incomplete:
    documentation:
      title: Incomplete documentation
      description: Check if all fields are filled in to send the analysis.
  error:
    upload:
      title: Error sending file
      description: >-
        An error occurred while sending the file, check the file type or size
        and try again.
    send:
      title: Error sending
      description: >-
        An error occurred while sending the form. Check the fields and try
        again.
    reproved:
      title: Reproved documentation
      description: |-
        Documentation sent does not comply with the required requirements.
        Make a new simulation or contact us.
  sended:
    documentation:
      title: Documentation sent
      description: Documentation sent successfully!
  success:
    send:
      description: |-
        We analyze the documentation sent and everything is correct!
        Follow to the equipment stage.
  informative:
    send:
      under_analisys:
        title: Documentation in Analysis with Specialist
        description: >-
          Our expert is analyzing the documentation sent and will return within
          24 hours.
payment-model:
  modal-confirmation:
    cancel: Back to Edition
    send: I'm aware and I want to continue
    title: Attention! Check out the selected options
    description-1: >-
      After sending the invoices for analysis, the options selected in the
      receiving model
    description-2: >-
      Be sure to check if you are selected the best way to receive your
      business! Do you want to continue?
    description-alert: cannot be edited
receipt-model:
  send-billing-letter:
    error:
      title: Unvised billing letter, please make sure the emails are correct.
      description: Check the selected emails and try again.
    success:
      title: Billing letter sent!
      description: The billing letter was sent to added emails.
  invoice:
    description-days: >-
      You have 10 days from the contract signing date to submit invoices,
      otherwise your company will be blocked for new projects.
    description-invoices-files: |-
      Agilize your analysis!
      Preferably for .pdf files.
      Maximum size of 10MB per file (JPEG, JPG, PNG and PDF).
result:
  tag:
    guarantor:
      pending: Need a guarantor
      approved: Approved guarantor
      partially_approved: Partial
      total_approved: Approved
billing_letter:
  download-title: Download the billing letter
defaultDenialReason: Validates if the file is correct
