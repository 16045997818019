/* eslint-disable */

export const AllTypesProps: Record<string,any> = {
	Order: "enum" as const,
	Upload: `scalar.Upload` as const,
	Query:{
		address_combos:{

		},
		address_cities:{

		},
		address_from_cep:{

		},
		address_installation:{

		},
		customer_form:{

		},
		documents:{

		},
		financing:{

		},
		financings:{
			input:"FinancingsInput"
		},
		financing_type:{

		},
		installation:{

		},
		mirrorStatus:{

		},
		legacyFinancing:{

		},
		partners:{
			input:"CoreGetPartnersInput"
		},
		project:{

		},
		project_proposal_changes:{

		},
		project_is_combo:{

		},
		registration:{

		},
		getOpportunities:{
			params:"GetOpportunitiesInput"
		},
		simulation_project_shopping_cart_id_get:{

		}
	},
	PersonTypes: "enum" as const,
	DocumentTypes: "enum" as const,
	LegalRepresentantTypes: "enum" as const,
	CustomerStatus: "enum" as const,
	RegistrationV2PersonType: "enum" as const,
	CustomerRegistrationAddress:{

	},
	CustomerRegistrationContact:{

	},
	CustomerRegistrationDocument:{

	},
	CustomerRegistrationCustomer:{
		contact:"CustomerRegistrationContact",
		documents:"CustomerRegistrationDocument",
		address:"CustomerRegistrationAddress"
	},
	CustomerRegistrationGuarantor:{
		contact:"CustomerRegistrationContact",
		documents:"CustomerRegistrationDocument",
		address:"CustomerRegistrationAddress"
	},
	CustomerRegistrationElectricityBill:{

	},
	CustomerRegistrationProject:{
		address:"CustomerRegistrationAddress",
		documents:"CustomerRegistrationDocument",
		electricity_bill:"CustomerRegistrationElectricityBill"
	},
	CustomerRegistrationCompany:{
		contact:"CustomerRegistrationContact",
		documents:"CustomerRegistrationDocument",
		majority_partner:"CustomerRegistrationCustomer",
		legal_representatives:"CustomerRegistrationLegalRepresentative"
	},
	CustomerRegistrationLegalRepresentative:{
		contact:"CustomerRegistrationContact",
		documents:"CustomerRegistrationDocument",
		address:"CustomerRegistrationAddress"
	},
	CustomerRegistrationInput:{
		person_type:"RegistrationV2PersonType",
		customer:"CustomerRegistrationCustomer",
		guarantor:"CustomerRegistrationGuarantor",
		project:"CustomerRegistrationProject",
		company:"CustomerRegistrationCompany"
	},
	Person:{

	},
	PersonInput:{

	},
	RegistrationContactChangeInput:{
		customer:"PersonInput",
		guarantor:"PersonInput",
		majority_partner:"PersonInput",
		legal_representative_1:"PersonInput",
		legal_representative_2:"PersonInput"
	},
	Mutation:{
		registration_contact_change:{
			input:"RegistrationContactChangeInput"
		},
		customer_document_upload:{
			documents:"Upload"
		},
		customer_document_form_analysis_cancel:{

		},
		customer_registration:{
			params:"CustomerRegistrationInput"
		},
		validateHardwareForm:{
			hardwareFormInput:"HardwareFormInput"
		},
		hardware_form_showcase:{
			hardwareFormShowcaseInput:"HardwareFormShowcaseInput"
		},
		hardware_form_draft:{
			hardwareFormDraftInput:"HardwareDraft"
		},
		installation_mirror_upload:{
			mirror:"Upload"
		},
		project_update_shopping_cart:{

		},
		project_proposal_update:{
			payload:"ProjectProposalUpdatePayloadInput"
		},
		registration:{
			params:"RegistrationUpdateInput"
		},
		processEnergyAccountAnalysis:{
			params:"AnalyzeEnergyBillInput"
		}
	},
	PersonType: "enum" as const,
	FinancingStatus: "enum" as const,
	SectionStatus: "enum" as const,
	FinancingSteps: "enum" as const,
	FinancingsInput:{
		steps:"FinancingSteps",
		status:"FinancingStatus"
	},
	FileStatusType: "enum" as const,
	DocumentStatusType: "enum" as const,
	TypeSection: "enum" as const,
	MirrorStatuses: "enum" as const,
	HardwareFormInverter:{

	},
	HardwareFormModule:{

	},
	HardwareFormComboEquipment:{

	},
	HardwareFormBatteries:{

	},
	HardwareFormComboMetadata:{
		inverters:"HardwareFormComboEquipment",
		modules:"HardwareFormComboEquipment"
	},
	HardwareFormInput:{
		inverters:"HardwareFormInverter",
		modules:"HardwareFormModule",
		batteries:"HardwareFormBatteries",
		combo_metadata:"HardwareFormComboMetadata"
	},
	HardwareFormShowcaseInput:{
		inverters:"HardwareFormInverter",
		modules:"HardwareFormModule"
	},
	HardwareDraftEquipment:{

	},
	HardwareDraft:{
		inverters:"HardwareDraftEquipment",
		modules:"HardwareDraftEquipment"
	},
	CoreGetPartnersInput:{

	},
	SystemTypes: "enum" as const,
	ProjectProposalUpdatePayloadInput:{

	},
	RegistrationPersonType: "enum" as const,
	RegistrationDocumentTypes: "enum" as const,
	RegistrationRepresentantTypes: "enum" as const,
	RegistrationDocumentModel: "enum" as const,
	RegistrationUpdateInput:{

	},
	AnalyzeEnergyBillInput:{

	},
	GetOpportunitiesInput:{

	}
}

export const ReturnTypes: Record<string,any> = {
	Upload: `scalar.Upload` as const,
	Combos:{
		data:"ComboData"
	},
	Cities:{
		data:"CityStateData"
	},
	AddressInstallation:{
		data:"AddressInstallationData"
	},
	ComboData:{
		nationality:"CityStateData",
		gender:"CityStateData",
		occupation:"CityStateData",
		state:"CityStateData"
	},
	CepAddress:{
		neighborhood:"String",
		street:"String",
		state:"CityStateData",
		city:"CityStateData"
	},
	AddressInstallationData:{
		postal_code:"String",
		street:"String",
		street_number:"Int",
		additional_info:"String",
		neighborhood:"String",
		city:"String",
		state:"String",
		rural_area:"Boolean"
	},
	CityStateData:{
		name:"String",
		id:"String"
	},
	Query:{
		address_combos:"Combos",
		address_cities:"Cities",
		address_from_cep:"CepAddress",
		address_installation:"AddressInstallation",
		customer_form:"CustomerFormResponse",
		documents:"DocumentResponse",
		financing:"Financing",
		financings:"FinancingsList",
		financing_type:"FinancingType",
		installation:"Installation",
		mirrorStatus:"MirrorStatus",
		legacyFinancing:"LegacyFinancing",
		partners:"CorePartner",
		project:"Project",
		project_proposal_changes:"ProjectProposal",
		project_is_combo:"IsComboResponse",
		project_system_types:"SystemTypes",
		registration:"RegistrationDetailsResponse",
		getOpportunities:"GetOpportunitiesResponse",
		simulation_project_shopping_cart_id_get:"String",
		me:"User"
	},
	RegistrationContactChangeResponse:{
		message:"String",
		status:"Int"
	},
	CustomerFormResponse:{
		project_id:"String",
		is_combo:"Boolean",
		person_type:"PersonTypes",
		customer:"CustomerData",
		guarantor:"CustomerData",
		project:"CustomerProject",
		remaining_changes_after_confirmation:"Int",
		company:"CompanyData",
		errors:"CustomerErrors",
		status:"CustomerStatus"
	},
	CustomerErrors:{
		field:"String",
		title:"String",
		description:"String"
	},
	CustomerContact:{
		email:"String",
		phone:"String"
	},
	CustomerDocument:{
		type:"DocumentTypes",
		status:"String",
		filename:"String",
		url_download:"String",
		url_preview:"String",
		mime_type:"String",
		id:"String"
	},
	CustomerAddress:{
		zipcode:"String",
		street:"String",
		street_number:"String",
		complement:"String",
		neighborhood:"String",
		city_id:"Int",
		state_id:"Int",
		city:"CustomerIdName",
		state:"CustomerIdName",
		is_rural:"Boolean"
	},
	CustomerData:{
		cpf:"String",
		full_name:"String",
		birthdate:"String",
		contact:"CustomerContact",
		documents:"CustomerDocument",
		document_type:"String",
		comment:"String",
		address:"CustomerAddress"
	},
	CustomerEletricityBill:{
		is_under_customer_name:"Boolean",
		holder_cpf:"String"
	},
	CustomerProject:{
		address:"CustomerAddress",
		documents:"CustomerDocument",
		document_type:"String",
		comment:"String",
		electricity_bill:"CustomerEletricityBill",
		residencial_is_the_same_of_plant:"Boolean"
	},
	CompanyMajorityPartner:{
		cpf:"String",
		full_name:"String",
		birthdate:"String",
		contact:"CustomerContact",
		documents:"CustomerDocument",
		document_type:"String",
		comment:"String",
		address:"CustomerAddress"
	},
	CompanyLegalRepresentant:{
		kind:"LegalRepresentantTypes",
		cpf:"String",
		full_name:"String",
		birthdate:"String",
		contact:"CustomerContact",
		documents:"CustomerDocument",
		document_type:"String",
		comment:"String",
		address:"CustomerAddress"
	},
	CompanyData:{
		company_name:"String",
		cnpj:"String",
		contact:"CustomerContact",
		documents:"CustomerDocument",
		document_type:"String",
		comment:"String",
		majority_partner:"CompanyMajorityPartner",
		majority_partner_is_legal_representative:"Boolean",
		is_only_one_legal_representative:"Boolean",
		legal_representatives:"CompanyLegalRepresentant"
	},
	CustomerIdName:{
		id:"Int",
		name:"String"
	},
	CustomerDocumentUpload:{
		id:"String",
		url:"String",
		url_inline:"String",
		filename:"String",
		mimetype:"String",
		size:"Int",
		docstorage_id:"String",
		created_at:"String"
	},
	CustomerRegistrationError:{
		field:"String",
		message:"String",
		type:"String",
		title:"String",
		description:"String"
	},
	CustomerRegistrationV2Response:{
		errors:"CustomerRegistrationError"
	},
	CustomerDocumentFormAnalysis:{
		id:"String",
		status:"String",
		project_id:"String",
		installation_document_matches_bill:"Boolean",
		installation_address_matches_bill:"Boolean",
		identity_matches_document:"Boolean",
		reason:"String",
		comments:"String",
		documents:"CustomerDocumentUpload"
	},
	Mutation:{
		registration_contact_change:"RegistrationContactChangeResponse",
		customer_document_upload:"CustomerDocumentUpload",
		customer_document_form_analysis_cancel:"CustomerDocumentFormAnalysis",
		customer_registration:"CustomerRegistrationV2Response",
		validateHardwareForm:"HardwareFormValidation",
		hardware_form_showcase:"HardwareFormShowcaseResponse",
		hardware_form_draft:"String",
		installation_mirror_upload:"MirrorUploadResponse",
		project_update_shopping_cart:"ID",
		project_proposal_update:"Boolean",
		registration:"Boolean",
		processEnergyAccountAnalysis:"EnergyBillAnalysisDecision"
	},
	DocumentResponse:{
		data:"DocumentData"
	},
	DocumentData:{
		project:"Documents",
		customer:"Documents",
		guarantor:"DocumentFile",
		company:"DocumentFile"
	},
	Documents:{
		id:"String",
		docstorage_id:"String",
		kind:"String",
		validated_at:"String",
		validation_mode:"String",
		deleted_at:"String",
		submitted_at:"String",
		canceled_at:"String",
		status:"String",
		reason:"DocumentReason",
		file:"DocumentFile"
	},
	DocumentFile:{
		public_id:"String",
		filename:"String",
		mimetype:"String",
		size:"Int",
		url:"String",
		url_inline:"String"
	},
	DocumentReason:{
		title:"String",
		description:"String"
	},
	FormalizationStatus:{
		contract:"SectionStatus",
		hardware:"SectionStatus",
		registration:"SectionStatus",
		documentation:"SectionStatus",
		receipt_model:"SectionStatus"
	},
	SectionStatuses:{
		concluded:"SectionStatus",
		simulation:"SectionStatus",
		installation:"SectionStatus",
		formalization:"FormalizationStatus"
	},
	Payment:{
		id:"Int",
		financing_id:"Float",
		value:"Float",
		total_value:"Float",
		payment_percentage:"Float",
		created_at:"String"
	},
	Financing:{
		id:"Int",
		project_id:"String",
		financed_value:"Float",
		customer_name:"String",
		document:"String",
		expires_at:"String",
		created_at:"String",
		formalized_at:"String",
		installed_at:"String",
		status:"FinancingStatus",
		step:"String",
		is_combo:"Boolean",
		payments:"Payment",
		flow_provider:"String",
		person_type:"PersonType",
		all_payments_sent:"Boolean",
		section_statuses:"FinancingSectionStatusV2",
		updated_at:"String",
		is_expired:"Boolean",
		system_power:"Float",
		new_journey:"Boolean",
		legacy_id:"Int",
		reason:"String",
		openable_steps:"FormalizationStatuses",
		is_aproved:"Boolean",
		is_final:"Boolean"
	},
	FormalizationStatuses:{
		registration:"Boolean",
		documentation:"Boolean",
		hardware:"Boolean",
		contract:"Boolean",
		receiptModel:"Boolean"
	},
	FinancingListTotalByStep:{
		simulation:"Int",
		formalization:"Int",
		installation:"Int",
		concluded:"Int",
		expired:"Int",
		proposal:"Int"
	},
	FinancingListTotalByStatus:{
		expired:"Int",
		reproved:"Int",
		approved:"Int",
		under_analysis:"Int",
		canceled:"Int",
		to_review:"Int",
		in_progress:"Int",
		waiting_customer:"Int"
	},
	FinancingProposal:{
		customer_data:"String",
		equipment:"String",
		biometry_and_contract:"String",
		payment_model:"String"
	},
	FinancingFormalization:{
		contract:"String",
		hardware:"String",
		registration:"String",
		documentation:"String",
		receipt_model:"String"
	},
	FinancingSectionStatusV2:{
		concluded:"String",
		simulation:"String",
		installation:"String",
		proposal:"FinancingProposal",
		formalization:"FinancingFormalization"
	},
	FinancingsList:{
		data:"Financing",
		total:"Int",
		total_by_step:"FinancingListTotalByStep",
		total_by_status:"FinancingListTotalByStatus"
	},
	FinancingType:{
		max_financing_value:"Float",
		min_financing_value:"Float",
		max_financing_installments:"Int",
		grace_period_financing:"GracePeriod",
		max_bnpl_value:"Float",
		max_bnpl_installments:"Int",
		grace_period_bnpl:"GracePeriod"
	},
	GracePeriod:{
		label:"String",
		value:"Int"
	},
	Status:{
		description:"String",
		id:"Int",
		slug:"DocumentStatusType"
	},
	Document:{
		filename:"String",
		filetype:"String",
		id:"String",
		status:"Status",
		type:"String",
		url:"String"
	},
	Section:{
		documents:"Document",
		id:"String",
		status:"Status",
		type:"TypeSection"
	},
	InstallationStatus:{
		description:"String",
		id:"Int",
		slug:"FileStatusType"
	},
	Installation:{
		comment:"String",
		concluded_at:"String",
		grade:"Int",
		has_ampera:"Boolean",
		has_ampera_activated:"Boolean",
		has_minimal_projects:"Boolean",
		id:"String",
		new_journey:"Boolean",
		plant_id:"String",
		plant_url:"String",
		project_id:"String",
		project_name:"String",
		sections:"Section",
		status:"InstallationStatus"
	},
	ValidateHardwareFormPricePerWatt:{
		is_valid:"Boolean",
		is_under_expected:"Boolean"
	},
	ValidateHardwareFormModules:{
		price_per_watt:"ValidateHardwareFormPricePerWatt"
	},
	ValidateHardwareFormOverload:{
		is_valid:"Boolean",
		is_under_expected:"Boolean"
	},
	ValidateHardwareFormInverters:{
		overload:"ValidateHardwareFormOverload"
	},
	ValidateHardwareFormHardware:{
		modules:"ValidateHardwareFormModules",
		inverters:"ValidateHardwareFormInverters"
	},
	HardwareFormValidation:{
		is_valid:"Boolean",
		hardware:"ValidateHardwareFormHardware"
	},
	HardwareFormShowcaseResponse:{
		overload_is_valid:"Boolean",
		price_per_watt_is_valid:"Boolean",
		percentage_of_service_with_combo_is_valid:"Boolean"
	},
	MirrorStatus:{
		status:"MirrorStatuses"
	},
	MirrorUploadResponse:{
		id:"String",
		url:"String"
	},
	LegacyFinancing:{
		projectId:"String",
		financingId:"Int",
		formalizedAt:"String",
		stage:"String",
		status:"String"
	},
	CorePartner:{
		id:"Int",
		cnpj:"String",
		responsible:"CoreParterResponsible",
		company_name:"String",
		fantasy_name:"String",
		status:"CoreParterStatus"
	},
	CoreParterResponsible:{
		id:"Int",
		full_name:"String"
	},
	CoreParterStatus:{
		active:"Boolean",
		reason:"String",
		date:"String"
	},
	ProjectProposal:{
		value_changed:"Boolean"
	},
	AppliedModifierOption:{
		type:"String",
		value:"String"
	},
	AppliedModifier:{
		name:"String",
		type:"String",
		options:"AppliedModifierOption"
	},
	AddonProduct:{
		id:"ID",
		product_name:"String",
		product_external_id:"String",
		simulation_id:"String",
		type:"String",
		product_slug:"String",
		applied:"Boolean",
		partner_commission:"Float",
		installment_price:"Float",
		total_price:"Float",
		monthly_interest_rate:"Float",
		engine_simulation_id:"String",
		previous_engine_simulation_id:"String",
		cet:"Float",
		applied_modifiers:"AppliedModifier",
		addon_type:"String"
	},
	SelectedInstallments:{
		cet:"String",
		iof:"Float",
		ipca:"String",
		addons:"AddonProduct",
		aggregated_addons:"AddonProduct",
		user_id:"Int",
		document:"String",
		is_combo:"Boolean",
		commission:"Float",
		partner_id:"Int",
		aliquot_iof:"Float",
		new_journey:"Boolean",
		person_type:"String",
		down_payment:"Float",
		grace_period:"Int",
		installments:"Int",
		project_name:"String",
		system_power:"Float",
		interest_rate:"Float",
		cet_percentage:"Float",
		commission_raw:"Float",
		financing_value:"Float",
		taxa_de_cadastro:"Float",
		installment_value:"Float",
		is_from_suggested:"Boolean",
		registration_fee_gross:"Float",
		registration_fee_raw_dry:"Float",
		registration_fee_raw_final:"Float"
	},
	RiskInfo:{
		title:"String",
		description:"String",
		todo:"String",
		reason:"String",
		decision:"Int"
	},
	BNPL:{
		decision_pre_analysis:"Int",
		min_entry_value:"Float",
		max_installments:"Int",
		pre_approved_value:"Float",
		status:"String"
	},
	Guarantor:{
		id:"String",
		document:"String",
		birth_date:"String",
		border_line:"Boolean",
		created_at:"String",
		customer_second_financing_policy:"Boolean",
		decision_pre_analysis:"Int",
		max_approved_project_value:"Float",
		max_financing_value:"Float",
		max_grace_period:"Int",
		max_installments:"Int",
		min_approved_project_value:"Float",
		min_installment_value:"Float",
		pre_approved_value:"Float",
		project_name:"String",
		project_state:"String",
		risk_class:"String",
		status:"String"
	},
	Project:{
		id:"ID",
		user_id:"Int",
		partner_id:"Int",
		person_type:"String",
		document:"String",
		created_at:"String",
		representative_document:"String",
		project_value:"Float",
		system_power:"Float",
		grace_period:"Int",
		down_payment:"Float",
		status:"String",
		project_status:"String",
		max_installments:"Int",
		max_grace_period:"Int",
		project_name:"String",
		project_state:"String",
		partner_profile:"String",
		pre_approved_value:"Float",
		products_applieds:"String",
		installments:"String",
		risk_info:"RiskInfo",
		state_registration:"String",
		complete:"Boolean",
		selected_installments:"SelectedInstallments",
		guarantor:"Guarantor",
		birth_date:"String",
		decision_pre_analysis:"Int",
		score_sol_facil:"Int",
		simulation_id:"String",
		addons:"AddonProduct",
		max_approved_project_value:"Float",
		min_approved_project_value:"Float",
		max_financing_value:"Float",
		border_line:"Boolean",
		is_formalized:"Boolean",
		new_journey:"Boolean",
		formalized_at:"String",
		shopping_cart_id:"String",
		bnpl:"BNPL",
		bnpl_simulation_id:"String",
		bnpl_status:"String",
		is_onflex_partner:"Boolean",
		can_select_ampera:"Boolean",
		has_energy_bill_analysis:"Boolean",
		insurance_commission:"Float",
		gained_points:"Int",
		original_installment_value:"Float",
		is_contract_signed:"Boolean",
		commission:"Float",
		risk_class:"String",
		selected_installment:"Float",
		credit_analysis_status:"String"
	},
	ProjectProposalUpdateResponse:{
		engine_simulation_id:"String",
		simulation_id:"String",
		project_id:"String",
		is_combo:"Boolean",
		is_ampera:"Boolean",
		commission:"Int",
		applied_addons:"String"
	},
	IsComboResponse:{
		data:"Boolean"
	},
	RegistrationContact:{
		email:"String",
		phone:"String"
	},
	RegistrationAddressCity:{
		id:"Int",
		name:"String"
	},
	RegistrationAddressState:{
		id:"Int",
		name:"String"
	},
	RegistrationElectricityBill:{
		holder_cpf:"String",
		is_under_customer_name:"Boolean"
	},
	RegistrationDocument:{
		type:"RegistrationDocumentTypes",
		status:"String",
		filename:"String",
		url_download:"String",
		url_preview:"String",
		mime_type:"String",
		docstorage_id:"String",
		id:"String"
	},
	RegistrationAddress:{
		zipcode:"String",
		street:"String",
		street_number:"String",
		complement:"String",
		neighborhood:"String",
		city_id:"Int",
		state_id:"Int",
		city:"RegistrationAddressCity",
		state:"RegistrationAddressState",
		is_rural:"Boolean"
	},
	RegistrationProject:{
		address:"RegistrationAddress",
		documents:"RegistrationDocument",
		electricity_bill:"RegistrationElectricityBill",
		residencial_is_the_same_of_plant:"Boolean",
		comment:"String"
	},
	RegistrationCustomer:{
		cpf:"String",
		full_name:"String",
		birthdate:"String",
		contact:"RegistrationContact",
		documents:"RegistrationDocument",
		document_type:"RegistrationDocumentModel",
		address:"RegistrationAddress"
	},
	RegistrationCompany:{
		company_name:"String",
		cnpj:"String",
		contact:"RegistrationContact",
		documents:"RegistrationDocument",
		majority_partner:"RegistrationCompanyMajorityPartner",
		legal_representatives:"RegistrationLegalRepresentative",
		majority_partner_is_legal_representative:"Boolean",
		is_only_one_legal_representative:"Boolean"
	},
	RegistrationLegalRepresentative:{
		cpf:"String",
		full_name:"String",
		birthdate:"String",
		contact:"RegistrationContact",
		documents:"RegistrationDocument",
		address:"RegistrationAddress",
		kind:"RegistrationRepresentantTypes",
		document_type:"RegistrationDocumentModel"
	},
	RegistrationCompanyMajorityPartner:{
		cpf:"String",
		is_legal_representative:"Boolean",
		full_name:"String",
		birthdate:"String",
		contact:"RegistrationContact",
		documents:"RegistrationDocument",
		address:"RegistrationAddress",
		document_type:"RegistrationDocumentModel"
	},
	RegistrationGuarantor:{
		cpf:"String",
		full_name:"String",
		birthdate:"String",
		contact:"RegistrationContact",
		documents:"RegistrationDocument",
		address:"RegistrationAddress",
		document_type:"RegistrationDocumentModel"
	},
	RegistrationError:{
		title:"String",
		description:"String",
		field:"String",
		type:"String"
	},
	RegistrationDetailsResponse:{
		project_id:"String",
		status:"String",
		is_combo:"Boolean",
		remaining_changes_after_confirmation:"Int",
		person_type:"RegistrationPersonType",
		customer:"RegistrationCustomer",
		company:"RegistrationCompany",
		guarantor:"RegistrationGuarantor",
		project:"RegistrationProject",
		errors:"RegistrationError"
	},
	EnergyBillAnalysisDecision:{
		decision:"String",
		details:"String"
	},
	GetOpportunitiesResponse:{
		addons_opportunities:"AddonsOpportunities",
		financing_gained_points:"Int"
	},
	AddonsOpportunities:{
		addon_type:"String",
		gained_points:"Int",
		product_slug:"String"
	},
	LevelBlackStatus:{
		kwGoalMonth:"Int",
		kwReachedMonth:"Int"
	},
	Contact:{
		landline:"String",
		mobile:"String",
		email:"String"
	},
	Address:{
		street:"String",
		number:"String",
		complement:"String",
		reference:"String",
		district:"String",
		zipcode:"String",
		city:"String",
		state:"String"
	},
	Responsible:{
		id:"Int",
		username:"String",
		profile:"String",
		fullname:"String",
		document:"String",
		birthdate:"String",
		active:"Boolean",
		created_at:"String",
		contact:"Contact",
		partner:"Partner"
	},
	Permission:{
		store:"Boolean",
		financing:"Boolean"
	},
	Score:{
		current:"Int",
		goal:"Int"
	},
	Downgrade:{
		has_risk:"Boolean"
	},
	Sfplus:{
		level:"Int",
		score:"Score",
		black_status:"LevelBlackStatus",
		downgrade:"Downgrade",
		is_level_black:"Boolean",
		next_transition_date:"String"
	},
	Partner:{
		id:"Int",
		corporate_name:"String",
		operation_term_accepted:"Boolean",
		trade_name:"String",
		document:"String",
		category:"String",
		state_registration:"String",
		active:"Boolean",
		created_at:"String",
		address:"Address",
		responsible:"Responsible",
		permission:"Permission",
		users:"User",
		sfplus:"Sfplus"
	},
	Post:{
		id:"Int",
		title:"String",
		body:"String",
		userId:"String",
		comments:"Comment"
	},
	Comment:{
		id:"Int",
		name:"String",
		email:"String",
		body:"String"
	},
	User:{
		id:"Int",
		username:"String",
		profile:"String",
		fullname:"String",
		document:"String",
		birthdate:"String",
		active:"Boolean",
		created_at:"String",
		contact:"Contact",
		partner:"Partner",
		posts:"Post"
	}
}

export const Ops = {
query: "Query" as const,
	mutation: "Mutation" as const
}