import { useQuery } from '@tanstack/vue-query'
import queryClient, { queryKeys } from '~/queryClient'
import financialBff from '~/services-v2/financial-bff/financial-bff'

export default function useFinancing(id: Ref<string>) {
  const REFETCH_INTERVAL = 15_000 // 15 seconds

  const queryKey = queryKeys.FINANCING.DETAILS(id.value)

  const refetchInterval = ref(REFETCH_INTERVAL)

  function resetRefetchInterval() {
    refetchInterval.value = 0
    nextTick(() => {
      refetchInterval.value = REFETCH_INTERVAL
    })
  }

  const queryFn = async () => {
    const res = await financialBff('query')({
      financing: [{ id: id.value }, {
        id: true,
        legacy_id: true,
        project_id: true,
        customer_name: true,
        status: true,
        person_type: true,
        step: true,
        financed_value: true,
        flow_provider: true,
        formalized_at: true,
        created_at: true,
        updated_at: true,
        expires_at: true,
        installed_at: true,
        is_aproved: true,
        is_combo: true,
        is_expired: true,
        is_final: true,
        new_journey: true,
        reason: true,
        system_power: true,
        document: true,
        all_payments_sent: true,
        payments: {
          id: true,
          value: true,
          financing_id: true,
          total_value: true,
          payment_percentage: true,
          created_at: true,
        },
        section_statuses: {
          concluded: true,
          simulation: true,
          formalization: {
            contract: true,
            documentation: true,
            hardware: true,
            receipt_model: true,
            registration: true,
          },
          proposal: {
            biometry_and_contract: true,
            customer_data: true,
            equipment: true,
            payment_model: true,
          },
        },
        openable_steps: {
          registration: true,
          documentation: true,
          hardware: true,
          contract: true,
          receiptModel: true,
        },
      }],
    })

    const oldData = queryClient.getQueryData<typeof res.financing>(queryKey)

    if (oldData && res.financing) {
      const oldStatus = oldData.section_statuses
      const newStatus = res.financing.section_statuses
      if (oldStatus.proposal?.customer_data !== newStatus.proposal?.customer_data) {
        await queryClient.refetchQueries({ queryKey: queryKeys.REGISTRATION.DATA(id.value) })
      }
    }

    resetRefetchInterval()

    return res.financing!
  }

  return useQuery({
    queryFn,
    queryKey,
    refetchInterval: refetchInterval.value,
    enabled: refetchInterval.value > 0,
  })
}

export type UseFinancingData = ReturnType<typeof useFinancing>['data']['value']
export type UseFinancingError = ReturnType<typeof useFinancing>['error']['value']
