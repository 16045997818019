import RestCompanyRegister from './RestCompanyRegister'
import RestCore from './RestCore'
import RestCustomerRegister from './RestCustomerRegister'
import RestFinancingFlow from './RestFinancingFlow'
import RestLdk from './RestLdk'
import RestMixpanel from './RestMixpanel'
import RestPaymentBFF from './RestPaymentBFF'
import RestSolfacilPlus from './RestSolfacilPlus'

const rest = {
  core: RestCore,
  ldk: RestLdk,
  customerRegister: RestCustomerRegister,
  companyRegister: RestCompanyRegister,
  solfacilPlus: RestSolfacilPlus,
  mixpanel: RestMixpanel,
  paymentBFF: RestPaymentBFF,
  financingFlow: RestFinancingFlow,
}

export type RestConfig = keyof typeof rest
export default rest
