import type { PartialDeep } from 'type-fest'
import type { FormContext } from 'vee-validate'
import type { UseRegistrationData } from '~/store-v2/registration/useRegistration'
import * as z from 'zod'
import { onlyNumbers } from '~/utils/string'

export function getSchema(t: any) {
  return z.object({
    zipcode: z.string({ message: t('form.required') }).transform(onlyNumbers).refine(v => v.length === 8, t('form.invalid_cep')),
    street: z.string({ message: t('form.required') }).min(1, { message: t('form.required') }),
    street_number: z.string({ message: t('form.required') }).min(1, { message: t('form.required') }),
    complement: z.string().max(100, t('form.max', { max: 100 })).optional(),
    neighborhood: z.string({ message: t('form.required') }).min(1, { message: t('form.required') }),
    state: z.string({ message: t('form.required') }).min(1, t('form.required')),
    city: z.string({ message: t('form.required') }).min(1, t('form.required')),
    state_id: z.number({ message: t('form.required') }).min(1, t('form.required')),
    city_id: z.number({ message: t('form.required') }).min(1, t('form.required')),
  })
}

export type FormSchema = z.infer<ReturnType<typeof getSchema>>
export type Form = FormContext<PartialDeep<FormSchema>>
export type Field = keyof(FormSchema)

export function getInitialValues(): Partial<FormSchema> {
  return {
    zipcode: '',
    street: '',
    street_number: '',
    complement: '',
    neighborhood: '',
    state: '',
    city: '',
    state_id: 0,
    city_id: 0,
  }
}

type AnalysisError = NonNullable<UseRegistrationData>['errors'][number]

export function extractErrorsByField(errors: AnalysisError[] | undefined, kind: string): Record<Field, AnalysisError> {
  const mappings: Record<string, Field> = {
    'address.zipcode': 'zipcode',
    'address.street': 'street',
    'address.street_number': 'street_number',
    'address.complement': 'complement',
    'address.neighborhood': 'neighborhood',
    'address.state': 'state',
    'address.city': 'city',
    'address.state_id': 'state_id',
    'address.city_id': 'city_id',
  } as const

  const mappingsWithKind = Object.entries(mappings).reduce((acc, [key, value]) => {
    acc[key] = value
    acc[`${kind}.${key}`] = value
    return acc
  }, {} as Record<string, Field>)

  return (errors || [])
    .filter(error => !!error)
    .reduce((acc, error) => {
      const field = mappingsWithKind[error.field]
      if (field) {
        acc[field] = error
      }
      return acc
    }, {} as Record<Field, AnalysisError>)
}
