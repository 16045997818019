<script setup lang="ts">
import type { Field, Form, FormSchema } from './ElectricityBill.schema'
import type { ModelTypes } from '~/services-v2/financial-bff/__generated__/zeus'
import { SolInputText, SolLink } from '@solfacil/girassol'
import { isEqual } from 'lodash'
import IconPlus from '~icons/material-symbols/add'
import FileStorageInput from '~/components/input/FileStorageInput.vue'
import { RegistrationDocumentTypes } from '~/services-v2/financial-bff/__generated__/zeus'
import useRegistration from '~/store-v2/registration/useRegistration'
import { convertToCPF } from '~/utils/string'
import WhatIsBondModal from '../WhatIsBondModal.vue'
import { extractErrorsByField } from './ElectricityBill.schema'

// Props
const props = defineProps<{
  form: Form
  disabled?: boolean
}>()

// initial base hooks
const { t } = useI18n()
const route = useRoute()

const projectId = computed(() => route.params.id as string)

const {
  data: registration,
} = useRegistration(projectId)

// fields
const [isUnderCustomerNameModel, isUnderCustomerNameProps] = props.form.defineField('is_under_customer_name')
const [holderCpfModel, holderCpfProps] = props.form.defineField('holder_cpf')
const [electricityBillModel, electricityBillProps] = props.form.defineField('electricity_bill_document')
const [holderDocumentModel, holderDocumentProps] = props.form.defineField('employment_proof_document')
const [additionalDocumentModel, additionalDocumentProps] = props.form.defineField('additional_document')
const [commentModel, commentProps] = props.form.defineField('comment')

// initial variables and computed
const modalWhatIsBondOpen = ref(false)
const showAdditionalComments = ref(false)
const ACCEPTED_FILE_TYPES = ['image/jpeg', 'image/jpg', 'image/png', 'image/webp', 'application/pdf']

const currentDocuments = reactive({
  electricity_bill_document: null as ModelTypes['RegistrationDocument'] | null,
  employment_proof_document: null as ModelTypes['RegistrationDocument'] | null,
  additional_document: null as ModelTypes['RegistrationDocument'] | null,
})

const isStatusToReview = computed(() => registration.value?.status === 'to_review')
const errors = computed(() => extractErrorsByField(registration.value?.errors))
const firstError = computed(() => Object.values(errors.value)[0])

const isFieldsDisabled = computed(() => props.disabled || false)

const radiosIsUnderCustomerName = [
  {
    name: 'isUnderCustomerNameModel',
    value: true,
    label: `${t('formAddress.yesCountryside')}`,
  },
  {
    name: 'isUnderCustomerNameModel',
    value: false,
    label: `${t('formAddress.noCountryside')}`,
  },
]

function handleOpenModalWhatIsBond() {
  modalWhatIsBondOpen.value = true
}

function handleShowAdditionalComments() {
  showAdditionalComments.value = !showAdditionalComments.value
}

watch(registration, (newValue, oldValue) => {
  if (isEqual(newValue, oldValue)) {
    return
  }

  if (newValue) {
    const data = newValue.project

    const electricityBillDoc = data?.documents?.find(doc => doc.type === RegistrationDocumentTypes.electricity_bill)
    const employmentProofDocument = data?.documents?.find(doc => doc.type === RegistrationDocumentTypes.employment_proof)
    const additionalDocument = data?.documents?.find(doc => doc.type === RegistrationDocumentTypes.additional)

    if (electricityBillDoc) {
      currentDocuments.electricity_bill_document = electricityBillDoc
      props.form.setFieldValue('electricity_bill_document', electricityBillDoc.docstorage_id)
    }

    if (employmentProofDocument) {
      currentDocuments.employment_proof_document = employmentProofDocument
      props.form.setFieldValue('employment_proof_document', employmentProofDocument.docstorage_id)
    }

    if (additionalDocument) {
      currentDocuments.additional_document = additionalDocument
      props.form.setFieldValue('additional_document', additionalDocument.docstorage_id)
    }

    props.form.setValues({
      is_under_customer_name: data?.electricity_bill?.is_under_customer_name ?? true,
      holder_cpf: convertToCPF(data?.electricity_bill?.holder_cpf) ?? undefined,
      comment: data?.comment ?? undefined,
    } as FormSchema, false)

    if (data?.comment) {
      showAdditionalComments.value = true
    }

    const errs = extractErrorsByField(newValue.errors)

    setTimeout(() => { // to avoid concorrency with the form values
      Object.entries(errs).forEach(([key, value]) => {
        props.form.setFieldError(key as Field, value.title)
      })
    }, 500)
  }
}, { immediate: true })

function disableField(field: Field) {
  return currentDocuments[field] && currentDocuments[field]?.status !== 'pending'
}
</script>

<template>
  <div v-if="registration">
    <!-- alert -->
    <SolAlert
      v-if="firstError && isStatusToReview"
      id="feedback"
      class="mb-4"
      :feedback="firstError.type ?? 'warning'"
      :title="firstError.title"
    >
      {{ firstError.description }}
    </SolAlert>
    <!--  -->

    <div class="flex flex-col items-start gap-6">
      <div class="flex flex-col items-start gap-4 w-full">
        <div class="flex flex-col items-start gap-2 w-full">
          <div class="flex flex-col items-start gap-4">
            <span class="font-highlight text-neutral-low-dark text-[16px] md:system:text-[20px]">
              {{ t('formalization.electricity_bill.title') }}
            </span>

            <div class="flex flex-col item-start gap-1">
              <SolRadioGroup
                id="is_under_customer_name"
                v-model="isUnderCustomerNameModel"
                :title="t('formalization.electricity_bill.form.electricity_bill_customer_name')"
                name="is_under_customer_name"
                direction="row"
                v-bind="isUnderCustomerNameProps"
                :disabled="isFieldsDisabled"
                :radios="radiosIsUnderCustomerName"
                :error="props.form.errors.value.is_under_customer_name"
              />
              <div class="arrow-text">
                <span class="fonts-body-small-regular text-brand-primary-light">
                  {{ t('formalization.electricity_bill.info_bill') }}
                </span>
              </div>
              <div class="arrow-text">
                <span class="fonts-body-small-regular text-brand-primary-light">
                  {{ t('formalization.electricity_bill.info_files') }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="flex items-start gap-6 w-full">
          <FileStorageInput
            id="electricity_bill"
            v-model="electricityBillModel"
            :project-id="registration?.project_id"
            name="electricity_bill"
            :accept="ACCEPTED_FILE_TYPES.join(',')"
            :disabled="isFieldsDisabled || disableField('electricity_bill_document')"
            :uploaded-file="currentDocuments.electricity_bill_document"
            v-bind="electricityBillProps"
            :placeholder="t('formDocument.placeholder')"
            :label="`${t('formalization.electricity_bill.title')}*`"
            :error="props.form.errors.value.electricity_bill_document"
            :enable-delete="isStatusToReview && !disableField('electricity_bill_document')"
          />
        </div>
        <div v-if="!props.form.values.is_under_customer_name" class="flex flex-col md:system:flex-row items-start gap-6 w-full">
          <div class="w-full">
            <FileStorageInput
              id="holder_document"
              v-model="holderDocumentModel"
              name="holder_document"
              :project-id="registration?.project_id"
              :label="`${t('formalization.electricity_bill.form.proof_link_document')}*`"
              v-bind="holderDocumentProps"
              :disabled="isFieldsDisabled || disableField('employment_proof_document')"
              :placeholder="t('formDocument.placeholder')"
              :accept="ACCEPTED_FILE_TYPES.join(',')"
              :error="props.form.errors.value.employment_proof_document"
              :uploaded-file="currentDocuments.employment_proof_document"
              :enable-delete="isStatusToReview && !disableField('employment_proof_document')"
            />

            <SolLink
              id="what-is-bond-link"
              size="medium"
              @click="handleOpenModalWhatIsBond"
            >
              {{ t('formalization.electricity_bill.what_is_proof_of_link') }}
            </SolLink>
          </div>

          <SolInputText
            id="holder_cpf"
            v-model="holderCpfModel"
            name="holder_cpf"
            class="w-full md:system:w-1/2"
            :label="`${t('formalization.electricity_bill.form.document_account_holder.label')}*`"
            v-bind="holderCpfProps"
            :disabled="isFieldsDisabled"
            :placeholder="t('formalization.electricity_bill.form.document_account_holder.placeholder')"
            :mask="{ preset: 'CPF' }"
            :error="props.form.errors.value.holder_cpf"
          />
        </div>
      </div>
      <SolLink
        id="add-document-link"
        size="medium"
        @click="handleShowAdditionalComments"
      >
        <template v-if="!showAdditionalComments" #icon>
          <IconPlus />
        </template>
        <template v-else #icon>
          <IconPlus class="transform rotate-45" />
        </template>
        {{ showAdditionalComments ? 'Esconder documento e comentário adicional' : 'Adicionar documento e comentário adicional' }}
      </SolLink>
      <div v-if="showAdditionalComments" class="flex flex-col items-start gap-6 w-full">
        <FileStorageInput
          id="additional_document"
          v-model="additionalDocumentModel"
          name="additional_document"
          :project-id="registration.project_id"
          label="Documento adicional"
          v-bind="additionalDocumentProps"
          :disabled="isFieldsDisabled || disableField('additional_document')"
          :placeholder="t('formDocument.placeholder')"
          :accept="ACCEPTED_FILE_TYPES.join(',')"
          :error="props.form.errors.value.additional_document"
          :uploaded-file="currentDocuments.additional_document || undefined"
          :enable-delete="isStatusToReview && !disableField('additional_document')"
        />
        <SolInputTextarea
          id="comment"
          v-model="commentModel"
          name="comment"
          v-bind="commentProps"
          :disabled="isFieldsDisabled"
          class="w-full"
          label="Deseja adicionar algum comentário?"
          :error="props.form.errors.value.comment"
        />
      </div>
    </div>

    <!--  -->
    <WhatIsBondModal
      :show="modalWhatIsBondOpen"
      @close="modalWhatIsBondOpen = false"
    />
    <!--  -->
  </div>
</template>

<style lang="scss" scoped>
.arrow-text::before {
  @apply text-brand-primary-light;
  content: '➔';
}

.error-document {
  :deep(.sol-download) {
    @apply border-feedback-negative-pure;
  }
}
</style>
