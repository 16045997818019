<script setup lang="ts">
import type { ErrorPayload } from './Formalization.types'
import type { CustomerRegistrationInput } from '~/store-v2/registration/useUpdateRegistration'
import type {
  StageFormalization,
} from '~/ui-blocks/Formalization/Formalization.types'
import { useToast } from '@solfacil/girassol'
import { toTypedSchema } from '@vee-validate/zod'
import { isEqual } from 'lodash'
import { useForm } from 'vee-validate'
import * as zod from 'zod'
// Icons
import IconLock from '~icons/material-symbols/lock'
import CancelStep from '~/components/shared/CancelStep.vue'
import useFinancing from '~/store-v2/financings/useFinancing'
import { FinancingStatus } from '~/store-v2/financings/useFinancings'
import useProject from '~/store-v2/projects/useProject'
import useRegistration from '~/store-v2/registration/useRegistration'
import useUpdateRegistration from '~/store-v2/registration/useUpdateRegistration'
import { Formalization } from '~/types/enum'
import { convertToISODate } from '~/utils/string'
// blocks
import AddressSectionForm from './blocks/address/Address.form.vue'
import * as addressSchema from './blocks/address/Address.schema'
import DocumentationUnderAnalysisModal from './blocks/DocumentationUnderAnalysisModal.vue'
import ElectricityBillSectionForm from './blocks/electricityBill/ElectricityBill.form.vue'
import * as electricityBillSchema from './blocks/electricityBill/ElectricityBill.schema'
import PersonSectionForm from './blocks/person/Person.form.vue'
import * as personSchema from './blocks/person/Person.schema'
import { categorizeErrorsPF, getStatusTag, showErrorsFieldsPF } from './Formalization.utils'

// Props
const props = defineProps<{
  position: number
  stage: StageFormalization
  stageReproved: string
  triggerRefetch: () => Promise<void>
}>()

// initial base hooks
const { t } = useI18n()
const route = useRoute()
const toast = useToast()

// initial variables and computed
const projectId = computed(() => route.params.id as string)
const blocked = ref(false)
const docUnderAnalysisModal = ref(false)
const isValidateCpfCustomer = ref(false)
const isValidateCpfGuarantor = ref(false)

// domain hooks

const {
  data: project,
} = useProject(projectId)

const {
  data: financing,
} = useFinancing(projectId)

const {
  data: registration,
} = useRegistration(projectId)

const {
  data: dataUpdateRegistration,
  mutateAsync: updateRegistration,
  isPending: isPendingUpdateRegistration,
} = useUpdateRegistration(projectId)

// INITIAL FORMS
const customerDocumentationForm = useForm({
  initialValues: personSchema.getInitialValues(),
  validationSchema: toTypedSchema(personSchema.getSchema(t, isValidateCpfCustomer.value)),
  validateOnMount: false,
})

const guarantorDocumentationForm = useForm({
  initialValues: personSchema.getInitialValues(),
  validationSchema: toTypedSchema(personSchema.getSchema(t, isValidateCpfGuarantor.value)),
  validateOnMount: false,
})

const residencialAddressForm = useForm({
  initialValues: addressSchema.getInitialValues(),
  validationSchema: toTypedSchema(addressSchema.getSchema(t)),
  validateOnMount: false,
})

const installationAddressForm = useForm({
  initialValues: addressSchema.getInitialValues(),
  validationSchema: toTypedSchema(addressSchema.getSchema(t)),
  validateOnMount: false,
})

const guarantorAddressForm = useForm({
  initialValues: addressSchema.getInitialValues(),
  validationSchema: toTypedSchema(addressSchema.getSchema(t)),
  validateOnMount: false,
})

const electricityBillForm = useForm({
  initialValues: electricityBillSchema.getInitialValues(),
  validationSchema: toTypedSchema(electricityBillSchema.getSchema(t)),
  validateOnMount: false,
})

const radiosForms = useForm({
  initialValues: {
    client_local_address: registration.value?.project?.residencial_is_the_same_of_plant || true,
    rural_area: registration.value?.project?.address?.is_rural || false,
  },
  validationSchema: toTypedSchema(zod.object({
    client_local_address: zod.boolean().nullable().or(zod.undefined()),
    rural_area: zod.boolean().nullable().or(zod.undefined()),
  })),
  validateOnMount: false,
})

// fields
const [clientLocalAddressModel, clientLocalAddressModeProps] = radiosForms.defineField('client_local_address')
const [ruralAreaModel, ruralAreaProps] = radiosForms.defineField('rural_area')

// RADIOS
const radiosRuralArea = [
  {
    name: 'ruralArea',
    value: false,
    label: `${t('formAddress.noCountryside')}`,
  },
  {
    name: 'ruralArea',
    value: true,
    label: `${t('formAddress.yesCountryside')}`,
  },
]
const radiosClientLocalAddress = [
  {
    name: 'localAddress',
    value: true,
    label: `${t('formAddress.yesCountryside')}`,
  },
  {
    name: 'localAddress',
    value: false,
    label: `${t('formAddress.noCountryside')}`,
  },
]

// computed
const isFinancingDisabled = computed(() => {
  // TODO: Migrate this logic to BFF
  if (!financing.value) {
    return false
  }
  if ([FinancingStatus.expired].includes(financing.value.status)) {
    return true
  }
  if (financing.value.section_statuses.proposal?.customer_data === 'approved') {
    return true
  }
  return [
    FinancingStatus.approved,
    FinancingStatus.reproved,
    FinancingStatus.expired,
    FinancingStatus.canceled,
  ].includes(financing.value.status) && financing.value.step === 'proposal'
})

const isRegistrationDisabled = computed(() => {
  if (!financing.value || !registration.value) {
    return false
  }
  return ['under_analysis', 'reproved', 'approved'].includes(registration.value?.status)
})

const hasAnyFormErrors = computed(() => {
  return validateFields(customerDocumentationForm)
    || validateFields(residencialAddressForm)
    || radiosForms.values.client_local_address === false
    ? validateFields(installationAddressForm)
    : false
      || validateFields(electricityBillForm)
      || validateFields(radiosForms)
})

const isSendDisabled = computed(() => {
  return isPendingUpdateRegistration.value
    || hasAnyFormErrors.value
    || isFinancingDisabled.value
    || isRegistrationDisabled.value
})

const isCancelDisabled = computed(() => {
  if (!financing.value || !registration.value) {
    return false
  }

  return isPendingUpdateRegistration.value
    || isFinancingDisabled.value
    || registration.value?.status !== 'under_analysis'
})

const isFieldsDisabled = computed(() => {
  return isFinancingDisabled.value || isRegistrationDisabled.value
})

// functions get and saves
function validateFields(form: any) {
  return Object.keys(form.errors.value).length > 0
}

async function validateAllForms(): Promise<boolean> {
  const forms = [
    customerDocumentationForm,
    residencialAddressForm,
    electricityBillForm,
    radiosForms,
  ]

  if (!radiosForms.values.client_local_address)
    forms.push(installationAddressForm)

  if (project.value?.guarantor) {
    forms.push(guarantorDocumentationForm)
    forms.push(guarantorAddressForm)
  }

  const results = await Promise.all(forms.map(form => form.validate()))
  const isValid = results.every(result => result.valid)

  return isValid
}

async function isValidForm() {
  const isValid = await validateAllForms()

  if (!isValid) {
    toast.createErrorToast({
      title: t('toast.incomplete.documentation.title'),
      description: t('toast.incomplete.documentation.description'),
      actionText: '',
    })
  }

  return isValid
}

async function createPayloadRegistration() {
  const residencialAddressPayload = {
    zipcode: residencialAddressForm.values.zipcode,
    street: residencialAddressForm.values.street,
    street_number: residencialAddressForm.values.street_number,
    complement: residencialAddressForm.values.complement || '',
    neighborhood: residencialAddressForm.values.neighborhood,
    city_id: residencialAddressForm.values.city_id,
    state_id: residencialAddressForm.values.state_id,
  }

  const installationAddressPayload = radiosForms.values.client_local_address
    ? { ...residencialAddressPayload, is_rural: radiosForms.values.rural_area }
    : {
        zipcode: installationAddressForm.values.zipcode,
        street: installationAddressForm.values.street,
        street_number: installationAddressForm.values.street_number,
        complement: installationAddressForm.values.complement || '',
        neighborhood: installationAddressForm.values.neighborhood,
        city_id: installationAddressForm.values.city_id,
        state_id: installationAddressForm.values.state_id,
        is_rural: radiosForms.values.rural_area,
      }

  const guarantorAddressPayload = {
    zipcode: guarantorAddressForm.values.zipcode,
    street: guarantorAddressForm.values.street,
    street_number: guarantorAddressForm.values.street_number,
    complement: guarantorAddressForm.values.complement || '',
    neighborhood: guarantorAddressForm.values.neighborhood,
    city_id: guarantorAddressForm.values.city_id,
    state_id: guarantorAddressForm.values.state_id,
  }

  const customerPayload = {
    full_name: customerDocumentationForm.values.full_name,
    birthdate: convertToISODate(customerDocumentationForm.values.birthdate),
    contact: {
      email: customerDocumentationForm.values.email,
      phone: customerDocumentationForm.values.phone,
    },
    documents: personSchema.extractDocumentEntries(customerDocumentationForm),
    address: residencialAddressPayload,
  }

  const guarantorObject = {
    full_name: guarantorDocumentationForm?.values?.full_name,
    birthdate: convertToISODate(guarantorDocumentationForm?.values?.birthdate),
    contact: {
      email: guarantorDocumentationForm?.values?.email,
      phone: guarantorDocumentationForm?.values?.phone,
    },
    documents: guarantorDocumentationForm?.values?.full_name ? personSchema.extractDocumentEntries(guarantorDocumentationForm) : null,
    address: guarantorAddressPayload,
  }
  const guarantorPayload = [undefined, ''].includes(guarantorDocumentationForm?.values?.full_name) ? null : guarantorObject

  const projectPayload = {
    address: installationAddressPayload,
    documents: electricityBillSchema.extractDocumentEntries(electricityBillForm),
    comment: electricityBillForm.values.comment,
    electricity_bill: {
      is_under_customer_name: electricityBillForm.values.is_under_customer_name,
      holder_cpf: electricityBillForm.values.holder_cpf,
    },
  }

  return {
    project_id: projectId.value,
    person_type: String(project.value?.person_type).toLowerCase(),
    customer: customerPayload,
    project: projectPayload,
    company: null,
    guarantor: guarantorPayload,
  } as unknown as CustomerRegistrationInput
}

async function handleSave() {
  const isValidRegistrationForm = await isValidForm()

  if (!isValidRegistrationForm || !financing.value || !project.value) {
    return
  }

  const updateRegistrationPayload = await createPayloadRegistration()
  try {
    const response = await updateRegistration(updateRegistrationPayload)
    const success = response?.errors.length === 0

    if (success) {
      docUnderAnalysisModal.value = true
    }
    else {
      toast.createErrorToast({
        title: t('toast.error.send.title'),
        description: t('toast.error.send.description'),
      })
      const dataErrors = categorizeErrorsPF(
        dataUpdateRegistration.value?.errors as ErrorPayload[]
        || [],
      )
      showErrorsFieldsPF(
        dataErrors,
        customerDocumentationForm,
        guarantorDocumentationForm,
        residencialAddressForm,
        electricityBillForm,
        installationAddressForm,
        guarantorAddressForm,
      )
    }
  }
  catch {
    docUnderAnalysisModal.value = false
    toast.createErrorToast({
      title: t('toast.error.send.title'),
      description: t('toast.error.send.description'),
    })
  }
}

function checkStageReproved() {
  if (props.stageReproved === '')
    blocked.value = false
  else
    blocked.value = props.stageReproved !== Formalization.CUSTOMER_DATA
}

onMounted(() => {
  checkStageReproved()
})

watch(registration, (newValue) => {
  if (newValue) {
    radiosForms.setValues({
      client_local_address: newValue.project?.residencial_is_the_same_of_plant ?? true,
      rural_area: newValue.project?.address?.is_rural ?? false,
    }, false)
  }
}, { immediate: true })

watch(clientLocalAddressModel, (newValue, oldValue) => {
  if (isEqual(newValue, oldValue)) {
    return
  }

  if (newValue) {
    installationAddressForm.setValues({
      zipcode: residencialAddressForm.values.zipcode,
      street: residencialAddressForm.values.street,
      street_number: residencialAddressForm.values.street_number,
      complement: residencialAddressForm.values.complement,
      neighborhood: residencialAddressForm.values.neighborhood,
      city_id: residencialAddressForm.values.city_id,
      state_id: residencialAddressForm.values.state_id,
    }, false)
  }
  else {
    installationAddressForm.setValues({
      zipcode: undefined,
      street: undefined,
      street_number: undefined,
      complement: undefined,
      neighborhood: undefined,
      city_id: undefined,
      state_id: undefined,
    }, false)
  }
})
</script>

<template>
  <CustomerElementAccordion
    :id="`accordion_${Formalization.CUSTOMER_DATA}`"
    :title="t('customer.client_data')"
    :position="String(position)"
    :tag="getStatusTag(t, financing?.section_statuses.proposal?.customer_data)"
    :blocked="blocked"
    :open-collapse="!blocked && stage === Formalization.CUSTOMER_DATA"
    state="done"
    class="-mt-6"
  >
    <template #icon>
      <IconLock v-if="blocked" />
    </template>

    <div>
      <p class="mt-2">
        {{ t('formalization.client.title') }}
      </p>
    </div>

    <div class="mt-sm !mb-0">
      <form class="flex flex-col gap-10">
        <PersonSectionForm
          :form="customerDocumentationForm"
          :title="t('customer.customer_section_title')"
          kind="customer"
          :disable-cpf="true"
          :disabled="isFieldsDisabled"
        />

        <SolDivider thickness="x-small" />

        <div v-show="project?.guarantor">
          <PersonSectionForm
            :form="guarantorDocumentationForm"
            :registration="registration"
            :title="t('customer.guarantor_section_title')"
            kind="guarantor"
            :disabled="isFieldsDisabled"
            :disable-cpf="true"
          />
        </div>

        <SolDivider v-show="project?.guarantor" thickness="x-small" />

        <ElectricityBillSectionForm
          :form="electricityBillForm"
          :disabled="isFieldsDisabled"
        />

        <SolDivider thickness="x-small" />

        <AddressSectionForm
          :form="residencialAddressForm"
          :title="`${t('formalization.client.address.label')}`"
          kind="customer"
          :disabled="isFieldsDisabled"
        />

        <div class="flex flex-col gap-6">
          <div>
            <span class="font-bold text-3xs text-brand-primary-pure">{{ t('formAddress.local') }}</span>
            <SolRadioGroup
              id="clientLocalAddress"
              v-model="clientLocalAddressModel"
              :value="radiosForms.values.client_local_address"
              title=""
              name="clientLocalAddress"
              v-bind="clientLocalAddressModeProps"
              direction="row"
              :disabled="isFieldsDisabled"
              :radios="radiosClientLocalAddress"
            />
          </div>

          <div v-show="!radiosForms.values.client_local_address">
            <AddressSectionForm
              :form="installationAddressForm"
              :title="t('formalization.address.installation.label')"
              kind="project"
              :disabled="isFieldsDisabled"
            />
          </div>

          <div v-show="project?.guarantor">
            <AddressSectionForm
              v-show="project?.guarantor"
              :form="guarantorAddressForm"
              :title="t('formalization.address.guarantor.label')"
              :registration="registration"
              :disabled="isFieldsDisabled"
              kind="guarantor"
            />
          </div>
        </div>

        <div>
          <span class="font-bold text-3xs text-brand-primary-pure">{{ t('formAddress.countryside') }}</span>
          <SolRadioGroup
            id="rural_area"
            v-model="ruralAreaModel"
            title=""
            name="rural_area"
            v-bind="ruralAreaProps"
            direction="row"
            :disabled="isFieldsDisabled"
            :radios="radiosRuralArea"
          />
        </div>

        <div class="flex justify-end border-t border-neutral-high-medium pt-6 mt-8 gap-4 flex-col md:system:flex-row">
          <CancelStep
            step-to-cancel="registration"
            :project-id="projectId"
            :disabled="isCancelDisabled"
          />

          <SolButton
            id="customer_confirm"
            class="w-full md:system:w-auto order-1 md:system:order-2"
            :loading="isPendingUpdateRegistration"
            size="large"
            :disabled="isSendDisabled"
            @click="handleSave"
          >
            {{ t('app.send') }}
          </SolButton>
        </div>
      </form>
    </div>

    <DocumentationUnderAnalysisModal
      v-if="docUnderAnalysisModal"
      @close="() => docUnderAnalysisModal = false"
    />
  </CustomerElementAccordion>
</template>

<style lang="scss" scoped>
.error-message {
  @apply text-feedback-negative-pure py-nano;
  @apply fonts-subtitle-small;
}

:deep(#input-text-input-phone) {
  @apply pl-xl;
}

[data-tooltip].tooltip:after {
  width: 250px;
  white-space: break-spaces;
}
</style>
