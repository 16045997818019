<script lang="ts" setup>
import DeleteIcon from '~icons/material-symbols/delete-outline'

interface Equipment {
  name?: string
  value?: string
}

interface EquipmentSelection extends Equipment {
  quantity?: number
}

const props = withDefaults(
  defineProps<{
    id: string
    modelValue?: EquipmentSelection
    selectLabel: string
    counterLabel: string
    equipments: Equipment[]
    disabled: boolean
    selectError?: string | null
    counterError?: string | null
    removable: boolean
  }>(),
  {
    disabled: false,
    removable: false,
  },
)

const emit = defineEmits<{
  (e: 'update:modelValue', value: EquipmentSelection): void
  (e: 'remove'): void
}>()

const equipment = ref<Equipment | undefined>(props.modelValue)
const quantity = ref<number | undefined>(props.modelValue?.quantity)

watch([equipment, quantity], () => {
  emit('update:modelValue', { name: equipment.value?.name, value: String(equipment.value?.value), quantity: quantity.value || 0 })
})
</script>

<template>
  <SolSelect
    :id="`equipment-${id}`"
    class="w-full"
    :name="`equipment-${id}`"
    :label="selectLabel"
    :options="equipments"
    :searchable="true"
    :disabled="disabled"
    :error="selectError"
    :selected="equipment"
    @update:selected="equipment = $event"
  />
  <div class="flex gap-2 items-center">
    <InputCounter
      v-model="quantity"
      :min="0"
      :label="counterLabel"
      :error="counterError"
      :disabled="disabled"
    />
    <template v-if="removable">
      <SolButton
        id="removeBattery"
        size="large"
        variant="tertiary"
        class="!h-[48px]"
        :disabled="disabled"
        @click="emit('remove')"
      >
        <template #icon:left>
          <DeleteIcon />
        </template>
      </SolButton>
    </template>
  </div>
</template>

<style scoped>
</style>
