import type { UseFinancingData } from '../financings/useFinancing'
import type { UseRegistrationData } from './useRegistration'
import type { ModelTypes } from '~/services-v2/financial-bff/__generated__/zeus'
import { useMutation } from '@tanstack/vue-query'
import KnowError from '~/exceptions/KnownError'
import queryClient, { queryKeys } from '~/queryClient'
import financialBff from '~/services-v2/financial-bff/financial-bff'

export default function useUpdateRegistration(projectId: Ref<string>) {
  const mutationFn = async (data: CustomerRegistrationInput) => {
    const res = await financialBff('mutation')({
      customer_registration: [
        {
          params: data,
        },
        {
          errors: {
            field: true,
            message: true,
            title: true,
            type: true,
            __typename: true,
          },
        },
      ],
    })

    if (!res.customer_registration) {
      throw new KnowError('NO_DATA')
    }

    queryClient.setQueryData<UseRegistrationData>(queryKeys.REGISTRATION.DATA(projectId.value), (prevData) => {
      if (!prevData || !res.customer_registration?.errors)
        return prevData

      return {
        ...prevData,
        errors: res.customer_registration.errors.map(error => ({
          field: error.field,
          title: String(error.title ?? 'Erro'),
          description: error.message,
        })),
      }
    })

    return res.customer_registration
  }

  return useMutation({
    mutationFn,
    mutationKey: queryKeys.REGISTRATION.UPDATE.DATA(projectId.value),
    onSuccess(data) {
      const success = data.errors.length === 0

      if (!success)
        return

      // optimistic update for financing details

      queryClient.setQueryData<UseFinancingData>(queryKeys.FINANCING.DETAILS(projectId.value), (prevData) => {
        if (!prevData)
          return prevData

        return {
          ...prevData,
          section_statuses: {
            ...prevData.section_statuses,
            proposal: {
              ...prevData.section_statuses.proposal,
              customer_data: 'under_analysis',
            },
          },
        }
      })

      // optimistic update for registration details
      queryClient.setQueryData<UseRegistrationData>(queryKeys.REGISTRATION.DATA(projectId.value), (prevData) => {
        if (!prevData)
          return prevData

        return {
          ...prevData,
          status: 'under_analysis',
        }
      })

      queryClient.refetchQueries({ queryKey: queryKeys.REGISTRATION.DATA(projectId.value) })
    },
    retry: 1,
  })
}

export type CustomerRegistrationResponse = ModelTypes['Mutation']['customer_registration']
export type CustomerRegistrationInput = ModelTypes['CustomerRegistrationInput']
