import type { BiometryResponse } from '~/utils/customer-register/Biometry'
import { useMutation } from '@tanstack/vue-query'
import { queryClient, queryKeys } from '~/queryClient'
import { sendCustomerBiometry } from '~/services-v2/contract-formalization-api/contract-formalization-api'

export default function useCustomerBiometrySend(projectId: Ref<string>) {
  const mutationFn = async (methodReceipt: string) => {
    const res = await sendCustomerBiometry(projectId.value, methodReceipt)

    await queryClient.refetchQueries({ queryKey: queryKeys.CONTRACT_BIOMETRY.DATA(projectId.value) })

    return res
  }

  return useMutation({
    mutationFn,
    mutationKey: queryKeys.CONTRACT_BIOMETRY.SEND,
    retry: 3,
    retryDelay: 1000,
    onSuccess: () => {
      queryClient.setQueryData<BiometryResponse>(queryKeys.CONTRACT_BIOMETRY.DATA(projectId.value), (oldData) => {
        if (!oldData)
          return oldData

        return {
          ...oldData,
          approval: 'waiting',
          biometry: { ...oldData.biometry, status: 'in_progress' },
          contract: { ...oldData.contract, status: 'waiting' },
          status: 'biometric_started',
        }
      })

      queryClient.invalidateQueries({
        queryKey: queryKeys.FINANCING.DETAILS(projectId.value),
      })
    },
  })
}
