import type { OptionsForm } from '~/utils/installation/Installation'

export function getHardwareListLabels(item: OptionsForm, typeUnit: string): string {
  const unitOfMeasurement = {
    modules: 'Wp', // watt-pico
    inverters: 'kW', // kilo-watt
    microinverters: 'kW', // kilo-watt
    batteries: 'kWh', // kilo-watt-hour
  }

  if (item.power && item.brand)
    return `${item.brand} - ${item.name} - ${item.power.toLocaleString('pt-BR')}${unitOfMeasurement[typeUnit]}`

  if (item.power)
    return `${item.name} - ${item.power}${unitOfMeasurement[typeUnit]}`

  return `${item.name}`
}

export function validateInverters(values, errors, setErrors, t) {
  let hasError = false

  if (values.inverters?.length > 0) {
    values.inverters.forEach((item, index) => {
      if (item.value === '0') {
        setErrors({
          ...errors.value,
          [`inverters[${index}].value`]: t('form.required'),
        })
        hasError = true
      }
      else {
        setErrors({
          ...errors.value,
          [`inverters[${index}].value`]: '',
        })
      }

      if (item.quantity === 0) {
        setErrors({
          ...errors.value,
          [`inverters[${index}].quantity`]: t('form.required'),
        })
        hasError = true
      }
      else {
        setErrors({
          ...errors.value,
          [`inverters[${index}].quantity`]: '',
        })
      }
    })
  }
  else {
    setErrors({
      ...errors.value,
      inverters: t('form.required'),
    })

    if (!values.inverters?.[0]?.quantity) {
      setErrors({
        ...errors.value,
        'inverters[0].quantity': t('form.required'),
      })
    }
    hasError = true
  }

  return hasError
}

export function validateModules(values, errors, setErrors, t) {
  let hasError = false

  if (values.modules) {
    if (values.modules?.value === '0') {
      setErrors({
        ...errors.value,
        'modules.value': t('form.required'),
      })
      hasError = true
    }
    else {
      setErrors({
        ...errors.value,
        'modules.value': '',
      })
    }

    if (values.modules?.quantity === 0) {
      setErrors({
        ...errors.value,
        'modules.quantity': t('form.required'),
      })
      hasError = true
    }
    else {
      setErrors({
        ...errors.value,
        'modules.quantity': '',
      })
    }
  }
  else {
    setErrors({
      ...errors.value,
      modules: t('form.required'),
    })
    setErrors({
      ...errors.value,
      'modules.quantity': t('form.required'),
    })
    hasError = true
  }

  return hasError
}

export function validateBatteries(values, errors, setErrors, t) {
  let hasError = false

  if (values.batteries?.length > 0) {
    values.batteries.forEach((item, index) => {
      if (item.value === '0') {
        setErrors({
          ...errors.value,
          [`batteries[${index}].value`]: t('form.required'),
        })
        hasError = true
      }
      else {
        setErrors({
          ...errors.value,
          [`batteries[${index}].value`]: '',
        })
      }

      if (item.quantity === 0) {
        setErrors({
          ...errors.value,
          [`batteries[${index}].quantity`]: t('form.required'),
        })
        hasError = true
      }
      else {
        setErrors({
          ...errors.value,
          [`batteries[${index}].quantity`]: '',
        })
      }
    })
  }
  else {
    setErrors({
      ...errors.value,
      batteries: t('form.required'),
    })
    setErrors({
      ...errors.value,
      [`batteries[0].quantity`]: t('form.required'),
    })
    hasError = true
  }

  return hasError
}

export function customValidation(values, errors, setErrors, t, hasBattery, isMicroInverter) {
  const hasModulesError = validateModules(values, errors, setErrors, t)

  if (isMicroInverter)
    return hasModulesError

  const hasInvertersError = validateInverters(values, errors, setErrors, t)

  if (!hasBattery)
    return hasInvertersError || hasModulesError

  const hasBatteriesError = validateBatteries(values, errors, setErrors, t)

  return hasInvertersError || hasModulesError || hasBatteriesError
}
