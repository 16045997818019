<script setup lang="ts">
const props = withDefaults(defineProps<{
  show: boolean
  type: 'bank' | 'qi-tech' | string
}>(), {
  show: false,
})

const emit = defineEmits<{
  (e: 'close'): void
  (e: 'update'): void
}>()
const router = useRouter()
const { t } = useI18n()

const title = computed(() => {
  if (props.type === 'bank') {
    return `${t('lack-bank.modal.title.bank')}`
  }
  return `${t('lack-bank.modal.title.qi-tech')}`
})

function goToListPage() {
  router.push('/')
}

function openWhatsApp() {
  const whatsappLink = 'https://wa.me/5511912386374?text=Ol%C3%A1!%20Preciso%20de%20ajuda%20para%20atualizar%20meus%20dados%20banc%C3%A1rios%20na%20plataforma.'
  window.open(whatsappLink, '_blank')
}
</script>

<template>
  <div>
    <SolModal
      id="modal-update-bank"
      :is-open="show"
      :title="title"
      :size="{ desktop: 'medium', mobile: 'full' }"
      :action-secondary-text="`${t('buttons.back')} lista de projetos`"
      @action:secondary="goToListPage"
      @close="emit('close')"
    >
      <template #action-primary>
        <SolButton id="btn-whatsapp" size="medium" @click="openWhatsApp">
          <template #icon:left>
            <img src="/icons/whatsapp-white.svg" class="w-5 h-4 mt-quark mr-quark" alt="whatsapp">
          </template>
          {{ t('lack-bank.modal.button') }}
        </SolButton>
      </template>
      <div id="icon-empty-state" class="sol-empty-state-core">
        <figure class="icon">
          <img src="/images/empty-state-error.svg" alt="Icone que representa uma pasta vazia">
        </figure>
      </div>
      <div class="flex flex-col justify-center items-center gap-6">
        <div class="flex flex-col justify-center items-center gap-6 items-baseline">
          <div class="flex flex-col items-start gap-4 text-center md:system:text-left">
            <span class="fonts-body-medium-regular text-neutral-low-medium">
              {{ t('lack-bank.modal.description_1') }}
              <span class="fonts-body-medium-bold text-brand-primary-dark">
                {{ t('lack-bank.modal.description_2') }}
              </span>
              {{ t('lack-bank.modal.description_3') }}
            </span>
          </div>
          <div>
            <div class="flex ">
              <div class="arrow-text" />
              <div>
                <span class="fonts-body-medium-regular ml-quark">
                  {{ t('lack-bank.modal.contact.description_1') }}
                  <span class="text-brand-secondary-pure">
                    {{ t('lack-bank.modal.contact.whatsapp') }}
                  </span>
                </span>
              </div>
            </div>
            <div class="flex">
              <div class="arrow-text" />
              <div>
                <span class="fonts-body-medium-regular ml-quark">
                  {{ t('lack-bank.modal.contact.description_2') }}
                  <span class="text-brand-secondary-pure">
                    {{ t('lack-bank.modal.contact.phone') }}
                  </span>
                  {{ t('lack-bank.modal.contact.description_3') }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SolModal>
  </div>
</template>

<style scoped>
.arrow-text::before {
  @apply text-brand-primary-light;
  content: '➔';
}
</style>
