<script lang="ts" setup>
import type { ModelTypes } from '~/services-v2/financial-bff/__generated__/zeus'
import { SolFileUpload, SolModal } from '@solfacil/girassol'
import { defineProps, ref, useAttrs } from 'vue'
import { useUploadFiles } from '~/store-v2/registration/useUploadFiles'
import download from '~/utils/download'

type Doc = ModelTypes['RegistrationDocument']

const props = defineProps<{
  label?: string
  accept: string
  modelValue: string | null | undefined
  id: string
  error?: string
  placeholder?: string
  disabled?: boolean
  uploadedFile?: Doc | null
  name?: string
  projectId: string
  enableDelete?: boolean
  maxFileSize?: number
}>()

const emit = defineEmits<{
  (e: 'update:modelValue', value: string | null): void
}>()

const attrs = useAttrs()

const projectId = computed(() => props.projectId)

// states
const fileSelected = ref<File[] | null | undefined>()
const uploadedFile = ref<Doc | null>(null)
const inputError = ref<string | null>(null)
const preview = ref(false)

// mutations
const {
  mutateAsync: handleUploadFile,
  isPending: isUploading,
  reset: resetUpload,
  status: uploadStatus,
} = useUploadFiles(projectId)

const error = computed(() => {
  if (uploadStatus.value === 'pending') {
    return null
  }
  return inputError.value || props.error
})

function getAcceptMessage(mimeTypeAccepted: string[]): string {
  const types = mimeTypeAccepted.map((mime) => {
    const [_, type] = mime.split('/')
    return type
  })
  return `Apenas arquivos ${types.map((ext, i) => `${i === types.length - 1 ? ' e ' : ''} .${ext}`)} são aceitos.`
}

function bytesToMegaBytes(bytes: number) {
  return (bytes / (1024 * 1024)).toFixed(2)
}

async function handleFileChange(files: File[]) {
  if (!files) {
    return
  }
  resetUpload()
  inputError.value = null

  const file = Array.from(files)[0]

  if (file.size > (props?.maxFileSize || 10 * 1000 * 1000)) {
    inputError.value = `Arquivo maior que o permitido: ${bytesToMegaBytes(file.size)}MB`
    emit('update:modelValue', null)
    return
  }

  if (props.accept && !props.accept.split(',').includes(file.type)) {
    inputError.value = getAcceptMessage(props.accept.split(','))
    emit('update:modelValue', null)
    return
  }

  // Atualiza o loading
  await handleUploadFile([file])
    .then((response) => {
      const files = response[0].docstorage_id
      emit('update:modelValue', files)
    })
    .catch(() => {
      inputError.value = 'Erro ao enviar arquivo, tente novamente.'
      emit('update:modelValue', null)
    })
}

async function handleDownloadUploadedFile() {
  if (!props.uploadedFile) {
    return
  }

  download.downloadFile(props.uploadedFile.url_download)
}

async function handleDeleteFile() {
  if (!props.uploadedFile) {
    return
  }
  uploadedFile.value = null
  emit('update:modelValue', null)
}

watch(fileSelected, (value) => {
  if (value) {
    handleFileChange(value)
  }
  else {
    emit('update:modelValue', null)
    inputError.value = null
  }
})

watchImmediate(() => props.uploadedFile, (value) => {
  if (value) {
    uploadedFile.value = value
  }
})
</script>

<template>
  <SolFileUpload
    v-if="!uploadedFile"
    v-bind="attrs"
    :id="props.id"
    v-model="fileSelected"
    :class="{
      'error-document': !!error,
      'pointer-events-none': disabled,
      'upload-disabled': disabled,
    }"
    :name="props.name"
    :label="props.label"
    class="w-full"
    :loading="isUploading"
    :placeholder="props.placeholder"
    :disabled="disabled"
    :error="error"
    @menu:delete="handleDeleteFile"
  />

  <SolFileUpload
    v-else
    :id="props.id"
    v-bind="attrs"
    class="w-full"
    name="document_front"
    :class="{
      'error': !!error,
      'upload-disabled': disabled,
    }"
    :label="props.label"
    :download-src="props.uploadedFile?.url_download"
    :file-name="props.uploadedFile?.filename"
    :download-menu="(props.enableDelete ? ['download', 'preview', 'delete'] : ['download', 'preview'])"
    :error="error"
    @menu:preview="() => preview = true"
    @menu:download="handleDownloadUploadedFile"
    @menu:delete="handleDeleteFile"
    @update:model-value="(val) => {
      if (val === null){
        handleDeleteFile()
      }
    }"
  />

  <SolModal
    id="modal-preview-file"
    :is-open="preview"
    title=""
    :size="{ desktop: 'full', mobile: 'full' }"
    @close="() => preview = false"
  >
    <div v-if="props.uploadedFile?.mime_type === 'application/pdf'">
      <iframe
        class="preview-iframe"
        :src="props.uploadedFile.url_preview"
        :type="props.uploadedFile?.mime_type"
        frameborder="0"
      />
    </div>
    <div
      v-else
      class="flex justify-center w-full"
    >
      <img :src="props.uploadedFile?.url_preview" :alt="props.uploadedFile?.filename">
    </div>
  </SolModal>
</template>

<style lang="scss" scoped>
  .preview-iframe {
    border: none;
    width: 100%;
    height: 85dvh;
  }
  .upload-disabled:deep(.sol-download){
    @apply bg-neutral-high-light;
  }
  .upload-disabled:deep(.sol-upload){
    @apply bg-neutral-high-light;
  }
  .error:deep(.sol-download){
    @apply border-1 border-feedback-negative-pure;
  }
</style>
