import type { PartialDeep } from 'type-fest'
import type { FormContext } from 'vee-validate'
import * as z from 'zod'

function fileSchema(t: any) {
  return z
    .any()
    .refine(uploadIdentifier => uploadIdentifier?.length, t('form.file_required'))
    .refine(files => !files?.[0]?.size || files[0].size <= 10000000, 'O arquivo precisa ter no máximo 10MB.')
    .refine(files => !files?.[0]?.type || files[0].type === 'application/pdf', 'Apenas arquivo pdf é aceito.')
}

export function getSchema({
  t,
  isSingleInvoice,
  isSeparateInvoices,
  hasFinancingCommissionToReceive,
}: {
  t: any
  isSingleInvoice: boolean
  isSeparateInvoices: boolean
  hasFinancingCommissionToReceive: boolean
}) {
  return z.object({
    service_product: isSingleInvoice ? fileSchema(t) : z.any().optional(),
    commission: hasFinancingCommissionToReceive ? fileSchema(t) : z.any().optional(),
    products: isSeparateInvoices ? fileSchema(t) : z.any().optional(),
    correction_letter: z.any().optional(),
  })
}

export type FormSchema = z.infer<ReturnType<typeof getSchema>>
export type Form = FormContext<PartialDeep<FormSchema>>
export type Field = keyof(FormSchema)

export function getInitialValues(): Partial<FormSchema> {
  return {
    commission: null,
    products: null,
    service_product: null,
    correction_letter: null,
  }
}
