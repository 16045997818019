import { useQuery } from '@tanstack/vue-query'
import { queryKeys } from '~/queryClient'
import financialBff from '~/services-v2/financial-bff/financial-bff'
import { onlyNumbers } from '~/utils/string'

export default function useCep(cep: Ref<string | undefined>) {
  const cepNumber = computed(() => onlyNumbers(cep.value || '0'))
  const queryKey = ref(queryKeys.ADDRESS.BY_CEP(cepNumber))

  const queryFn = async () => {
    if (!cep.value)
      throw new Error('CEP não informado')

    const res = await financialBff('query')({
      address_from_cep: [{ cep: cepNumber.value }, {
        city: {
          id: true,
          name: true,
        },
        state: {
          id: true,
          name: true,
        },
        neighborhood: true,
        street: true,
      }],
    })

    return res.address_from_cep
  }

  const query = useQuery({
    queryKey,
    queryFn,
    retry: 1,
  })

  watch(cepNumber, () => {
    if (cepNumber.value.length === 8) {
      queryKey.value = queryKeys.ADDRESS.BY_CEP(cepNumber)
    }
  })

  return query
}

export type UseAddressByCepData = ReturnType<typeof useCep>['data']['value']
