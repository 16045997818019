/* eslint-disable */

import { AllTypesProps, ReturnTypes, Ops } from './const';
export const HOST = "https://stg-financial-bff.solfacil.com.br/graphql"


export const HEADERS = {}
export const apiSubscription = (options: chainOptions) => (query: string) => {
  try {
    const queryString = options[0] + '?query=' + encodeURIComponent(query);
    const wsString = queryString.replace('http', 'ws');
    const host = (options.length > 1 && options[1]?.websocket?.[0]) || wsString;
    const webSocketOptions = options[1]?.websocket || [host];
    const ws = new WebSocket(...webSocketOptions);
    return {
      ws,
      on: (e: (args: any) => void) => {
        ws.onmessage = (event: any) => {
          if (event.data) {
            const parsed = JSON.parse(event.data);
            const data = parsed.data;
            return e(data);
          }
        };
      },
      off: (e: (args: any) => void) => {
        ws.onclose = e;
      },
      error: (e: (args: any) => void) => {
        ws.onerror = e;
      },
      open: (e: () => void) => {
        ws.onopen = e;
      },
    };
  } catch {
    throw new Error('No websockets implemented');
  }
};
const handleFetchResponse = (response: Response): Promise<GraphQLResponse> => {
  if (!response.ok) {
    return new Promise((_, reject) => {
      response
        .text()
        .then((text) => {
          try {
            reject(JSON.parse(text));
          } catch (err) {
            reject(text);
          }
        })
        .catch(reject);
    });
  }
  return response.json() as Promise<GraphQLResponse>;
};

export const apiFetch =
  (options: fetchOptions) =>
  (query: string, variables: Record<string, unknown> = {}) => {
    const fetchOptions = options[1] || {};
    if (fetchOptions.method && fetchOptions.method === 'GET') {
      return fetch(`${options[0]}?query=${encodeURIComponent(query)}`, fetchOptions)
        .then(handleFetchResponse)
        .then((response: GraphQLResponse) => {
          if (response.errors) {
            throw new GraphQLError(response);
          }
          return response.data;
        });
    }
    return fetch(`${options[0]}`, {
      body: JSON.stringify({ query, variables }),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      ...fetchOptions,
    })
      .then(handleFetchResponse)
      .then((response: GraphQLResponse) => {
        if (response.errors) {
          throw new GraphQLError(response);
        }
        return response.data;
      });
  };

export const InternalsBuildQuery = ({
  ops,
  props,
  returns,
  options,
  scalars,
}: {
  props: AllTypesPropsType;
  returns: ReturnTypesType;
  ops: Operations;
  options?: OperationOptions;
  scalars?: ScalarDefinition;
}) => {
  const ibb = (
    k: string,
    o: InputValueType | VType,
    p = '',
    root = true,
    vars: Array<{ name: string; graphQLType: string }> = [],
  ): string => {
    const keyForPath = purifyGraphQLKey(k);
    const newPath = [p, keyForPath].join(SEPARATOR);
    if (!o) {
      return '';
    }
    if (typeof o === 'boolean' || typeof o === 'number') {
      return k;
    }
    if (typeof o === 'string') {
      return `${k} ${o}`;
    }
    if (Array.isArray(o)) {
      const args = InternalArgsBuilt({
        props,
        returns,
        ops,
        scalars,
        vars,
      })(o[0], newPath);
      return `${ibb(args ? `${k}(${args})` : k, o[1], p, false, vars)}`;
    }
    if (k === '__alias') {
      return Object.entries(o)
        .map(([alias, objectUnderAlias]) => {
          if (typeof objectUnderAlias !== 'object' || Array.isArray(objectUnderAlias)) {
            throw new Error(
              'Invalid alias it should be __alias:{ YOUR_ALIAS_NAME: { OPERATION_NAME: { ...selectors }}}',
            );
          }
          const operationName = Object.keys(objectUnderAlias)[0];
          const operation = objectUnderAlias[operationName];
          return ibb(`${alias}:${operationName}`, operation, p, false, vars);
        })
        .join('\n');
    }
    const hasOperationName = root && options?.operationName ? ' ' + options.operationName : '';
    const keyForDirectives = o.__directives ?? '';
    const query = `{${Object.entries(o)
      .filter(([k]) => k !== '__directives')
      .map((e) => ibb(...e, [p, `field<>${keyForPath}`].join(SEPARATOR), false, vars))
      .join('\n')}}`;
    if (!root) {
      return `${k} ${keyForDirectives}${hasOperationName} ${query}`;
    }
    const varsString = vars.map((v) => `${v.name}: ${v.graphQLType}`).join(', ');
    return `${k} ${keyForDirectives}${hasOperationName}${varsString ? `(${varsString})` : ''} ${query}`;
  };
  return ibb;
};

type UnionOverrideKeys<T, U> = Omit<T, keyof U> & U;

export const Thunder =
  <SCLR extends ScalarDefinition>(fn: FetchFunction, thunderGraphQLOptions?: ThunderGraphQLOptions<SCLR>) =>
  <O extends keyof typeof Ops, OVERRIDESCLR extends SCLR, R extends keyof ValueTypes = GenericOperation<O>>(
    operation: O,
    graphqlOptions?: ThunderGraphQLOptions<OVERRIDESCLR>,
  ) =>
  <Z extends ValueTypes[R]>(
    o: Z & {
      [P in keyof Z]: P extends keyof ValueTypes[R] ? Z[P] : never;
    },
    ops?: OperationOptions & { variables?: Record<string, unknown> },
  ) => {
    const options = {
      ...thunderGraphQLOptions,
      ...graphqlOptions,
    };
    return fn(
      Zeus(operation, o, {
        operationOptions: ops,
        scalars: options?.scalars,
      }),
      ops?.variables,
    ).then((data) => {
      if (options?.scalars) {
        return decodeScalarsInResponse({
          response: data,
          initialOp: operation,
          initialZeusQuery: o as VType,
          returns: ReturnTypes,
          scalars: options.scalars,
          ops: Ops,
        });
      }
      return data;
    }) as Promise<InputType<GraphQLTypes[R], Z, UnionOverrideKeys<SCLR, OVERRIDESCLR>>>;
  };

export const Chain = (...options: chainOptions) => Thunder(apiFetch(options));

export const SubscriptionThunder =
  <SCLR extends ScalarDefinition>(fn: SubscriptionFunction, thunderGraphQLOptions?: ThunderGraphQLOptions<SCLR>) =>
  <O extends keyof typeof Ops, OVERRIDESCLR extends SCLR, R extends keyof ValueTypes = GenericOperation<O>>(
    operation: O,
    graphqlOptions?: ThunderGraphQLOptions<OVERRIDESCLR>,
  ) =>
  <Z extends ValueTypes[R]>(
    o: Z & {
      [P in keyof Z]: P extends keyof ValueTypes[R] ? Z[P] : never;
    },
    ops?: OperationOptions & { variables?: ExtractVariables<Z> },
  ) => {
    const options = {
      ...thunderGraphQLOptions,
      ...graphqlOptions,
    };
    type CombinedSCLR = UnionOverrideKeys<SCLR, OVERRIDESCLR>;
    const returnedFunction = fn(
      Zeus(operation, o, {
        operationOptions: ops,
        scalars: options?.scalars,
      }),
    ) as SubscriptionToGraphQL<Z, GraphQLTypes[R], CombinedSCLR>;
    if (returnedFunction?.on && options?.scalars) {
      const wrapped = returnedFunction.on;
      returnedFunction.on = (fnToCall: (args: InputType<GraphQLTypes[R], Z, CombinedSCLR>) => void) =>
        wrapped((data: InputType<GraphQLTypes[R], Z, CombinedSCLR>) => {
          if (options?.scalars) {
            return fnToCall(
              decodeScalarsInResponse({
                response: data,
                initialOp: operation,
                initialZeusQuery: o as VType,
                returns: ReturnTypes,
                scalars: options.scalars,
                ops: Ops,
              }),
            );
          }
          return fnToCall(data);
        });
    }
    return returnedFunction;
  };

export const Subscription = (...options: chainOptions) => SubscriptionThunder(apiSubscription(options));
export const Zeus = <
  Z extends ValueTypes[R],
  O extends keyof typeof Ops,
  R extends keyof ValueTypes = GenericOperation<O>,
>(
  operation: O,
  o: Z,
  ops?: {
    operationOptions?: OperationOptions;
    scalars?: ScalarDefinition;
  },
) =>
  InternalsBuildQuery({
    props: AllTypesProps,
    returns: ReturnTypes,
    ops: Ops,
    options: ops?.operationOptions,
    scalars: ops?.scalars,
  })(operation, o as VType);

export const ZeusSelect = <T>() => ((t: unknown) => t) as SelectionFunction<T>;

export const Selector = <T extends keyof ValueTypes>(key: T) => key && ZeusSelect<ValueTypes[T]>();

export const TypeFromSelector = <T extends keyof ValueTypes>(key: T) => key && ZeusSelect<ValueTypes[T]>();
export const Gql = Chain(HOST, {
  headers: {
    'Content-Type': 'application/json',
    ...HEADERS,
  },
});

export const ZeusScalars = ZeusSelect<ScalarCoders>();

type ScalarsSelector<T> = {
  [X in Required<{
    [P in keyof T]: T[P] extends number | string | undefined | boolean ? P : never;
  }>[keyof T]]: true;
};

export const fields = <T extends keyof ModelTypes>(k: T) => {
  const t = ReturnTypes[k];
  const o = Object.fromEntries(
    Object.entries(t)
      .filter(([, value]) => {
        const isReturnType = ReturnTypes[value as string];
        if (!isReturnType || (typeof isReturnType === 'string' && isReturnType.startsWith('scalar.'))) {
          return true;
        }
      })
      .map(([key]) => [key, true as const]),
  );
  return o as ScalarsSelector<ModelTypes[T]>;
};

export const decodeScalarsInResponse = <O extends Operations>({
  response,
  scalars,
  returns,
  ops,
  initialZeusQuery,
  initialOp,
}: {
  ops: O;
  response: any;
  returns: ReturnTypesType;
  scalars?: Record<string, ScalarResolver | undefined>;
  initialOp: keyof O;
  initialZeusQuery: InputValueType | VType;
}) => {
  if (!scalars) {
    return response;
  }
  const builder = PrepareScalarPaths({
    ops,
    returns,
  });

  const scalarPaths = builder(initialOp as string, ops[initialOp], initialZeusQuery);
  if (scalarPaths) {
    const r = traverseResponse({ scalarPaths, resolvers: scalars })(initialOp as string, response, [ops[initialOp]]);
    return r;
  }
  return response;
};

export const traverseResponse = ({
  resolvers,
  scalarPaths,
}: {
  scalarPaths: { [x: string]: `scalar.${string}` };
  resolvers: {
    [x: string]: ScalarResolver | undefined;
  };
}) => {
  const ibb = (k: string, o: InputValueType | VType, p: string[] = []): unknown => {
    if (Array.isArray(o)) {
      return o.map((eachO) => ibb(k, eachO, p));
    }
    if (o == null) {
      return o;
    }
    const scalarPathString = p.join(SEPARATOR);
    const currentScalarString = scalarPaths[scalarPathString];
    if (currentScalarString) {
      const currentDecoder = resolvers[currentScalarString.split('.')[1]]?.decode;
      if (currentDecoder) {
        return currentDecoder(o);
      }
    }
    if (typeof o === 'boolean' || typeof o === 'number' || typeof o === 'string' || !o) {
      return o;
    }
    const entries = Object.entries(o).map(([k, v]) => [k, ibb(k, v, [...p, purifyGraphQLKey(k)])] as const);
    const objectFromEntries = entries.reduce<Record<string, unknown>>((a, [k, v]) => {
      a[k] = v;
      return a;
    }, {});
    return objectFromEntries;
  };
  return ibb;
};

export type AllTypesPropsType = {
  [x: string]:
    | undefined
    | `scalar.${string}`
    | 'enum'
    | {
        [x: string]:
          | undefined
          | string
          | {
              [x: string]: string | undefined;
            };
      };
};

export type ReturnTypesType = {
  [x: string]:
    | {
        [x: string]: string | undefined;
      }
    | `scalar.${string}`
    | undefined;
};
export type InputValueType = {
  [x: string]: undefined | boolean | string | number | [any, undefined | boolean | InputValueType] | InputValueType;
};
export type VType =
  | undefined
  | boolean
  | string
  | number
  | [any, undefined | boolean | InputValueType]
  | InputValueType;

export type PlainType = boolean | number | string | null | undefined;
export type ZeusArgsType =
  | PlainType
  | {
      [x: string]: ZeusArgsType;
    }
  | Array<ZeusArgsType>;

export type Operations = Record<string, string>;

export type VariableDefinition = {
  [x: string]: unknown;
};

export const SEPARATOR = '|';

export type fetchOptions = Parameters<typeof fetch>;
type websocketOptions = typeof WebSocket extends new (...args: infer R) => WebSocket ? R : never;
export type chainOptions = [fetchOptions[0], fetchOptions[1] & { websocket?: websocketOptions }] | [fetchOptions[0]];
export type FetchFunction = (query: string, variables?: Record<string, unknown>) => Promise<any>;
export type SubscriptionFunction = (query: string) => any;
type NotUndefined<T> = T extends undefined ? never : T;
export type ResolverType<F> = NotUndefined<F extends [infer ARGS, any] ? ARGS : undefined>;

export type OperationOptions = {
  operationName?: string;
};

export type ScalarCoder = Record<string, (s: unknown) => string>;

export interface GraphQLResponse {
  data?: Record<string, any>;
  errors?: Array<{
    message: string;
  }>;
}
export class GraphQLError extends Error {
  constructor(public response: GraphQLResponse) {
    super('');
    console.error(response);
  }
  toString() {
    return 'GraphQL Response Error';
  }
}
export type GenericOperation<O> = O extends keyof typeof Ops ? typeof Ops[O] : never;
export type ThunderGraphQLOptions<SCLR extends ScalarDefinition> = {
  scalars?: SCLR | ScalarCoders;
};

const ExtractScalar = (mappedParts: string[], returns: ReturnTypesType): `scalar.${string}` | undefined => {
  if (mappedParts.length === 0) {
    return;
  }
  const oKey = mappedParts[0];
  const returnP1 = returns[oKey];
  if (typeof returnP1 === 'object') {
    const returnP2 = returnP1[mappedParts[1]];
    if (returnP2) {
      return ExtractScalar([returnP2, ...mappedParts.slice(2)], returns);
    }
    return undefined;
  }
  return returnP1 as `scalar.${string}` | undefined;
};

export const PrepareScalarPaths = ({ ops, returns }: { returns: ReturnTypesType; ops: Operations }) => {
  const ibb = (
    k: string,
    originalKey: string,
    o: InputValueType | VType,
    p: string[] = [],
    pOriginals: string[] = [],
    root = true,
  ): { [x: string]: `scalar.${string}` } | undefined => {
    if (!o) {
      return;
    }
    if (typeof o === 'boolean' || typeof o === 'number' || typeof o === 'string') {
      const extractionArray = [...pOriginals, originalKey];
      const isScalar = ExtractScalar(extractionArray, returns);
      if (isScalar?.startsWith('scalar')) {
        const partOfTree = {
          [[...p, k].join(SEPARATOR)]: isScalar,
        };
        return partOfTree;
      }
      return {};
    }
    if (Array.isArray(o)) {
      return ibb(k, k, o[1], p, pOriginals, false);
    }
    if (k === '__alias') {
      return Object.entries(o)
        .map(([alias, objectUnderAlias]) => {
          if (typeof objectUnderAlias !== 'object' || Array.isArray(objectUnderAlias)) {
            throw new Error(
              'Invalid alias it should be __alias:{ YOUR_ALIAS_NAME: { OPERATION_NAME: { ...selectors }}}',
            );
          }
          const operationName = Object.keys(objectUnderAlias)[0];
          const operation = objectUnderAlias[operationName];
          return ibb(alias, operationName, operation, p, pOriginals, false);
        })
        .reduce((a, b) => ({
          ...a,
          ...b,
        }));
    }
    const keyName = root ? ops[k] : k;
    return Object.entries(o)
      .filter(([k]) => k !== '__directives')
      .map(([k, v]) => {
        // Inline fragments shouldn't be added to the path as they aren't a field
        const isInlineFragment = originalKey.match(/^...\s*on/) != null;
        return ibb(
          k,
          k,
          v,
          isInlineFragment ? p : [...p, purifyGraphQLKey(keyName || k)],
          isInlineFragment ? pOriginals : [...pOriginals, purifyGraphQLKey(originalKey)],
          false,
        );
      })
      .reduce((a, b) => ({
        ...a,
        ...b,
      }));
  };
  return ibb;
};

export const purifyGraphQLKey = (k: string) => k.replace(/\([^)]*\)/g, '').replace(/^[^:]*\:/g, '');

const mapPart = (p: string) => {
  const [isArg, isField] = p.split('<>');
  if (isField) {
    return {
      v: isField,
      __type: 'field',
    } as const;
  }
  return {
    v: isArg,
    __type: 'arg',
  } as const;
};

type Part = ReturnType<typeof mapPart>;

export const ResolveFromPath = (props: AllTypesPropsType, returns: ReturnTypesType, ops: Operations) => {
  const ResolvePropsType = (mappedParts: Part[]) => {
    const oKey = ops[mappedParts[0].v];
    const propsP1 = oKey ? props[oKey] : props[mappedParts[0].v];
    if (propsP1 === 'enum' && mappedParts.length === 1) {
      return 'enum';
    }
    if (typeof propsP1 === 'string' && propsP1.startsWith('scalar.') && mappedParts.length === 1) {
      return propsP1;
    }
    if (typeof propsP1 === 'object') {
      if (mappedParts.length < 2) {
        return 'not';
      }
      const propsP2 = propsP1[mappedParts[1].v];
      if (typeof propsP2 === 'string') {
        return rpp(
          `${propsP2}${SEPARATOR}${mappedParts
            .slice(2)
            .map((mp) => mp.v)
            .join(SEPARATOR)}`,
        );
      }
      if (typeof propsP2 === 'object') {
        if (mappedParts.length < 3) {
          return 'not';
        }
        const propsP3 = propsP2[mappedParts[2].v];
        if (propsP3 && mappedParts[2].__type === 'arg') {
          return rpp(
            `${propsP3}${SEPARATOR}${mappedParts
              .slice(3)
              .map((mp) => mp.v)
              .join(SEPARATOR)}`,
          );
        }
      }
    }
  };
  const ResolveReturnType = (mappedParts: Part[]) => {
    if (mappedParts.length === 0) {
      return 'not';
    }
    const oKey = ops[mappedParts[0].v];
    const returnP1 = oKey ? returns[oKey] : returns[mappedParts[0].v];
    if (typeof returnP1 === 'object') {
      if (mappedParts.length < 2) return 'not';
      const returnP2 = returnP1[mappedParts[1].v];
      if (returnP2) {
        return rpp(
          `${returnP2}${SEPARATOR}${mappedParts
            .slice(2)
            .map((mp) => mp.v)
            .join(SEPARATOR)}`,
        );
      }
    }
  };
  const rpp = (path: string): 'enum' | 'not' | `scalar.${string}` => {
    const parts = path.split(SEPARATOR).filter((l) => l.length > 0);
    const mappedParts = parts.map(mapPart);
    const propsP1 = ResolvePropsType(mappedParts);
    if (propsP1) {
      return propsP1;
    }
    const returnP1 = ResolveReturnType(mappedParts);
    if (returnP1) {
      return returnP1;
    }
    return 'not';
  };
  return rpp;
};

export const InternalArgsBuilt = ({
  props,
  ops,
  returns,
  scalars,
  vars,
}: {
  props: AllTypesPropsType;
  returns: ReturnTypesType;
  ops: Operations;
  scalars?: ScalarDefinition;
  vars: Array<{ name: string; graphQLType: string }>;
}) => {
  const arb = (a: ZeusArgsType, p = '', root = true): string => {
    if (typeof a === 'string') {
      if (a.startsWith(START_VAR_NAME)) {
        const [varName, graphQLType] = a.replace(START_VAR_NAME, '$').split(GRAPHQL_TYPE_SEPARATOR);
        const v = vars.find((v) => v.name === varName);
        if (!v) {
          vars.push({
            name: varName,
            graphQLType,
          });
        } else {
          if (v.graphQLType !== graphQLType) {
            throw new Error(
              `Invalid variable exists with two different GraphQL Types, "${v.graphQLType}" and ${graphQLType}`,
            );
          }
        }
        return varName;
      }
    }
    const checkType = ResolveFromPath(props, returns, ops)(p);
    if (checkType.startsWith('scalar.')) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, ...splittedScalar] = checkType.split('.');
      const scalarKey = splittedScalar.join('.');
      return (scalars?.[scalarKey]?.encode?.(a) as string) || JSON.stringify(a);
    }
    if (Array.isArray(a)) {
      return `[${a.map((arr) => arb(arr, p, false)).join(', ')}]`;
    }
    if (typeof a === 'string') {
      if (checkType === 'enum') {
        return a;
      }
      return `${JSON.stringify(a)}`;
    }
    if (typeof a === 'object') {
      if (a === null) {
        return `null`;
      }
      const returnedObjectString = Object.entries(a)
        .filter(([, v]) => typeof v !== 'undefined')
        .map(([k, v]) => `${k}: ${arb(v, [p, k].join(SEPARATOR), false)}`)
        .join(',\n');
      if (!root) {
        return `{${returnedObjectString}}`;
      }
      return returnedObjectString;
    }
    return `${a}`;
  };
  return arb;
};

export const resolverFor = <X, T extends keyof ResolverInputTypes, Z extends keyof ResolverInputTypes[T]>(
  type: T,
  field: Z,
  fn: (
    args: Required<ResolverInputTypes[T]>[Z] extends [infer Input, any] ? Input : any,
    source: any,
  ) => Z extends keyof ModelTypes[T] ? ModelTypes[T][Z] | Promise<ModelTypes[T][Z]> | X : never,
) => fn as (args?: any, source?: any) => ReturnType<typeof fn>;

export type UnwrapPromise<T> = T extends Promise<infer R> ? R : T;
export type ZeusState<T extends (...args: any[]) => Promise<any>> = NonNullable<UnwrapPromise<ReturnType<T>>>;
export type ZeusHook<
  T extends (...args: any[]) => Record<string, (...args: any[]) => Promise<any>>,
  N extends keyof ReturnType<T>,
> = ZeusState<ReturnType<T>[N]>;

export type WithTypeNameValue<T> = T & {
  __typename?: boolean;
  __directives?: string;
};
export type AliasType<T> = WithTypeNameValue<T> & {
  __alias?: Record<string, WithTypeNameValue<T>>;
};
type DeepAnify<T> = {
  [P in keyof T]?: any;
};
type IsPayLoad<T> = T extends [any, infer PayLoad] ? PayLoad : T;
export type ScalarDefinition = Record<string, ScalarResolver>;

type IsScalar<S, SCLR extends ScalarDefinition> = S extends 'scalar' & { name: infer T }
  ? T extends keyof SCLR
    ? SCLR[T]['decode'] extends (s: unknown) => unknown
      ? ReturnType<SCLR[T]['decode']>
      : unknown
    : unknown
  : S;
type IsArray<T, U, SCLR extends ScalarDefinition> = T extends Array<infer R>
  ? InputType<R, U, SCLR>[]
  : InputType<T, U, SCLR>;
type FlattenArray<T> = T extends Array<infer R> ? R : T;
type BaseZeusResolver = boolean | 1 | string | Variable<any, string>;

type IsInterfaced<SRC extends DeepAnify<DST>, DST, SCLR extends ScalarDefinition> = FlattenArray<SRC> extends
  | ZEUS_INTERFACES
  | ZEUS_UNIONS
  ? {
      [P in keyof SRC]: SRC[P] extends '__union' & infer R
        ? P extends keyof DST
          ? IsArray<R, '__typename' extends keyof DST ? DST[P] & { __typename: true } : DST[P], SCLR>
          : IsArray<R, '__typename' extends keyof DST ? { __typename: true } : Record<string, never>, SCLR>
        : never;
    }[keyof SRC] & {
      [P in keyof Omit<
        Pick<
          SRC,
          {
            [P in keyof DST]: SRC[P] extends '__union' & infer R ? never : P;
          }[keyof DST]
        >,
        '__typename'
      >]: IsPayLoad<DST[P]> extends BaseZeusResolver ? IsScalar<SRC[P], SCLR> : IsArray<SRC[P], DST[P], SCLR>;
    }
  : {
      [P in keyof Pick<SRC, keyof DST>]: IsPayLoad<DST[P]> extends BaseZeusResolver
        ? IsScalar<SRC[P], SCLR>
        : IsArray<SRC[P], DST[P], SCLR>;
    };

export type MapType<SRC, DST, SCLR extends ScalarDefinition> = SRC extends DeepAnify<DST>
  ? IsInterfaced<SRC, DST, SCLR>
  : never;
// eslint-disable-next-line @typescript-eslint/ban-types
export type InputType<SRC, DST, SCLR extends ScalarDefinition = {}> = IsPayLoad<DST> extends { __alias: infer R }
  ? {
      [P in keyof R]: MapType<SRC, R[P], SCLR>[keyof MapType<SRC, R[P], SCLR>];
    } & MapType<SRC, Omit<IsPayLoad<DST>, '__alias'>, SCLR>
  : MapType<SRC, IsPayLoad<DST>, SCLR>;
export type SubscriptionToGraphQL<Z, T, SCLR extends ScalarDefinition> = {
  ws: WebSocket;
  on: (fn: (args: InputType<T, Z, SCLR>) => void) => void;
  off: (fn: (e: { data?: InputType<T, Z, SCLR>; code?: number; reason?: string; message?: string }) => void) => void;
  error: (fn: (e: { data?: InputType<T, Z, SCLR>; errors?: string[] }) => void) => void;
  open: () => void;
};

// eslint-disable-next-line @typescript-eslint/ban-types
export type FromSelector<SELECTOR, NAME extends keyof GraphQLTypes, SCLR extends ScalarDefinition = {}> = InputType<
  GraphQLTypes[NAME],
  SELECTOR,
  SCLR
>;

export type ScalarResolver = {
  encode?: (s: unknown) => string;
  decode?: (s: unknown) => unknown;
};

export type SelectionFunction<V> = <Z extends V>(
  t: Z & {
    [P in keyof Z]: P extends keyof V ? Z[P] : never;
  },
) => Z;

type BuiltInVariableTypes = {
  ['String']: string;
  ['Int']: number;
  ['Float']: number;
  ['ID']: unknown;
  ['Boolean']: boolean;
};
type AllVariableTypes = keyof BuiltInVariableTypes | keyof ZEUS_VARIABLES;
type VariableRequired<T extends string> = `${T}!` | T | `[${T}]` | `[${T}]!` | `[${T}!]` | `[${T}!]!`;
type VR<T extends string> = VariableRequired<VariableRequired<T>>;

export type GraphQLVariableType = VR<AllVariableTypes>;

type ExtractVariableTypeString<T extends string> = T extends VR<infer R1>
  ? R1 extends VR<infer R2>
    ? R2 extends VR<infer R3>
      ? R3 extends VR<infer R4>
        ? R4 extends VR<infer R5>
          ? R5
          : R4
        : R3
      : R2
    : R1
  : T;

type DecomposeType<T, Type> = T extends `[${infer R}]`
  ? Array<DecomposeType<R, Type>> | undefined
  : T extends `${infer R}!`
  ? NonNullable<DecomposeType<R, Type>>
  : Type | undefined;

type ExtractTypeFromGraphQLType<T extends string> = T extends keyof ZEUS_VARIABLES
  ? ZEUS_VARIABLES[T]
  : T extends keyof BuiltInVariableTypes
  ? BuiltInVariableTypes[T]
  : any;

export type GetVariableType<T extends string> = DecomposeType<
  T,
  ExtractTypeFromGraphQLType<ExtractVariableTypeString<T>>
>;

type UndefinedKeys<T> = {
  [K in keyof T]-?: T[K] extends NonNullable<T[K]> ? never : K;
}[keyof T];

type WithNullableKeys<T> = Pick<T, UndefinedKeys<T>>;
type WithNonNullableKeys<T> = Omit<T, UndefinedKeys<T>>;

type OptionalKeys<T> = {
  [P in keyof T]?: T[P];
};

export type WithOptionalNullables<T> = OptionalKeys<WithNullableKeys<T>> & WithNonNullableKeys<T>;

export type Variable<T extends GraphQLVariableType, Name extends string> = {
  ' __zeus_name': Name;
  ' __zeus_type': T;
};

export type ExtractVariablesDeep<Query> = Query extends Variable<infer VType, infer VName>
  ? { [key in VName]: GetVariableType<VType> }
  : Query extends string | number | boolean | Array<string | number | boolean>
  ? // eslint-disable-next-line @typescript-eslint/ban-types
    {}
  : UnionToIntersection<{ [K in keyof Query]: WithOptionalNullables<ExtractVariablesDeep<Query[K]>> }[keyof Query]>;

export type ExtractVariables<Query> = Query extends Variable<infer VType, infer VName>
  ? { [key in VName]: GetVariableType<VType> }
  : Query extends [infer Inputs, infer Outputs]
  ? ExtractVariablesDeep<Inputs> & ExtractVariables<Outputs>
  : Query extends string | number | boolean | Array<string | number | boolean>
  ? // eslint-disable-next-line @typescript-eslint/ban-types
    {}
  : UnionToIntersection<{ [K in keyof Query]: WithOptionalNullables<ExtractVariables<Query[K]>> }[keyof Query]>;

type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (k: infer I) => void ? I : never;

export const START_VAR_NAME = `$ZEUS_VAR`;
export const GRAPHQL_TYPE_SEPARATOR = `__$GRAPHQL__`;

export const $ = <Type extends GraphQLVariableType, Name extends string>(name: Name, graphqlType: Type) => {
  return (START_VAR_NAME + name + GRAPHQL_TYPE_SEPARATOR + graphqlType) as unknown as Variable<Type, Name>;
};
type ZEUS_INTERFACES = never
export type ScalarCoders = {
	Upload?: ScalarResolver;
}
type ZEUS_UNIONS = never

export type ValueTypes = {
    ["Order"]:Order;
	/** The `Upload` scalar type represents a file upload. */
["Upload"]:unknown;
	["Combos"]: AliasType<{
	data?:ValueTypes["ComboData"],
		__typename?: boolean | `@${string}`
}>;
	["Cities"]: AliasType<{
	data?:ValueTypes["CityStateData"],
		__typename?: boolean | `@${string}`
}>;
	["AddressInstallation"]: AliasType<{
	data?:ValueTypes["AddressInstallationData"],
		__typename?: boolean | `@${string}`
}>;
	["ComboData"]: AliasType<{
	nationality?:ValueTypes["CityStateData"],
	gender?:ValueTypes["CityStateData"],
	occupation?:ValueTypes["CityStateData"],
	state?:ValueTypes["CityStateData"],
		__typename?: boolean | `@${string}`
}>;
	["CepAddress"]: AliasType<{
	neighborhood?:boolean | `@${string}`,
	street?:boolean | `@${string}`,
	state?:ValueTypes["CityStateData"],
	city?:ValueTypes["CityStateData"],
		__typename?: boolean | `@${string}`
}>;
	["AddressInstallationData"]: AliasType<{
	postal_code?:boolean | `@${string}`,
	street?:boolean | `@${string}`,
	street_number?:boolean | `@${string}`,
	additional_info?:boolean | `@${string}`,
	neighborhood?:boolean | `@${string}`,
	city?:boolean | `@${string}`,
	state?:boolean | `@${string}`,
	rural_area?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CityStateData"]: AliasType<{
	name?:boolean | `@${string}`,
	id?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Query"]: AliasType<{
address_combos?: [{	nationality?: boolean | undefined | null | Variable<any, string>,	gender?: boolean | undefined | null | Variable<any, string>,	occupation?: boolean | undefined | null | Variable<any, string>,	state?: boolean | undefined | null | Variable<any, string>},ValueTypes["Combos"]],
address_cities?: [{	state_id: number | Variable<any, string>},ValueTypes["Cities"]],
address_from_cep?: [{	cep: string | Variable<any, string>},ValueTypes["CepAddress"]],
address_installation?: [{	project_id: string | Variable<any, string>},ValueTypes["AddressInstallation"]],
customer_form?: [{	project_id: string | Variable<any, string>},ValueTypes["CustomerFormResponse"]],
documents?: [{	project_id: string | Variable<any, string>},ValueTypes["DocumentResponse"]],
financing?: [{	id: string | Variable<any, string>},ValueTypes["Financing"]],
financings?: [{	input: ValueTypes["FinancingsInput"] | Variable<any, string>},ValueTypes["FinancingsList"]],
financing_type?: [{	person_type: string | Variable<any, string>},ValueTypes["FinancingType"]],
installation?: [{	projectId: string | Variable<any, string>},ValueTypes["Installation"]],
mirrorStatus?: [{	projectId: string | Variable<any, string>},ValueTypes["MirrorStatus"]],
legacyFinancing?: [{	projectId: string | Variable<any, string>},ValueTypes["LegacyFinancing"]],
partners?: [{	input?: ValueTypes["CoreGetPartnersInput"] | undefined | null | Variable<any, string>},ValueTypes["CorePartner"]],
project?: [{	projectId: string | Variable<any, string>},ValueTypes["Project"]],
project_proposal_changes?: [{	project_id: string | Variable<any, string>},ValueTypes["ProjectProposal"]],
project_is_combo?: [{	project_id: string | Variable<any, string>},ValueTypes["IsComboResponse"]],
	project_system_types?:boolean | `@${string}`,
registration?: [{	project_id: string | Variable<any, string>},ValueTypes["RegistrationDetailsResponse"]],
getOpportunities?: [{	params: ValueTypes["GetOpportunitiesInput"] | Variable<any, string>},ValueTypes["GetOpportunitiesResponse"]],
simulation_project_shopping_cart_id_get?: [{	projectId: string | Variable<any, string>},boolean | `@${string}`],
	me?:ValueTypes["User"],
		__typename?: boolean | `@${string}`
}>;
	["PersonTypes"]:PersonTypes;
	["DocumentTypes"]:DocumentTypes;
	["LegalRepresentantTypes"]:LegalRepresentantTypes;
	["CustomerStatus"]:CustomerStatus;
	["RegistrationContactChangeResponse"]: AliasType<{
	message?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CustomerFormResponse"]: AliasType<{
	project_id?:boolean | `@${string}`,
	is_combo?:boolean | `@${string}`,
	person_type?:boolean | `@${string}`,
	customer?:ValueTypes["CustomerData"],
	guarantor?:ValueTypes["CustomerData"],
	project?:ValueTypes["CustomerProject"],
	remaining_changes_after_confirmation?:boolean | `@${string}`,
	company?:ValueTypes["CompanyData"],
	errors?:ValueTypes["CustomerErrors"],
	status?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CustomerErrors"]: AliasType<{
	field?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CustomerContact"]: AliasType<{
	email?:boolean | `@${string}`,
	phone?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CustomerDocument"]: AliasType<{
	type?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
	filename?:boolean | `@${string}`,
	url_download?:boolean | `@${string}`,
	url_preview?:boolean | `@${string}`,
	mime_type?:boolean | `@${string}`,
	id?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CustomerAddress"]: AliasType<{
	zipcode?:boolean | `@${string}`,
	street?:boolean | `@${string}`,
	street_number?:boolean | `@${string}`,
	complement?:boolean | `@${string}`,
	neighborhood?:boolean | `@${string}`,
	city_id?:boolean | `@${string}`,
	state_id?:boolean | `@${string}`,
	city?:ValueTypes["CustomerIdName"],
	state?:ValueTypes["CustomerIdName"],
	is_rural?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CustomerData"]: AliasType<{
	cpf?:boolean | `@${string}`,
	full_name?:boolean | `@${string}`,
	birthdate?:boolean | `@${string}`,
	contact?:ValueTypes["CustomerContact"],
	documents?:ValueTypes["CustomerDocument"],
	document_type?:boolean | `@${string}`,
	comment?:boolean | `@${string}`,
	address?:ValueTypes["CustomerAddress"],
		__typename?: boolean | `@${string}`
}>;
	["CustomerEletricityBill"]: AliasType<{
	is_under_customer_name?:boolean | `@${string}`,
	holder_cpf?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CustomerProject"]: AliasType<{
	address?:ValueTypes["CustomerAddress"],
	documents?:ValueTypes["CustomerDocument"],
	document_type?:boolean | `@${string}`,
	comment?:boolean | `@${string}`,
	electricity_bill?:ValueTypes["CustomerEletricityBill"],
	residencial_is_the_same_of_plant?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CompanyMajorityPartner"]: AliasType<{
	cpf?:boolean | `@${string}`,
	full_name?:boolean | `@${string}`,
	birthdate?:boolean | `@${string}`,
	contact?:ValueTypes["CustomerContact"],
	documents?:ValueTypes["CustomerDocument"],
	document_type?:boolean | `@${string}`,
	comment?:boolean | `@${string}`,
	address?:ValueTypes["CustomerAddress"],
		__typename?: boolean | `@${string}`
}>;
	["CompanyLegalRepresentant"]: AliasType<{
	kind?:boolean | `@${string}`,
	cpf?:boolean | `@${string}`,
	full_name?:boolean | `@${string}`,
	birthdate?:boolean | `@${string}`,
	contact?:ValueTypes["CustomerContact"],
	documents?:ValueTypes["CustomerDocument"],
	document_type?:boolean | `@${string}`,
	comment?:boolean | `@${string}`,
	address?:ValueTypes["CustomerAddress"],
		__typename?: boolean | `@${string}`
}>;
	["CompanyData"]: AliasType<{
	company_name?:boolean | `@${string}`,
	cnpj?:boolean | `@${string}`,
	contact?:ValueTypes["CustomerContact"],
	documents?:ValueTypes["CustomerDocument"],
	document_type?:boolean | `@${string}`,
	comment?:boolean | `@${string}`,
	majority_partner?:ValueTypes["CompanyMajorityPartner"],
	majority_partner_is_legal_representative?:boolean | `@${string}`,
	is_only_one_legal_representative?:boolean | `@${string}`,
	legal_representatives?:ValueTypes["CompanyLegalRepresentant"],
		__typename?: boolean | `@${string}`
}>;
	["RegistrationV2PersonType"]:RegistrationV2PersonType;
	["CustomerIdName"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CustomerRegistrationAddress"]: {
	zipcode: string | Variable<any, string>,
	street: string | Variable<any, string>,
	street_number: string | Variable<any, string>,
	complement?: string | undefined | null | Variable<any, string>,
	neighborhood: string | Variable<any, string>,
	city_id: number | Variable<any, string>,
	state_id: number | Variable<any, string>,
	is_rural?: boolean | undefined | null | Variable<any, string>
};
	["CustomerRegistrationContact"]: {
	email: string | Variable<any, string>,
	phone: string | Variable<any, string>
};
	["CustomerRegistrationDocument"]: {
	type: string | Variable<any, string>,
	docstorage_id: string | Variable<any, string>
};
	["CustomerRegistrationCustomer"]: {
	full_name: string | Variable<any, string>,
	birthdate: string | Variable<any, string>,
	contact: ValueTypes["CustomerRegistrationContact"] | Variable<any, string>,
	documents: Array<ValueTypes["CustomerRegistrationDocument"]> | Variable<any, string>,
	address: ValueTypes["CustomerRegistrationAddress"] | Variable<any, string>
};
	["CustomerRegistrationGuarantor"]: {
	full_name: string | Variable<any, string>,
	birthdate: string | Variable<any, string>,
	contact: ValueTypes["CustomerRegistrationContact"] | Variable<any, string>,
	documents: Array<ValueTypes["CustomerRegistrationDocument"]> | Variable<any, string>,
	address: ValueTypes["CustomerRegistrationAddress"] | Variable<any, string>
};
	["CustomerRegistrationElectricityBill"]: {
	is_under_customer_name: boolean | Variable<any, string>,
	holder_cpf?: string | undefined | null | Variable<any, string>
};
	["CustomerRegistrationProject"]: {
	address: ValueTypes["CustomerRegistrationAddress"] | Variable<any, string>,
	documents: Array<ValueTypes["CustomerRegistrationDocument"]> | Variable<any, string>,
	comment?: string | undefined | null | Variable<any, string>,
	electricity_bill: ValueTypes["CustomerRegistrationElectricityBill"] | Variable<any, string>
};
	["CustomerRegistrationCompany"]: {
	company_name: string | Variable<any, string>,
	contact: ValueTypes["CustomerRegistrationContact"] | Variable<any, string>,
	documents: Array<ValueTypes["CustomerRegistrationDocument"]> | Variable<any, string>,
	majority_partner: ValueTypes["CustomerRegistrationCustomer"] | Variable<any, string>,
	legal_representatives: Array<ValueTypes["CustomerRegistrationLegalRepresentative"]> | Variable<any, string>
};
	["CustomerRegistrationLegalRepresentative"]: {
	kind: string | Variable<any, string>,
	cpf: string | Variable<any, string>,
	full_name: string | Variable<any, string>,
	birthdate: string | Variable<any, string>,
	contact: ValueTypes["CustomerRegistrationContact"] | Variable<any, string>,
	documents: Array<ValueTypes["CustomerRegistrationDocument"]> | Variable<any, string>,
	address: ValueTypes["CustomerRegistrationAddress"] | Variable<any, string>
};
	["CustomerRegistrationInput"]: {
	project_id: string | Variable<any, string>,
	person_type: ValueTypes["RegistrationV2PersonType"] | Variable<any, string>,
	customer?: ValueTypes["CustomerRegistrationCustomer"] | undefined | null | Variable<any, string>,
	guarantor?: ValueTypes["CustomerRegistrationGuarantor"] | undefined | null | Variable<any, string>,
	project?: ValueTypes["CustomerRegistrationProject"] | undefined | null | Variable<any, string>,
	company?: ValueTypes["CustomerRegistrationCompany"] | undefined | null | Variable<any, string>
};
	["Person"]: {
	email?: string | undefined | null | Variable<any, string>,
	partner_id: string | Variable<any, string>,
	phone?: string | undefined | null | Variable<any, string>
};
	["CustomerDocumentUpload"]: AliasType<{
	id?:boolean | `@${string}`,
	url?:boolean | `@${string}`,
	url_inline?:boolean | `@${string}`,
	filename?:boolean | `@${string}`,
	mimetype?:boolean | `@${string}`,
	size?:boolean | `@${string}`,
	docstorage_id?:boolean | `@${string}`,
	created_at?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CustomerRegistrationError"]: AliasType<{
	field?:boolean | `@${string}`,
	message?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CustomerRegistrationV2Response"]: AliasType<{
	errors?:ValueTypes["CustomerRegistrationError"],
		__typename?: boolean | `@${string}`
}>;
	["CustomerDocumentFormAnalysis"]: AliasType<{
	id?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
	project_id?:boolean | `@${string}`,
	installation_document_matches_bill?:boolean | `@${string}`,
	installation_address_matches_bill?:boolean | `@${string}`,
	identity_matches_document?:boolean | `@${string}`,
	reason?:boolean | `@${string}`,
	comments?:boolean | `@${string}`,
	documents?:ValueTypes["CustomerDocumentUpload"],
		__typename?: boolean | `@${string}`
}>;
	["PersonInput"]: {
	email: string | Variable<any, string>,
	phone: string | Variable<any, string>
};
	["RegistrationContactChangeInput"]: {
	project_id: string | Variable<any, string>,
	customer?: ValueTypes["PersonInput"] | undefined | null | Variable<any, string>,
	guarantor?: ValueTypes["PersonInput"] | undefined | null | Variable<any, string>,
	majority_partner?: ValueTypes["PersonInput"] | undefined | null | Variable<any, string>,
	legal_representative_1?: ValueTypes["PersonInput"] | undefined | null | Variable<any, string>,
	legal_representative_2?: ValueTypes["PersonInput"] | undefined | null | Variable<any, string>
};
	["Mutation"]: AliasType<{
registration_contact_change?: [{	input: ValueTypes["RegistrationContactChangeInput"] | Variable<any, string>},ValueTypes["RegistrationContactChangeResponse"]],
customer_document_upload?: [{	documents: Array<ValueTypes["Upload"]> | Variable<any, string>},ValueTypes["CustomerDocumentUpload"]],
customer_document_form_analysis_cancel?: [{	project_id: string | Variable<any, string>,	step: string | Variable<any, string>},ValueTypes["CustomerDocumentFormAnalysis"]],
customer_registration?: [{	params: ValueTypes["CustomerRegistrationInput"] | Variable<any, string>},ValueTypes["CustomerRegistrationV2Response"]],
validateHardwareForm?: [{	hardwareFormInput: ValueTypes["HardwareFormInput"] | Variable<any, string>},ValueTypes["HardwareFormValidation"]],
hardware_form_showcase?: [{	hardwareFormShowcaseInput: ValueTypes["HardwareFormShowcaseInput"] | Variable<any, string>},ValueTypes["HardwareFormShowcaseResponse"]],
hardware_form_draft?: [{	hardwareFormDraftInput: ValueTypes["HardwareDraft"] | Variable<any, string>},boolean | `@${string}`],
installation_mirror_upload?: [{	projectId: string | Variable<any, string>,	mirror: ValueTypes["Upload"] | Variable<any, string>},ValueTypes["MirrorUploadResponse"]],
project_update_shopping_cart?: [{	project_id: string | Variable<any, string>,	shopping_cart_id: string | Variable<any, string>},boolean | `@${string}`],
project_proposal_update?: [{	project_id: string | Variable<any, string>,	payload: ValueTypes["ProjectProposalUpdatePayloadInput"] | Variable<any, string>},boolean | `@${string}`],
registration?: [{	params: ValueTypes["RegistrationUpdateInput"] | Variable<any, string>},boolean | `@${string}`],
processEnergyAccountAnalysis?: [{	params: ValueTypes["AnalyzeEnergyBillInput"] | Variable<any, string>},ValueTypes["EnergyBillAnalysisDecision"]],
		__typename?: boolean | `@${string}`
}>;
	["DocumentResponse"]: AliasType<{
	data?:ValueTypes["DocumentData"],
		__typename?: boolean | `@${string}`
}>;
	["DocumentData"]: AliasType<{
	project?:ValueTypes["Documents"],
	customer?:ValueTypes["Documents"],
	guarantor?:ValueTypes["DocumentFile"],
	company?:ValueTypes["DocumentFile"],
		__typename?: boolean | `@${string}`
}>;
	["Documents"]: AliasType<{
	id?:boolean | `@${string}`,
	docstorage_id?:boolean | `@${string}`,
	kind?:boolean | `@${string}`,
	validated_at?:boolean | `@${string}`,
	validation_mode?:boolean | `@${string}`,
	deleted_at?:boolean | `@${string}`,
	submitted_at?:boolean | `@${string}`,
	canceled_at?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
	reason?:ValueTypes["DocumentReason"],
	file?:ValueTypes["DocumentFile"],
		__typename?: boolean | `@${string}`
}>;
	["DocumentFile"]: AliasType<{
	public_id?:boolean | `@${string}`,
	filename?:boolean | `@${string}`,
	mimetype?:boolean | `@${string}`,
	size?:boolean | `@${string}`,
	url?:boolean | `@${string}`,
	url_inline?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DocumentReason"]: AliasType<{
	title?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PersonType"]:PersonType;
	["FinancingStatus"]:FinancingStatus;
	["SectionStatus"]:SectionStatus;
	["FormalizationStatus"]: AliasType<{
	contract?:boolean | `@${string}`,
	hardware?:boolean | `@${string}`,
	registration?:boolean | `@${string}`,
	documentation?:boolean | `@${string}`,
	receipt_model?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SectionStatuses"]: AliasType<{
	concluded?:boolean | `@${string}`,
	simulation?:boolean | `@${string}`,
	installation?:boolean | `@${string}`,
	formalization?:ValueTypes["FormalizationStatus"],
		__typename?: boolean | `@${string}`
}>;
	["FinancingSteps"]:FinancingSteps;
	["Payment"]: AliasType<{
	id?:boolean | `@${string}`,
	financing_id?:boolean | `@${string}`,
	value?:boolean | `@${string}`,
	total_value?:boolean | `@${string}`,
	payment_percentage?:boolean | `@${string}`,
	created_at?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Financing"]: AliasType<{
	id?:boolean | `@${string}`,
	project_id?:boolean | `@${string}`,
	financed_value?:boolean | `@${string}`,
	customer_name?:boolean | `@${string}`,
	document?:boolean | `@${string}`,
	expires_at?:boolean | `@${string}`,
	created_at?:boolean | `@${string}`,
	formalized_at?:boolean | `@${string}`,
	installed_at?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
	step?:boolean | `@${string}`,
	is_combo?:boolean | `@${string}`,
	payments?:ValueTypes["Payment"],
	flow_provider?:boolean | `@${string}`,
	person_type?:boolean | `@${string}`,
	all_payments_sent?:boolean | `@${string}`,
	section_statuses?:ValueTypes["FinancingSectionStatusV2"],
	updated_at?:boolean | `@${string}`,
	is_expired?:boolean | `@${string}`,
	system_power?:boolean | `@${string}`,
	new_journey?:boolean | `@${string}`,
	legacy_id?:boolean | `@${string}`,
	reason?:boolean | `@${string}`,
	openable_steps?:ValueTypes["FormalizationStatuses"],
	is_aproved?:boolean | `@${string}`,
	is_final?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["FormalizationStatuses"]: AliasType<{
	registration?:boolean | `@${string}`,
	documentation?:boolean | `@${string}`,
	hardware?:boolean | `@${string}`,
	contract?:boolean | `@${string}`,
	receiptModel?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["FinancingListTotalByStep"]: AliasType<{
	simulation?:boolean | `@${string}`,
	formalization?:boolean | `@${string}`,
	installation?:boolean | `@${string}`,
	concluded?:boolean | `@${string}`,
	expired?:boolean | `@${string}`,
	proposal?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["FinancingListTotalByStatus"]: AliasType<{
	expired?:boolean | `@${string}`,
	reproved?:boolean | `@${string}`,
	approved?:boolean | `@${string}`,
	under_analysis?:boolean | `@${string}`,
	canceled?:boolean | `@${string}`,
	to_review?:boolean | `@${string}`,
	in_progress?:boolean | `@${string}`,
	waiting_customer?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["FinancingProposal"]: AliasType<{
	customer_data?:boolean | `@${string}`,
	equipment?:boolean | `@${string}`,
	biometry_and_contract?:boolean | `@${string}`,
	payment_model?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["FinancingFormalization"]: AliasType<{
	contract?:boolean | `@${string}`,
	hardware?:boolean | `@${string}`,
	registration?:boolean | `@${string}`,
	documentation?:boolean | `@${string}`,
	receipt_model?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["FinancingSectionStatusV2"]: AliasType<{
	concluded?:boolean | `@${string}`,
	simulation?:boolean | `@${string}`,
	installation?:boolean | `@${string}`,
	proposal?:ValueTypes["FinancingProposal"],
	formalization?:ValueTypes["FinancingFormalization"],
		__typename?: boolean | `@${string}`
}>;
	["FinancingsList"]: AliasType<{
	data?:ValueTypes["Financing"],
	total?:boolean | `@${string}`,
	total_by_step?:ValueTypes["FinancingListTotalByStep"],
	total_by_status?:ValueTypes["FinancingListTotalByStatus"],
		__typename?: boolean | `@${string}`
}>;
	["FinancingsInput"]: {
	query?: string | undefined | null | Variable<any, string>,
	page?: number | undefined | null | Variable<any, string>,
	partner_id?: string | undefined | null | Variable<any, string>,
	steps?: Array<ValueTypes["FinancingSteps"]> | undefined | null | Variable<any, string>,
	status?: Array<ValueTypes["FinancingStatus"]> | undefined | null | Variable<any, string>,
	is_combo?: string | undefined | null | Variable<any, string>,
	page_size?: number | undefined | null | Variable<any, string>,
	order_by?: string | undefined | null | Variable<any, string>
};
	["FinancingType"]: AliasType<{
	max_financing_value?:boolean | `@${string}`,
	min_financing_value?:boolean | `@${string}`,
	max_financing_installments?:boolean | `@${string}`,
	grace_period_financing?:ValueTypes["GracePeriod"],
	max_bnpl_value?:boolean | `@${string}`,
	max_bnpl_installments?:boolean | `@${string}`,
	grace_period_bnpl?:ValueTypes["GracePeriod"],
		__typename?: boolean | `@${string}`
}>;
	["GracePeriod"]: AliasType<{
	label?:boolean | `@${string}`,
	value?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["FileStatusType"]:FileStatusType;
	["DocumentStatusType"]:DocumentStatusType;
	["TypeSection"]:TypeSection;
	["MirrorStatuses"]:MirrorStatuses;
	["Status"]: AliasType<{
	description?:boolean | `@${string}`,
	id?:boolean | `@${string}`,
	slug?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Document"]: AliasType<{
	filename?:boolean | `@${string}`,
	filetype?:boolean | `@${string}`,
	id?:boolean | `@${string}`,
	status?:ValueTypes["Status"],
	type?:boolean | `@${string}`,
	url?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Section"]: AliasType<{
	documents?:ValueTypes["Document"],
	id?:boolean | `@${string}`,
	status?:ValueTypes["Status"],
	type?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InstallationStatus"]: AliasType<{
	description?:boolean | `@${string}`,
	id?:boolean | `@${string}`,
	slug?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Installation"]: AliasType<{
	comment?:boolean | `@${string}`,
	concluded_at?:boolean | `@${string}`,
	grade?:boolean | `@${string}`,
	has_ampera?:boolean | `@${string}`,
	has_ampera_activated?:boolean | `@${string}`,
	has_minimal_projects?:boolean | `@${string}`,
	id?:boolean | `@${string}`,
	new_journey?:boolean | `@${string}`,
	plant_id?:boolean | `@${string}`,
	plant_url?:boolean | `@${string}`,
	project_id?:boolean | `@${string}`,
	project_name?:boolean | `@${string}`,
	sections?:ValueTypes["Section"],
	status?:ValueTypes["InstallationStatus"],
		__typename?: boolean | `@${string}`
}>;
	["HardwareFormInverter"]: {
	id: number | Variable<any, string>,
	quantity: number | Variable<any, string>,
	power?: number | undefined | null | Variable<any, string>
};
	["HardwareFormModule"]: {
	id: number | Variable<any, string>,
	quantity: number | Variable<any, string>,
	power?: number | undefined | null | Variable<any, string>
};
	["HardwareFormComboEquipment"]: {
	sku: string | Variable<any, string>,
	power?: number | undefined | null | Variable<any, string>,
	quantity: number | Variable<any, string>
};
	["HardwareFormBatteries"]: {
	id: number | Variable<any, string>,
	quantity: number | Variable<any, string>,
	power?: number | undefined | null | Variable<any, string>
};
	["HardwareFormComboMetadata"]: {
	project_id: string | Variable<any, string>,
	sku?: string | undefined | null | Variable<any, string>,
	power?: number | undefined | null | Variable<any, string>,
	price?: number | undefined | null | Variable<any, string>,
	inverters: Array<ValueTypes["HardwareFormComboEquipment"]> | Variable<any, string>,
	modules: Array<ValueTypes["HardwareFormComboEquipment"]> | Variable<any, string>
};
	["HardwareFormInput"]: {
	is_combo: boolean | Variable<any, string>,
	id?: string | undefined | null | Variable<any, string>,
	combo_price?: number | undefined | null | Variable<any, string>,
	inverter_type?: string | undefined | null | Variable<any, string>,
	project_id: string | Variable<any, string>,
	distributor_id: number | Variable<any, string>,
	phase_id: number | Variable<any, string>,
	inverters: Array<ValueTypes["HardwareFormInverter"]> | Variable<any, string>,
	modules: Array<ValueTypes["HardwareFormModule"]> | Variable<any, string>,
	batteries?: Array<ValueTypes["HardwareFormBatteries"]> | undefined | null | Variable<any, string>,
	combo_metadata?: ValueTypes["HardwareFormComboMetadata"] | undefined | null | Variable<any, string>,
	is_showcase?: boolean | undefined | null | Variable<any, string>,
	shopping_cart_id?: string | undefined | null | Variable<any, string>
};
	["HardwareFormShowcaseInput"]: {
	price?: number | undefined | null | Variable<any, string>,
	project_id: string | Variable<any, string>,
	inverters: Array<ValueTypes["HardwareFormInverter"]> | Variable<any, string>,
	modules: Array<ValueTypes["HardwareFormModule"]> | Variable<any, string>,
	shopping_cart_id?: string | undefined | null | Variable<any, string>
};
	["ValidateHardwareFormPricePerWatt"]: AliasType<{
	is_valid?:boolean | `@${string}`,
	is_under_expected?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ValidateHardwareFormModules"]: AliasType<{
	price_per_watt?:ValueTypes["ValidateHardwareFormPricePerWatt"],
		__typename?: boolean | `@${string}`
}>;
	["ValidateHardwareFormOverload"]: AliasType<{
	is_valid?:boolean | `@${string}`,
	is_under_expected?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ValidateHardwareFormInverters"]: AliasType<{
	overload?:ValueTypes["ValidateHardwareFormOverload"],
		__typename?: boolean | `@${string}`
}>;
	["ValidateHardwareFormHardware"]: AliasType<{
	modules?:ValueTypes["ValidateHardwareFormModules"],
	inverters?:ValueTypes["ValidateHardwareFormInverters"],
		__typename?: boolean | `@${string}`
}>;
	["HardwareFormValidation"]: AliasType<{
	is_valid?:boolean | `@${string}`,
	hardware?:ValueTypes["ValidateHardwareFormHardware"],
		__typename?: boolean | `@${string}`
}>;
	["HardwareFormShowcaseResponse"]: AliasType<{
	overload_is_valid?:boolean | `@${string}`,
	price_per_watt_is_valid?:boolean | `@${string}`,
	percentage_of_service_with_combo_is_valid?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MirrorStatus"]: AliasType<{
	status?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MirrorUploadResponse"]: AliasType<{
	id?:boolean | `@${string}`,
	url?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HardwareDraftEquipment"]: {
	sku: string | Variable<any, string>,
	quantity: number | Variable<any, string>,
	power?: number | undefined | null | Variable<any, string>,
	model: string | Variable<any, string>,
	brand: string | Variable<any, string>
};
	["HardwareDraft"]: {
	project_id: string | Variable<any, string>,
	sku?: string | undefined | null | Variable<any, string>,
	power?: number | undefined | null | Variable<any, string>,
	price?: number | undefined | null | Variable<any, string>,
	inverters: Array<ValueTypes["HardwareDraftEquipment"]> | Variable<any, string>,
	modules: Array<ValueTypes["HardwareDraftEquipment"]> | Variable<any, string>
};
	["LegacyFinancing"]: AliasType<{
	projectId?:boolean | `@${string}`,
	financingId?:boolean | `@${string}`,
	formalizedAt?:boolean | `@${string}`,
	stage?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CorePartner"]: AliasType<{
	id?:boolean | `@${string}`,
	cnpj?:boolean | `@${string}`,
	responsible?:ValueTypes["CoreParterResponsible"],
	company_name?:boolean | `@${string}`,
	fantasy_name?:boolean | `@${string}`,
	status?:ValueTypes["CoreParterStatus"],
		__typename?: boolean | `@${string}`
}>;
	["CoreParterResponsible"]: AliasType<{
	id?:boolean | `@${string}`,
	full_name?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CoreParterStatus"]: AliasType<{
	active?:boolean | `@${string}`,
	reason?:boolean | `@${string}`,
	date?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CoreGetPartnersInput"]: {
	page?: number | undefined | null | Variable<any, string>,
	search_term?: string | undefined | null | Variable<any, string>
};
	["SystemTypes"]:SystemTypes;
	["ProjectProposal"]: AliasType<{
	value_changed?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AppliedModifierOption"]: AliasType<{
	type?:boolean | `@${string}`,
	value?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AppliedModifier"]: AliasType<{
	name?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	options?:ValueTypes["AppliedModifierOption"],
		__typename?: boolean | `@${string}`
}>;
	["AddonProduct"]: AliasType<{
	id?:boolean | `@${string}`,
	product_name?:boolean | `@${string}`,
	product_external_id?:boolean | `@${string}`,
	simulation_id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	product_slug?:boolean | `@${string}`,
	applied?:boolean | `@${string}`,
	partner_commission?:boolean | `@${string}`,
	installment_price?:boolean | `@${string}`,
	total_price?:boolean | `@${string}`,
	monthly_interest_rate?:boolean | `@${string}`,
	engine_simulation_id?:boolean | `@${string}`,
	previous_engine_simulation_id?:boolean | `@${string}`,
	cet?:boolean | `@${string}`,
	applied_modifiers?:ValueTypes["AppliedModifier"],
	addon_type?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SelectedInstallments"]: AliasType<{
	cet?:boolean | `@${string}`,
	iof?:boolean | `@${string}`,
	ipca?:boolean | `@${string}`,
	addons?:ValueTypes["AddonProduct"],
	aggregated_addons?:ValueTypes["AddonProduct"],
	user_id?:boolean | `@${string}`,
	document?:boolean | `@${string}`,
	is_combo?:boolean | `@${string}`,
	commission?:boolean | `@${string}`,
	partner_id?:boolean | `@${string}`,
	aliquot_iof?:boolean | `@${string}`,
	new_journey?:boolean | `@${string}`,
	person_type?:boolean | `@${string}`,
	down_payment?:boolean | `@${string}`,
	grace_period?:boolean | `@${string}`,
	installments?:boolean | `@${string}`,
	project_name?:boolean | `@${string}`,
	system_power?:boolean | `@${string}`,
	interest_rate?:boolean | `@${string}`,
	cet_percentage?:boolean | `@${string}`,
	commission_raw?:boolean | `@${string}`,
	financing_value?:boolean | `@${string}`,
	taxa_de_cadastro?:boolean | `@${string}`,
	installment_value?:boolean | `@${string}`,
	is_from_suggested?:boolean | `@${string}`,
	registration_fee_gross?:boolean | `@${string}`,
	registration_fee_raw_dry?:boolean | `@${string}`,
	registration_fee_raw_final?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RiskInfo"]: AliasType<{
	title?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	todo?:boolean | `@${string}`,
	reason?:boolean | `@${string}`,
	decision?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BNPL"]: AliasType<{
	decision_pre_analysis?:boolean | `@${string}`,
	min_entry_value?:boolean | `@${string}`,
	max_installments?:boolean | `@${string}`,
	pre_approved_value?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Guarantor"]: AliasType<{
	id?:boolean | `@${string}`,
	document?:boolean | `@${string}`,
	birth_date?:boolean | `@${string}`,
	border_line?:boolean | `@${string}`,
	created_at?:boolean | `@${string}`,
	customer_second_financing_policy?:boolean | `@${string}`,
	decision_pre_analysis?:boolean | `@${string}`,
	max_approved_project_value?:boolean | `@${string}`,
	max_financing_value?:boolean | `@${string}`,
	max_grace_period?:boolean | `@${string}`,
	max_installments?:boolean | `@${string}`,
	min_approved_project_value?:boolean | `@${string}`,
	min_installment_value?:boolean | `@${string}`,
	pre_approved_value?:boolean | `@${string}`,
	project_name?:boolean | `@${string}`,
	project_state?:boolean | `@${string}`,
	risk_class?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Project"]: AliasType<{
	id?:boolean | `@${string}`,
	user_id?:boolean | `@${string}`,
	partner_id?:boolean | `@${string}`,
	person_type?:boolean | `@${string}`,
	document?:boolean | `@${string}`,
	created_at?:boolean | `@${string}`,
	representative_document?:boolean | `@${string}`,
	project_value?:boolean | `@${string}`,
	system_power?:boolean | `@${string}`,
	grace_period?:boolean | `@${string}`,
	down_payment?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
	project_status?:boolean | `@${string}`,
	max_installments?:boolean | `@${string}`,
	max_grace_period?:boolean | `@${string}`,
	project_name?:boolean | `@${string}`,
	project_state?:boolean | `@${string}`,
	partner_profile?:boolean | `@${string}`,
	pre_approved_value?:boolean | `@${string}`,
	products_applieds?:boolean | `@${string}`,
	installments?:boolean | `@${string}`,
	risk_info?:ValueTypes["RiskInfo"],
	state_registration?:boolean | `@${string}`,
	complete?:boolean | `@${string}`,
	selected_installments?:ValueTypes["SelectedInstallments"],
	guarantor?:ValueTypes["Guarantor"],
	birth_date?:boolean | `@${string}`,
	decision_pre_analysis?:boolean | `@${string}`,
	score_sol_facil?:boolean | `@${string}`,
	simulation_id?:boolean | `@${string}`,
	addons?:ValueTypes["AddonProduct"],
	max_approved_project_value?:boolean | `@${string}`,
	min_approved_project_value?:boolean | `@${string}`,
	max_financing_value?:boolean | `@${string}`,
	border_line?:boolean | `@${string}`,
	is_formalized?:boolean | `@${string}`,
	new_journey?:boolean | `@${string}`,
	formalized_at?:boolean | `@${string}`,
	shopping_cart_id?:boolean | `@${string}`,
	bnpl?:ValueTypes["BNPL"],
	bnpl_simulation_id?:boolean | `@${string}`,
	bnpl_status?:boolean | `@${string}`,
	is_onflex_partner?:boolean | `@${string}`,
	can_select_ampera?:boolean | `@${string}`,
	has_energy_bill_analysis?:boolean | `@${string}`,
	insurance_commission?:boolean | `@${string}`,
	gained_points?:boolean | `@${string}`,
	original_installment_value?:boolean | `@${string}`,
	is_contract_signed?:boolean | `@${string}`,
	commission?:boolean | `@${string}`,
	risk_class?:boolean | `@${string}`,
	selected_installment?:boolean | `@${string}`,
	credit_analysis_status?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProjectProposalUpdatePayloadInput"]: {
	engine_simulation_id: string | Variable<any, string>,
	simulation_id: string | Variable<any, string>,
	is_combo: boolean | Variable<any, string>,
	is_ampera: boolean | Variable<any, string>,
	commission: number | Variable<any, string>,
	applied_addons: Array<string> | Variable<any, string>
};
	["ProjectProposalUpdateResponse"]: AliasType<{
	engine_simulation_id?:boolean | `@${string}`,
	simulation_id?:boolean | `@${string}`,
	project_id?:boolean | `@${string}`,
	is_combo?:boolean | `@${string}`,
	is_ampera?:boolean | `@${string}`,
	commission?:boolean | `@${string}`,
	applied_addons?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["IsComboResponse"]: AliasType<{
	data?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RegistrationPersonType"]:RegistrationPersonType;
	["RegistrationDocumentTypes"]:RegistrationDocumentTypes;
	["RegistrationRepresentantTypes"]:RegistrationRepresentantTypes;
	["RegistrationContact"]: AliasType<{
	email?:boolean | `@${string}`,
	phone?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RegistrationAddressCity"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RegistrationAddressState"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RegistrationDocumentModel"]:RegistrationDocumentModel;
	["RegistrationElectricityBill"]: AliasType<{
	holder_cpf?:boolean | `@${string}`,
	is_under_customer_name?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RegistrationDocument"]: AliasType<{
	type?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
	filename?:boolean | `@${string}`,
	url_download?:boolean | `@${string}`,
	url_preview?:boolean | `@${string}`,
	mime_type?:boolean | `@${string}`,
	docstorage_id?:boolean | `@${string}`,
	id?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RegistrationAddress"]: AliasType<{
	zipcode?:boolean | `@${string}`,
	street?:boolean | `@${string}`,
	street_number?:boolean | `@${string}`,
	complement?:boolean | `@${string}`,
	neighborhood?:boolean | `@${string}`,
	city_id?:boolean | `@${string}`,
	state_id?:boolean | `@${string}`,
	city?:ValueTypes["RegistrationAddressCity"],
	state?:ValueTypes["RegistrationAddressState"],
	is_rural?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RegistrationProject"]: AliasType<{
	address?:ValueTypes["RegistrationAddress"],
	documents?:ValueTypes["RegistrationDocument"],
	electricity_bill?:ValueTypes["RegistrationElectricityBill"],
	residencial_is_the_same_of_plant?:boolean | `@${string}`,
	comment?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RegistrationCustomer"]: AliasType<{
	cpf?:boolean | `@${string}`,
	full_name?:boolean | `@${string}`,
	birthdate?:boolean | `@${string}`,
	contact?:ValueTypes["RegistrationContact"],
	documents?:ValueTypes["RegistrationDocument"],
	document_type?:boolean | `@${string}`,
	address?:ValueTypes["RegistrationAddress"],
		__typename?: boolean | `@${string}`
}>;
	["RegistrationCompany"]: AliasType<{
	company_name?:boolean | `@${string}`,
	cnpj?:boolean | `@${string}`,
	contact?:ValueTypes["RegistrationContact"],
	documents?:ValueTypes["RegistrationDocument"],
	majority_partner?:ValueTypes["RegistrationCompanyMajorityPartner"],
	legal_representatives?:ValueTypes["RegistrationLegalRepresentative"],
	majority_partner_is_legal_representative?:boolean | `@${string}`,
	is_only_one_legal_representative?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RegistrationLegalRepresentative"]: AliasType<{
	cpf?:boolean | `@${string}`,
	full_name?:boolean | `@${string}`,
	birthdate?:boolean | `@${string}`,
	contact?:ValueTypes["RegistrationContact"],
	documents?:ValueTypes["RegistrationDocument"],
	address?:ValueTypes["RegistrationAddress"],
	kind?:boolean | `@${string}`,
	document_type?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RegistrationCompanyMajorityPartner"]: AliasType<{
	cpf?:boolean | `@${string}`,
	is_legal_representative?:boolean | `@${string}`,
	full_name?:boolean | `@${string}`,
	birthdate?:boolean | `@${string}`,
	contact?:ValueTypes["RegistrationContact"],
	documents?:ValueTypes["RegistrationDocument"],
	address?:ValueTypes["RegistrationAddress"],
	document_type?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RegistrationGuarantor"]: AliasType<{
	cpf?:boolean | `@${string}`,
	full_name?:boolean | `@${string}`,
	birthdate?:boolean | `@${string}`,
	contact?:ValueTypes["RegistrationContact"],
	documents?:ValueTypes["RegistrationDocument"],
	address?:ValueTypes["RegistrationAddress"],
	document_type?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RegistrationError"]: AliasType<{
	title?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	field?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RegistrationUpdateInput"]: {
	project_id: string | Variable<any, string>
};
	["RegistrationDetailsResponse"]: AliasType<{
	project_id?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
	is_combo?:boolean | `@${string}`,
	remaining_changes_after_confirmation?:boolean | `@${string}`,
	person_type?:boolean | `@${string}`,
	customer?:ValueTypes["RegistrationCustomer"],
	company?:ValueTypes["RegistrationCompany"],
	guarantor?:ValueTypes["RegistrationGuarantor"],
	project?:ValueTypes["RegistrationProject"],
	errors?:ValueTypes["RegistrationError"],
		__typename?: boolean | `@${string}`
}>;
	["AnalyzeEnergyBillInput"]: {
	person_type: string | Variable<any, string>,
	project_id: string | Variable<any, string>,
	document_customer: string | Variable<any, string>,
	financing_id: number | Variable<any, string>,
	document_owner_energy_bill: string | Variable<any, string>,
	auto_cancel_flow_on_rejection: boolean | Variable<any, string>
};
	["GetOpportunitiesInput"]: {
	financing_value: number | Variable<any, string>,
	partner_id: number | Variable<any, string>,
	project_type: string | Variable<any, string>
};
	["EnergyBillAnalysisDecision"]: AliasType<{
	decision?:boolean | `@${string}`,
	details?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["GetOpportunitiesResponse"]: AliasType<{
	addons_opportunities?:ValueTypes["AddonsOpportunities"],
	financing_gained_points?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AddonsOpportunities"]: AliasType<{
	addon_type?:boolean | `@${string}`,
	gained_points?:boolean | `@${string}`,
	product_slug?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LevelBlackStatus"]: AliasType<{
	kwGoalMonth?:boolean | `@${string}`,
	kwReachedMonth?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Contact"]: AliasType<{
	landline?:boolean | `@${string}`,
	mobile?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Address"]: AliasType<{
	street?:boolean | `@${string}`,
	number?:boolean | `@${string}`,
	complement?:boolean | `@${string}`,
	reference?:boolean | `@${string}`,
	district?:boolean | `@${string}`,
	zipcode?:boolean | `@${string}`,
	city?:boolean | `@${string}`,
	state?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Responsible"]: AliasType<{
	id?:boolean | `@${string}`,
	username?:boolean | `@${string}`,
	profile?:boolean | `@${string}`,
	fullname?:boolean | `@${string}`,
	document?:boolean | `@${string}`,
	birthdate?:boolean | `@${string}`,
	active?:boolean | `@${string}`,
	created_at?:boolean | `@${string}`,
	contact?:ValueTypes["Contact"],
	partner?:ValueTypes["Partner"],
		__typename?: boolean | `@${string}`
}>;
	["Permission"]: AliasType<{
	store?:boolean | `@${string}`,
	financing?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Score"]: AliasType<{
	current?:boolean | `@${string}`,
	goal?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Downgrade"]: AliasType<{
	has_risk?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Sfplus"]: AliasType<{
	level?:boolean | `@${string}`,
	score?:ValueTypes["Score"],
	black_status?:ValueTypes["LevelBlackStatus"],
	downgrade?:ValueTypes["Downgrade"],
	is_level_black?:boolean | `@${string}`,
	next_transition_date?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Partner"]: AliasType<{
	id?:boolean | `@${string}`,
	corporate_name?:boolean | `@${string}`,
	operation_term_accepted?:boolean | `@${string}`,
	trade_name?:boolean | `@${string}`,
	document?:boolean | `@${string}`,
	category?:boolean | `@${string}`,
	state_registration?:boolean | `@${string}`,
	active?:boolean | `@${string}`,
	created_at?:boolean | `@${string}`,
	address?:ValueTypes["Address"],
	responsible?:ValueTypes["Responsible"],
	permission?:ValueTypes["Permission"],
	users?:ValueTypes["User"],
	sfplus?:ValueTypes["Sfplus"],
		__typename?: boolean | `@${string}`
}>;
	["Post"]: AliasType<{
	id?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	body?:boolean | `@${string}`,
	userId?:boolean | `@${string}`,
	comments?:ValueTypes["Comment"],
		__typename?: boolean | `@${string}`
}>;
	["Comment"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
	body?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["User"]: AliasType<{
	id?:boolean | `@${string}`,
	username?:boolean | `@${string}`,
	profile?:boolean | `@${string}`,
	fullname?:boolean | `@${string}`,
	document?:boolean | `@${string}`,
	birthdate?:boolean | `@${string}`,
	active?:boolean | `@${string}`,
	created_at?:boolean | `@${string}`,
	contact?:ValueTypes["Contact"],
	partner?:ValueTypes["Partner"],
	posts?:ValueTypes["Post"],
		__typename?: boolean | `@${string}`
}>
  }

export type ResolverInputTypes = {
    ["Order"]:Order;
	/** The `Upload` scalar type represents a file upload. */
["Upload"]:unknown;
	["Combos"]: AliasType<{
	data?:ResolverInputTypes["ComboData"],
		__typename?: boolean | `@${string}`
}>;
	["Cities"]: AliasType<{
	data?:ResolverInputTypes["CityStateData"],
		__typename?: boolean | `@${string}`
}>;
	["AddressInstallation"]: AliasType<{
	data?:ResolverInputTypes["AddressInstallationData"],
		__typename?: boolean | `@${string}`
}>;
	["ComboData"]: AliasType<{
	nationality?:ResolverInputTypes["CityStateData"],
	gender?:ResolverInputTypes["CityStateData"],
	occupation?:ResolverInputTypes["CityStateData"],
	state?:ResolverInputTypes["CityStateData"],
		__typename?: boolean | `@${string}`
}>;
	["CepAddress"]: AliasType<{
	neighborhood?:boolean | `@${string}`,
	street?:boolean | `@${string}`,
	state?:ResolverInputTypes["CityStateData"],
	city?:ResolverInputTypes["CityStateData"],
		__typename?: boolean | `@${string}`
}>;
	["AddressInstallationData"]: AliasType<{
	postal_code?:boolean | `@${string}`,
	street?:boolean | `@${string}`,
	street_number?:boolean | `@${string}`,
	additional_info?:boolean | `@${string}`,
	neighborhood?:boolean | `@${string}`,
	city?:boolean | `@${string}`,
	state?:boolean | `@${string}`,
	rural_area?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CityStateData"]: AliasType<{
	name?:boolean | `@${string}`,
	id?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Query"]: AliasType<{
address_combos?: [{	nationality?: boolean | undefined | null,	gender?: boolean | undefined | null,	occupation?: boolean | undefined | null,	state?: boolean | undefined | null},ResolverInputTypes["Combos"]],
address_cities?: [{	state_id: number},ResolverInputTypes["Cities"]],
address_from_cep?: [{	cep: string},ResolverInputTypes["CepAddress"]],
address_installation?: [{	project_id: string},ResolverInputTypes["AddressInstallation"]],
customer_form?: [{	project_id: string},ResolverInputTypes["CustomerFormResponse"]],
documents?: [{	project_id: string},ResolverInputTypes["DocumentResponse"]],
financing?: [{	id: string},ResolverInputTypes["Financing"]],
financings?: [{	input: ResolverInputTypes["FinancingsInput"]},ResolverInputTypes["FinancingsList"]],
financing_type?: [{	person_type: string},ResolverInputTypes["FinancingType"]],
installation?: [{	projectId: string},ResolverInputTypes["Installation"]],
mirrorStatus?: [{	projectId: string},ResolverInputTypes["MirrorStatus"]],
legacyFinancing?: [{	projectId: string},ResolverInputTypes["LegacyFinancing"]],
partners?: [{	input?: ResolverInputTypes["CoreGetPartnersInput"] | undefined | null},ResolverInputTypes["CorePartner"]],
project?: [{	projectId: string},ResolverInputTypes["Project"]],
project_proposal_changes?: [{	project_id: string},ResolverInputTypes["ProjectProposal"]],
project_is_combo?: [{	project_id: string},ResolverInputTypes["IsComboResponse"]],
	project_system_types?:boolean | `@${string}`,
registration?: [{	project_id: string},ResolverInputTypes["RegistrationDetailsResponse"]],
getOpportunities?: [{	params: ResolverInputTypes["GetOpportunitiesInput"]},ResolverInputTypes["GetOpportunitiesResponse"]],
simulation_project_shopping_cart_id_get?: [{	projectId: string},boolean | `@${string}`],
	me?:ResolverInputTypes["User"],
		__typename?: boolean | `@${string}`
}>;
	["PersonTypes"]:PersonTypes;
	["DocumentTypes"]:DocumentTypes;
	["LegalRepresentantTypes"]:LegalRepresentantTypes;
	["CustomerStatus"]:CustomerStatus;
	["RegistrationContactChangeResponse"]: AliasType<{
	message?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CustomerFormResponse"]: AliasType<{
	project_id?:boolean | `@${string}`,
	is_combo?:boolean | `@${string}`,
	person_type?:boolean | `@${string}`,
	customer?:ResolverInputTypes["CustomerData"],
	guarantor?:ResolverInputTypes["CustomerData"],
	project?:ResolverInputTypes["CustomerProject"],
	remaining_changes_after_confirmation?:boolean | `@${string}`,
	company?:ResolverInputTypes["CompanyData"],
	errors?:ResolverInputTypes["CustomerErrors"],
	status?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CustomerErrors"]: AliasType<{
	field?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CustomerContact"]: AliasType<{
	email?:boolean | `@${string}`,
	phone?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CustomerDocument"]: AliasType<{
	type?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
	filename?:boolean | `@${string}`,
	url_download?:boolean | `@${string}`,
	url_preview?:boolean | `@${string}`,
	mime_type?:boolean | `@${string}`,
	id?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CustomerAddress"]: AliasType<{
	zipcode?:boolean | `@${string}`,
	street?:boolean | `@${string}`,
	street_number?:boolean | `@${string}`,
	complement?:boolean | `@${string}`,
	neighborhood?:boolean | `@${string}`,
	city_id?:boolean | `@${string}`,
	state_id?:boolean | `@${string}`,
	city?:ResolverInputTypes["CustomerIdName"],
	state?:ResolverInputTypes["CustomerIdName"],
	is_rural?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CustomerData"]: AliasType<{
	cpf?:boolean | `@${string}`,
	full_name?:boolean | `@${string}`,
	birthdate?:boolean | `@${string}`,
	contact?:ResolverInputTypes["CustomerContact"],
	documents?:ResolverInputTypes["CustomerDocument"],
	document_type?:boolean | `@${string}`,
	comment?:boolean | `@${string}`,
	address?:ResolverInputTypes["CustomerAddress"],
		__typename?: boolean | `@${string}`
}>;
	["CustomerEletricityBill"]: AliasType<{
	is_under_customer_name?:boolean | `@${string}`,
	holder_cpf?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CustomerProject"]: AliasType<{
	address?:ResolverInputTypes["CustomerAddress"],
	documents?:ResolverInputTypes["CustomerDocument"],
	document_type?:boolean | `@${string}`,
	comment?:boolean | `@${string}`,
	electricity_bill?:ResolverInputTypes["CustomerEletricityBill"],
	residencial_is_the_same_of_plant?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CompanyMajorityPartner"]: AliasType<{
	cpf?:boolean | `@${string}`,
	full_name?:boolean | `@${string}`,
	birthdate?:boolean | `@${string}`,
	contact?:ResolverInputTypes["CustomerContact"],
	documents?:ResolverInputTypes["CustomerDocument"],
	document_type?:boolean | `@${string}`,
	comment?:boolean | `@${string}`,
	address?:ResolverInputTypes["CustomerAddress"],
		__typename?: boolean | `@${string}`
}>;
	["CompanyLegalRepresentant"]: AliasType<{
	kind?:boolean | `@${string}`,
	cpf?:boolean | `@${string}`,
	full_name?:boolean | `@${string}`,
	birthdate?:boolean | `@${string}`,
	contact?:ResolverInputTypes["CustomerContact"],
	documents?:ResolverInputTypes["CustomerDocument"],
	document_type?:boolean | `@${string}`,
	comment?:boolean | `@${string}`,
	address?:ResolverInputTypes["CustomerAddress"],
		__typename?: boolean | `@${string}`
}>;
	["CompanyData"]: AliasType<{
	company_name?:boolean | `@${string}`,
	cnpj?:boolean | `@${string}`,
	contact?:ResolverInputTypes["CustomerContact"],
	documents?:ResolverInputTypes["CustomerDocument"],
	document_type?:boolean | `@${string}`,
	comment?:boolean | `@${string}`,
	majority_partner?:ResolverInputTypes["CompanyMajorityPartner"],
	majority_partner_is_legal_representative?:boolean | `@${string}`,
	is_only_one_legal_representative?:boolean | `@${string}`,
	legal_representatives?:ResolverInputTypes["CompanyLegalRepresentant"],
		__typename?: boolean | `@${string}`
}>;
	["RegistrationV2PersonType"]:RegistrationV2PersonType;
	["CustomerIdName"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CustomerRegistrationAddress"]: {
	zipcode: string,
	street: string,
	street_number: string,
	complement?: string | undefined | null,
	neighborhood: string,
	city_id: number,
	state_id: number,
	is_rural?: boolean | undefined | null
};
	["CustomerRegistrationContact"]: {
	email: string,
	phone: string
};
	["CustomerRegistrationDocument"]: {
	type: string,
	docstorage_id: string
};
	["CustomerRegistrationCustomer"]: {
	full_name: string,
	birthdate: string,
	contact: ResolverInputTypes["CustomerRegistrationContact"],
	documents: Array<ResolverInputTypes["CustomerRegistrationDocument"]>,
	address: ResolverInputTypes["CustomerRegistrationAddress"]
};
	["CustomerRegistrationGuarantor"]: {
	full_name: string,
	birthdate: string,
	contact: ResolverInputTypes["CustomerRegistrationContact"],
	documents: Array<ResolverInputTypes["CustomerRegistrationDocument"]>,
	address: ResolverInputTypes["CustomerRegistrationAddress"]
};
	["CustomerRegistrationElectricityBill"]: {
	is_under_customer_name: boolean,
	holder_cpf?: string | undefined | null
};
	["CustomerRegistrationProject"]: {
	address: ResolverInputTypes["CustomerRegistrationAddress"],
	documents: Array<ResolverInputTypes["CustomerRegistrationDocument"]>,
	comment?: string | undefined | null,
	electricity_bill: ResolverInputTypes["CustomerRegistrationElectricityBill"]
};
	["CustomerRegistrationCompany"]: {
	company_name: string,
	contact: ResolverInputTypes["CustomerRegistrationContact"],
	documents: Array<ResolverInputTypes["CustomerRegistrationDocument"]>,
	majority_partner: ResolverInputTypes["CustomerRegistrationCustomer"],
	legal_representatives: Array<ResolverInputTypes["CustomerRegistrationLegalRepresentative"]>
};
	["CustomerRegistrationLegalRepresentative"]: {
	kind: string,
	cpf: string,
	full_name: string,
	birthdate: string,
	contact: ResolverInputTypes["CustomerRegistrationContact"],
	documents: Array<ResolverInputTypes["CustomerRegistrationDocument"]>,
	address: ResolverInputTypes["CustomerRegistrationAddress"]
};
	["CustomerRegistrationInput"]: {
	project_id: string,
	person_type: ResolverInputTypes["RegistrationV2PersonType"],
	customer?: ResolverInputTypes["CustomerRegistrationCustomer"] | undefined | null,
	guarantor?: ResolverInputTypes["CustomerRegistrationGuarantor"] | undefined | null,
	project?: ResolverInputTypes["CustomerRegistrationProject"] | undefined | null,
	company?: ResolverInputTypes["CustomerRegistrationCompany"] | undefined | null
};
	["Person"]: {
	email?: string | undefined | null,
	partner_id: string,
	phone?: string | undefined | null
};
	["CustomerDocumentUpload"]: AliasType<{
	id?:boolean | `@${string}`,
	url?:boolean | `@${string}`,
	url_inline?:boolean | `@${string}`,
	filename?:boolean | `@${string}`,
	mimetype?:boolean | `@${string}`,
	size?:boolean | `@${string}`,
	docstorage_id?:boolean | `@${string}`,
	created_at?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CustomerRegistrationError"]: AliasType<{
	field?:boolean | `@${string}`,
	message?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CustomerRegistrationV2Response"]: AliasType<{
	errors?:ResolverInputTypes["CustomerRegistrationError"],
		__typename?: boolean | `@${string}`
}>;
	["CustomerDocumentFormAnalysis"]: AliasType<{
	id?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
	project_id?:boolean | `@${string}`,
	installation_document_matches_bill?:boolean | `@${string}`,
	installation_address_matches_bill?:boolean | `@${string}`,
	identity_matches_document?:boolean | `@${string}`,
	reason?:boolean | `@${string}`,
	comments?:boolean | `@${string}`,
	documents?:ResolverInputTypes["CustomerDocumentUpload"],
		__typename?: boolean | `@${string}`
}>;
	["PersonInput"]: {
	email: string,
	phone: string
};
	["RegistrationContactChangeInput"]: {
	project_id: string,
	customer?: ResolverInputTypes["PersonInput"] | undefined | null,
	guarantor?: ResolverInputTypes["PersonInput"] | undefined | null,
	majority_partner?: ResolverInputTypes["PersonInput"] | undefined | null,
	legal_representative_1?: ResolverInputTypes["PersonInput"] | undefined | null,
	legal_representative_2?: ResolverInputTypes["PersonInput"] | undefined | null
};
	["Mutation"]: AliasType<{
registration_contact_change?: [{	input: ResolverInputTypes["RegistrationContactChangeInput"]},ResolverInputTypes["RegistrationContactChangeResponse"]],
customer_document_upload?: [{	documents: Array<ResolverInputTypes["Upload"]>},ResolverInputTypes["CustomerDocumentUpload"]],
customer_document_form_analysis_cancel?: [{	project_id: string,	step: string},ResolverInputTypes["CustomerDocumentFormAnalysis"]],
customer_registration?: [{	params: ResolverInputTypes["CustomerRegistrationInput"]},ResolverInputTypes["CustomerRegistrationV2Response"]],
validateHardwareForm?: [{	hardwareFormInput: ResolverInputTypes["HardwareFormInput"]},ResolverInputTypes["HardwareFormValidation"]],
hardware_form_showcase?: [{	hardwareFormShowcaseInput: ResolverInputTypes["HardwareFormShowcaseInput"]},ResolverInputTypes["HardwareFormShowcaseResponse"]],
hardware_form_draft?: [{	hardwareFormDraftInput: ResolverInputTypes["HardwareDraft"]},boolean | `@${string}`],
installation_mirror_upload?: [{	projectId: string,	mirror: ResolverInputTypes["Upload"]},ResolverInputTypes["MirrorUploadResponse"]],
project_update_shopping_cart?: [{	project_id: string,	shopping_cart_id: string},boolean | `@${string}`],
project_proposal_update?: [{	project_id: string,	payload: ResolverInputTypes["ProjectProposalUpdatePayloadInput"]},boolean | `@${string}`],
registration?: [{	params: ResolverInputTypes["RegistrationUpdateInput"]},boolean | `@${string}`],
processEnergyAccountAnalysis?: [{	params: ResolverInputTypes["AnalyzeEnergyBillInput"]},ResolverInputTypes["EnergyBillAnalysisDecision"]],
		__typename?: boolean | `@${string}`
}>;
	["DocumentResponse"]: AliasType<{
	data?:ResolverInputTypes["DocumentData"],
		__typename?: boolean | `@${string}`
}>;
	["DocumentData"]: AliasType<{
	project?:ResolverInputTypes["Documents"],
	customer?:ResolverInputTypes["Documents"],
	guarantor?:ResolverInputTypes["DocumentFile"],
	company?:ResolverInputTypes["DocumentFile"],
		__typename?: boolean | `@${string}`
}>;
	["Documents"]: AliasType<{
	id?:boolean | `@${string}`,
	docstorage_id?:boolean | `@${string}`,
	kind?:boolean | `@${string}`,
	validated_at?:boolean | `@${string}`,
	validation_mode?:boolean | `@${string}`,
	deleted_at?:boolean | `@${string}`,
	submitted_at?:boolean | `@${string}`,
	canceled_at?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
	reason?:ResolverInputTypes["DocumentReason"],
	file?:ResolverInputTypes["DocumentFile"],
		__typename?: boolean | `@${string}`
}>;
	["DocumentFile"]: AliasType<{
	public_id?:boolean | `@${string}`,
	filename?:boolean | `@${string}`,
	mimetype?:boolean | `@${string}`,
	size?:boolean | `@${string}`,
	url?:boolean | `@${string}`,
	url_inline?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DocumentReason"]: AliasType<{
	title?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PersonType"]:PersonType;
	["FinancingStatus"]:FinancingStatus;
	["SectionStatus"]:SectionStatus;
	["FormalizationStatus"]: AliasType<{
	contract?:boolean | `@${string}`,
	hardware?:boolean | `@${string}`,
	registration?:boolean | `@${string}`,
	documentation?:boolean | `@${string}`,
	receipt_model?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SectionStatuses"]: AliasType<{
	concluded?:boolean | `@${string}`,
	simulation?:boolean | `@${string}`,
	installation?:boolean | `@${string}`,
	formalization?:ResolverInputTypes["FormalizationStatus"],
		__typename?: boolean | `@${string}`
}>;
	["FinancingSteps"]:FinancingSteps;
	["Payment"]: AliasType<{
	id?:boolean | `@${string}`,
	financing_id?:boolean | `@${string}`,
	value?:boolean | `@${string}`,
	total_value?:boolean | `@${string}`,
	payment_percentage?:boolean | `@${string}`,
	created_at?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Financing"]: AliasType<{
	id?:boolean | `@${string}`,
	project_id?:boolean | `@${string}`,
	financed_value?:boolean | `@${string}`,
	customer_name?:boolean | `@${string}`,
	document?:boolean | `@${string}`,
	expires_at?:boolean | `@${string}`,
	created_at?:boolean | `@${string}`,
	formalized_at?:boolean | `@${string}`,
	installed_at?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
	step?:boolean | `@${string}`,
	is_combo?:boolean | `@${string}`,
	payments?:ResolverInputTypes["Payment"],
	flow_provider?:boolean | `@${string}`,
	person_type?:boolean | `@${string}`,
	all_payments_sent?:boolean | `@${string}`,
	section_statuses?:ResolverInputTypes["FinancingSectionStatusV2"],
	updated_at?:boolean | `@${string}`,
	is_expired?:boolean | `@${string}`,
	system_power?:boolean | `@${string}`,
	new_journey?:boolean | `@${string}`,
	legacy_id?:boolean | `@${string}`,
	reason?:boolean | `@${string}`,
	openable_steps?:ResolverInputTypes["FormalizationStatuses"],
	is_aproved?:boolean | `@${string}`,
	is_final?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["FormalizationStatuses"]: AliasType<{
	registration?:boolean | `@${string}`,
	documentation?:boolean | `@${string}`,
	hardware?:boolean | `@${string}`,
	contract?:boolean | `@${string}`,
	receiptModel?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["FinancingListTotalByStep"]: AliasType<{
	simulation?:boolean | `@${string}`,
	formalization?:boolean | `@${string}`,
	installation?:boolean | `@${string}`,
	concluded?:boolean | `@${string}`,
	expired?:boolean | `@${string}`,
	proposal?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["FinancingListTotalByStatus"]: AliasType<{
	expired?:boolean | `@${string}`,
	reproved?:boolean | `@${string}`,
	approved?:boolean | `@${string}`,
	under_analysis?:boolean | `@${string}`,
	canceled?:boolean | `@${string}`,
	to_review?:boolean | `@${string}`,
	in_progress?:boolean | `@${string}`,
	waiting_customer?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["FinancingProposal"]: AliasType<{
	customer_data?:boolean | `@${string}`,
	equipment?:boolean | `@${string}`,
	biometry_and_contract?:boolean | `@${string}`,
	payment_model?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["FinancingFormalization"]: AliasType<{
	contract?:boolean | `@${string}`,
	hardware?:boolean | `@${string}`,
	registration?:boolean | `@${string}`,
	documentation?:boolean | `@${string}`,
	receipt_model?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["FinancingSectionStatusV2"]: AliasType<{
	concluded?:boolean | `@${string}`,
	simulation?:boolean | `@${string}`,
	installation?:boolean | `@${string}`,
	proposal?:ResolverInputTypes["FinancingProposal"],
	formalization?:ResolverInputTypes["FinancingFormalization"],
		__typename?: boolean | `@${string}`
}>;
	["FinancingsList"]: AliasType<{
	data?:ResolverInputTypes["Financing"],
	total?:boolean | `@${string}`,
	total_by_step?:ResolverInputTypes["FinancingListTotalByStep"],
	total_by_status?:ResolverInputTypes["FinancingListTotalByStatus"],
		__typename?: boolean | `@${string}`
}>;
	["FinancingsInput"]: {
	query?: string | undefined | null,
	page?: number | undefined | null,
	partner_id?: string | undefined | null,
	steps?: Array<ResolverInputTypes["FinancingSteps"]> | undefined | null,
	status?: Array<ResolverInputTypes["FinancingStatus"]> | undefined | null,
	is_combo?: string | undefined | null,
	page_size?: number | undefined | null,
	order_by?: string | undefined | null
};
	["FinancingType"]: AliasType<{
	max_financing_value?:boolean | `@${string}`,
	min_financing_value?:boolean | `@${string}`,
	max_financing_installments?:boolean | `@${string}`,
	grace_period_financing?:ResolverInputTypes["GracePeriod"],
	max_bnpl_value?:boolean | `@${string}`,
	max_bnpl_installments?:boolean | `@${string}`,
	grace_period_bnpl?:ResolverInputTypes["GracePeriod"],
		__typename?: boolean | `@${string}`
}>;
	["GracePeriod"]: AliasType<{
	label?:boolean | `@${string}`,
	value?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["FileStatusType"]:FileStatusType;
	["DocumentStatusType"]:DocumentStatusType;
	["TypeSection"]:TypeSection;
	["MirrorStatuses"]:MirrorStatuses;
	["Status"]: AliasType<{
	description?:boolean | `@${string}`,
	id?:boolean | `@${string}`,
	slug?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Document"]: AliasType<{
	filename?:boolean | `@${string}`,
	filetype?:boolean | `@${string}`,
	id?:boolean | `@${string}`,
	status?:ResolverInputTypes["Status"],
	type?:boolean | `@${string}`,
	url?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Section"]: AliasType<{
	documents?:ResolverInputTypes["Document"],
	id?:boolean | `@${string}`,
	status?:ResolverInputTypes["Status"],
	type?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InstallationStatus"]: AliasType<{
	description?:boolean | `@${string}`,
	id?:boolean | `@${string}`,
	slug?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Installation"]: AliasType<{
	comment?:boolean | `@${string}`,
	concluded_at?:boolean | `@${string}`,
	grade?:boolean | `@${string}`,
	has_ampera?:boolean | `@${string}`,
	has_ampera_activated?:boolean | `@${string}`,
	has_minimal_projects?:boolean | `@${string}`,
	id?:boolean | `@${string}`,
	new_journey?:boolean | `@${string}`,
	plant_id?:boolean | `@${string}`,
	plant_url?:boolean | `@${string}`,
	project_id?:boolean | `@${string}`,
	project_name?:boolean | `@${string}`,
	sections?:ResolverInputTypes["Section"],
	status?:ResolverInputTypes["InstallationStatus"],
		__typename?: boolean | `@${string}`
}>;
	["HardwareFormInverter"]: {
	id: number,
	quantity: number,
	power?: number | undefined | null
};
	["HardwareFormModule"]: {
	id: number,
	quantity: number,
	power?: number | undefined | null
};
	["HardwareFormComboEquipment"]: {
	sku: string,
	power?: number | undefined | null,
	quantity: number
};
	["HardwareFormBatteries"]: {
	id: number,
	quantity: number,
	power?: number | undefined | null
};
	["HardwareFormComboMetadata"]: {
	project_id: string,
	sku?: string | undefined | null,
	power?: number | undefined | null,
	price?: number | undefined | null,
	inverters: Array<ResolverInputTypes["HardwareFormComboEquipment"]>,
	modules: Array<ResolverInputTypes["HardwareFormComboEquipment"]>
};
	["HardwareFormInput"]: {
	is_combo: boolean,
	id?: string | undefined | null,
	combo_price?: number | undefined | null,
	inverter_type?: string | undefined | null,
	project_id: string,
	distributor_id: number,
	phase_id: number,
	inverters: Array<ResolverInputTypes["HardwareFormInverter"]>,
	modules: Array<ResolverInputTypes["HardwareFormModule"]>,
	batteries?: Array<ResolverInputTypes["HardwareFormBatteries"]> | undefined | null,
	combo_metadata?: ResolverInputTypes["HardwareFormComboMetadata"] | undefined | null,
	is_showcase?: boolean | undefined | null,
	shopping_cart_id?: string | undefined | null
};
	["HardwareFormShowcaseInput"]: {
	price?: number | undefined | null,
	project_id: string,
	inverters: Array<ResolverInputTypes["HardwareFormInverter"]>,
	modules: Array<ResolverInputTypes["HardwareFormModule"]>,
	shopping_cart_id?: string | undefined | null
};
	["ValidateHardwareFormPricePerWatt"]: AliasType<{
	is_valid?:boolean | `@${string}`,
	is_under_expected?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ValidateHardwareFormModules"]: AliasType<{
	price_per_watt?:ResolverInputTypes["ValidateHardwareFormPricePerWatt"],
		__typename?: boolean | `@${string}`
}>;
	["ValidateHardwareFormOverload"]: AliasType<{
	is_valid?:boolean | `@${string}`,
	is_under_expected?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ValidateHardwareFormInverters"]: AliasType<{
	overload?:ResolverInputTypes["ValidateHardwareFormOverload"],
		__typename?: boolean | `@${string}`
}>;
	["ValidateHardwareFormHardware"]: AliasType<{
	modules?:ResolverInputTypes["ValidateHardwareFormModules"],
	inverters?:ResolverInputTypes["ValidateHardwareFormInverters"],
		__typename?: boolean | `@${string}`
}>;
	["HardwareFormValidation"]: AliasType<{
	is_valid?:boolean | `@${string}`,
	hardware?:ResolverInputTypes["ValidateHardwareFormHardware"],
		__typename?: boolean | `@${string}`
}>;
	["HardwareFormShowcaseResponse"]: AliasType<{
	overload_is_valid?:boolean | `@${string}`,
	price_per_watt_is_valid?:boolean | `@${string}`,
	percentage_of_service_with_combo_is_valid?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MirrorStatus"]: AliasType<{
	status?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MirrorUploadResponse"]: AliasType<{
	id?:boolean | `@${string}`,
	url?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HardwareDraftEquipment"]: {
	sku: string,
	quantity: number,
	power?: number | undefined | null,
	model: string,
	brand: string
};
	["HardwareDraft"]: {
	project_id: string,
	sku?: string | undefined | null,
	power?: number | undefined | null,
	price?: number | undefined | null,
	inverters: Array<ResolverInputTypes["HardwareDraftEquipment"]>,
	modules: Array<ResolverInputTypes["HardwareDraftEquipment"]>
};
	["LegacyFinancing"]: AliasType<{
	projectId?:boolean | `@${string}`,
	financingId?:boolean | `@${string}`,
	formalizedAt?:boolean | `@${string}`,
	stage?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CorePartner"]: AliasType<{
	id?:boolean | `@${string}`,
	cnpj?:boolean | `@${string}`,
	responsible?:ResolverInputTypes["CoreParterResponsible"],
	company_name?:boolean | `@${string}`,
	fantasy_name?:boolean | `@${string}`,
	status?:ResolverInputTypes["CoreParterStatus"],
		__typename?: boolean | `@${string}`
}>;
	["CoreParterResponsible"]: AliasType<{
	id?:boolean | `@${string}`,
	full_name?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CoreParterStatus"]: AliasType<{
	active?:boolean | `@${string}`,
	reason?:boolean | `@${string}`,
	date?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CoreGetPartnersInput"]: {
	page?: number | undefined | null,
	search_term?: string | undefined | null
};
	["SystemTypes"]:SystemTypes;
	["ProjectProposal"]: AliasType<{
	value_changed?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AppliedModifierOption"]: AliasType<{
	type?:boolean | `@${string}`,
	value?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AppliedModifier"]: AliasType<{
	name?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	options?:ResolverInputTypes["AppliedModifierOption"],
		__typename?: boolean | `@${string}`
}>;
	["AddonProduct"]: AliasType<{
	id?:boolean | `@${string}`,
	product_name?:boolean | `@${string}`,
	product_external_id?:boolean | `@${string}`,
	simulation_id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	product_slug?:boolean | `@${string}`,
	applied?:boolean | `@${string}`,
	partner_commission?:boolean | `@${string}`,
	installment_price?:boolean | `@${string}`,
	total_price?:boolean | `@${string}`,
	monthly_interest_rate?:boolean | `@${string}`,
	engine_simulation_id?:boolean | `@${string}`,
	previous_engine_simulation_id?:boolean | `@${string}`,
	cet?:boolean | `@${string}`,
	applied_modifiers?:ResolverInputTypes["AppliedModifier"],
	addon_type?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SelectedInstallments"]: AliasType<{
	cet?:boolean | `@${string}`,
	iof?:boolean | `@${string}`,
	ipca?:boolean | `@${string}`,
	addons?:ResolverInputTypes["AddonProduct"],
	aggregated_addons?:ResolverInputTypes["AddonProduct"],
	user_id?:boolean | `@${string}`,
	document?:boolean | `@${string}`,
	is_combo?:boolean | `@${string}`,
	commission?:boolean | `@${string}`,
	partner_id?:boolean | `@${string}`,
	aliquot_iof?:boolean | `@${string}`,
	new_journey?:boolean | `@${string}`,
	person_type?:boolean | `@${string}`,
	down_payment?:boolean | `@${string}`,
	grace_period?:boolean | `@${string}`,
	installments?:boolean | `@${string}`,
	project_name?:boolean | `@${string}`,
	system_power?:boolean | `@${string}`,
	interest_rate?:boolean | `@${string}`,
	cet_percentage?:boolean | `@${string}`,
	commission_raw?:boolean | `@${string}`,
	financing_value?:boolean | `@${string}`,
	taxa_de_cadastro?:boolean | `@${string}`,
	installment_value?:boolean | `@${string}`,
	is_from_suggested?:boolean | `@${string}`,
	registration_fee_gross?:boolean | `@${string}`,
	registration_fee_raw_dry?:boolean | `@${string}`,
	registration_fee_raw_final?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RiskInfo"]: AliasType<{
	title?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	todo?:boolean | `@${string}`,
	reason?:boolean | `@${string}`,
	decision?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BNPL"]: AliasType<{
	decision_pre_analysis?:boolean | `@${string}`,
	min_entry_value?:boolean | `@${string}`,
	max_installments?:boolean | `@${string}`,
	pre_approved_value?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Guarantor"]: AliasType<{
	id?:boolean | `@${string}`,
	document?:boolean | `@${string}`,
	birth_date?:boolean | `@${string}`,
	border_line?:boolean | `@${string}`,
	created_at?:boolean | `@${string}`,
	customer_second_financing_policy?:boolean | `@${string}`,
	decision_pre_analysis?:boolean | `@${string}`,
	max_approved_project_value?:boolean | `@${string}`,
	max_financing_value?:boolean | `@${string}`,
	max_grace_period?:boolean | `@${string}`,
	max_installments?:boolean | `@${string}`,
	min_approved_project_value?:boolean | `@${string}`,
	min_installment_value?:boolean | `@${string}`,
	pre_approved_value?:boolean | `@${string}`,
	project_name?:boolean | `@${string}`,
	project_state?:boolean | `@${string}`,
	risk_class?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Project"]: AliasType<{
	id?:boolean | `@${string}`,
	user_id?:boolean | `@${string}`,
	partner_id?:boolean | `@${string}`,
	person_type?:boolean | `@${string}`,
	document?:boolean | `@${string}`,
	created_at?:boolean | `@${string}`,
	representative_document?:boolean | `@${string}`,
	project_value?:boolean | `@${string}`,
	system_power?:boolean | `@${string}`,
	grace_period?:boolean | `@${string}`,
	down_payment?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
	project_status?:boolean | `@${string}`,
	max_installments?:boolean | `@${string}`,
	max_grace_period?:boolean | `@${string}`,
	project_name?:boolean | `@${string}`,
	project_state?:boolean | `@${string}`,
	partner_profile?:boolean | `@${string}`,
	pre_approved_value?:boolean | `@${string}`,
	products_applieds?:boolean | `@${string}`,
	installments?:boolean | `@${string}`,
	risk_info?:ResolverInputTypes["RiskInfo"],
	state_registration?:boolean | `@${string}`,
	complete?:boolean | `@${string}`,
	selected_installments?:ResolverInputTypes["SelectedInstallments"],
	guarantor?:ResolverInputTypes["Guarantor"],
	birth_date?:boolean | `@${string}`,
	decision_pre_analysis?:boolean | `@${string}`,
	score_sol_facil?:boolean | `@${string}`,
	simulation_id?:boolean | `@${string}`,
	addons?:ResolverInputTypes["AddonProduct"],
	max_approved_project_value?:boolean | `@${string}`,
	min_approved_project_value?:boolean | `@${string}`,
	max_financing_value?:boolean | `@${string}`,
	border_line?:boolean | `@${string}`,
	is_formalized?:boolean | `@${string}`,
	new_journey?:boolean | `@${string}`,
	formalized_at?:boolean | `@${string}`,
	shopping_cart_id?:boolean | `@${string}`,
	bnpl?:ResolverInputTypes["BNPL"],
	bnpl_simulation_id?:boolean | `@${string}`,
	bnpl_status?:boolean | `@${string}`,
	is_onflex_partner?:boolean | `@${string}`,
	can_select_ampera?:boolean | `@${string}`,
	has_energy_bill_analysis?:boolean | `@${string}`,
	insurance_commission?:boolean | `@${string}`,
	gained_points?:boolean | `@${string}`,
	original_installment_value?:boolean | `@${string}`,
	is_contract_signed?:boolean | `@${string}`,
	commission?:boolean | `@${string}`,
	risk_class?:boolean | `@${string}`,
	selected_installment?:boolean | `@${string}`,
	credit_analysis_status?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProjectProposalUpdatePayloadInput"]: {
	engine_simulation_id: string,
	simulation_id: string,
	is_combo: boolean,
	is_ampera: boolean,
	commission: number,
	applied_addons: Array<string>
};
	["ProjectProposalUpdateResponse"]: AliasType<{
	engine_simulation_id?:boolean | `@${string}`,
	simulation_id?:boolean | `@${string}`,
	project_id?:boolean | `@${string}`,
	is_combo?:boolean | `@${string}`,
	is_ampera?:boolean | `@${string}`,
	commission?:boolean | `@${string}`,
	applied_addons?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["IsComboResponse"]: AliasType<{
	data?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RegistrationPersonType"]:RegistrationPersonType;
	["RegistrationDocumentTypes"]:RegistrationDocumentTypes;
	["RegistrationRepresentantTypes"]:RegistrationRepresentantTypes;
	["RegistrationContact"]: AliasType<{
	email?:boolean | `@${string}`,
	phone?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RegistrationAddressCity"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RegistrationAddressState"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RegistrationDocumentModel"]:RegistrationDocumentModel;
	["RegistrationElectricityBill"]: AliasType<{
	holder_cpf?:boolean | `@${string}`,
	is_under_customer_name?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RegistrationDocument"]: AliasType<{
	type?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
	filename?:boolean | `@${string}`,
	url_download?:boolean | `@${string}`,
	url_preview?:boolean | `@${string}`,
	mime_type?:boolean | `@${string}`,
	docstorage_id?:boolean | `@${string}`,
	id?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RegistrationAddress"]: AliasType<{
	zipcode?:boolean | `@${string}`,
	street?:boolean | `@${string}`,
	street_number?:boolean | `@${string}`,
	complement?:boolean | `@${string}`,
	neighborhood?:boolean | `@${string}`,
	city_id?:boolean | `@${string}`,
	state_id?:boolean | `@${string}`,
	city?:ResolverInputTypes["RegistrationAddressCity"],
	state?:ResolverInputTypes["RegistrationAddressState"],
	is_rural?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RegistrationProject"]: AliasType<{
	address?:ResolverInputTypes["RegistrationAddress"],
	documents?:ResolverInputTypes["RegistrationDocument"],
	electricity_bill?:ResolverInputTypes["RegistrationElectricityBill"],
	residencial_is_the_same_of_plant?:boolean | `@${string}`,
	comment?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RegistrationCustomer"]: AliasType<{
	cpf?:boolean | `@${string}`,
	full_name?:boolean | `@${string}`,
	birthdate?:boolean | `@${string}`,
	contact?:ResolverInputTypes["RegistrationContact"],
	documents?:ResolverInputTypes["RegistrationDocument"],
	document_type?:boolean | `@${string}`,
	address?:ResolverInputTypes["RegistrationAddress"],
		__typename?: boolean | `@${string}`
}>;
	["RegistrationCompany"]: AliasType<{
	company_name?:boolean | `@${string}`,
	cnpj?:boolean | `@${string}`,
	contact?:ResolverInputTypes["RegistrationContact"],
	documents?:ResolverInputTypes["RegistrationDocument"],
	majority_partner?:ResolverInputTypes["RegistrationCompanyMajorityPartner"],
	legal_representatives?:ResolverInputTypes["RegistrationLegalRepresentative"],
	majority_partner_is_legal_representative?:boolean | `@${string}`,
	is_only_one_legal_representative?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RegistrationLegalRepresentative"]: AliasType<{
	cpf?:boolean | `@${string}`,
	full_name?:boolean | `@${string}`,
	birthdate?:boolean | `@${string}`,
	contact?:ResolverInputTypes["RegistrationContact"],
	documents?:ResolverInputTypes["RegistrationDocument"],
	address?:ResolverInputTypes["RegistrationAddress"],
	kind?:boolean | `@${string}`,
	document_type?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RegistrationCompanyMajorityPartner"]: AliasType<{
	cpf?:boolean | `@${string}`,
	is_legal_representative?:boolean | `@${string}`,
	full_name?:boolean | `@${string}`,
	birthdate?:boolean | `@${string}`,
	contact?:ResolverInputTypes["RegistrationContact"],
	documents?:ResolverInputTypes["RegistrationDocument"],
	address?:ResolverInputTypes["RegistrationAddress"],
	document_type?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RegistrationGuarantor"]: AliasType<{
	cpf?:boolean | `@${string}`,
	full_name?:boolean | `@${string}`,
	birthdate?:boolean | `@${string}`,
	contact?:ResolverInputTypes["RegistrationContact"],
	documents?:ResolverInputTypes["RegistrationDocument"],
	address?:ResolverInputTypes["RegistrationAddress"],
	document_type?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RegistrationError"]: AliasType<{
	title?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	field?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RegistrationUpdateInput"]: {
	project_id: string
};
	["RegistrationDetailsResponse"]: AliasType<{
	project_id?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
	is_combo?:boolean | `@${string}`,
	remaining_changes_after_confirmation?:boolean | `@${string}`,
	person_type?:boolean | `@${string}`,
	customer?:ResolverInputTypes["RegistrationCustomer"],
	company?:ResolverInputTypes["RegistrationCompany"],
	guarantor?:ResolverInputTypes["RegistrationGuarantor"],
	project?:ResolverInputTypes["RegistrationProject"],
	errors?:ResolverInputTypes["RegistrationError"],
		__typename?: boolean | `@${string}`
}>;
	["AnalyzeEnergyBillInput"]: {
	person_type: string,
	project_id: string,
	document_customer: string,
	financing_id: number,
	document_owner_energy_bill: string,
	auto_cancel_flow_on_rejection: boolean
};
	["GetOpportunitiesInput"]: {
	financing_value: number,
	partner_id: number,
	project_type: string
};
	["EnergyBillAnalysisDecision"]: AliasType<{
	decision?:boolean | `@${string}`,
	details?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["GetOpportunitiesResponse"]: AliasType<{
	addons_opportunities?:ResolverInputTypes["AddonsOpportunities"],
	financing_gained_points?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AddonsOpportunities"]: AliasType<{
	addon_type?:boolean | `@${string}`,
	gained_points?:boolean | `@${string}`,
	product_slug?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LevelBlackStatus"]: AliasType<{
	kwGoalMonth?:boolean | `@${string}`,
	kwReachedMonth?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Contact"]: AliasType<{
	landline?:boolean | `@${string}`,
	mobile?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Address"]: AliasType<{
	street?:boolean | `@${string}`,
	number?:boolean | `@${string}`,
	complement?:boolean | `@${string}`,
	reference?:boolean | `@${string}`,
	district?:boolean | `@${string}`,
	zipcode?:boolean | `@${string}`,
	city?:boolean | `@${string}`,
	state?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Responsible"]: AliasType<{
	id?:boolean | `@${string}`,
	username?:boolean | `@${string}`,
	profile?:boolean | `@${string}`,
	fullname?:boolean | `@${string}`,
	document?:boolean | `@${string}`,
	birthdate?:boolean | `@${string}`,
	active?:boolean | `@${string}`,
	created_at?:boolean | `@${string}`,
	contact?:ResolverInputTypes["Contact"],
	partner?:ResolverInputTypes["Partner"],
		__typename?: boolean | `@${string}`
}>;
	["Permission"]: AliasType<{
	store?:boolean | `@${string}`,
	financing?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Score"]: AliasType<{
	current?:boolean | `@${string}`,
	goal?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Downgrade"]: AliasType<{
	has_risk?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Sfplus"]: AliasType<{
	level?:boolean | `@${string}`,
	score?:ResolverInputTypes["Score"],
	black_status?:ResolverInputTypes["LevelBlackStatus"],
	downgrade?:ResolverInputTypes["Downgrade"],
	is_level_black?:boolean | `@${string}`,
	next_transition_date?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Partner"]: AliasType<{
	id?:boolean | `@${string}`,
	corporate_name?:boolean | `@${string}`,
	operation_term_accepted?:boolean | `@${string}`,
	trade_name?:boolean | `@${string}`,
	document?:boolean | `@${string}`,
	category?:boolean | `@${string}`,
	state_registration?:boolean | `@${string}`,
	active?:boolean | `@${string}`,
	created_at?:boolean | `@${string}`,
	address?:ResolverInputTypes["Address"],
	responsible?:ResolverInputTypes["Responsible"],
	permission?:ResolverInputTypes["Permission"],
	users?:ResolverInputTypes["User"],
	sfplus?:ResolverInputTypes["Sfplus"],
		__typename?: boolean | `@${string}`
}>;
	["Post"]: AliasType<{
	id?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	body?:boolean | `@${string}`,
	userId?:boolean | `@${string}`,
	comments?:ResolverInputTypes["Comment"],
		__typename?: boolean | `@${string}`
}>;
	["Comment"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
	body?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["User"]: AliasType<{
	id?:boolean | `@${string}`,
	username?:boolean | `@${string}`,
	profile?:boolean | `@${string}`,
	fullname?:boolean | `@${string}`,
	document?:boolean | `@${string}`,
	birthdate?:boolean | `@${string}`,
	active?:boolean | `@${string}`,
	created_at?:boolean | `@${string}`,
	contact?:ResolverInputTypes["Contact"],
	partner?:ResolverInputTypes["Partner"],
	posts?:ResolverInputTypes["Post"],
		__typename?: boolean | `@${string}`
}>;
	["schema"]: AliasType<{
	query?:ResolverInputTypes["Query"],
	mutation?:ResolverInputTypes["Mutation"],
		__typename?: boolean | `@${string}`
}>
  }

export type ModelTypes = {
    ["Order"]:Order;
	/** The `Upload` scalar type represents a file upload. */
["Upload"]:any;
	["Combos"]: {
		data: ModelTypes["ComboData"]
};
	["Cities"]: {
		data: Array<ModelTypes["CityStateData"]>
};
	["AddressInstallation"]: {
		data: ModelTypes["AddressInstallationData"]
};
	["ComboData"]: {
		nationality?: Array<ModelTypes["CityStateData"] | undefined> | undefined,
	gender?: Array<ModelTypes["CityStateData"] | undefined> | undefined,
	occupation?: Array<ModelTypes["CityStateData"] | undefined> | undefined,
	state?: Array<ModelTypes["CityStateData"] | undefined> | undefined
};
	["CepAddress"]: {
		neighborhood: string,
	street: string,
	state: ModelTypes["CityStateData"],
	city: ModelTypes["CityStateData"]
};
	["AddressInstallationData"]: {
		postal_code: string,
	street: string,
	street_number: number,
	additional_info?: string | undefined,
	neighborhood: string,
	city: string,
	state: string,
	rural_area: boolean
};
	["CityStateData"]: {
		name: string,
	id: string
};
	["Query"]: {
		/** Get customer registration combos
param: nationality: boolean - if want nationality
param: gender: boolean - if want gender
param: occupation: boolean - if want occupation
param: state: boolean - if want state */
	address_combos: ModelTypes["Combos"],
	/** Get cities based on state
param: state_id: int - id of state */
	address_cities: ModelTypes["Cities"],
	/** Get address information from CEP
param: cep: string - address CEP */
	address_from_cep: ModelTypes["CepAddress"],
	/** Get installation address
param: project_id: string - id of project */
	address_installation: ModelTypes["AddressInstallation"],
	customer_form?: ModelTypes["CustomerFormResponse"] | undefined,
	documents?: ModelTypes["DocumentResponse"] | undefined,
	financing?: ModelTypes["Financing"] | undefined,
	financings: ModelTypes["FinancingsList"],
	financing_type?: ModelTypes["FinancingType"] | undefined,
	installation?: ModelTypes["Installation"] | undefined,
	mirrorStatus?: ModelTypes["MirrorStatus"] | undefined,
	legacyFinancing?: ModelTypes["LegacyFinancing"] | undefined,
	partners?: Array<ModelTypes["CorePartner"]> | undefined,
	project?: ModelTypes["Project"] | undefined,
	project_proposal_changes?: ModelTypes["ProjectProposal"] | undefined,
	project_is_combo?: ModelTypes["IsComboResponse"] | undefined,
	project_system_types: Array<ModelTypes["SystemTypes"]>,
	registration?: ModelTypes["RegistrationDetailsResponse"] | undefined,
	getOpportunities?: ModelTypes["GetOpportunitiesResponse"] | undefined,
	simulation_project_shopping_cart_id_get?: string | undefined,
	me?: ModelTypes["User"] | undefined
};
	["PersonTypes"]:PersonTypes;
	["DocumentTypes"]:DocumentTypes;
	["LegalRepresentantTypes"]:LegalRepresentantTypes;
	["CustomerStatus"]:CustomerStatus;
	["RegistrationContactChangeResponse"]: {
		message: string,
	status?: number | undefined
};
	["CustomerFormResponse"]: {
		project_id: string,
	is_combo?: boolean | undefined,
	person_type?: ModelTypes["PersonTypes"] | undefined,
	customer?: ModelTypes["CustomerData"] | undefined,
	guarantor?: ModelTypes["CustomerData"] | undefined,
	project?: ModelTypes["CustomerProject"] | undefined,
	remaining_changes_after_confirmation?: number | undefined,
	company?: ModelTypes["CompanyData"] | undefined,
	errors?: Array<ModelTypes["CustomerErrors"] | undefined> | undefined,
	status?: ModelTypes["CustomerStatus"] | undefined
};
	["CustomerErrors"]: {
		field: string,
	title: string,
	description: string
};
	["CustomerContact"]: {
		email?: string | undefined,
	phone?: string | undefined
};
	["CustomerDocument"]: {
		type: ModelTypes["DocumentTypes"],
	status: string,
	filename: string,
	url_download: string,
	url_preview: string,
	mime_type: string,
	id: string
};
	["CustomerAddress"]: {
		zipcode?: string | undefined,
	street?: string | undefined,
	street_number?: string | undefined,
	complement?: string | undefined,
	neighborhood?: string | undefined,
	city_id?: number | undefined,
	state_id?: number | undefined,
	city?: ModelTypes["CustomerIdName"] | undefined,
	state?: ModelTypes["CustomerIdName"] | undefined,
	is_rural?: boolean | undefined
};
	["CustomerData"]: {
		cpf: string,
	full_name?: string | undefined,
	birthdate?: string | undefined,
	contact?: ModelTypes["CustomerContact"] | undefined,
	documents?: Array<ModelTypes["CustomerDocument"] | undefined> | undefined,
	document_type?: string | undefined,
	comment?: string | undefined,
	address?: ModelTypes["CustomerAddress"] | undefined
};
	["CustomerEletricityBill"]: {
		is_under_customer_name?: boolean | undefined,
	holder_cpf?: string | undefined
};
	["CustomerProject"]: {
		address?: ModelTypes["CustomerAddress"] | undefined,
	documents?: Array<ModelTypes["CustomerDocument"] | undefined> | undefined,
	document_type?: string | undefined,
	comment?: string | undefined,
	electricity_bill?: ModelTypes["CustomerEletricityBill"] | undefined,
	residencial_is_the_same_of_plant?: boolean | undefined
};
	["CompanyMajorityPartner"]: {
		cpf: string,
	full_name?: string | undefined,
	birthdate?: string | undefined,
	contact?: ModelTypes["CustomerContact"] | undefined,
	documents?: Array<ModelTypes["CustomerDocument"] | undefined> | undefined,
	document_type?: string | undefined,
	comment?: string | undefined,
	address?: ModelTypes["CustomerAddress"] | undefined
};
	["CompanyLegalRepresentant"]: {
		kind?: ModelTypes["LegalRepresentantTypes"] | undefined,
	cpf: string,
	full_name?: string | undefined,
	birthdate?: string | undefined,
	contact?: ModelTypes["CustomerContact"] | undefined,
	documents?: Array<ModelTypes["CustomerDocument"] | undefined> | undefined,
	document_type?: string | undefined,
	comment?: string | undefined,
	address?: ModelTypes["CustomerAddress"] | undefined
};
	["CompanyData"]: {
		company_name?: string | undefined,
	cnpj?: string | undefined,
	contact?: ModelTypes["CustomerContact"] | undefined,
	documents?: Array<ModelTypes["CustomerDocument"] | undefined> | undefined,
	document_type?: string | undefined,
	comment?: string | undefined,
	majority_partner?: ModelTypes["CompanyMajorityPartner"] | undefined,
	majority_partner_is_legal_representative?: boolean | undefined,
	is_only_one_legal_representative?: boolean | undefined,
	legal_representatives?: Array<ModelTypes["CompanyLegalRepresentant"] | undefined> | undefined
};
	["RegistrationV2PersonType"]:RegistrationV2PersonType;
	["CustomerIdName"]: {
		id: number,
	name: string
};
	["CustomerRegistrationAddress"]: {
	zipcode: string,
	street: string,
	street_number: string,
	complement?: string | undefined,
	neighborhood: string,
	city_id: number,
	state_id: number,
	is_rural?: boolean | undefined
};
	["CustomerRegistrationContact"]: {
	email: string,
	phone: string
};
	["CustomerRegistrationDocument"]: {
	type: string,
	docstorage_id: string
};
	["CustomerRegistrationCustomer"]: {
	full_name: string,
	birthdate: string,
	contact: ModelTypes["CustomerRegistrationContact"],
	documents: Array<ModelTypes["CustomerRegistrationDocument"]>,
	address: ModelTypes["CustomerRegistrationAddress"]
};
	["CustomerRegistrationGuarantor"]: {
	full_name: string,
	birthdate: string,
	contact: ModelTypes["CustomerRegistrationContact"],
	documents: Array<ModelTypes["CustomerRegistrationDocument"]>,
	address: ModelTypes["CustomerRegistrationAddress"]
};
	["CustomerRegistrationElectricityBill"]: {
	is_under_customer_name: boolean,
	holder_cpf?: string | undefined
};
	["CustomerRegistrationProject"]: {
	address: ModelTypes["CustomerRegistrationAddress"],
	documents: Array<ModelTypes["CustomerRegistrationDocument"]>,
	comment?: string | undefined,
	electricity_bill: ModelTypes["CustomerRegistrationElectricityBill"]
};
	["CustomerRegistrationCompany"]: {
	company_name: string,
	contact: ModelTypes["CustomerRegistrationContact"],
	documents: Array<ModelTypes["CustomerRegistrationDocument"]>,
	majority_partner: ModelTypes["CustomerRegistrationCustomer"],
	legal_representatives: Array<ModelTypes["CustomerRegistrationLegalRepresentative"]>
};
	["CustomerRegistrationLegalRepresentative"]: {
	kind: string,
	cpf: string,
	full_name: string,
	birthdate: string,
	contact: ModelTypes["CustomerRegistrationContact"],
	documents: Array<ModelTypes["CustomerRegistrationDocument"]>,
	address: ModelTypes["CustomerRegistrationAddress"]
};
	["CustomerRegistrationInput"]: {
	project_id: string,
	person_type: ModelTypes["RegistrationV2PersonType"],
	customer?: ModelTypes["CustomerRegistrationCustomer"] | undefined,
	guarantor?: ModelTypes["CustomerRegistrationGuarantor"] | undefined,
	project?: ModelTypes["CustomerRegistrationProject"] | undefined,
	company?: ModelTypes["CustomerRegistrationCompany"] | undefined
};
	["Person"]: {
	email?: string | undefined,
	partner_id: string,
	phone?: string | undefined
};
	["CustomerDocumentUpload"]: {
		id: string,
	url?: string | undefined,
	url_inline?: string | undefined,
	filename?: string | undefined,
	mimetype?: string | undefined,
	size?: number | undefined,
	docstorage_id?: string | undefined,
	created_at?: string | undefined
};
	["CustomerRegistrationError"]: {
		field: string,
	message: string,
	type: string,
	title?: string | undefined,
	description?: string | undefined
};
	["CustomerRegistrationV2Response"]: {
		errors: Array<ModelTypes["CustomerRegistrationError"] | undefined>
};
	["CustomerDocumentFormAnalysis"]: {
		id?: string | undefined,
	status?: string | undefined,
	project_id?: string | undefined,
	installation_document_matches_bill?: boolean | undefined,
	installation_address_matches_bill?: boolean | undefined,
	identity_matches_document?: boolean | undefined,
	reason?: string | undefined,
	comments?: string | undefined,
	documents?: Array<ModelTypes["CustomerDocumentUpload"] | undefined> | undefined
};
	["PersonInput"]: {
	email: string,
	phone: string
};
	["RegistrationContactChangeInput"]: {
	project_id: string,
	customer?: ModelTypes["PersonInput"] | undefined,
	guarantor?: ModelTypes["PersonInput"] | undefined,
	majority_partner?: ModelTypes["PersonInput"] | undefined,
	legal_representative_1?: ModelTypes["PersonInput"] | undefined,
	legal_representative_2?: ModelTypes["PersonInput"] | undefined
};
	["Mutation"]: {
		registration_contact_change: ModelTypes["RegistrationContactChangeResponse"],
	customer_document_upload?: Array<ModelTypes["CustomerDocumentUpload"] | undefined> | undefined,
	customer_document_form_analysis_cancel?: ModelTypes["CustomerDocumentFormAnalysis"] | undefined,
	customer_registration?: ModelTypes["CustomerRegistrationV2Response"] | undefined,
	validateHardwareForm?: ModelTypes["HardwareFormValidation"] | undefined,
	hardware_form_showcase?: ModelTypes["HardwareFormShowcaseResponse"] | undefined,
	hardware_form_draft?: string | undefined,
	installation_mirror_upload?: ModelTypes["MirrorUploadResponse"] | undefined,
	/** Update the shopping cart linkage of an project. */
	project_update_shopping_cart: string,
	/** Update the project proposal. */
	project_proposal_update: boolean,
	registration?: boolean | undefined,
	processEnergyAccountAnalysis?: ModelTypes["EnergyBillAnalysisDecision"] | undefined
};
	["DocumentResponse"]: {
		data?: ModelTypes["DocumentData"] | undefined
};
	["DocumentData"]: {
		project?: Array<ModelTypes["Documents"] | undefined> | undefined,
	customer?: Array<ModelTypes["Documents"] | undefined> | undefined,
	guarantor?: Array<ModelTypes["DocumentFile"] | undefined> | undefined,
	company?: Array<ModelTypes["DocumentFile"] | undefined> | undefined
};
	["Documents"]: {
		id?: string | undefined,
	docstorage_id?: string | undefined,
	kind?: string | undefined,
	validated_at?: string | undefined,
	validation_mode?: string | undefined,
	deleted_at?: string | undefined,
	submitted_at?: string | undefined,
	canceled_at?: string | undefined,
	status?: string | undefined,
	reason?: ModelTypes["DocumentReason"] | undefined,
	file?: ModelTypes["DocumentFile"] | undefined
};
	["DocumentFile"]: {
		public_id?: string | undefined,
	filename?: string | undefined,
	mimetype?: string | undefined,
	size?: number | undefined,
	url?: string | undefined,
	url_inline?: string | undefined
};
	["DocumentReason"]: {
		title?: string | undefined,
	description?: string | undefined
};
	["PersonType"]:PersonType;
	["FinancingStatus"]:FinancingStatus;
	["SectionStatus"]:SectionStatus;
	["FormalizationStatus"]: {
		contract?: ModelTypes["SectionStatus"] | undefined,
	hardware?: ModelTypes["SectionStatus"] | undefined,
	registration?: ModelTypes["SectionStatus"] | undefined,
	documentation?: ModelTypes["SectionStatus"] | undefined,
	receipt_model?: ModelTypes["SectionStatus"] | undefined
};
	["SectionStatuses"]: {
		concluded?: ModelTypes["SectionStatus"] | undefined,
	simulation?: ModelTypes["SectionStatus"] | undefined,
	installation?: ModelTypes["SectionStatus"] | undefined,
	formalization?: ModelTypes["FormalizationStatus"] | undefined
};
	["FinancingSteps"]:FinancingSteps;
	["Payment"]: {
		id?: number | undefined,
	financing_id?: number | undefined,
	value?: number | undefined,
	total_value?: number | undefined,
	payment_percentage?: number | undefined,
	created_at?: string | undefined
};
	["Financing"]: {
		id: number,
	project_id: string,
	financed_value: number,
	customer_name: string,
	document: string,
	expires_at: string,
	created_at: string,
	formalized_at?: string | undefined,
	installed_at?: string | undefined,
	status: ModelTypes["FinancingStatus"],
	step: string,
	is_combo: boolean,
	payments: Array<ModelTypes["Payment"]>,
	flow_provider: string,
	person_type: ModelTypes["PersonType"],
	all_payments_sent: boolean,
	section_statuses: ModelTypes["FinancingSectionStatusV2"],
	updated_at?: string | undefined,
	is_expired: boolean,
	system_power?: number | undefined,
	new_journey?: boolean | undefined,
	legacy_id?: number | undefined,
	reason?: string | undefined,
	openable_steps?: ModelTypes["FormalizationStatuses"] | undefined,
	is_aproved?: boolean | undefined,
	is_final?: boolean | undefined
};
	["FormalizationStatuses"]: {
		registration: boolean,
	documentation: boolean,
	hardware: boolean,
	contract: boolean,
	receiptModel: boolean
};
	["FinancingListTotalByStep"]: {
		simulation?: number | undefined,
	formalization?: number | undefined,
	installation?: number | undefined,
	concluded?: number | undefined,
	expired?: number | undefined,
	proposal?: number | undefined
};
	["FinancingListTotalByStatus"]: {
		expired?: number | undefined,
	reproved?: number | undefined,
	approved?: number | undefined,
	under_analysis?: number | undefined,
	canceled?: number | undefined,
	to_review?: number | undefined,
	in_progress?: number | undefined,
	waiting_customer?: number | undefined
};
	["FinancingProposal"]: {
		customer_data?: string | undefined,
	equipment?: string | undefined,
	biometry_and_contract?: string | undefined,
	payment_model?: string | undefined
};
	["FinancingFormalization"]: {
		contract?: string | undefined,
	hardware?: string | undefined,
	registration?: string | undefined,
	documentation?: string | undefined,
	receipt_model?: string | undefined
};
	["FinancingSectionStatusV2"]: {
		concluded?: string | undefined,
	simulation?: string | undefined,
	installation?: string | undefined,
	proposal?: ModelTypes["FinancingProposal"] | undefined,
	formalization?: ModelTypes["FinancingFormalization"] | undefined
};
	["FinancingsList"]: {
		data: Array<ModelTypes["Financing"]>,
	total?: number | undefined,
	total_by_step?: ModelTypes["FinancingListTotalByStep"] | undefined,
	total_by_status?: ModelTypes["FinancingListTotalByStatus"] | undefined
};
	["FinancingsInput"]: {
	query?: string | undefined,
	page?: number | undefined,
	partner_id?: string | undefined,
	steps?: Array<ModelTypes["FinancingSteps"]> | undefined,
	status?: Array<ModelTypes["FinancingStatus"]> | undefined,
	is_combo?: string | undefined,
	page_size?: number | undefined,
	order_by?: string | undefined
};
	["FinancingType"]: {
		max_financing_value: number,
	min_financing_value: number,
	max_financing_installments: number,
	grace_period_financing: ModelTypes["GracePeriod"],
	max_bnpl_value?: number | undefined,
	max_bnpl_installments?: number | undefined,
	grace_period_bnpl?: ModelTypes["GracePeriod"] | undefined
};
	["GracePeriod"]: {
		label?: string | undefined,
	value: number
};
	["FileStatusType"]:FileStatusType;
	["DocumentStatusType"]:DocumentStatusType;
	["TypeSection"]:TypeSection;
	["MirrorStatuses"]:MirrorStatuses;
	["Status"]: {
		description: string,
	id: number,
	slug: ModelTypes["DocumentStatusType"]
};
	["Document"]: {
		filename: string,
	filetype: string,
	id: string,
	status?: ModelTypes["Status"] | undefined,
	type: string,
	url: string
};
	["Section"]: {
		documents?: Array<ModelTypes["Document"] | undefined> | undefined,
	id: string,
	status: ModelTypes["Status"],
	type: ModelTypes["TypeSection"]
};
	["InstallationStatus"]: {
		description: string,
	id: number,
	slug: ModelTypes["FileStatusType"]
};
	["Installation"]: {
		comment?: string | undefined,
	concluded_at?: string | undefined,
	grade: number,
	has_ampera: boolean,
	has_ampera_activated: boolean,
	has_minimal_projects: boolean,
	id: string,
	new_journey: boolean,
	plant_id?: string | undefined,
	plant_url?: string | undefined,
	project_id: string,
	project_name: string,
	sections: Array<ModelTypes["Section"]>,
	status: ModelTypes["InstallationStatus"]
};
	["HardwareFormInverter"]: {
	id: number,
	quantity: number,
	power?: number | undefined
};
	["HardwareFormModule"]: {
	id: number,
	quantity: number,
	power?: number | undefined
};
	["HardwareFormComboEquipment"]: {
	sku: string,
	power?: number | undefined,
	quantity: number
};
	["HardwareFormBatteries"]: {
	id: number,
	quantity: number,
	power?: number | undefined
};
	["HardwareFormComboMetadata"]: {
	project_id: string,
	sku?: string | undefined,
	power?: number | undefined,
	price?: number | undefined,
	inverters: Array<ModelTypes["HardwareFormComboEquipment"]>,
	modules: Array<ModelTypes["HardwareFormComboEquipment"]>
};
	["HardwareFormInput"]: {
	is_combo: boolean,
	id?: string | undefined,
	combo_price?: number | undefined,
	inverter_type?: string | undefined,
	project_id: string,
	distributor_id: number,
	phase_id: number,
	inverters: Array<ModelTypes["HardwareFormInverter"]>,
	modules: Array<ModelTypes["HardwareFormModule"]>,
	batteries?: Array<ModelTypes["HardwareFormBatteries"]> | undefined,
	combo_metadata?: ModelTypes["HardwareFormComboMetadata"] | undefined,
	is_showcase?: boolean | undefined,
	shopping_cart_id?: string | undefined
};
	["HardwareFormShowcaseInput"]: {
	price?: number | undefined,
	project_id: string,
	inverters: Array<ModelTypes["HardwareFormInverter"]>,
	modules: Array<ModelTypes["HardwareFormModule"]>,
	shopping_cart_id?: string | undefined
};
	["ValidateHardwareFormPricePerWatt"]: {
		is_valid?: boolean | undefined,
	is_under_expected?: boolean | undefined
};
	["ValidateHardwareFormModules"]: {
		price_per_watt?: ModelTypes["ValidateHardwareFormPricePerWatt"] | undefined
};
	["ValidateHardwareFormOverload"]: {
		is_valid?: boolean | undefined,
	is_under_expected?: boolean | undefined
};
	["ValidateHardwareFormInverters"]: {
		overload?: ModelTypes["ValidateHardwareFormOverload"] | undefined
};
	["ValidateHardwareFormHardware"]: {
		modules?: ModelTypes["ValidateHardwareFormModules"] | undefined,
	inverters?: ModelTypes["ValidateHardwareFormInverters"] | undefined
};
	["HardwareFormValidation"]: {
		is_valid?: boolean | undefined,
	hardware?: ModelTypes["ValidateHardwareFormHardware"] | undefined
};
	["HardwareFormShowcaseResponse"]: {
		overload_is_valid?: boolean | undefined,
	price_per_watt_is_valid?: boolean | undefined,
	percentage_of_service_with_combo_is_valid?: boolean | undefined
};
	["MirrorStatus"]: {
		status: ModelTypes["MirrorStatuses"]
};
	["MirrorUploadResponse"]: {
		id: string,
	url: string
};
	["HardwareDraftEquipment"]: {
	sku: string,
	quantity: number,
	power?: number | undefined,
	model: string,
	brand: string
};
	["HardwareDraft"]: {
	project_id: string,
	sku?: string | undefined,
	power?: number | undefined,
	price?: number | undefined,
	inverters: Array<ModelTypes["HardwareDraftEquipment"]>,
	modules: Array<ModelTypes["HardwareDraftEquipment"]>
};
	["LegacyFinancing"]: {
		projectId: string,
	financingId: number,
	formalizedAt?: string | undefined,
	stage: string,
	status: string
};
	["CorePartner"]: {
		id: number,
	cnpj?: string | undefined,
	responsible?: ModelTypes["CoreParterResponsible"] | undefined,
	company_name: string,
	fantasy_name: string,
	status: Array<ModelTypes["CoreParterStatus"] | undefined>
};
	["CoreParterResponsible"]: {
		id?: number | undefined,
	full_name?: string | undefined
};
	["CoreParterStatus"]: {
		active?: boolean | undefined,
	reason?: string | undefined,
	date?: string | undefined
};
	["CoreGetPartnersInput"]: {
	page?: number | undefined,
	search_term?: string | undefined
};
	["SystemTypes"]:SystemTypes;
	["ProjectProposal"]: {
		value_changed?: boolean | undefined
};
	["AppliedModifierOption"]: {
		type: string,
	value: string
};
	["AppliedModifier"]: {
		name: string,
	type: string,
	options: Array<ModelTypes["AppliedModifierOption"]>
};
	["AddonProduct"]: {
		id: string,
	product_name: string,
	product_external_id: string,
	simulation_id: string,
	type: string,
	product_slug: string,
	applied: boolean,
	partner_commission: number,
	installment_price: number,
	total_price: number,
	monthly_interest_rate?: number | undefined,
	engine_simulation_id: string,
	previous_engine_simulation_id: string,
	cet?: number | undefined,
	applied_modifiers: Array<ModelTypes["AppliedModifier"]>,
	addon_type?: string | undefined
};
	["SelectedInstallments"]: {
		cet: string,
	iof: number,
	ipca: string,
	addons: Array<ModelTypes["AddonProduct"]>,
	aggregated_addons: Array<ModelTypes["AddonProduct"]>,
	user_id: number,
	document: string,
	is_combo: boolean,
	commission: number,
	partner_id: number,
	aliquot_iof: number,
	new_journey: boolean,
	person_type: string,
	down_payment: number,
	grace_period: number,
	installments: number,
	project_name: string,
	system_power: number,
	interest_rate: number,
	cet_percentage: number,
	commission_raw: number,
	financing_value: number,
	taxa_de_cadastro: number,
	installment_value: number,
	is_from_suggested: boolean,
	registration_fee_gross: number,
	registration_fee_raw_dry: number,
	registration_fee_raw_final: number
};
	["RiskInfo"]: {
		title: string,
	description: string,
	todo: string,
	reason?: string | undefined,
	decision: number
};
	["BNPL"]: {
		decision_pre_analysis: number,
	min_entry_value: number,
	max_installments: number,
	pre_approved_value: number,
	status?: string | undefined
};
	["Guarantor"]: {
		id?: string | undefined,
	document?: string | undefined,
	birth_date?: string | undefined,
	border_line?: boolean | undefined,
	created_at?: string | undefined,
	customer_second_financing_policy?: boolean | undefined,
	decision_pre_analysis?: number | undefined,
	max_approved_project_value?: number | undefined,
	max_financing_value?: number | undefined,
	max_grace_period?: number | undefined,
	max_installments?: number | undefined,
	min_approved_project_value?: number | undefined,
	min_installment_value?: number | undefined,
	pre_approved_value?: number | undefined,
	project_name?: string | undefined,
	project_state?: string | undefined,
	risk_class?: string | undefined,
	status?: string | undefined
};
	["Project"]: {
		id: string,
	user_id: number,
	partner_id: number,
	person_type: string,
	document: string,
	created_at: string,
	representative_document?: string | undefined,
	project_value: number,
	system_power?: number | undefined,
	grace_period: number,
	down_payment: number,
	status: string,
	project_status: string,
	max_installments: number,
	max_grace_period: number,
	project_name: string,
	project_state: string,
	partner_profile: string,
	pre_approved_value: number,
	products_applieds?: string | undefined,
	installments?: string | undefined,
	risk_info?: ModelTypes["RiskInfo"] | undefined,
	state_registration?: string | undefined,
	complete: boolean,
	selected_installments?: ModelTypes["SelectedInstallments"] | undefined,
	guarantor?: ModelTypes["Guarantor"] | undefined,
	birth_date: string,
	decision_pre_analysis: number,
	score_sol_facil: number,
	simulation_id?: string | undefined,
	addons: Array<ModelTypes["AddonProduct"]>,
	max_approved_project_value: number,
	min_approved_project_value: number,
	max_financing_value: number,
	border_line: boolean,
	is_formalized: boolean,
	new_journey: boolean,
	formalized_at?: string | undefined,
	shopping_cart_id?: string | undefined,
	bnpl?: ModelTypes["BNPL"] | undefined,
	bnpl_simulation_id?: string | undefined,
	bnpl_status?: string | undefined,
	is_onflex_partner: boolean,
	can_select_ampera: boolean,
	has_energy_bill_analysis: boolean,
	insurance_commission: number,
	gained_points: number,
	original_installment_value: number,
	is_contract_signed?: boolean | undefined,
	commission?: number | undefined,
	risk_class?: string | undefined,
	selected_installment?: number | undefined,
	credit_analysis_status?: string | undefined
};
	["ProjectProposalUpdatePayloadInput"]: {
	engine_simulation_id: string,
	simulation_id: string,
	is_combo: boolean,
	is_ampera: boolean,
	commission: number,
	applied_addons: Array<string>
};
	["ProjectProposalUpdateResponse"]: {
		engine_simulation_id: string,
	simulation_id: string,
	project_id: string,
	is_combo: boolean,
	is_ampera: boolean,
	commission: number,
	applied_addons: Array<string>
};
	["IsComboResponse"]: {
		data?: boolean | undefined
};
	["RegistrationPersonType"]:RegistrationPersonType;
	["RegistrationDocumentTypes"]:RegistrationDocumentTypes;
	["RegistrationRepresentantTypes"]:RegistrationRepresentantTypes;
	["RegistrationContact"]: {
		email: string,
	phone: string
};
	["RegistrationAddressCity"]: {
		id: number,
	name: string
};
	["RegistrationAddressState"]: {
		id: number,
	name: string
};
	["RegistrationDocumentModel"]:RegistrationDocumentModel;
	["RegistrationElectricityBill"]: {
		holder_cpf: string,
	is_under_customer_name: boolean
};
	["RegistrationDocument"]: {
		type: ModelTypes["RegistrationDocumentTypes"],
	status: string,
	filename: string,
	url_download: string,
	url_preview: string,
	mime_type: string,
	docstorage_id: string,
	id: string
};
	["RegistrationAddress"]: {
		zipcode?: string | undefined,
	street?: string | undefined,
	street_number?: string | undefined,
	complement?: string | undefined,
	neighborhood?: string | undefined,
	city_id?: number | undefined,
	state_id?: number | undefined,
	city?: ModelTypes["RegistrationAddressCity"] | undefined,
	state?: ModelTypes["RegistrationAddressState"] | undefined,
	is_rural?: boolean | undefined
};
	["RegistrationProject"]: {
		address?: ModelTypes["RegistrationAddress"] | undefined,
	documents?: Array<ModelTypes["RegistrationDocument"] | undefined> | undefined,
	electricity_bill?: ModelTypes["RegistrationElectricityBill"] | undefined,
	residencial_is_the_same_of_plant?: boolean | undefined,
	comment?: string | undefined
};
	["RegistrationCustomer"]: {
		cpf: string,
	full_name?: string | undefined,
	birthdate?: string | undefined,
	contact?: ModelTypes["RegistrationContact"] | undefined,
	documents: Array<ModelTypes["RegistrationDocument"]>,
	document_type?: ModelTypes["RegistrationDocumentModel"] | undefined,
	address?: ModelTypes["RegistrationAddress"] | undefined
};
	["RegistrationCompany"]: {
		company_name: string,
	cnpj: string,
	contact?: ModelTypes["RegistrationContact"] | undefined,
	documents: Array<ModelTypes["RegistrationDocument"]>,
	majority_partner: ModelTypes["RegistrationCompanyMajorityPartner"],
	legal_representatives: Array<ModelTypes["RegistrationLegalRepresentative"]>,
	majority_partner_is_legal_representative?: boolean | undefined,
	is_only_one_legal_representative?: boolean | undefined
};
	["RegistrationLegalRepresentative"]: {
		cpf: string,
	full_name?: string | undefined,
	birthdate?: string | undefined,
	contact?: ModelTypes["RegistrationContact"] | undefined,
	documents: Array<ModelTypes["RegistrationDocument"]>,
	address?: ModelTypes["RegistrationAddress"] | undefined,
	kind: ModelTypes["RegistrationRepresentantTypes"],
	document_type?: ModelTypes["RegistrationDocumentModel"] | undefined
};
	["RegistrationCompanyMajorityPartner"]: {
		cpf: string,
	is_legal_representative?: boolean | undefined,
	full_name?: string | undefined,
	birthdate?: string | undefined,
	contact?: ModelTypes["RegistrationContact"] | undefined,
	documents: Array<ModelTypes["RegistrationDocument"]>,
	address?: ModelTypes["RegistrationAddress"] | undefined,
	document_type?: ModelTypes["RegistrationDocumentModel"] | undefined
};
	["RegistrationGuarantor"]: {
		cpf: string,
	full_name: string,
	birthdate?: string | undefined,
	contact?: ModelTypes["RegistrationContact"] | undefined,
	documents: Array<ModelTypes["RegistrationDocument"]>,
	address?: ModelTypes["RegistrationAddress"] | undefined,
	document_type?: ModelTypes["RegistrationDocumentModel"] | undefined
};
	["RegistrationError"]: {
		title?: string | undefined,
	description: string,
	field: string,
	type?: string | undefined
};
	["RegistrationUpdateInput"]: {
	project_id: string
};
	["RegistrationDetailsResponse"]: {
		project_id: string,
	status: string,
	is_combo: boolean,
	remaining_changes_after_confirmation?: number | undefined,
	person_type: ModelTypes["RegistrationPersonType"],
	customer?: ModelTypes["RegistrationCustomer"] | undefined,
	company?: ModelTypes["RegistrationCompany"] | undefined,
	guarantor?: ModelTypes["RegistrationGuarantor"] | undefined,
	project?: ModelTypes["RegistrationProject"] | undefined,
	errors: Array<ModelTypes["RegistrationError"]>
};
	["AnalyzeEnergyBillInput"]: {
	person_type: string,
	project_id: string,
	document_customer: string,
	financing_id: number,
	document_owner_energy_bill: string,
	auto_cancel_flow_on_rejection: boolean
};
	["GetOpportunitiesInput"]: {
	financing_value: number,
	partner_id: number,
	project_type: string
};
	["EnergyBillAnalysisDecision"]: {
		decision: string,
	details?: string | undefined
};
	["GetOpportunitiesResponse"]: {
		addons_opportunities: Array<ModelTypes["AddonsOpportunities"]>,
	financing_gained_points?: number | undefined
};
	["AddonsOpportunities"]: {
		addon_type?: string | undefined,
	gained_points?: number | undefined,
	product_slug?: string | undefined
};
	["LevelBlackStatus"]: {
		kwGoalMonth: number,
	kwReachedMonth: number
};
	["Contact"]: {
		landline?: string | undefined,
	mobile: string,
	email: string
};
	["Address"]: {
		street: string,
	number: string,
	complement?: string | undefined,
	reference?: string | undefined,
	district: string,
	zipcode: string,
	city: string,
	state: string
};
	["Responsible"]: {
		id: number,
	username?: string | undefined,
	profile: string,
	fullname: string,
	document?: string | undefined,
	birthdate?: string | undefined,
	active: boolean,
	created_at: string,
	contact: ModelTypes["Contact"],
	partner?: ModelTypes["Partner"] | undefined
};
	["Permission"]: {
		store: boolean,
	financing: boolean
};
	["Score"]: {
		current: number,
	goal: number
};
	["Downgrade"]: {
		has_risk: boolean
};
	["Sfplus"]: {
		level: number,
	score?: ModelTypes["Score"] | undefined,
	black_status?: ModelTypes["LevelBlackStatus"] | undefined,
	downgrade?: ModelTypes["Downgrade"] | undefined,
	is_level_black: boolean,
	next_transition_date?: string | undefined
};
	["Partner"]: {
		id: number,
	corporate_name: string,
	operation_term_accepted: boolean,
	trade_name: string,
	document: string,
	category?: string | undefined,
	state_registration: string,
	active: boolean,
	created_at: string,
	address: ModelTypes["Address"],
	responsible: ModelTypes["Responsible"],
	permission: ModelTypes["Permission"],
	users?: Array<ModelTypes["User"] | undefined> | undefined,
	sfplus?: ModelTypes["Sfplus"] | undefined
};
	["Post"]: {
		id: number,
	title: string,
	body: string,
	userId: string,
	comments?: Array<ModelTypes["Comment"] | undefined> | undefined
};
	["Comment"]: {
		id: number,
	name: string,
	email: string,
	body: string
};
	["User"]: {
		id: number,
	username?: string | undefined,
	profile?: string | undefined,
	fullname: string,
	document?: string | undefined,
	birthdate?: string | undefined,
	active: boolean,
	created_at: string,
	contact: ModelTypes["Contact"],
	partner?: ModelTypes["Partner"] | undefined,
	posts?: Array<ModelTypes["Post"] | undefined> | undefined
};
	["schema"]: {
	query?: ModelTypes["Query"] | undefined,
	mutation?: ModelTypes["Mutation"] | undefined
}
    }

export type GraphQLTypes = {
    ["Order"]: Order;
	/** The `Upload` scalar type represents a file upload. */
["Upload"]: "scalar" & { name: "Upload" };
	["Combos"]: {
	__typename: "Combos",
	data: GraphQLTypes["ComboData"]
};
	["Cities"]: {
	__typename: "Cities",
	data: Array<GraphQLTypes["CityStateData"]>
};
	["AddressInstallation"]: {
	__typename: "AddressInstallation",
	data: GraphQLTypes["AddressInstallationData"]
};
	["ComboData"]: {
	__typename: "ComboData",
	nationality?: Array<GraphQLTypes["CityStateData"] | undefined> | undefined,
	gender?: Array<GraphQLTypes["CityStateData"] | undefined> | undefined,
	occupation?: Array<GraphQLTypes["CityStateData"] | undefined> | undefined,
	state?: Array<GraphQLTypes["CityStateData"] | undefined> | undefined
};
	["CepAddress"]: {
	__typename: "CepAddress",
	neighborhood: string,
	street: string,
	state: GraphQLTypes["CityStateData"],
	city: GraphQLTypes["CityStateData"]
};
	["AddressInstallationData"]: {
	__typename: "AddressInstallationData",
	postal_code: string,
	street: string,
	street_number: number,
	additional_info?: string | undefined,
	neighborhood: string,
	city: string,
	state: string,
	rural_area: boolean
};
	["CityStateData"]: {
	__typename: "CityStateData",
	name: string,
	id: string
};
	["Query"]: {
	__typename: "Query",
	/** Get customer registration combos
param: nationality: boolean - if want nationality
param: gender: boolean - if want gender
param: occupation: boolean - if want occupation
param: state: boolean - if want state */
	address_combos: GraphQLTypes["Combos"],
	/** Get cities based on state
param: state_id: int - id of state */
	address_cities: GraphQLTypes["Cities"],
	/** Get address information from CEP
param: cep: string - address CEP */
	address_from_cep: GraphQLTypes["CepAddress"],
	/** Get installation address
param: project_id: string - id of project */
	address_installation: GraphQLTypes["AddressInstallation"],
	customer_form?: GraphQLTypes["CustomerFormResponse"] | undefined,
	documents?: GraphQLTypes["DocumentResponse"] | undefined,
	financing?: GraphQLTypes["Financing"] | undefined,
	financings: GraphQLTypes["FinancingsList"],
	financing_type?: GraphQLTypes["FinancingType"] | undefined,
	installation?: GraphQLTypes["Installation"] | undefined,
	mirrorStatus?: GraphQLTypes["MirrorStatus"] | undefined,
	legacyFinancing?: GraphQLTypes["LegacyFinancing"] | undefined,
	partners?: Array<GraphQLTypes["CorePartner"]> | undefined,
	project?: GraphQLTypes["Project"] | undefined,
	project_proposal_changes?: GraphQLTypes["ProjectProposal"] | undefined,
	project_is_combo?: GraphQLTypes["IsComboResponse"] | undefined,
	project_system_types: Array<GraphQLTypes["SystemTypes"]>,
	registration?: GraphQLTypes["RegistrationDetailsResponse"] | undefined,
	getOpportunities?: GraphQLTypes["GetOpportunitiesResponse"] | undefined,
	simulation_project_shopping_cart_id_get?: string | undefined,
	me?: GraphQLTypes["User"] | undefined
};
	["PersonTypes"]: PersonTypes;
	["DocumentTypes"]: DocumentTypes;
	["LegalRepresentantTypes"]: LegalRepresentantTypes;
	["CustomerStatus"]: CustomerStatus;
	["RegistrationContactChangeResponse"]: {
	__typename: "RegistrationContactChangeResponse",
	message: string,
	status?: number | undefined
};
	["CustomerFormResponse"]: {
	__typename: "CustomerFormResponse",
	project_id: string,
	is_combo?: boolean | undefined,
	person_type?: GraphQLTypes["PersonTypes"] | undefined,
	customer?: GraphQLTypes["CustomerData"] | undefined,
	guarantor?: GraphQLTypes["CustomerData"] | undefined,
	project?: GraphQLTypes["CustomerProject"] | undefined,
	remaining_changes_after_confirmation?: number | undefined,
	company?: GraphQLTypes["CompanyData"] | undefined,
	errors?: Array<GraphQLTypes["CustomerErrors"] | undefined> | undefined,
	status?: GraphQLTypes["CustomerStatus"] | undefined
};
	["CustomerErrors"]: {
	__typename: "CustomerErrors",
	field: string,
	title: string,
	description: string
};
	["CustomerContact"]: {
	__typename: "CustomerContact",
	email?: string | undefined,
	phone?: string | undefined
};
	["CustomerDocument"]: {
	__typename: "CustomerDocument",
	type: GraphQLTypes["DocumentTypes"],
	status: string,
	filename: string,
	url_download: string,
	url_preview: string,
	mime_type: string,
	id: string
};
	["CustomerAddress"]: {
	__typename: "CustomerAddress",
	zipcode?: string | undefined,
	street?: string | undefined,
	street_number?: string | undefined,
	complement?: string | undefined,
	neighborhood?: string | undefined,
	city_id?: number | undefined,
	state_id?: number | undefined,
	city?: GraphQLTypes["CustomerIdName"] | undefined,
	state?: GraphQLTypes["CustomerIdName"] | undefined,
	is_rural?: boolean | undefined
};
	["CustomerData"]: {
	__typename: "CustomerData",
	cpf: string,
	full_name?: string | undefined,
	birthdate?: string | undefined,
	contact?: GraphQLTypes["CustomerContact"] | undefined,
	documents?: Array<GraphQLTypes["CustomerDocument"] | undefined> | undefined,
	document_type?: string | undefined,
	comment?: string | undefined,
	address?: GraphQLTypes["CustomerAddress"] | undefined
};
	["CustomerEletricityBill"]: {
	__typename: "CustomerEletricityBill",
	is_under_customer_name?: boolean | undefined,
	holder_cpf?: string | undefined
};
	["CustomerProject"]: {
	__typename: "CustomerProject",
	address?: GraphQLTypes["CustomerAddress"] | undefined,
	documents?: Array<GraphQLTypes["CustomerDocument"] | undefined> | undefined,
	document_type?: string | undefined,
	comment?: string | undefined,
	electricity_bill?: GraphQLTypes["CustomerEletricityBill"] | undefined,
	residencial_is_the_same_of_plant?: boolean | undefined
};
	["CompanyMajorityPartner"]: {
	__typename: "CompanyMajorityPartner",
	cpf: string,
	full_name?: string | undefined,
	birthdate?: string | undefined,
	contact?: GraphQLTypes["CustomerContact"] | undefined,
	documents?: Array<GraphQLTypes["CustomerDocument"] | undefined> | undefined,
	document_type?: string | undefined,
	comment?: string | undefined,
	address?: GraphQLTypes["CustomerAddress"] | undefined
};
	["CompanyLegalRepresentant"]: {
	__typename: "CompanyLegalRepresentant",
	kind?: GraphQLTypes["LegalRepresentantTypes"] | undefined,
	cpf: string,
	full_name?: string | undefined,
	birthdate?: string | undefined,
	contact?: GraphQLTypes["CustomerContact"] | undefined,
	documents?: Array<GraphQLTypes["CustomerDocument"] | undefined> | undefined,
	document_type?: string | undefined,
	comment?: string | undefined,
	address?: GraphQLTypes["CustomerAddress"] | undefined
};
	["CompanyData"]: {
	__typename: "CompanyData",
	company_name?: string | undefined,
	cnpj?: string | undefined,
	contact?: GraphQLTypes["CustomerContact"] | undefined,
	documents?: Array<GraphQLTypes["CustomerDocument"] | undefined> | undefined,
	document_type?: string | undefined,
	comment?: string | undefined,
	majority_partner?: GraphQLTypes["CompanyMajorityPartner"] | undefined,
	majority_partner_is_legal_representative?: boolean | undefined,
	is_only_one_legal_representative?: boolean | undefined,
	legal_representatives?: Array<GraphQLTypes["CompanyLegalRepresentant"] | undefined> | undefined
};
	["RegistrationV2PersonType"]: RegistrationV2PersonType;
	["CustomerIdName"]: {
	__typename: "CustomerIdName",
	id: number,
	name: string
};
	["CustomerRegistrationAddress"]: {
		zipcode: string,
	street: string,
	street_number: string,
	complement?: string | undefined,
	neighborhood: string,
	city_id: number,
	state_id: number,
	is_rural?: boolean | undefined
};
	["CustomerRegistrationContact"]: {
		email: string,
	phone: string
};
	["CustomerRegistrationDocument"]: {
		type: string,
	docstorage_id: string
};
	["CustomerRegistrationCustomer"]: {
		full_name: string,
	birthdate: string,
	contact: GraphQLTypes["CustomerRegistrationContact"],
	documents: Array<GraphQLTypes["CustomerRegistrationDocument"]>,
	address: GraphQLTypes["CustomerRegistrationAddress"]
};
	["CustomerRegistrationGuarantor"]: {
		full_name: string,
	birthdate: string,
	contact: GraphQLTypes["CustomerRegistrationContact"],
	documents: Array<GraphQLTypes["CustomerRegistrationDocument"]>,
	address: GraphQLTypes["CustomerRegistrationAddress"]
};
	["CustomerRegistrationElectricityBill"]: {
		is_under_customer_name: boolean,
	holder_cpf?: string | undefined
};
	["CustomerRegistrationProject"]: {
		address: GraphQLTypes["CustomerRegistrationAddress"],
	documents: Array<GraphQLTypes["CustomerRegistrationDocument"]>,
	comment?: string | undefined,
	electricity_bill: GraphQLTypes["CustomerRegistrationElectricityBill"]
};
	["CustomerRegistrationCompany"]: {
		company_name: string,
	contact: GraphQLTypes["CustomerRegistrationContact"],
	documents: Array<GraphQLTypes["CustomerRegistrationDocument"]>,
	majority_partner: GraphQLTypes["CustomerRegistrationCustomer"],
	legal_representatives: Array<GraphQLTypes["CustomerRegistrationLegalRepresentative"]>
};
	["CustomerRegistrationLegalRepresentative"]: {
		kind: string,
	cpf: string,
	full_name: string,
	birthdate: string,
	contact: GraphQLTypes["CustomerRegistrationContact"],
	documents: Array<GraphQLTypes["CustomerRegistrationDocument"]>,
	address: GraphQLTypes["CustomerRegistrationAddress"]
};
	["CustomerRegistrationInput"]: {
		project_id: string,
	person_type: GraphQLTypes["RegistrationV2PersonType"],
	customer?: GraphQLTypes["CustomerRegistrationCustomer"] | undefined,
	guarantor?: GraphQLTypes["CustomerRegistrationGuarantor"] | undefined,
	project?: GraphQLTypes["CustomerRegistrationProject"] | undefined,
	company?: GraphQLTypes["CustomerRegistrationCompany"] | undefined
};
	["Person"]: {
		email?: string | undefined,
	partner_id: string,
	phone?: string | undefined
};
	["CustomerDocumentUpload"]: {
	__typename: "CustomerDocumentUpload",
	id: string,
	url?: string | undefined,
	url_inline?: string | undefined,
	filename?: string | undefined,
	mimetype?: string | undefined,
	size?: number | undefined,
	docstorage_id?: string | undefined,
	created_at?: string | undefined
};
	["CustomerRegistrationError"]: {
	__typename: "CustomerRegistrationError",
	field: string,
	message: string,
	type: string,
	title?: string | undefined,
	description?: string | undefined
};
	["CustomerRegistrationV2Response"]: {
	__typename: "CustomerRegistrationV2Response",
	errors: Array<GraphQLTypes["CustomerRegistrationError"] | undefined>
};
	["CustomerDocumentFormAnalysis"]: {
	__typename: "CustomerDocumentFormAnalysis",
	id?: string | undefined,
	status?: string | undefined,
	project_id?: string | undefined,
	installation_document_matches_bill?: boolean | undefined,
	installation_address_matches_bill?: boolean | undefined,
	identity_matches_document?: boolean | undefined,
	reason?: string | undefined,
	comments?: string | undefined,
	documents?: Array<GraphQLTypes["CustomerDocumentUpload"] | undefined> | undefined
};
	["PersonInput"]: {
		email: string,
	phone: string
};
	["RegistrationContactChangeInput"]: {
		project_id: string,
	customer?: GraphQLTypes["PersonInput"] | undefined,
	guarantor?: GraphQLTypes["PersonInput"] | undefined,
	majority_partner?: GraphQLTypes["PersonInput"] | undefined,
	legal_representative_1?: GraphQLTypes["PersonInput"] | undefined,
	legal_representative_2?: GraphQLTypes["PersonInput"] | undefined
};
	["Mutation"]: {
	__typename: "Mutation",
	registration_contact_change: GraphQLTypes["RegistrationContactChangeResponse"],
	customer_document_upload?: Array<GraphQLTypes["CustomerDocumentUpload"] | undefined> | undefined,
	customer_document_form_analysis_cancel?: GraphQLTypes["CustomerDocumentFormAnalysis"] | undefined,
	customer_registration?: GraphQLTypes["CustomerRegistrationV2Response"] | undefined,
	validateHardwareForm?: GraphQLTypes["HardwareFormValidation"] | undefined,
	hardware_form_showcase?: GraphQLTypes["HardwareFormShowcaseResponse"] | undefined,
	hardware_form_draft?: string | undefined,
	installation_mirror_upload?: GraphQLTypes["MirrorUploadResponse"] | undefined,
	/** Update the shopping cart linkage of an project. */
	project_update_shopping_cart: string,
	/** Update the project proposal. */
	project_proposal_update: boolean,
	registration?: boolean | undefined,
	processEnergyAccountAnalysis?: GraphQLTypes["EnergyBillAnalysisDecision"] | undefined
};
	["DocumentResponse"]: {
	__typename: "DocumentResponse",
	data?: GraphQLTypes["DocumentData"] | undefined
};
	["DocumentData"]: {
	__typename: "DocumentData",
	project?: Array<GraphQLTypes["Documents"] | undefined> | undefined,
	customer?: Array<GraphQLTypes["Documents"] | undefined> | undefined,
	guarantor?: Array<GraphQLTypes["DocumentFile"] | undefined> | undefined,
	company?: Array<GraphQLTypes["DocumentFile"] | undefined> | undefined
};
	["Documents"]: {
	__typename: "Documents",
	id?: string | undefined,
	docstorage_id?: string | undefined,
	kind?: string | undefined,
	validated_at?: string | undefined,
	validation_mode?: string | undefined,
	deleted_at?: string | undefined,
	submitted_at?: string | undefined,
	canceled_at?: string | undefined,
	status?: string | undefined,
	reason?: GraphQLTypes["DocumentReason"] | undefined,
	file?: GraphQLTypes["DocumentFile"] | undefined
};
	["DocumentFile"]: {
	__typename: "DocumentFile",
	public_id?: string | undefined,
	filename?: string | undefined,
	mimetype?: string | undefined,
	size?: number | undefined,
	url?: string | undefined,
	url_inline?: string | undefined
};
	["DocumentReason"]: {
	__typename: "DocumentReason",
	title?: string | undefined,
	description?: string | undefined
};
	["PersonType"]: PersonType;
	["FinancingStatus"]: FinancingStatus;
	["SectionStatus"]: SectionStatus;
	["FormalizationStatus"]: {
	__typename: "FormalizationStatus",
	contract?: GraphQLTypes["SectionStatus"] | undefined,
	hardware?: GraphQLTypes["SectionStatus"] | undefined,
	registration?: GraphQLTypes["SectionStatus"] | undefined,
	documentation?: GraphQLTypes["SectionStatus"] | undefined,
	receipt_model?: GraphQLTypes["SectionStatus"] | undefined
};
	["SectionStatuses"]: {
	__typename: "SectionStatuses",
	concluded?: GraphQLTypes["SectionStatus"] | undefined,
	simulation?: GraphQLTypes["SectionStatus"] | undefined,
	installation?: GraphQLTypes["SectionStatus"] | undefined,
	formalization?: GraphQLTypes["FormalizationStatus"] | undefined
};
	["FinancingSteps"]: FinancingSteps;
	["Payment"]: {
	__typename: "Payment",
	id?: number | undefined,
	financing_id?: number | undefined,
	value?: number | undefined,
	total_value?: number | undefined,
	payment_percentage?: number | undefined,
	created_at?: string | undefined
};
	["Financing"]: {
	__typename: "Financing",
	id: number,
	project_id: string,
	financed_value: number,
	customer_name: string,
	document: string,
	expires_at: string,
	created_at: string,
	formalized_at?: string | undefined,
	installed_at?: string | undefined,
	status: GraphQLTypes["FinancingStatus"],
	step: string,
	is_combo: boolean,
	payments: Array<GraphQLTypes["Payment"]>,
	flow_provider: string,
	person_type: GraphQLTypes["PersonType"],
	all_payments_sent: boolean,
	section_statuses: GraphQLTypes["FinancingSectionStatusV2"],
	updated_at?: string | undefined,
	is_expired: boolean,
	system_power?: number | undefined,
	new_journey?: boolean | undefined,
	legacy_id?: number | undefined,
	reason?: string | undefined,
	openable_steps?: GraphQLTypes["FormalizationStatuses"] | undefined,
	is_aproved?: boolean | undefined,
	is_final?: boolean | undefined
};
	["FormalizationStatuses"]: {
	__typename: "FormalizationStatuses",
	registration: boolean,
	documentation: boolean,
	hardware: boolean,
	contract: boolean,
	receiptModel: boolean
};
	["FinancingListTotalByStep"]: {
	__typename: "FinancingListTotalByStep",
	simulation?: number | undefined,
	formalization?: number | undefined,
	installation?: number | undefined,
	concluded?: number | undefined,
	expired?: number | undefined,
	proposal?: number | undefined
};
	["FinancingListTotalByStatus"]: {
	__typename: "FinancingListTotalByStatus",
	expired?: number | undefined,
	reproved?: number | undefined,
	approved?: number | undefined,
	under_analysis?: number | undefined,
	canceled?: number | undefined,
	to_review?: number | undefined,
	in_progress?: number | undefined,
	waiting_customer?: number | undefined
};
	["FinancingProposal"]: {
	__typename: "FinancingProposal",
	customer_data?: string | undefined,
	equipment?: string | undefined,
	biometry_and_contract?: string | undefined,
	payment_model?: string | undefined
};
	["FinancingFormalization"]: {
	__typename: "FinancingFormalization",
	contract?: string | undefined,
	hardware?: string | undefined,
	registration?: string | undefined,
	documentation?: string | undefined,
	receipt_model?: string | undefined
};
	["FinancingSectionStatusV2"]: {
	__typename: "FinancingSectionStatusV2",
	concluded?: string | undefined,
	simulation?: string | undefined,
	installation?: string | undefined,
	proposal?: GraphQLTypes["FinancingProposal"] | undefined,
	formalization?: GraphQLTypes["FinancingFormalization"] | undefined
};
	["FinancingsList"]: {
	__typename: "FinancingsList",
	data: Array<GraphQLTypes["Financing"]>,
	total?: number | undefined,
	total_by_step?: GraphQLTypes["FinancingListTotalByStep"] | undefined,
	total_by_status?: GraphQLTypes["FinancingListTotalByStatus"] | undefined
};
	["FinancingsInput"]: {
		query?: string | undefined,
	page?: number | undefined,
	partner_id?: string | undefined,
	steps?: Array<GraphQLTypes["FinancingSteps"]> | undefined,
	status?: Array<GraphQLTypes["FinancingStatus"]> | undefined,
	is_combo?: string | undefined,
	page_size?: number | undefined,
	order_by?: string | undefined
};
	["FinancingType"]: {
	__typename: "FinancingType",
	max_financing_value: number,
	min_financing_value: number,
	max_financing_installments: number,
	grace_period_financing: GraphQLTypes["GracePeriod"],
	max_bnpl_value?: number | undefined,
	max_bnpl_installments?: number | undefined,
	grace_period_bnpl?: GraphQLTypes["GracePeriod"] | undefined
};
	["GracePeriod"]: {
	__typename: "GracePeriod",
	label?: string | undefined,
	value: number
};
	["FileStatusType"]: FileStatusType;
	["DocumentStatusType"]: DocumentStatusType;
	["TypeSection"]: TypeSection;
	["MirrorStatuses"]: MirrorStatuses;
	["Status"]: {
	__typename: "Status",
	description: string,
	id: number,
	slug: GraphQLTypes["DocumentStatusType"]
};
	["Document"]: {
	__typename: "Document",
	filename: string,
	filetype: string,
	id: string,
	status?: GraphQLTypes["Status"] | undefined,
	type: string,
	url: string
};
	["Section"]: {
	__typename: "Section",
	documents?: Array<GraphQLTypes["Document"] | undefined> | undefined,
	id: string,
	status: GraphQLTypes["Status"],
	type: GraphQLTypes["TypeSection"]
};
	["InstallationStatus"]: {
	__typename: "InstallationStatus",
	description: string,
	id: number,
	slug: GraphQLTypes["FileStatusType"]
};
	["Installation"]: {
	__typename: "Installation",
	comment?: string | undefined,
	concluded_at?: string | undefined,
	grade: number,
	has_ampera: boolean,
	has_ampera_activated: boolean,
	has_minimal_projects: boolean,
	id: string,
	new_journey: boolean,
	plant_id?: string | undefined,
	plant_url?: string | undefined,
	project_id: string,
	project_name: string,
	sections: Array<GraphQLTypes["Section"]>,
	status: GraphQLTypes["InstallationStatus"]
};
	["HardwareFormInverter"]: {
		id: number,
	quantity: number,
	power?: number | undefined
};
	["HardwareFormModule"]: {
		id: number,
	quantity: number,
	power?: number | undefined
};
	["HardwareFormComboEquipment"]: {
		sku: string,
	power?: number | undefined,
	quantity: number
};
	["HardwareFormBatteries"]: {
		id: number,
	quantity: number,
	power?: number | undefined
};
	["HardwareFormComboMetadata"]: {
		project_id: string,
	sku?: string | undefined,
	power?: number | undefined,
	price?: number | undefined,
	inverters: Array<GraphQLTypes["HardwareFormComboEquipment"]>,
	modules: Array<GraphQLTypes["HardwareFormComboEquipment"]>
};
	["HardwareFormInput"]: {
		is_combo: boolean,
	id?: string | undefined,
	combo_price?: number | undefined,
	inverter_type?: string | undefined,
	project_id: string,
	distributor_id: number,
	phase_id: number,
	inverters: Array<GraphQLTypes["HardwareFormInverter"]>,
	modules: Array<GraphQLTypes["HardwareFormModule"]>,
	batteries?: Array<GraphQLTypes["HardwareFormBatteries"]> | undefined,
	combo_metadata?: GraphQLTypes["HardwareFormComboMetadata"] | undefined,
	is_showcase?: boolean | undefined,
	shopping_cart_id?: string | undefined
};
	["HardwareFormShowcaseInput"]: {
		price?: number | undefined,
	project_id: string,
	inverters: Array<GraphQLTypes["HardwareFormInverter"]>,
	modules: Array<GraphQLTypes["HardwareFormModule"]>,
	shopping_cart_id?: string | undefined
};
	["ValidateHardwareFormPricePerWatt"]: {
	__typename: "ValidateHardwareFormPricePerWatt",
	is_valid?: boolean | undefined,
	is_under_expected?: boolean | undefined
};
	["ValidateHardwareFormModules"]: {
	__typename: "ValidateHardwareFormModules",
	price_per_watt?: GraphQLTypes["ValidateHardwareFormPricePerWatt"] | undefined
};
	["ValidateHardwareFormOverload"]: {
	__typename: "ValidateHardwareFormOverload",
	is_valid?: boolean | undefined,
	is_under_expected?: boolean | undefined
};
	["ValidateHardwareFormInverters"]: {
	__typename: "ValidateHardwareFormInverters",
	overload?: GraphQLTypes["ValidateHardwareFormOverload"] | undefined
};
	["ValidateHardwareFormHardware"]: {
	__typename: "ValidateHardwareFormHardware",
	modules?: GraphQLTypes["ValidateHardwareFormModules"] | undefined,
	inverters?: GraphQLTypes["ValidateHardwareFormInverters"] | undefined
};
	["HardwareFormValidation"]: {
	__typename: "HardwareFormValidation",
	is_valid?: boolean | undefined,
	hardware?: GraphQLTypes["ValidateHardwareFormHardware"] | undefined
};
	["HardwareFormShowcaseResponse"]: {
	__typename: "HardwareFormShowcaseResponse",
	overload_is_valid?: boolean | undefined,
	price_per_watt_is_valid?: boolean | undefined,
	percentage_of_service_with_combo_is_valid?: boolean | undefined
};
	["MirrorStatus"]: {
	__typename: "MirrorStatus",
	status: GraphQLTypes["MirrorStatuses"]
};
	["MirrorUploadResponse"]: {
	__typename: "MirrorUploadResponse",
	id: string,
	url: string
};
	["HardwareDraftEquipment"]: {
		sku: string,
	quantity: number,
	power?: number | undefined,
	model: string,
	brand: string
};
	["HardwareDraft"]: {
		project_id: string,
	sku?: string | undefined,
	power?: number | undefined,
	price?: number | undefined,
	inverters: Array<GraphQLTypes["HardwareDraftEquipment"]>,
	modules: Array<GraphQLTypes["HardwareDraftEquipment"]>
};
	["LegacyFinancing"]: {
	__typename: "LegacyFinancing",
	projectId: string,
	financingId: number,
	formalizedAt?: string | undefined,
	stage: string,
	status: string
};
	["CorePartner"]: {
	__typename: "CorePartner",
	id: number,
	cnpj?: string | undefined,
	responsible?: GraphQLTypes["CoreParterResponsible"] | undefined,
	company_name: string,
	fantasy_name: string,
	status: Array<GraphQLTypes["CoreParterStatus"] | undefined>
};
	["CoreParterResponsible"]: {
	__typename: "CoreParterResponsible",
	id?: number | undefined,
	full_name?: string | undefined
};
	["CoreParterStatus"]: {
	__typename: "CoreParterStatus",
	active?: boolean | undefined,
	reason?: string | undefined,
	date?: string | undefined
};
	["CoreGetPartnersInput"]: {
		page?: number | undefined,
	search_term?: string | undefined
};
	["SystemTypes"]: SystemTypes;
	["ProjectProposal"]: {
	__typename: "ProjectProposal",
	value_changed?: boolean | undefined
};
	["AppliedModifierOption"]: {
	__typename: "AppliedModifierOption",
	type: string,
	value: string
};
	["AppliedModifier"]: {
	__typename: "AppliedModifier",
	name: string,
	type: string,
	options: Array<GraphQLTypes["AppliedModifierOption"]>
};
	["AddonProduct"]: {
	__typename: "AddonProduct",
	id: string,
	product_name: string,
	product_external_id: string,
	simulation_id: string,
	type: string,
	product_slug: string,
	applied: boolean,
	partner_commission: number,
	installment_price: number,
	total_price: number,
	monthly_interest_rate?: number | undefined,
	engine_simulation_id: string,
	previous_engine_simulation_id: string,
	cet?: number | undefined,
	applied_modifiers: Array<GraphQLTypes["AppliedModifier"]>,
	addon_type?: string | undefined
};
	["SelectedInstallments"]: {
	__typename: "SelectedInstallments",
	cet: string,
	iof: number,
	ipca: string,
	addons: Array<GraphQLTypes["AddonProduct"]>,
	aggregated_addons: Array<GraphQLTypes["AddonProduct"]>,
	user_id: number,
	document: string,
	is_combo: boolean,
	commission: number,
	partner_id: number,
	aliquot_iof: number,
	new_journey: boolean,
	person_type: string,
	down_payment: number,
	grace_period: number,
	installments: number,
	project_name: string,
	system_power: number,
	interest_rate: number,
	cet_percentage: number,
	commission_raw: number,
	financing_value: number,
	taxa_de_cadastro: number,
	installment_value: number,
	is_from_suggested: boolean,
	registration_fee_gross: number,
	registration_fee_raw_dry: number,
	registration_fee_raw_final: number
};
	["RiskInfo"]: {
	__typename: "RiskInfo",
	title: string,
	description: string,
	todo: string,
	reason?: string | undefined,
	decision: number
};
	["BNPL"]: {
	__typename: "BNPL",
	decision_pre_analysis: number,
	min_entry_value: number,
	max_installments: number,
	pre_approved_value: number,
	status?: string | undefined
};
	["Guarantor"]: {
	__typename: "Guarantor",
	id?: string | undefined,
	document?: string | undefined,
	birth_date?: string | undefined,
	border_line?: boolean | undefined,
	created_at?: string | undefined,
	customer_second_financing_policy?: boolean | undefined,
	decision_pre_analysis?: number | undefined,
	max_approved_project_value?: number | undefined,
	max_financing_value?: number | undefined,
	max_grace_period?: number | undefined,
	max_installments?: number | undefined,
	min_approved_project_value?: number | undefined,
	min_installment_value?: number | undefined,
	pre_approved_value?: number | undefined,
	project_name?: string | undefined,
	project_state?: string | undefined,
	risk_class?: string | undefined,
	status?: string | undefined
};
	["Project"]: {
	__typename: "Project",
	id: string,
	user_id: number,
	partner_id: number,
	person_type: string,
	document: string,
	created_at: string,
	representative_document?: string | undefined,
	project_value: number,
	system_power?: number | undefined,
	grace_period: number,
	down_payment: number,
	status: string,
	project_status: string,
	max_installments: number,
	max_grace_period: number,
	project_name: string,
	project_state: string,
	partner_profile: string,
	pre_approved_value: number,
	products_applieds?: string | undefined,
	installments?: string | undefined,
	risk_info?: GraphQLTypes["RiskInfo"] | undefined,
	state_registration?: string | undefined,
	complete: boolean,
	selected_installments?: GraphQLTypes["SelectedInstallments"] | undefined,
	guarantor?: GraphQLTypes["Guarantor"] | undefined,
	birth_date: string,
	decision_pre_analysis: number,
	score_sol_facil: number,
	simulation_id?: string | undefined,
	addons: Array<GraphQLTypes["AddonProduct"]>,
	max_approved_project_value: number,
	min_approved_project_value: number,
	max_financing_value: number,
	border_line: boolean,
	is_formalized: boolean,
	new_journey: boolean,
	formalized_at?: string | undefined,
	shopping_cart_id?: string | undefined,
	bnpl?: GraphQLTypes["BNPL"] | undefined,
	bnpl_simulation_id?: string | undefined,
	bnpl_status?: string | undefined,
	is_onflex_partner: boolean,
	can_select_ampera: boolean,
	has_energy_bill_analysis: boolean,
	insurance_commission: number,
	gained_points: number,
	original_installment_value: number,
	is_contract_signed?: boolean | undefined,
	commission?: number | undefined,
	risk_class?: string | undefined,
	selected_installment?: number | undefined,
	credit_analysis_status?: string | undefined
};
	["ProjectProposalUpdatePayloadInput"]: {
		engine_simulation_id: string,
	simulation_id: string,
	is_combo: boolean,
	is_ampera: boolean,
	commission: number,
	applied_addons: Array<string>
};
	["ProjectProposalUpdateResponse"]: {
	__typename: "ProjectProposalUpdateResponse",
	engine_simulation_id: string,
	simulation_id: string,
	project_id: string,
	is_combo: boolean,
	is_ampera: boolean,
	commission: number,
	applied_addons: Array<string>
};
	["IsComboResponse"]: {
	__typename: "IsComboResponse",
	data?: boolean | undefined
};
	["RegistrationPersonType"]: RegistrationPersonType;
	["RegistrationDocumentTypes"]: RegistrationDocumentTypes;
	["RegistrationRepresentantTypes"]: RegistrationRepresentantTypes;
	["RegistrationContact"]: {
	__typename: "RegistrationContact",
	email: string,
	phone: string
};
	["RegistrationAddressCity"]: {
	__typename: "RegistrationAddressCity",
	id: number,
	name: string
};
	["RegistrationAddressState"]: {
	__typename: "RegistrationAddressState",
	id: number,
	name: string
};
	["RegistrationDocumentModel"]: RegistrationDocumentModel;
	["RegistrationElectricityBill"]: {
	__typename: "RegistrationElectricityBill",
	holder_cpf: string,
	is_under_customer_name: boolean
};
	["RegistrationDocument"]: {
	__typename: "RegistrationDocument",
	type: GraphQLTypes["RegistrationDocumentTypes"],
	status: string,
	filename: string,
	url_download: string,
	url_preview: string,
	mime_type: string,
	docstorage_id: string,
	id: string
};
	["RegistrationAddress"]: {
	__typename: "RegistrationAddress",
	zipcode?: string | undefined,
	street?: string | undefined,
	street_number?: string | undefined,
	complement?: string | undefined,
	neighborhood?: string | undefined,
	city_id?: number | undefined,
	state_id?: number | undefined,
	city?: GraphQLTypes["RegistrationAddressCity"] | undefined,
	state?: GraphQLTypes["RegistrationAddressState"] | undefined,
	is_rural?: boolean | undefined
};
	["RegistrationProject"]: {
	__typename: "RegistrationProject",
	address?: GraphQLTypes["RegistrationAddress"] | undefined,
	documents?: Array<GraphQLTypes["RegistrationDocument"] | undefined> | undefined,
	electricity_bill?: GraphQLTypes["RegistrationElectricityBill"] | undefined,
	residencial_is_the_same_of_plant?: boolean | undefined,
	comment?: string | undefined
};
	["RegistrationCustomer"]: {
	__typename: "RegistrationCustomer",
	cpf: string,
	full_name?: string | undefined,
	birthdate?: string | undefined,
	contact?: GraphQLTypes["RegistrationContact"] | undefined,
	documents: Array<GraphQLTypes["RegistrationDocument"]>,
	document_type?: GraphQLTypes["RegistrationDocumentModel"] | undefined,
	address?: GraphQLTypes["RegistrationAddress"] | undefined
};
	["RegistrationCompany"]: {
	__typename: "RegistrationCompany",
	company_name: string,
	cnpj: string,
	contact?: GraphQLTypes["RegistrationContact"] | undefined,
	documents: Array<GraphQLTypes["RegistrationDocument"]>,
	majority_partner: GraphQLTypes["RegistrationCompanyMajorityPartner"],
	legal_representatives: Array<GraphQLTypes["RegistrationLegalRepresentative"]>,
	majority_partner_is_legal_representative?: boolean | undefined,
	is_only_one_legal_representative?: boolean | undefined
};
	["RegistrationLegalRepresentative"]: {
	__typename: "RegistrationLegalRepresentative",
	cpf: string,
	full_name?: string | undefined,
	birthdate?: string | undefined,
	contact?: GraphQLTypes["RegistrationContact"] | undefined,
	documents: Array<GraphQLTypes["RegistrationDocument"]>,
	address?: GraphQLTypes["RegistrationAddress"] | undefined,
	kind: GraphQLTypes["RegistrationRepresentantTypes"],
	document_type?: GraphQLTypes["RegistrationDocumentModel"] | undefined
};
	["RegistrationCompanyMajorityPartner"]: {
	__typename: "RegistrationCompanyMajorityPartner",
	cpf: string,
	is_legal_representative?: boolean | undefined,
	full_name?: string | undefined,
	birthdate?: string | undefined,
	contact?: GraphQLTypes["RegistrationContact"] | undefined,
	documents: Array<GraphQLTypes["RegistrationDocument"]>,
	address?: GraphQLTypes["RegistrationAddress"] | undefined,
	document_type?: GraphQLTypes["RegistrationDocumentModel"] | undefined
};
	["RegistrationGuarantor"]: {
	__typename: "RegistrationGuarantor",
	cpf: string,
	full_name: string,
	birthdate?: string | undefined,
	contact?: GraphQLTypes["RegistrationContact"] | undefined,
	documents: Array<GraphQLTypes["RegistrationDocument"]>,
	address?: GraphQLTypes["RegistrationAddress"] | undefined,
	document_type?: GraphQLTypes["RegistrationDocumentModel"] | undefined
};
	["RegistrationError"]: {
	__typename: "RegistrationError",
	title?: string | undefined,
	description: string,
	field: string,
	type?: string | undefined
};
	["RegistrationUpdateInput"]: {
		project_id: string
};
	["RegistrationDetailsResponse"]: {
	__typename: "RegistrationDetailsResponse",
	project_id: string,
	status: string,
	is_combo: boolean,
	remaining_changes_after_confirmation?: number | undefined,
	person_type: GraphQLTypes["RegistrationPersonType"],
	customer?: GraphQLTypes["RegistrationCustomer"] | undefined,
	company?: GraphQLTypes["RegistrationCompany"] | undefined,
	guarantor?: GraphQLTypes["RegistrationGuarantor"] | undefined,
	project?: GraphQLTypes["RegistrationProject"] | undefined,
	errors: Array<GraphQLTypes["RegistrationError"]>
};
	["AnalyzeEnergyBillInput"]: {
		person_type: string,
	project_id: string,
	document_customer: string,
	financing_id: number,
	document_owner_energy_bill: string,
	auto_cancel_flow_on_rejection: boolean
};
	["GetOpportunitiesInput"]: {
		financing_value: number,
	partner_id: number,
	project_type: string
};
	["EnergyBillAnalysisDecision"]: {
	__typename: "EnergyBillAnalysisDecision",
	decision: string,
	details?: string | undefined
};
	["GetOpportunitiesResponse"]: {
	__typename: "GetOpportunitiesResponse",
	addons_opportunities: Array<GraphQLTypes["AddonsOpportunities"]>,
	financing_gained_points?: number | undefined
};
	["AddonsOpportunities"]: {
	__typename: "AddonsOpportunities",
	addon_type?: string | undefined,
	gained_points?: number | undefined,
	product_slug?: string | undefined
};
	["LevelBlackStatus"]: {
	__typename: "LevelBlackStatus",
	kwGoalMonth: number,
	kwReachedMonth: number
};
	["Contact"]: {
	__typename: "Contact",
	landline?: string | undefined,
	mobile: string,
	email: string
};
	["Address"]: {
	__typename: "Address",
	street: string,
	number: string,
	complement?: string | undefined,
	reference?: string | undefined,
	district: string,
	zipcode: string,
	city: string,
	state: string
};
	["Responsible"]: {
	__typename: "Responsible",
	id: number,
	username?: string | undefined,
	profile: string,
	fullname: string,
	document?: string | undefined,
	birthdate?: string | undefined,
	active: boolean,
	created_at: string,
	contact: GraphQLTypes["Contact"],
	partner?: GraphQLTypes["Partner"] | undefined
};
	["Permission"]: {
	__typename: "Permission",
	store: boolean,
	financing: boolean
};
	["Score"]: {
	__typename: "Score",
	current: number,
	goal: number
};
	["Downgrade"]: {
	__typename: "Downgrade",
	has_risk: boolean
};
	["Sfplus"]: {
	__typename: "Sfplus",
	level: number,
	score?: GraphQLTypes["Score"] | undefined,
	black_status?: GraphQLTypes["LevelBlackStatus"] | undefined,
	downgrade?: GraphQLTypes["Downgrade"] | undefined,
	is_level_black: boolean,
	next_transition_date?: string | undefined
};
	["Partner"]: {
	__typename: "Partner",
	id: number,
	corporate_name: string,
	operation_term_accepted: boolean,
	trade_name: string,
	document: string,
	category?: string | undefined,
	state_registration: string,
	active: boolean,
	created_at: string,
	address: GraphQLTypes["Address"],
	responsible: GraphQLTypes["Responsible"],
	permission: GraphQLTypes["Permission"],
	users?: Array<GraphQLTypes["User"] | undefined> | undefined,
	sfplus?: GraphQLTypes["Sfplus"] | undefined
};
	["Post"]: {
	__typename: "Post",
	id: number,
	title: string,
	body: string,
	userId: string,
	comments?: Array<GraphQLTypes["Comment"] | undefined> | undefined
};
	["Comment"]: {
	__typename: "Comment",
	id: number,
	name: string,
	email: string,
	body: string
};
	["User"]: {
	__typename: "User",
	id: number,
	username?: string | undefined,
	profile?: string | undefined,
	fullname: string,
	document?: string | undefined,
	birthdate?: string | undefined,
	active: boolean,
	created_at: string,
	contact: GraphQLTypes["Contact"],
	partner?: GraphQLTypes["Partner"] | undefined,
	posts?: Array<GraphQLTypes["Post"] | undefined> | undefined
}
    }
export const enum Order {
	asc = "asc",
	desc = "desc"
}
export const enum PersonTypes {
	pf = "pf",
	pj = "pj"
}
export const enum DocumentTypes {
	driver_license_front = "driver_license_front",
	driver_license_back = "driver_license_back",
	digital_driver_license = "digital_driver_license",
	identity_document_front = "identity_document_front",
	identity_document_back = "identity_document_back",
	employment_proof = "employment_proof",
	electricity_bill = "electricity_bill",
	additional = "additional",
	company_official_document = "company_official_document"
}
export const enum LegalRepresentantTypes {
	legal_representative_1 = "legal_representative_1",
	legal_representative_2 = "legal_representative_2",
	majority_partner = "majority_partner"
}
export const enum CustomerStatus {
	in_progress = "in_progress",
	under_analysis = "under_analysis",
	approved = "approved",
	reproved = "reproved",
	to_review = "to_review"
}
export const enum RegistrationV2PersonType {
	pf = "pf",
	pj = "pj"
}
export const enum PersonType {
	PF = "PF",
	PJ = "PJ"
}
export const enum FinancingStatus {
	expired = "expired",
	reproved = "reproved",
	approved = "approved",
	under_analysis = "under_analysis",
	canceled = "canceled",
	in_progress = "in_progress",
	waiting_customer = "waiting_customer",
	to_review = "to_review"
}
export const enum SectionStatus {
	pending = "pending",
	pendency = "pendency",
	approved = "approved",
	submitted = "submitted",
	reproved = "reproved",
	under_analysis = "under_analysis",
	kit_pending = "kit_pending",
	sent = "sent",
	concluded = "concluded",
	canceled = "canceled",
	expired = "expired",
	pending_customer = "pending_customer",
	pending_receipt = "pending_receipt",
	send_contract = "send_contract"
}
export const enum FinancingSteps {
	simulation = "simulation",
	formalization = "formalization",
	installation = "installation",
	concluded = "concluded",
	expired = "expired",
	proposal = "proposal"
}
export const enum FileStatusType {
	error = "error",
	processing = "processing",
	success = "success",
	waiting = "waiting"
}
export const enum DocumentStatusType {
	approved = "approved",
	pending = "pending",
	reproved = "reproved"
}
export const enum TypeSection {
	ampera = "ampera",
	installation = "installation",
	inverters = "inverters",
	inverter_label = "inverter_label",
	battery_label = "battery_label"
}
export const enum MirrorStatuses {
	approved = "approved",
	reproved = "reproved",
	pending = "pending"
}
export const enum SystemTypes {
	with_battery = "with_battery",
	without_battery = "without_battery"
}
export const enum RegistrationPersonType {
	pf = "pf",
	pj = "pj"
}
export const enum RegistrationDocumentTypes {
	driver_license_front = "driver_license_front",
	driver_license_back = "driver_license_back",
	digital_driver_license = "digital_driver_license",
	identity_document_front = "identity_document_front",
	identity_document_back = "identity_document_back",
	employment_proof = "employment_proof",
	electricity_bill = "electricity_bill",
	additional = "additional",
	company_official_document = "company_official_document"
}
export const enum RegistrationRepresentantTypes {
	legal_representative_1 = "legal_representative_1",
	legal_representative_2 = "legal_representative_2",
	majority_partner = "majority_partner"
}
export const enum RegistrationDocumentModel {
	rg = "rg",
	cnh = "cnh",
	cnh_digital = "cnh_digital"
}

type ZEUS_VARIABLES = {
	["Order"]: ValueTypes["Order"];
	["Upload"]: ValueTypes["Upload"];
	["PersonTypes"]: ValueTypes["PersonTypes"];
	["DocumentTypes"]: ValueTypes["DocumentTypes"];
	["LegalRepresentantTypes"]: ValueTypes["LegalRepresentantTypes"];
	["CustomerStatus"]: ValueTypes["CustomerStatus"];
	["RegistrationV2PersonType"]: ValueTypes["RegistrationV2PersonType"];
	["CustomerRegistrationAddress"]: ValueTypes["CustomerRegistrationAddress"];
	["CustomerRegistrationContact"]: ValueTypes["CustomerRegistrationContact"];
	["CustomerRegistrationDocument"]: ValueTypes["CustomerRegistrationDocument"];
	["CustomerRegistrationCustomer"]: ValueTypes["CustomerRegistrationCustomer"];
	["CustomerRegistrationGuarantor"]: ValueTypes["CustomerRegistrationGuarantor"];
	["CustomerRegistrationElectricityBill"]: ValueTypes["CustomerRegistrationElectricityBill"];
	["CustomerRegistrationProject"]: ValueTypes["CustomerRegistrationProject"];
	["CustomerRegistrationCompany"]: ValueTypes["CustomerRegistrationCompany"];
	["CustomerRegistrationLegalRepresentative"]: ValueTypes["CustomerRegistrationLegalRepresentative"];
	["CustomerRegistrationInput"]: ValueTypes["CustomerRegistrationInput"];
	["Person"]: ValueTypes["Person"];
	["PersonInput"]: ValueTypes["PersonInput"];
	["RegistrationContactChangeInput"]: ValueTypes["RegistrationContactChangeInput"];
	["PersonType"]: ValueTypes["PersonType"];
	["FinancingStatus"]: ValueTypes["FinancingStatus"];
	["SectionStatus"]: ValueTypes["SectionStatus"];
	["FinancingSteps"]: ValueTypes["FinancingSteps"];
	["FinancingsInput"]: ValueTypes["FinancingsInput"];
	["FileStatusType"]: ValueTypes["FileStatusType"];
	["DocumentStatusType"]: ValueTypes["DocumentStatusType"];
	["TypeSection"]: ValueTypes["TypeSection"];
	["MirrorStatuses"]: ValueTypes["MirrorStatuses"];
	["HardwareFormInverter"]: ValueTypes["HardwareFormInverter"];
	["HardwareFormModule"]: ValueTypes["HardwareFormModule"];
	["HardwareFormComboEquipment"]: ValueTypes["HardwareFormComboEquipment"];
	["HardwareFormBatteries"]: ValueTypes["HardwareFormBatteries"];
	["HardwareFormComboMetadata"]: ValueTypes["HardwareFormComboMetadata"];
	["HardwareFormInput"]: ValueTypes["HardwareFormInput"];
	["HardwareFormShowcaseInput"]: ValueTypes["HardwareFormShowcaseInput"];
	["HardwareDraftEquipment"]: ValueTypes["HardwareDraftEquipment"];
	["HardwareDraft"]: ValueTypes["HardwareDraft"];
	["CoreGetPartnersInput"]: ValueTypes["CoreGetPartnersInput"];
	["SystemTypes"]: ValueTypes["SystemTypes"];
	["ProjectProposalUpdatePayloadInput"]: ValueTypes["ProjectProposalUpdatePayloadInput"];
	["RegistrationPersonType"]: ValueTypes["RegistrationPersonType"];
	["RegistrationDocumentTypes"]: ValueTypes["RegistrationDocumentTypes"];
	["RegistrationRepresentantTypes"]: ValueTypes["RegistrationRepresentantTypes"];
	["RegistrationDocumentModel"]: ValueTypes["RegistrationDocumentModel"];
	["RegistrationUpdateInput"]: ValueTypes["RegistrationUpdateInput"];
	["AnalyzeEnergyBillInput"]: ValueTypes["AnalyzeEnergyBillInput"];
	["GetOpportunitiesInput"]: ValueTypes["GetOpportunitiesInput"];
}