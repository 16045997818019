import { useMutation } from '@tanstack/vue-query'
import simulatorApi from '~/services-v2/simulator-api/simulator-api'

export default function useGuarantor() {
  return useMutation({
    mutationKey: ['GUARANTOR'],

    mutationFn: async (variables: { project_id: string, document: string }) => {
      const response = await simulatorApi.guarantor(variables.project_id, variables.document)
      return response.data
    },

    retry: 2,
    retryDelay: 1000,
  })
}
