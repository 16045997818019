<script setup lang="ts">
import type { Addons } from '~/utils/simulation/Simulation'
import { SolButton, SolModal, useToast } from '@solfacil/girassol'
import KnowError from '~/exceptions/KnownError'
import useLegacyFinancing from '~/store-v2/legacy-financing/useLegacyFinancing'
import useProject from '~/store-v2/projects/useProject'
import useProjectProposalUpdate from '~/store-v2/projects/useProjectProposalUpdate'

const props = defineProps<{
  disabledFields: boolean
  loadingConclusion: boolean
  cartIdSelected: string
  shoppingCartId: string
}>()

const { t } = useI18n()
const router = useRouter()
const route = useRoute()
const id = computed(() => route.params.id as string)

const { createErrorToast, createSuccessToast } = useToast()

const {
  data: project,
} = useProject(id)

const {
  getSelectedInstallment,
  getValueOfAmpera,
  getAddonsApplieds,
  getCurrentProject,
  getCommissionApplied,
  getSumOfInsurancesApplied,
} = useResumeStore()

const {
  mutateAsync: handleProposalUpdate,
  isPending: isPendingProposalUpdate,
} = useProjectProposalUpdate(id)

const loadingLocal = ref(false)
const disabledButton = ref(true)
const blockFields = ref(false)
const confirmationModal = ref(false)

const config = import.meta.env

const currentProject = getCurrentProject()

const {
  data: financingLegacy,
  isLoading: isLoadingFinancingLegacy,
} = useLegacyFinancing(id.value, {
  skip: !currentProject?.complete,
})

onUpdated(() => {
  disabledButton.value = !props.disabledFields || blockFields.value || !getSelectedInstallment() || loadingLocal.value
})

const oldValue = (getSelectedInstallment()?.installments_value || 0)
  + getSumOfInsurancesApplied()
  + getValueOfAmpera()
  + Number(getCommissionApplied() || 0)
  + getAddonsApplieds()
    .map((addon: Addons) => addon.total_price)
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0)

const newValue = computed(() => {
  return (getSelectedInstallment()?.installments_value || 0)
    + getSumOfInsurancesApplied()
    + getValueOfAmpera()
    + Number(getCommissionApplied() || 0)
    + getAddonsApplieds()
      .map((addon: Addons) => addon.total_price)
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0)
})

const valueHasChanges = computed(() => oldValue !== newValue.value)

async function submitFinancial() {
  try {
    const selectedInstallment = getSelectedInstallment()
    const commission = getCommissionApplied()

    loadingLocal.value = true

    if (selectedInstallment === null || currentProject === null || commission === undefined)
      return

    const addonsAppliedId = getAddonsApplieds().map((addon: Addons) => {
      if (addon.applied)
        return addon.id
      return null
    }).filter((n: string | null) => n)

    const payload = {
      engine_simulation_id: selectedInstallment.engine_simulation_id,
      is_combo: selectedInstallment.combo,
      is_ampera: Boolean(getValueOfAmpera()),
      commission: selectedInstallment?.is_from_suggested ? Number(selectedInstallment.commission) : Number(commission),
      simulation_id: selectedInstallment.simulation_id,
      applied_addons: addonsAppliedId as any[],
    }

    await handleProposalUpdate(payload)

    createSuccessToast({
      title: 'Financiamento atualizado com sucesso',
      description: 'As condições e valores para este projeto foram alteradas.',
    })
    disabledButton.value = true

    if (financingLegacy.value?.financingId) {
      window.open(`${config.VITE_URL_LEGACY}/financiamento/etapas/${financingLegacy.value.financingId}`, '_self')
      return
    }
    if (project.value?.person_type) {
      if (project.value.person_type === 'PF')
        return router.push(`/customer/${id.value}`)
      return router.push(`/company/${id.value}`)
    }
  }
  catch (e: unknown) {
    if (e instanceof KnowError) {
      if (e.message === 'SELECT_INSTALLMENT') {
        return createErrorToast('Selecione uma opção de parcelamento.')
      }
      if (e.message === 'METHOD_NOT_ALLOWED') {
        return createErrorToast('Esse recurso não está disponível para este projeto.')
      }
      if (e.message === 'UNKNOWN') {
        return createErrorToast('Ocorreu um erro ao tentar atualizar o projeto, verifique se existe algum contrato gerado.')
      }
      return createErrorToast('Ocorreu um erro ao tentar atualizar o projeto, verifique se existe algum contrato gerado.')
    }
  }
  finally {
    loadingLocal.value = false
    confirmationModal.value = false
  }
}

function discartChanges() {
  window.location.reload()
}
</script>

<template>
  <SolModal
    id="modal"
    :is-open="confirmationModal"
    :title="t('price-change.modal.title')"
    :action-primary-text="isPendingProposalUpdate ? t('price-change.modal.primary-loading') : t('price-change.modal.primary')"
    :action-secondary-text="t('price-change.modal.secondary')"
    :size="{
      desktop: 'medium',
      mobile: 'bottom-sheet',
    }"
    @close="() => confirmationModal = false"
    @action:primary="submitFinancial"
    @action:secondary="discartChanges"
  >
    <div class="flex justify-center flex-col items-center">
      <!-- benefits -->
      <div class="grid gap-3">
        <div class="items-center text-left py-2 col-span-3 md:system:col-span-5">
          <p class="text-micro sm:system:text-3xs text-neutral-low-light leading-4">
            {{ t('price-change.modal.body') }}
          </p>
        </div>
      </div>
    </div>
  </SolModal>

  <div class="w-full justify-end">
    <div v-if="!isPendingProposalUpdate" class="w-full flex gap-3 justify-end">
      <SolButton
        v-if="!valueHasChanges"
        id="back"
        class="xsm:system:w-full"
        :loading="loadingLocal"
        size="large"
        @click="() => router.back()"
      >
        {{ t('buttons.back') }}
      </SolButton>

      <SolButton
        v-if="valueHasChanges"
        id="back"
        class="xsm:system:w-full"
        :loading="loadingLocal"
        size="large"
        variant="secondary"
        @click="discartChanges"
      >
        {{ t('buttons.discart') }}
      </SolButton>

      <SolButton
        v-if="valueHasChanges"
        id="go"
        class="xsm:system:w-full"
        :disabled="disabledButton || loadingConclusion || !valueHasChanges"
        :loading="isPendingProposalUpdate || isLoadingFinancingLegacy"
        size="large"
        @click="() => confirmationModal = true"
      >
        {{ t('buttons.edit_save') }}
      </SolButton>
    </div>
  </div>
</template>
