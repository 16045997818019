<script setup lang="ts">
import type { User } from '~/utils/auth/User'
// Services
import { SolButton, SolEmptyState, useToast } from '@solfacil/girassol'
import Loading from '~/components/loading/index.vue'
import validateCNPJ from '~/composables/validatorCNPJ'
import validateCPF from '~/composables/validatorCPF'
import SimulatorService from '~/services/simulator/Simulator'
import useAuth from '~/store-v2/auth/useAuth'
import useFinancingTypes from '~/store-v2/projects/useFinancingTypes'
import currencyUtils from '~/utils/currency'

const { t } = useI18n()
const router = useRouter()
const shoppingCartId = ref('')

const { data: financingTypes, isLoading: isFinancingTypesLoading } = useFinancingTypes()

const {
  data: auth,
} = useAuth()

onMounted(() => {
  shoppingCartId.value = String(router.currentRoute.value.query.shopping_cart_id) !== 'undefined' ? String(router.currentRoute.value.query.shopping_cart_id) : ''
})

const {
  createErrorToast,
} = useToast()

const optionsButtonReport = computed(() => {
  return [
    {
      title: t('simulation.newPage.pf.title'),
      description:
        t('simulation.newPage.pf.description'),
      financing: {
        title: t('simulation.newPage.pf.financing.title', { value: currencyUtils.formatBRL(Number(financingTypes?.value?.pf?.max_financing_value)) }),
        description: t('simulation.newPage.pf.financing.description', {
          maxFinancingInstallments: financingTypes?.value?.pf?.max_financing_installments,
          period: financingTypes?.value?.pf?.grace_period_financing?.label,
        }),
      },
      bnpl: {
        title: t('simulation.newPage.pf.bnpl.title', { value: currencyUtils.formatBRL(Number(financingTypes?.value?.pf?.max_bnpl_value)) }),
        description: t('simulation.newPage.pf.bnpl.description', {
          maxBnplInstallments: financingTypes?.value?.pf?.max_bnpl_installments,
          period: financingTypes?.value?.pf?.grace_period_bnpl?.label,
        }),
      },
      key: 'pf',
    },
    {
      title: t('simulation.newPage.pj.title'),
      description:
        t('simulation.newPage.pj.description'),
      financing: {
        title: t('simulation.newPage.pj.financing.title', { value: currencyUtils.formatBRL(Number(financingTypes?.value?.pj?.max_financing_value)) }),
        description: t('simulation.newPage.pj.financing.description', {
          maxFinancingInstallments: financingTypes?.value?.pj?.max_financing_installments,
          period: financingTypes?.value?.pj?.grace_period_financing?.label,
        }),
      },
      bnpl: {
        description: t('simulation.newPage.pj.bnpl.description'),
      },
      key: 'pj',
    },
  ]
})

onUpdated(() => {
  smoothScroll()
})

const downPayment = ref(0)
const downPaymentError = ref('')
const projectValue = ref(0)
const projectValueError = ref('')
const document = ref<string | undefined>()
const documentError = ref('')
const representativeDocument = ref<string | undefined>()
const representativeDocumentError = ref('')
const systemPower = ref(0)
const systemPowerError = ref('')
const billValue = ref(0)
const billValueError = ref('')

const systemTypeOptions = computed(() => financingTypes.value?.system_types.map(type => ({
  name: t(`simulation.newPage.form.system_type.${type}`),
  value: type,
})))

const systemType = ref<{ name: string, value: string }>()

const agreeWithTerms = ref(false)
const agreeWithTermsError = ref('')

const maxFinancedValue = ref()
const minFinancedValue = ref()

const typeReport = ref<string | undefined>()
const typeReportError = ref('')
const typeReportOptions = ref<HTMLDivElement | undefined>()

const { track } = useMixpanel()

function downPaymentNotGtProjectValue(value: number, fromSubmit = false) {
  if (value >= projectValue.value && !fromSubmit)
    return 'Valor de entrada não pode ser maior que o valor do projeto'

  if (value < 0)
    return 'O valor de entrada não pode ser negativo'

  return ''
}

function projectError(value: number) {
  if (!value)
    return notEmpty(value)

  if (value < 0)
    return 'O valor do projeto não pode ser negativo'

  if ((projectValue.value - downPayment.value) > maxFinancedValue.value)
    return 'Valor do financiamento acima do permitido'

  if (minFinancedValue.value && value < minFinancedValue.value)
    return 'Valor do financiamento abaixo do permitido'

  return ''
}

function formatDocument(document?: string) {
  if (document)
    return document.replaceAll('.', '').replace('-', '').replace('/', '')

  return null
}

function notEmpty(value: number | string | null | undefined) {
  if (!value)
    return 'Campo obrigatório'

  return ''
}

function billValueRules(value: number) {
  let msg = ''
  if (!value || value === 0) {
    msg = notEmpty(value)
  }
  else if (typeof value === 'number') {
    if (value < 10)
      msg = 'O valor da conta de luz está abaixo do limite permitido'
    else if (value > 50000)
      msg = 'O valor da conta de luz excede o limite permitido'
  }
  return msg
}

function systemPowerRules(value: number) {
  if (!value || value === 0)
    return notEmpty(value)
  else if (typeof value === 'number' && (value < 1 || value > 999))
    return 'A potência do sistema deve estar entre 1 kWp e 999 kWp'

  return ''
}

const getDocument = computed(() => {
  return typeReport.value === 'pf' ? document.value : representativeDocument.value
})

function setErrorValue(message: string) {
  (typeReport.value === 'pf') ? documentError.value = message : representativeDocumentError.value = message
}

function validateDocument() {
  const cpf = getDocument.value ? getDocument.value : ''
  const isValidCPF = validateCPF(cpf)
  const isValidCNPJ = validateCNPJ(document.value!)

  if (!isValidCPF) {
    setErrorValue('Por favor, digite um CPF válido.')
    return
  }

  if (typeReport.value === 'pj' && !isValidCNPJ) {
    documentError.value = 'Por favor, digite um CNPJ válido.'
    return
  }

  return true
}

watch(downPayment, (value) => {
  downPaymentError.value = downPaymentNotGtProjectValue(value)
  projectValueError.value = projectError(projectValue.value)
})

watch(document, (value) => {
  documentError.value = notEmpty(value)
})
watch(billValue, (value) => {
  billValueError.value = billValueRules(value)
})

watch(systemPower, (value) => {
  systemPowerError.value = systemPowerRules(value)
})

watch(projectValue, (value) => {
  projectValueError.value = projectError(value)
})

watch(representativeDocument, (value) => {
  representativeDocumentError.value = notEmpty(value)
})

const simulatorService = new SimulatorService()

const loadingMessages = t('loading.messages').split(', ')
const isLoadingSubmit = ref(false)
const loadingInputFields = ref(false)

const user: User = useAppStorage().get('user')

function trackEventMixpanel() {
  track('new-simulation_anti-fraud_funnel', { trigger: 'Análise técnica reprovou na simulação ' })
}

const hasFieldErrors = computed(() => {
  return Boolean(typeReportError.value
    || downPaymentError.value
    || agreeWithTermsError.value
    || systemPowerError.value
    || projectValueError.value
    || billValueError.value
    || !systemType.value,
  )
})

async function handleSubmit() {
  projectValueError.value = projectError(projectValue.value)
  if (!document.value || !projectValue.value || !systemPower.value || !billValue.value) {
    billValueError.value = billValueRules(billValue.value)
    documentError.value = notEmpty(document.value)
    downPaymentError.value = downPayment.value < 0 ? downPaymentNotGtProjectValue(downPayment.value, true) : ''
    systemPowerError.value = systemPowerRules(systemPower.value)
    if (typeReport.value === 'pj')
      representativeDocumentError.value = notEmpty(representativeDocument.value)

    return
  }

  if (hasFieldErrors.value)
    return

  if (!validateDocument())
    return

  isLoadingSubmit.value = true

  try {
    const response = await simulatorService.create_project({
      person_type: typeReport.value?.toUpperCase(),
      document: formatDocument(document?.value),
      representative_document: formatDocument(representativeDocument.value),
      project_name: '',
      project_value: projectValue?.value,
      down_payment: downPayment?.value,
      bill_value: billValue.value,
      partner_id: user?.parceiro?.id,
      user_id: user?.id,
      system_power: systemPower.value,
      shopping_cart_id: shoppingCartId.value ? shoppingCartId.value : null,
      system_type: systemType.value?.value,
    })

    if (response.status === 201)
      return router.push(`/simulation/result/${response.data?.id}${shoppingCartId.value ? `?shopping_cart_id=${shoppingCartId.value}` : ''}`)

    return router.push('/simulation/new/waiting')
  }
  catch (e: any) {
    if (e?.response?.status === 422) {
      systemPowerError.value = `${t('simulation.newPage.client_data.form.power.system_power_error_422')}`
      trackEventMixpanel()

      return
    }

    createErrorToast(e?.response?._data?.detail || 'Ocorreu um erro ao tentar criar o projeto.')
    return router.push('/simulation/new/waiting')
  }
  finally {
    isLoadingSubmit.value = false
  }
}

function selectedTypeReport(type: string) {
  setTypeReport(type)
  clearErrors()
}

async function setTypeReport(type: string) {
  try {
    loadingInputFields.value = true

    typeReport.value = type

    if (type) {
      const typeKey = `${type}`
      const maxFinancingValue = financingTypes?.value?.[typeKey]?.max_financing_value
      const minFinancingValue = financingTypes?.value?.[typeKey]?.min_financing_value
      maxFinancedValue.value = maxFinancingValue
      minFinancedValue.value = minFinancingValue
    }
    loadingInputFields.value = false
  }
  catch {
    loadingInputFields.value = false
  }
  finally {
    loadingInputFields.value = false
  }
}

function clearErrors() {
  const errors = [documentError, representativeDocumentError, projectValueError, downPaymentError, systemPowerError]
  errors.map(error => error.value = '')
}

function smoothScroll() {
  if (typeReportOptions.value) {
    const topOffset = typeReportOptions.value.getBoundingClientRect().top + window.pageYOffset
    window.scrollTo({ top: topOffset, behavior: 'smooth' })
  }
}
const isAdmin = computed(() => {
  return !!(user?.perfil?.toLocaleLowerCase() === 'admin')
})

const isIntegradorLight = computed(() => {
  return !!(user?.perfil?.toLocaleLowerCase() === 'integrador_light')
})

const hasFinancingPermission = computed(() => auth.value?.person.partner?.permission.financing === true)
</script>

<template>
  <NavigationHeaderBar stage="simulation" />

  <template v-if="hasFinancingPermission">
    <div class="container">
      <form class="grid-row-2 grid gap-5 justify-self-stretch mb-2xs !mt-0 w-full" @submit.prevent="handleSubmit">
        <div v-if="isLoadingSubmit" class="my-4 card-container w-full h-96 flex justify-center">
          <Loading :messages="loadingMessages" />
        </div>
        <div
          v-if="!isLoadingSubmit && !isAdmin && !isIntegradorLight"
          class="mb-4 !mt-0 card-container flex flex-col justify-between w-full"
        >
          <div>
            <div>
              <span v-if="financingTypes" class="fonts-body-large-bold font-bold">
                <h1 class="text-sm font-bold">{{ t('simulation.newPage.title') }}</h1>
                <span class="text-neutral-low-light text-2xs font-regular">{{ t('simulation.newPage.description')
                }}</span>
              </span>
              <div class="flex flex-col justify-between w-full">
                <span class="text-stroke-feedback-negative-dark">{{ typeReportError }}</span>

                <SimulationMultiSelectButton
                  v-if="financingTypes && !isFinancingTypesLoading"
                  :options="optionsButtonReport" :active="typeReport" @selected="selectedTypeReport"
                />

                <div v-if="isFinancingTypesLoading" class="flex w-full items-center justify-center">
                  <Loading />
                </div>

                <span v-if="typeReportError" class="error-message">{{ typeReportError }}</span>
              </div>
            </div>
            <div v-if="!financingTypes && !isFinancingTypesLoading" class="w-full items-center justify-center">
              <SolEmptyState
                id="empty-financing-types" class="max-w-full" :title="t('simulation.newPage.empty.title')"
                :subtitle="t('simulation.newPage.empty.subtitle')" variant="no-content"
              />
            </div>
            <div v-if="loadingInputFields && typeReport" class="w-full h-40">
              <div class="loader-lines" />
            </div>
            <div v-if="typeReport && !loadingInputFields" ref="typeReportOptions" class="pt-xs">
              <span class="title">
                {{ t('simulation.newPage.client_data.title') }}
              </span>
              <p class="sub-title">
                {{ t('simulation.newPage.client_data.description') }}
              </p>

              <div class="input-options grid-cols-1 md:system:grid-cols-3">
                <SolInputText
                  v-if="typeReport === 'pf' || !typeReport" id="just-test-2" v-model="document"
                  :error="documentError" :disabled="!typeReport" name="document"
                  :label="t('simulation.newPage.client_data.form.document_pf.title')" :mask="{ preset: 'CPF' }"
                  placeholder="000.000.000-00"
                />
                <SolInputText
                  v-if="typeReport === 'pj'" id="just-test-2" v-model="document" :error="documentError"
                  :disabled="!typeReport" name="document"
                  :label="t('simulation.newPage.client_data.form.document_pj.title')" :mask="{ preset: 'CNPJ' }"
                  placeholder="00.000.000/0000-00"
                />
                <SolInputText
                  v-if="typeReport === 'pj'" id="just-test-2" v-model="representativeDocument"
                  :error="representativeDocumentError" :disabled="!typeReport" name="representativeDocument"
                  :label="t('simulation.newPage.client_data.form.document_pj.majoritary.title')"
                  :mask="{ preset: 'CPF' }" placeholder="000.000.000-00"
                />
                <InputMoneyInput
                  id="just-test-2" v-model="projectValue" :disabled="!typeReport" size="medium" required
                  :error="projectValueError" name="projectValue"
                  :label="t('simulation.newPage.client_data.form.value.title')"
                  :placeholder="t('simulation.newPage.client_data.form.value.placeholder')"
                />
                <InputMoneyInput
                  id="just-test-2" v-model="downPayment" :disabled="!typeReport" size="medium"
                  name="downPayment" :error="downPaymentError"
                  :label="t('simulation.newPage.client_data.form.down_payment.title')"
                  :placeholder="t('simulation.newPage.client_data.form.down_payment.placeholder')"
                />
                <SolSelect
                  id="project-type"
                  v-model:selected="systemType"
                  :options="systemTypeOptions"
                  :disabled="!typeReport"
                  name="systemType"
                  :label="t('simulation.newPage.form.system_type.label')"
                  :placeholder="t('simulation.newPage.form.system_type.placeholder')"
                  :required="true"
                />
                <InputMoneyInput
                  id="bill-value" v-model="billValue" :disabled="!typeReport" size="medium"
                  name="billValue" :error="billValueError"
                  :label="t('simulation.newPage.client_data.form.bill_value.title')"
                  :placeholder="t('simulation.newPage.client_data.form.bill_value.placeholder')"
                />
                <InputSystemPower
                  id="system-power" v-model="systemPower" :disabled="!typeReport" size="medium"
                  name="systemPower" :error="systemPowerError"
                  :label="t('simulation.newPage.client_data.form.power.title')"
                  :placeholder="t('simulation.newPage.client_data.form.power.placeholder')"
                />
              </div>
              <SolCheckbox
                id="is-admin" name="admin" value="yes" :checked="agreeWithTerms"
                @change="agreeWithTerms = !agreeWithTerms"
              >
                <span>{{ t('simulation.cpf_use_terms.client') }}</span>
                <a
                  href="https://helpcentersolfacil.zendesk.com/hc/pt-br/articles/26540116637211-TERMOS-E-CONDI%C3%87%C3%95ES-PLATAFORMA-SOLF%C3%81CIL-TERMO"
                  target="_blank" class="ml-1 text-brand-secondary-pure hover:text-brand-secondary-dark"
                >
                  {{ t('simulation.cpf_use_terms.terms') }}
                </a>
                <span>{{ t('simulation.cpf_use_terms.with') }}</span>
                <a
                  href="https://landing.solfacil.com.br/scr/" target="_blank"
                  class="mx-1 text-brand-secondary-pure hover:text-brand-secondary-dark"
                >
                  {{ t('simulation.cpf_use_terms.terms_authorization') }}
                </a>
                <span>{{ t('simulation.cpf_use_terms.and_with') }}</span>
                <a
                  href="https://landing.solfacil.com.br/politica-de-privacidade/?_gl=1*a56jbv*_ga*MTg5MDEwMTU0OS4xNjk3MDc2Mzkx*_ga_NQJ9ZNTL71*MTY5NzIwNzM2NC4zLjEuMTY5NzIwNzM3My41MS4wLjA./"
                  target="_blank" class="mx-1 text-brand-secondary-pure hover:text-brand-secondary-dark"
                >
                  {{ t('simulation.cpf_use_terms.privacy_policy') }}
                </a>
                <span>{{ t('simulation.cpf_use_terms.solfacil') }}</span>
              </SolCheckbox>
              <span class="error-message">{{ agreeWithTermsError }}</span>
            </div>
          </div>
        </div>
        <div v-if="!isAdmin && !isIntegradorLight && !isLoadingSubmit" class="submit-container">
          <SolButton
            id="go" class="self-end" type="submit"
            :disabled="!agreeWithTerms || isLoadingSubmit || isFinancingTypesLoading || hasFieldErrors" size="large"
          >
            {{ t('app.next') }}
          </SolButton>
        </div>
      </form>
    </div>
  </template>

  <!--  -->
  <template v-else>
    <div class="container">
      <div class="my-4 card-container flex flex-col justify-between w-full items-center">
        <SolEmptyState
          id="empty-adm-solfacil" title="Seu perfil é básico e não permite acesso a essa função"
          subtitle="Devido à política de precificação, você ainda não tem acesso às linhas de financiamento. Entre em contato com o atendimento para verificar os requisitos para acessar o financiamento."
          variant="no-content"
        >
          <template #action>
            <SolButton id="go" class="self-end" type="submit" size="large" @click="() => router.push('/')">
              {{ t('app.back') }}
            </SolButton>
          </template>
        </SolEmptyState>
      </div>
    </div>
  </template>
</template>

<style lang="scss" scoped>
.container {
  @apply flex flex-col items-start px-4xs pb-6 mt-6;

  @screen md:system {
    @apply px-12 py-12 mt-0;
  }

  .loader-lines {
    @apply h-40 rounded-lg mt-5;
    @apply top-0 right-0 bottom-0 left-0 z-50;
    background: rgba(#fff, 0.9) url(https://cdn.solfacil.com.br/assets/img/loading-for-legacy.gif) no-repeat center center;
  }

  >form {
    @apply my-0;

    @screen md:system {
      @apply my-2xs;
    }
  }

  .title {
    @apply flex justify-between;
    @apply fonts-body-large-bold;
    @apply text-neutral-low-dark text-sm;
    @apply pb-1;
  }

  .sub-title {
    @apply fonts-subtitle-small text-neutral-low-light text-2xs;
  }
}

.submit-container {
  @apply border-t-neutral-low-light w-full flex justify-end;

  @screen xsm:system {
    @apply justify-center;
  }

  >button {
    @screen xsm:system {
      @apply w-full;
    }
  }
}

.input-options {
  @apply my-2xs grid gap-4;
}

.error-message {
  @apply text-feedback-negative-pure py-nano;
  @apply fonts-subtitle-small;
}

.card-container {
  @apply m-0 px-4xs py-6;
  background-color: white;
  background-color: white;
  border-radius: 16px;

  @screen md:system {
    @apply p-12 my-6;
  }
}
</style>

<route lang="yaml">
meta:
  layout: simulation
</route>
