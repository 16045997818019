import type { UseFinancingData } from '../financings/useFinancing'
import type { UseRegistrationData } from './useRegistration'
import { useMutation } from '@tanstack/vue-query'
import queryClient, { queryKeys } from '~/queryClient'
import financingBff from '~/services-v2/financial-bff/financial-bff'

export default function useCustomerDocumentFormAnalysisCancel(projectId: Ref<string>) {
  const mutationFn = async (step: string) => {
    const res = await financingBff('mutation')({
      customer_document_form_analysis_cancel: [
        {
          project_id: projectId.value,
          step,
        },
        {
          id: true,
          status: true,
          project_id: true,
          installation_document_matches_bill: true,
          installation_address_matches_bill: true,
          identity_matches_document: true,
          reason: true,
          comments: true,
        },
      ],
    })
    return res.customer_document_form_analysis_cancel
  }

  return useMutation({
    mutationFn,
    mutationKey: queryKeys.REGISTRATION.FORM_ANALYSIS_CANCEL,
    onSuccess() {
      if (!projectId)
        return

      queryClient.setQueryData<UseFinancingData>(queryKeys.FINANCING.DETAILS(projectId.value), (prevData) => {
        if (!prevData)
          return prevData

        return {
          ...prevData,
          section_statuses: {
            ...prevData.section_statuses,
            proposal: {
              ...prevData.section_statuses.proposal,
              customer_data: 'in_progress',
            },
          },
        }
      })

      // optimistic update for registration details
      queryClient.setQueryData<UseRegistrationData>(queryKeys.REGISTRATION.DATA(projectId.value), (prevData) => {
        if (!prevData)
          return prevData

        return {
          ...prevData,
          status: 'in_progress',
        }
      })

      queryClient.invalidateQueries({ queryKey: queryKeys.REGISTRATION.DATA(projectId.value) })
      queryClient.refetchQueries({ queryKey: queryKeys.REGISTRATION.DATA(projectId.value) })
    },
  })
}
