import type { HardwareCombosModel } from '~/utils/installation/Installation'
import { useQuery } from '@tanstack/vue-query'
import queryClient, { queryKeys } from '~/queryClient'
import financialBff from '~/services-v2/financial-bff/financial-bff'
import { HardwareMirrorStatus } from '~/utils/installation/Installation'

export default function useMirrorStatus(projectId: Ref<string>, skip: Ref<boolean>) {
  const queryKey = ref(queryKeys.HARDWARE.MIRROR_STATUS(projectId.value, skip.value))

  const queryFn = async () => {
    const hardwareForm = queryClient.getQueryData<HardwareCombosModel>(queryKeys.HARDWARE.FORM(projectId.value))

    if (hardwareForm && hardwareForm.mirror_metadata?.status === HardwareMirrorStatus.APPROVED) {
      queryClient.cancelQueries({
        queryKey,
      })

      return HardwareMirrorStatus.APPROVED
    }

    const response = await financialBff('query')({
      mirrorStatus: [
        {
          projectId: projectId.value,
        },
        {
          status: true,
        },
      ],
    })

    return response.mirrorStatus?.status
  }

  watch(skip, () => {
    queryKey.value = queryKeys.HARDWARE.MIRROR_STATUS(projectId.value, skip.value)
  })

  return useQuery({
    queryKey: queryKey.value,
    queryFn,
    refetchInterval: 15 * 1000,
    retry: 2,
    retryDelay: 1000,
    enabled: !skip.value,
  })
}
