<script setup lang="ts">
import IconDownload from '~icons/girassol/download'
import checkIcon from '~icons/material-symbols/check-circle-outline-rounded'
import lockIcon from '~icons/material-symbols/lock-outline'
import useFinancingV2 from '~/store-v2/financings/useFinancing'
import download from '~/utils/download'

const props = defineProps<{
  data: any
  hasTransfers: boolean
  statusFlow: string
}>()

const { t } = useI18n()
const { formatMoney } = useMoney()
const config = import.meta.env
const route = useRoute()

const id = computed(() => route.params.id as string)
const { data: financing } = useFinancingV2(id)
const partnerTransfers = props.data.transfers?.filter(transfer => transfer.category === 'financing') || []

const statusConfig = {
  waiting_send_payment: { icon: lockIcon, class: 'text-neutral-low-light' },
  paid: { icon: checkIcon, class: 'fonts-body-large-bold text-brand-secondary-pure' },
  fail: { icon: lockIcon, class: 'text-neutral-low-light' },
}

function getLink(item) {
  const paymentMap = {
    financing: {
      after_project_formalization: 4,
      after_validation_installation: 5,
    },
    equipment: 6,
    commission_financing: 7,
    commission_insurance: 8,
  }

  const payment_id = computed(() => {
    if (item.category === 'financing') {
      return paymentMap[item.category]?.[item.kind] ?? item.financing_id
    }
    return paymentMap[item.category]
  })

  const url = `${config.VITE_PAYMENT_MODEL_TRANSACTIONS_API_URL}/transfer-receipt?financing_id=${financing.value?.id}&payment_type_id=${payment_id.value}`
  download.downloadFile(url)
}

function getStatusProperty(transfer, property) {
  if (!transfer)
    return null
  return statusConfig[transfer.status]?.[property] || null
}

const statusEquipment = props.data.transfers.filter(transfer => transfer.category === 'equipment')

const showButtonToDownloadLink = false
</script>

<template>
  <div class="md:system:pt-4xs pt-2xs">
    <div class="flex flex-col md:system:ml-4xs">
      <!-- TRANSFERS -->
      <template v-if="hasTransfers">
        <div v-if="statusEquipment.length" class="pt-0 mb-[10px] flex flex-col md:system:flex-row md:system:justify-between">
          <span class="block break-words md:system:flex md:system:w-1/2">
            {{ props.data.has_combo ? t('customer.kit_value') : t('customer.equipment_value') }}:
          </span>
          <div class="flex flex-col-reverse md:site:flex-row   md:site:items-center md:site:justify-end">
            <SolButton
              v-if="showButtonToDownloadLink"
              id="transaction-download"
              variant="tertiary"
              size="medium"
              @click="getLink({ category: 'equipment' })"
            >
              <template #icon:left>
                <IconDownload />
              </template>

              Baixar comprovante
            </SolButton>
            <span class="md:system:pt-0 pt-quark flex md:system:ml-nano" :class="getStatusProperty(statusEquipment[0], 'class')">
              <component :is="getStatusProperty(statusEquipment[0], 'icon')" class="mr-quark" />
              {{ formatMoney(statusEquipment[0].value) }}
            </span>
          </div>
        </div>
        <div v-for="(transfer, indexTransfer) in partnerTransfers" :key="indexTransfer">
          <div
            v-if="transfer.value > 0"
            class="pb-quark flex flex-col md:system:flex-row md:system:justify-between"
          >
            <span class="block break-words md:system:flex md:system:w-1/2">
              {{ t(`customer.transfer_${transfer.kind}`) }} ({{ transfer.percent }}%):
            </span>
            <div class="flex flex-col-reverse md:site:flex-row   md:site:items-center md:site:justify-end">
              <SolButton
                v-if="showButtonToDownloadLink"
                id="transaction-download"
                variant="tertiary"
                size="medium"
                @click="getLink(transfer)"
              >
                <template #icon:left>
                  <IconDownload />
                </template>

                Baixar comprovante
              </SolButton>

              <span class="md:system:pt-0 pt-quark flex md:system:ml-nano" :class="getStatusProperty(transfer, 'class')">
                <component :is="getStatusProperty(transfer, 'icon')" class="mr-quark" />
                {{ formatMoney(transfer.value) }}
              </span>
            </div>
          </div>
        </div>
      </template>

      <!-- AWAITING TRANSFER -->
      <template v-else>
        <div class="pt-0 mb-[10px] flex flex-col md:system:flex-row md:system:justify-between">
          <span class="block break-words md:system:flex md:system:w-1/2">
            {{ props.data.has_combo ? t('customer.waiting_kit_value') : t('customer.equipment_value') }}:
          </span>
          <span class="md:system:pt-0 pt-quark"> {{ t('customer.opened') }}</span>
        </div>
        <div class="pb-quark flex flex-col md:system:flex-row md:system:justify-between">
          <span class="block break-words md:system:flex md:system:w-1/2">
            {{ t('customer.transfer_waiting') }}
          </span>
          <span class="md:system:pt-0 pt-quark">
            {{ t('customer.opened') }}
          </span>
        </div>
      </template>
    </div>
  </div>
</template>

<style scoped lang="scss">
:deep(.sol-button-core.-medium){
  @apply  px-0 py-4xs;
  @screen md:site {
    @apply p-0;
    height: auto;
  }
}
</style>
