import type { GuarantorResponse, SimulatorApi } from './simulator-api.types'
import type { ResponseModel } from '~/utils/connections/rest/RestConnections'
import type { Project } from '~/utils/project/Project'
import http from './simulator-api.http'

export async function guarantor(project_id: string, document: string) {
  return http<GuarantorResponse>(`/project/guarantor`, {
    method: 'POST',
    data: { project_id, document },
  })
}

export async function getProject(projectId: string): Promise<ResponseModel<Project>> {
  const response = await http.get<ResponseModel<Project>>(`/project/${projectId}`)
  return response.data
}

export async function getSimulationPdf(projectId: string, params: SimulatorApi.GetSimulationPdfRequest) {
  const res = await http.get(`/simulation/${projectId}/pdf/v2`, {
    params,
    responseType: 'blob',
    headers: {
      'Content-Type': 'application/pdf',
      'Accept': 'application/pdf',
    },
  })
  return res.data
}

export default {
  guarantor,
  getSimulationPdf,
}
