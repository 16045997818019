<script setup lang="ts">
import type { Option } from '@solfacil/girassol/dist/types/components/forms/select/types'
import type { DocumentFields, Field, Form } from './Person.schema'
import type { RegistrationDocument } from '~/services-v2/financial-bff/financial-bff.types'
import { SolInputText, SolLink, SolSelect } from '@solfacil/girassol'
import { isEqual } from 'lodash'
import FileStorageInput from '~/components/input/FileStorageInput.vue'
import { RegistrationDocumentModel, RegistrationDocumentTypes } from '~/services-v2/financial-bff/__generated__/zeus'
import useRegistration from '~/store-v2/registration/useRegistration'
import { documentOptions } from '~/ui-blocks/Formalization/Formalization.utils'
import { convertToBrDate, convertToBrPhone, convertToCPF } from '~/utils/string'
import * as personSchema from './Person.schema'

// Props
const props = defineProps<{
  form: Form
  disableCpf?: boolean
  title?: string
  kind: 'customer' | 'majority_partner' | 'legal_representative_1' | 'legal_representative_2' | 'guarantor'
  disabled?: boolean
}>()

// initial base hooks
const { t } = useI18n()
const route = useRoute()

const projectId = computed(() => route.params.id as string)

const {
  data: registration,
} = useRegistration(projectId)

// fields
const [documentTypeModel, _documentTypeProps] = props.form.defineField('document_type')
const [fullnameModel, fullnameProps] = props.form.defineField('full_name')
const [cpfModel, cpfProps] = props.form.defineField('cpf')
const [emailModel, emailProps] = props.form.defineField('email')
const [phoneModel, phoneProps] = props.form.defineField('phone')
const [birthdateModel, birthdateProps] = props.form.defineField('birthdate')
const [documentFrontModel, documentFrontProps] = props.form.defineField('document_front')
const [documentBackModel, documentBackProps] = props.form.defineField('document_back')
const [documentDigitalModel, documentDigitalProps] = props.form.defineField('document_digital')

// initial variables and computed
const showModalDocumentExample = ref(false)
const isGuarantor = props.kind === 'guarantor'

const currentDocuments = reactive<Record<DocumentFields, RegistrationDocument | undefined>>({
  document_front: undefined,
  document_back: undefined,
  document_digital: undefined,
})

const ACCEPTED_FILE_TYPES = ['image/jpeg', 'image/jpg', 'image/png', 'image/webp', 'application/pdf']

const isFieldsDisabled = computed(() => props.disabled ?? false)

const errors = computed(() => personSchema.extractErrorsByField(registration.value?.errors, props.kind))
const isCnhOrRg = computed(() => documentTypeModel.value === 'rg' || documentTypeModel.value === 'cnh')
const firstError = computed(() => Object.values(errors.value)[0])
const isStatusToReview = computed(() => registration.value?.status === 'to_review')

// functions
function handleOpenModalDocumentExample() {
  showModalDocumentExample.value = true
}

function handleSelectedDocumentType(val) {
  if (val.value === 'rg' || val.value === 'cnh') {
    props.form.setFieldValue('document_digital', undefined)
  }
  else {
    props.form.setFieldValue('document_front', undefined)
    props.form.setFieldValue('document_back', undefined)
  }
}

function disableField(field: Field) {
  return currentDocuments[field] && currentDocuments[field]?.status !== 'pending'
}

// watch
watch(registration, async (newValue, oldValue) => {
  if (isEqual(newValue, oldValue)) {
    return
  }
  let legal_representative_1
  let legal_representative_2

  const major = newValue?.company?.majority_partner
  const legals = newValue?.company?.legal_representatives || []
  const majorIsLegalRep = legals.some(l => l.cpf === major?.cpf)

  if (majorIsLegalRep) {
    legal_representative_1 = legals.find(l => l.cpf !== major?.cpf)
  }
  else {
    legal_representative_1 = legals.find(l => l.kind === 'legal_representative_1')
    legal_representative_2 = legals.find(l => l.kind === 'legal_representative_2')
  }

  const data = {
    customer: newValue?.customer,
    majority_partner: newValue?.company?.majority_partner,
    guarantor: newValue?.guarantor,
    legal_representative_1,
    legal_representative_2,
  }[props.kind]

  if (!data) {
    return
  }

  props.form.setValues({
    email: data?.contact?.email,
    phone: convertToBrPhone(data?.contact?.phone),
    full_name: data?.full_name,
    cpf: convertToCPF(data?.cpf),
    birthdate: convertToBrDate(data?.birthdate),
    document_type: (data?.document_type as 'rg' | 'cnh_digital' | 'cnh') || 'rg',
  }, false)

  if (data.document_type === RegistrationDocumentModel.rg) {
    const document_front = data?.documents.find(doc => doc.type === RegistrationDocumentTypes.identity_document_front)
    const document_back = data?.documents.find(doc => doc.type === RegistrationDocumentTypes.identity_document_back)
    if (document_front) {
      currentDocuments.document_front = document_front
      props.form.setFieldValue('document_front', document_front.docstorage_id, false)
    }
    if (document_back) {
      currentDocuments.document_back = document_back
      props.form.setFieldValue('document_back', document_back.docstorage_id, false)
    }
  }

  if (data.document_type === RegistrationDocumentModel.cnh) {
    const document_front = data?.documents.find(doc => doc.type === RegistrationDocumentTypes.driver_license_front)
    const document_back = data?.documents.find(doc => doc.type === RegistrationDocumentTypes.driver_license_back)
    if (document_front) {
      currentDocuments.document_front = document_front
      props.form.setFieldValue('document_front', document_front.docstorage_id, false)
    }
    if (document_back) {
      currentDocuments.document_back = document_back
      props.form.setFieldValue('document_back', document_back.docstorage_id, false)
    }
  }

  if (data.document_type === RegistrationDocumentModel.cnh_digital) {
    const document_digital = data?.documents.find(doc => doc.type === RegistrationDocumentTypes.digital_driver_license)
    if (document_digital) {
      currentDocuments.document_digital = document_digital
      props.form.setFieldValue('document_digital', document_digital.docstorage_id, false)
    }
  }

  const errs = personSchema.extractErrorsByField(newValue?.errors, props.kind)

  setTimeout(() => { // to avoid concorrency with the form values
    Object.entries(errs).forEach(([key, value]) => {
      props.form.setFieldError(key as Field, value.title)
    })
  }, 1000)
}, { immediate: true })
</script>

<template>
  <!-- alerts -->
  <SolAlert
    v-if="firstError && isStatusToReview"
    :id="`${kind}-alert-error`"
    :feedback="firstError.type ?? 'warning'"
    :title="firstError.title"
  >
    {{ firstError.description }}
  </SolAlert>
  <!--  -->

  <div class="document-container">
    <div class="document-content">
      <div class="document-input">
        <span class="font-highlight text-neutral-low-dark text-[16px] md:system:text-[20px]">
          {{ title }}
        </span>

        <SolSelect
          :id="`${kind}-document_type`"
          name="document_type"
          class="w-full"
          :label="`${t('formalization.customer_documents.documents')}*`"
          :options="documentOptions"
          :disabled="isFieldsDisabled"
          :selected="documentOptions.find(option => option.value === documentTypeModel)"
          @update:selected="props.form.setFieldValue('document_type', ($event as Option).value as any)"
          @selected="val => handleSelectedDocumentType(val)"
        />
        <div class="arrow-text block md:system:hidden">
          <span class="fonts-body-medium-regular text-brand-primary-light">
            <strong>{{ t('formalization.upload.cpf_attention.text') }}</strong>
            {{ t('formalization.upload.cpf_attention.description') }}
          </span>
        </div>
        <!-- Fisical Document -->
        <div v-if="isCnhOrRg" class="flex flex-col md:system:flex-row gap-4 w-full">
          <!-- Frontal Document -->
          <FileStorageInput
            :id="`${kind}-document_front`"
            v-model="documentFrontModel"
            v-bind="documentFrontProps"
            :disabled="isFieldsDisabled || disableField('document_front')"
            name="document_front"
            :label="`${t('formalization.customer_documents.document.type_front', { count: documentTypeModel === 'rg' ? 1 : 0 })}`"
            class="w-full md:system:w-1/2"
            :accept="ACCEPTED_FILE_TYPES.join(',')"
            :placeholder="t('formDocument.placeholder')"
            :error="props.form.errors.value.document_front"
            :project-id="registration?.project_id!"
            :uploaded-file="currentDocuments?.document_front"
            :enable-delete="isStatusToReview && !disableField('document_front')"
          />

          <!-- Back Document -->
          <FileStorageInput
            :id="`${kind}-document_back`"
            v-model="documentBackModel"
            v-bind="documentBackProps"
            name="document_back"
            :label="`${t('formalization.customer_documents.document.type_back', { count: documentTypeModel === 'rg' ? 1 : 0 })}`"
            class="w-full md:system:w-1/2"
            :disabled="isFieldsDisabled || disableField('document_back')"
            :accept="ACCEPTED_FILE_TYPES.join(',')"
            :placeholder="t('formDocument.placeholder')"
            :error="props.form.errors.value.document_back"
            :project-id="registration?.project_id!"
            :uploaded-file="currentDocuments?.document_back"
            :enable-delete="isStatusToReview && !disableField('document_back')"
          />
        </div>

        <!-- Digital Document -->

        <div v-else class="flex flex-col md:system:flex-row gap-4 w-full">
          <FileStorageInput
            :id="`${kind}-document_digital`"
            v-model="documentDigitalModel"
            v-bind="documentDigitalProps"
            :disabled="isFieldsDisabled || disableField('document_digital')"
            name="document_digital"
            :label="`${t('formalization.customer_documents.digital_document')}*`"
            class="w-full"
            accept="application/pdf"
            :placeholder="t('formDocument.placeholder')"
            :error="props.form.errors.value.document_digital"
            :project-id="registration?.project_id!"
            :uploaded-file="currentDocuments?.document_digital"
            :enable-delete="isStatusToReview && !disableField('document_digital')"
          />
        </div>

        <div class="arrow-text">
          <span class="fonts-body-small-regular text-brand-primary-light">
            {{ t('formalization.upload.maximum_size.text') }}
            <strong>{{ t('formalization.upload.maximum_size.10mb') }}</strong>
            {{ t('formalization.upload.accepted_files.text') }}
            <strong>{{ t('formalization.upload.accepted_files.files') }}</strong>
          </span>
        </div>
        <SolLink
          :id="`${kind}-what-is-bond-link`"
          size="medium"
          class="block md:system:hidden"
          @click="handleOpenModalDocumentExample"
        >
          {{ t('formalization.customer_documents.document.example.link') }}
        </SolLink>
      </div>

      <!--  -->
      <div class="document-example">
        <div class="document-content">
          <p class="title">
            {{ t('formalization.customer_documents.document.example.title') }}
          </p>
          <div class="flex items-center gap-3">
            <div class="flex flex-col gap-1 items-center justify-center">
              <img
                src="/images/document_front.svg"
                :alt="t('formalization.customer_documents.document.example.image.front.alt')"
              >
              <span class="fonts-body-small-bold text-neutral-low-light">
                {{ t('formalization.customer_documents.document.example.image.front.title') }}
              </span>
            </div>
            <div class="flex flex-col gap-1 items-center justify-center">
              <img
                src="/images/document_back.svg"
                :alt="t('formalization.customer_documents.document.example.image.back.alt')"
              >
              <span class="fonts-body-small-bold text-neutral-low-light">
                {{ t('formalization.customer_documents.document.example.image.back.title') }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="data-container">
      <div class="flex flex-col md:system:flex-row gap-6 w-full">
        <SolInputText
          :id="`${kind}-fullname`"
          v-model="fullnameModel"
          name="fullname"
          class="w-full md:system:w-2/3"
          v-bind="fullnameProps"
          :label="`${isGuarantor ? t('formalization.client.name.label_guarantor') : t('formalization.client.name.label')}*`"
          :disabled="isFieldsDisabled"
          :placeholder="`${isGuarantor ? t('formalization.client.name.placeholder_guarantor') : t('formalization.client.name.placeholder')}`"
          :error="props.form.errors.value.full_name"
        />
        <SolInputText
          :id="`${kind}-cpf`"
          v-model="cpfModel"
          name="cpf"
          class="md:system:w-1/3 w-full"
          v-bind="cpfProps"
          :label="`${isGuarantor ? t('formalization.client.cpf.label_guarantor') : t('formalization.client.cpf.label')}*`"
          :mask="{ preset: 'CPF' }"
          :disabled="props.disableCpf || isFieldsDisabled"
          :placeholder="t('formalization.client.cpf.placeholder')"
          :error="props.form.errors.value.cpf"
        />
      </div>
      <div class="flex flex-col md:system:flex-row gap-6 w-full">
        <SolInputText
          :id="`${kind}-email`"
          v-model="emailModel"
          name="email"
          class="w-full md:system:w-1/3"
          type="email"
          :disabled="isFieldsDisabled"
          :label="`${isGuarantor ? t('formalization.client.email.label_guarantor') : t('formalization.client.email.label')}*`"
          v-bind="emailProps"
          :placeholder="`${isGuarantor ? t('formalization.client.email.placeholder_guarantor') : t('formalization.client.email.placeholder')}`"
          :error="props.form.errors.value.email"
        />
        <SolInputText
          :id="`${kind}-phone`"
          v-model="phoneModel"
          name="phone"
          class="md:system:w-1/3 w-full"
          :mask="{ preset: 'MobilePhoneDDD' }"
          :label="`${isGuarantor ? t('formalization.client.phone.label_guarantor') : t('formalization.client.phone.label')}*`"
          v-bind="phoneProps"
          :placeholder="`${t('formalization.client.phone.placeholder')}`"
          :disabled="isFieldsDisabled"
          :error="props.form.errors.value.phone"
        >
          <template #icon:left>
            +55
          </template>
        </SolInputText>

        <SolInputText
          :id="`${kind}-birthdate`"
          v-model="birthdateModel"
          name="birthdate"
          class="w-full md:system:w-1/3"
          :label="`${isGuarantor ? t('formalization.client.birthdate.label_guarantor') : t('formalization.client.birthdate.label')}*`"
          v-bind="birthdateProps"
          mask="##/##/####"
          :disabled="isFieldsDisabled"
          :placeholder="`${t('formalization.client.birthdate.placeholder')}`"
          :error="props.form.errors.value.birthdate"
        />
      </div>
    </div>
  </div>

  <SolModal
    :id="`${kind}-modal-document-example`"
    :is-open="showModalDocumentExample"
    :title="`${t('formalization.customer_documents.document.example.link')}`"
    :action-primary-text="t('formalization.electricity_bill.modal_what_is_bond.okay')"
    :size="{ desktop: 'extra-large', mobile: 'full' }"
    @close="showModalDocumentExample = false"
    @action:primary="showModalDocumentExample = false"
  >
    <div class="flex flex-col justify-center items-center gap-4">
      <p class="fonts-body-small-bold text-neutral-low-light">
        {{ t('formalization.customer_documents.document.example.title') }}
      </p>
      <div class="flex flex-col justify-center items-center gap-2 w-full">
        <div class="flex flex-col items-center">
          <img
            src="/images/document_front.svg"
            :alt="t('formalization.customer_documents.document.example.image.front.alt')"
          >
          <span class="fonts-body-small-bold text-neutral-low-light">
            {{ t('formalization.customer_documents.document.example.image.front.title') }}
          </span>
        </div>
        <SolDivider thickness="x-small" />
        <div class="flex flex-col gap-1 items-center justify-center">
          <img
            src="/images/document_back.svg"
            :alt="t('formalization.customer_documents.document.example.image.back.alt')"
          >
          <span class="fonts-body-small-bold text-neutral-low-light">
            {{ t('formalization.customer_documents.document.example.image.back.title') }}
          </span>
        </div>
      </div>
    </div>
  </SolModal>
</template>

<style lang="scss" scoped>
.document-container {
  @apply flex flex-col items-start gap-8 self-stretch w-full;

  .document-content {
    @apply flex items-center gap-6 self-stretch;

    .document-input {
      @apply flex flex-col items-start gap-4 w-full md:system:w-1/2 lg:system:w-full;
      flex-grow: 1;
      min-width: 0;
      .arrow-text::before {
        @apply text-brand-primary-light;
        content: '➔';
      }
    }

    .document-example {
      @apply hidden md:system:flex;
      @apply flex-col items-start justify-center gap-4 p-4xs;
      @apply bg-neutral-high-light rounded-md;
      @apply min-w-[342px];

      .document-content {
        @apply flex flex-col items-center gap-4 w-full;

        .title {
          @apply fonts-body-small-bold text-neutral-low-light;
        }
      }
    }
  }

  .data-container {
    @apply flex flex-col items-start gap-6 self-stretch;
  }
}

:deep(.sol-input-core){
    &.-left-icon {
      @apply pl-xl;
    }
  }
.error-document {
  :deep(.sol-download) {
    @apply border-feedback-negative-pure;
  }
}
</style>
