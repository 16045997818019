import type { InputPaymentModelSendBillingLetter } from './payment-model-api.types'
import type { ResponseModel } from '~/utils/connections/rest/RestConnections'
import type { PaymentModelSupplier, ReceiptModel, ResponseUpload } from '~/utils/receipt-model/ReceiptModel'
import http from './payment-model-api.http'

export async function postPaymentModelUpload(body: any) {
  const response = await http.post<ResponseModel<ResponseUpload>>('/receipt-model/upload', body)
  return response.data
}

export async function deletePaymentModelUpload(id: string) {
  const response = await http.delete<ResponseModel<any>>(`/receipt-model/upload/${id}`)
  return response.data
}

export async function getPaymentModelSuppliers() {
  const response = await http.get<PaymentModelSupplier[]>('/receipt-model/suppliers')
  return response.data
}

export async function postPaymentModelSendBillingLetter(inputs: InputPaymentModelSendBillingLetter) {
  const response = await http.put<ResponseModel<any>>('/receipt-model/send-billing-letter', {
    ...inputs,
  })

  return response.data
}

export async function getPaymentModel(projectId: string) {
  const response = await http.get<ReceiptModel>(`/receipt-model/${projectId}`)
  return response.data
}

export async function postPaymentModel(inputs: any): Promise<ResponseModel<any>> {
  const response = await http.post<ResponseModel<any>>('/receipt-model', inputs)
  return response.data
}
