<script setup lang="ts">
import { defineProps } from 'vue'
import { useI18n } from 'vue-i18n'
import priceUpdateImage from '/public/images/price-update.png'

const props = defineProps<{
  isOpen: boolean
  handleClose: () => void
  onPrimaryAction: () => void
  onSecondaryAction: () => void
}>()

const { t } = useI18n()
</script>

<template>
  <SolModal
    id="modal"
    :is-open="props.isOpen"
    :title="t('price.modal.title')"
    :action-primary-text="t('price.modal.primary')"
    :action-secondary-text="t('price.modal.secondary')"
    :size="{
      desktop: 'medium',
      mobile: 'bottom-sheet',
    }"
    @close="handleClose"
    @action:primary="props.onPrimaryAction"
    @action:secondary="props.onSecondaryAction"
  >
    <div class="flex justify-center flex-col items-center">
      <!-- image -->
      <img
        class="md:system:w-[30%] sm:system:w-[70%] mb-4"
        :src="priceUpdateImage"
        :alt="t('simulation.modal.altImg')"
      >

      <!-- benefits -->
      <div class="grid gap-3">
        <div class="items-center text-center py-2 col-span-3 md:system:col-span-5">
          <h1 class="p-0 font-bold text-xs text-neutral-low-dark mb-4" style="line-height: 110%">
            {{ t('price.modal.body') }}
          </h1>
          <p class="text-micro sm:system:text-3xs text-neutral-low-light leading-4">
            {{ $t('price.modal.subbody') }}
          </p>
        </div>
      </div>
    </div>
  </SolModal>
</template>
