<script setup lang="ts">
import { useToast } from '@solfacil/girassol'
import { computed, toRaw } from 'vue'
import IMaterialSymbolsDownload from '~icons/material-symbols/download'
import IMaterialSymbolsShareOutline from '~icons/material-symbols/share-outline'
import env from '~/env'
import useAuth from '~/store-v2/auth/useAuth'
import useDownloadSimulationPdf from '~/store-v2/simulation/useSimulationPdf'
import { parseObjectToSearchParams } from '~/utils/search-params'

const props = defineProps<{
  commission: number | undefined
  gracePeriod: number | undefined
  isCombo: boolean | undefined
}>()

const { t } = useI18n()
const windowSize = useWindowSize()
const { createErrorToast, createSuccessToast } = useToast()
const route = useRoute()

const projectId = computed(() => route.params.id as string)

const {
  getAddonsApplieds,
  getSelectedInstallment,
} = useResumeStore()

const {
  data: auth,
} = useAuth()

const {
  mutateAsync: mutateDownloadSimulationPdf,
  isPending: isPendingDownloadSimulationPdf,
} = useDownloadSimulationPdf(projectId)

const isLoading = computed(() => isPendingDownloadSimulationPdf.value)

function indexerKinderPdfAdapter(indexer_kind: string) {
  if (indexer_kind === 'PRE_FIXADO')
    return 'PRE'
  return 'POST'
}
async function getProposalParams() {
  const { gracePeriod, commission } = props

  if (!auth.value)
    throw new Error('Parametros inválidos')

  const commissionValue = toRaw(commission) as number
  const gracePeriodValue = toRaw(gracePeriod) as number
  const has_ampera = !!(getAddonsApplieds()?.find(item => item.product_slug === 'Ampera')) as boolean
  const has_income_loss_insurance = !!(getAddonsApplieds()?.find(item => item.product_slug === 'Prestamista')) as boolean
  const has_equipment_insurance = !!(getAddonsApplieds()?.find(item => item.product_slug === 'Riscos Diversos')) as boolean
  const installments = getSelectedInstallment()?.installments as number
  const indexer_kind = getSelectedInstallment()?.indexer_kind as string

  return {
    grace_period: gracePeriodValue,
    commission: commissionValue,
    with_combo: props.isCombo,
    partner_level: auth.value.me?.parceiro?.nivelSolfacilMais || '',
    is_ampera: has_ampera,
    income_loss_insurance: has_income_loss_insurance,
    equipment_insurance: has_equipment_insurance,
    selected_installment: installments,
    indexer_kind: indexerKinderPdfAdapter(indexer_kind),
  }
}

async function handleSuccess() {
  createSuccessToast('PDF gerado com sucesso!')
}

async function handleError(error: unknown) {
  if (error instanceof Error) {
    createErrorToast(`Ocorreu um erro ao tentar baixar o PDF.: ${error.message}`)
  }
  else {
    createErrorToast('Ocorreu um erro ao tentar baixar o PDF.')
  }
}

async function handleDownloadPdf() {
  try {
    const params = await getProposalParams()
    await mutateDownloadSimulationPdf(params)
    handleSuccess()
  }
  catch (e) {
    handleError(e)
  }
}

async function handleShareWhatsapp() {
  try {
    const params = await getProposalParams()
    const searchParams = parseObjectToSearchParams(params)
    const url = `${env.VITE_SIMULATOR_SERVICE_URL_DIRECT}/simulation/${projectId.value}/pdf/v2?${searchParams.toString()}`
    window.open(`https://wa.me?text=${encodeURIComponent(url)}`, '_blank')
    handleSuccess()
  }
  catch (e) {
    handleError(e)
  }
}

const data = [
  {
    text: t('app.whatsapp'),
    active: false,
    external: true,
    action: handleShareWhatsapp,
  },
  {
    text: t('app.save'),
    active: false,
    external: true,
    action: handleDownloadPdf,
  },
]

const mdSize = 1024
const position = computed(() => {
  return windowSize.width.value < mdSize ? 'right' : 'top'
})
</script>

<template>
  <div class="share-container">
    <SolMenu id="just-test" :position="position" class="flex justify-end z-20">
      <template #label>
        <SolButton
          id="primary-right-icon"
          :loading="isLoading"
          size="large"
          variant="secondary"
          class="share-button-large"
        >
          <template #icon:left>
            <IMaterialSymbolsShareOutline class="share-icon" />
          </template>
          <span>
            {{ t('app.share') }}
          </span>
        </SolButton>
        <SolButton
          id="primary-right-icon"
          :loading="isLoading"
          size="medium"
          variant="secondary"
          class="share-button-medium !h-[56px] !w-[56px]"
        >
          <template #icon:left>
            <IMaterialSymbolsShareOutline class="mx-nano" />
          </template>
        </SolButton>
      </template>
      <nav v-if="!isLoading" id="navbarShare">
        <ul role="menubar">
          <ItemsMenuItems
            v-for="({ text, active, external, action }, index) in data"
            :id="text"
            :key="index"
            :text="text"
            :action="action"
            :active="active"
            :external="external"
            class="menu-spacer"
          >
            <template #icon:WhatsApp>
              <img src="/icons/whatsapp.svg" class="mx-nano">
            </template>
            <template #icon:Salvar>
              <IMaterialSymbolsDownload class="mx-nano" />
            </template>
          </ItemsMenuItems>
        </ul>
      </nav>
    </SolMenu>
  </div>
</template>

<style lang="scss" scoped>
.share-container{
  @apply self-end;

  .menu-spacer{
    @apply pr-xs text-neutral-low-medium;
  }
  .share-button-large{
    @apply hidden;
    @apply md:site:flex;
  }

  .share-button-medium{
    @apply flex;
    @apply md:site:hidden;
  }
  .share-icon{
      @apply h-6;
    }
  .btn-selectable-left{
    @apply border border-brand-primary-light rounded-l-md;
    @apply p-nano cursor-pointer font-bold;
  }

  .btn-selectable-right{
    @apply border border-brand-primary-light rounded-r-md;
    @apply p-nano cursor-pointer font-bold;
  }
  .btn-selected{
    @apply bg-brand-secondary-pure border-brand-secondary-pure;

    .title {
      @apply text-neutral-high-pure;
    }

  }
}
.buttons {
  @apply flex;
}
</style>
