/* eslint-disable jsdoc/check-param-names */
/* eslint-disable jsdoc/require-returns-description */
import type { ResponseModel } from '~/utils/connections/rest/RestConnections'
import type { Project } from '~/utils/project/Project'
import type { Addons, CommissionOptionals, RequestSimulationOpportunities, Simulation, SimulationConclusion, SimulationList, SimulationOpportunities, SimulationsListParams } from '~/utils/simulation/Simulation'
import type { InstallmentResponse } from '~/utils/simulation/SimulationApi'
import http from '~/services-v2/simulator-api/simulator-api.http'

const DEFAULT_PROJECT_LIST_PARAMS: SimulationsListParams = {
  page: 0,
  offset: 0,
  order: 'asc',
}
export default class SimulatorService {
  async create_project(payload: any): Promise<ResponseModel<Project>> {
    try {
      const status = 202

      const response = await http<ResponseModel<Project>>('/project/create-project', {
        method: 'POST',
        data: payload,
      })

      return {
        ...response.data,
        status: response?.data?.code ?? status,
      }
    }
    catch (e) {
      return Promise.reject(e)
    }
  }

  async approve_new_down_payment(payload: any): Promise<ResponseModel<string>> {
    try {
      const response = await http<ResponseModel<string>>('/project/approve-down-payment', {
        method: 'PUT',
        data: payload,
      })

      return response.data
    }
    catch (e) {
      return Promise.reject(e)
    }
  }

  async get_last_simulation_by_project(project_id: any): Promise<ResponseModel<Simulation>> {
    try {
      const response = await http<ResponseModel<Simulation>>(`/simulation/by-project/${project_id}`, {
        method: 'get',
      })
      return response.data
    }
    catch (e) {
      return Promise.reject(e)
    }
  }

  async get_simulation_by_shopping_cart_id(project_id: string): Promise<ResponseModel<Simulation>> {
    try {
      const response = await http<ResponseModel<Simulation>>(`/project/${project_id}/shopping_cart_id`, {
        method: 'GET',
      })
      return response.data
    }
    catch (e) {
      return Promise.reject(e)
    }
  }

  async put_simulation_by_shopping_cart_id(project_id: string, payload?: { shopping_cart_id: string }): Promise<ResponseModel<Simulation>> {
    try {
      const response = await http<ResponseModel<Simulation>>(`/project/${project_id}/shopping_cart_id`, {
        method: 'PUT',
        data: payload,
      })
      return response.data
    }
    catch (e) {
      return Promise.reject(e)
    }
  }

  async create_financial(payload: SimulationConclusion): Promise<ResponseModel<any>> {
    try {
      const project = await http<ResponseModel<any>>('/simulation/simulation-conclusion', {
        method: 'POST',
        data: payload,
      })
      return project.data
    }
    catch (e) {
      return Promise.reject(e)
    }
  }

  async get_project(project_id: any): Promise<ResponseModel<Project>> {
    try {
      const project = await http<ResponseModel<Project>>(`/project/${project_id}`, {
        method: 'GET',
      })

      return project.data
    }
    catch (e) {
      return Promise.reject(e)
    }
  }

  async calculate_project(payload: any): Promise<ResponseModel<InstallmentResponse>> {
    try {
      const project = await http<ResponseModel<InstallmentResponse>>('/simulation/installment-details-calculate', {
        method: 'POST',
        data: payload,
      })

      return project.data
    }
    catch (e) {
      return Promise.reject(e)
    }
  }

  async get_addons(simulation_id: string, engine_simulation_id: string, installment_value: number): Promise<ResponseModel<Addons[]>> {
    try {
      const response
        = await http<ResponseModel<Addons[]>>
        (`/simulation/${simulation_id}/addons?engine_simulation_id=${engine_simulation_id}&installment_value=${installment_value}`, {
          method: 'GET',
        })

      return response.data
    }
    catch (e) {
      return Promise.reject(e)
    }
  }

  async get_ampera(simulation_id: string, engine_simulation_id: string, installment_value: number): Promise<ResponseModel<Addons[]>> {
    try {
      const response
        = await http<ResponseModel<Addons[]>>
        (`/simulation/${simulation_id}/ampera?engine_simulation_id=${engine_simulation_id}&installment_value=${installment_value}`, {
          method: 'GET',
        })

      return response.data
    }
    catch (e) {
      return Promise.reject(e)
    }
  }

  async get_insurance(simulation_id: string, engine_simulation_id: string, installment_value: number): Promise<ResponseModel<Addons[]>> {
    try {
      const response
        = await http<ResponseModel<Addons[]>>
        (`/simulation/${simulation_id}/insurance?engine_simulation_id=${engine_simulation_id}&installment_value=${installment_value}`, {
          method: 'GET',
        })

      return response.data
    }
    catch (e) {
      return Promise.reject(e)
    }
  }

  async get_list_of_projects(params?: SimulationsListParams): Promise<SimulationList> {
    const _params = { ...DEFAULT_PROJECT_LIST_PARAMS, ...params }

    let url = `/project/list/all?page=${_params.page}&offset=${_params.offset}&order=${_params.order}`

    if (Array.isArray(_params.steps)) {
      url += `?${_params.steps.map(step => `steps=${step}`).join('&')}`
      delete _params.steps
    }

    try {
      const response = await http<SimulationList>(url, {
        method: 'GET',
        params: _params,
      })

      return response.data
    }
    catch (e) {
      return Promise.reject(e)
    }
  }

  async get_resume_of_installments_file(grace_period: number, commission: number, with_combo: boolean | null, project_id: string, partner_level: string, is_ampera: boolean): Promise<BlobPart> {
    try {
      const response = await http<BlobPart>(`/simulation/${project_id}/pdf?grace_period=${grace_period}&commission=${commission}&with_combo=${with_combo}&partner_level=${partner_level}&is_ampera=${is_ampera}`, {
        method: 'GET',
      })

      return response.data
    }
    catch (e) {
      return Promise.reject(e)
    }
  }

  async get_resume_of_installments_file_v2(
    grace_period: number,
    commission: number,
    with_combo: boolean | null,
    project_id: string,
    partner_level: string,
    is_ampera: boolean,
    income_loss_insurance: boolean,
    equipment_insurance: boolean,
    selected_installment: number,
    indexer_kind: string,
  ): Promise<BlobPart> {
    try {
      const response = await http<BlobPart>(`/simulation/${project_id}/pdf/v2?grace_period=${grace_period}&commission=${commission}&with_combo=${with_combo}&partner_level=${partner_level}&is_ampera=${is_ampera}&income_loss_insurance=${income_loss_insurance}&equipment_insurance=${equipment_insurance}&selected_installment=${selected_installment}&indexer_kind=${indexer_kind}`, {
        method: 'GET',
      })

      return response.data
    }
    catch (e) {
      return Promise.reject(e)
    }
  }

  async get_commissions(project_id: string): Promise<ResponseModel<CommissionOptionals[]>> {
    try {
      const response = await http<ResponseModel<CommissionOptionals[]>>(`/simulation/commissions/${project_id}`, {
        method: 'get',
      })

      return response.data
    }
    catch (e) {
      return Promise.reject(e)
    }
  }

  /**
   * Endpoint para informações dos pontos solfácil +
   * @param payload(interface: RequestSimulationOpportunities)
   * @returns
   */
  async post_simulation_opportunities(payload: RequestSimulationOpportunities): Promise<ResponseModel<SimulationOpportunities>> {
    try {
      const response = await http<ResponseModel<SimulationOpportunities>>('/simulation/opportunities', {
        method: 'POST',
        data: payload,
      })
      return response.data
    }
    catch (e) {
      return Promise.reject(e)
    }
  }
}
