import type { Ref } from 'vue'
import type { Models } from '~/services-v2/financial-bff/financial-bff'
import { useMutation } from '@tanstack/vue-query'
import queryClient, { queryKeys } from '~/queryClient'
import financialBff from '~/services-v2/financial-bff/financial-bff'

type Payload = Models['ProjectProposalUpdatePayloadInput']

export default function useProjectProposalUpdate(projectId: Ref<string>) {
  const mutationKey = ['PROJECT', 'PROPOSAL_UPDATE', projectId.value]

  const mutationFn = async (payload: Payload) => {
    const res = await financialBff('mutation')({
      project_proposal_update: [{
        project_id: projectId.value,
        payload,
      }, true],
    })

    return res.project_proposal_update
  }

  return useMutation({
    mutationFn,
    mutationKey,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: queryKeys.PROJECT.DETAILS(projectId.value) })
      await queryClient.invalidateQueries({ queryKey: ['OLD_PROJECT', projectId.value] })
      await queryClient.invalidateQueries({ queryKey: queryKeys.PROJECT.PROPOSAL_CHANGES(projectId.value) })
    },
  })
};
