import { useMutation } from '@tanstack/vue-query'
import KnowError from '~/exceptions/KnownError'

import queryClient, { queryKeys } from '~/queryClient'
import financialBff from '~/services-v2/financial-bff/financial-bff'

const knowErrors = [
  'BIOMETRY_ALREADY_DONE',
  'FORMALIZATION_COMPLETED',
  'INVALID_STATUS',
  'MAX_TRIES',
  'MATRIX_RESTRICTIONS',
  'SAME_EMAIL_PHONE',
  'LIMIT_CHANGES_EXCEEDED',
]

export interface RegistrationUpdateByBiometryForm {
  project_id: string
  customer?: {
    phone: string
    email: string
  } | null
  majority_partner?: {
    phone: string
    email: string
  } | null
  legal_representative_1?: {
    phone: string
    email: string
  } | null
  legal_representative_2?: {
    phone: string
    email: string
  } | null
  guarantor?: {
    phone: string
    email: string
  } | null
}

export function useRegistrationContactChange(project_id: string) {
  const mutationFn = async (data: RegistrationUpdateByBiometryForm) => {
    const res = await financialBff('mutation')({
      registration_contact_change: [{
        input: {
          project_id: data.project_id,
          customer: data.customer,
          majority_partner: data.majority_partner,
          legal_representative_1: data.legal_representative_1,
          legal_representative_2: data.legal_representative_2,
          guarantor: data.guarantor,
        },
      }, {
        message: true,
        status: true,
      }],
    })

    await queryClient.refetchQueries({ queryKey: queryKeys.REGISTRATION.DATA(project_id) })

    if (knowErrors.includes(res.registration_contact_change.message)) {
      throw new KnowError(res.registration_contact_change.message)
    }
  }

  return useMutation({
    mutationFn,
    mutationKey: queryKeys.REGISTRATION.REGISTRATION_CONTACT_CHANGE,
  })
}

export type CustomerUpdateResponse = ReturnType<typeof useRegistrationContactChange>['data']
