import { useMutation } from '@tanstack/vue-query'
import { queryKeys } from '~/queryClient'
import { deletePaymentModelUpload } from '~/services-v2/payment-model-api/payment-model-api'

export default function usePaymentModelDeleteFile() {
  const mutationFn = async (id: any) => {
    const response = await deletePaymentModelUpload(id)
    return response
  }

  return useMutation({
    mutationFn,
    mutationKey: queryKeys.PAYMENT_MODEL.DELETE_FILE,

    retry: 2,
    retryDelay: 1000,
  })
}
