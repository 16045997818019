import axios from 'axios'
import {
  injectAccessTokenInterceptor,
  refreshTokenResponseInterceptor,
} from '~/services-v2/keycloack-api/keycloack-api.interceptors'

const http = axios.create({
  baseURL: import.meta.env.VITE_SIMULATOR_SERVICE_URL,
})

http.interceptors.request.use(injectAccessTokenInterceptor)
http.interceptors.response.use(c => c, refreshTokenResponseInterceptor)

export default http
