<script setup lang="ts">
import type { HardwareBodySave, HardwareCombosModel, HardwareCombosSelect, OptionsForm } from '~/utils/installation/Installation'
import { useToast } from '@solfacil/girassol'
import { toTypedSchema } from '@vee-validate/zod'
import { useField, useForm } from 'vee-validate'
import * as zod from 'zod'
import AddIcon from '~icons/material-symbols/add'
import useFinancingV2 from '~/store-v2/financings/useFinancing'
import useOpenableSteps from '~/store-v2/financings/useOpenableSteps'
import useHardwareValidation from '~/store-v2/hardware/useHardwareValidation'
import useCombosHardware from '~/store-v2/installation/useCombosHardware'
import useSaveHardware from '~/store-v2/installation/useSaveHardware'
import useProject from '~/store-v2/projects/useProject'
import { GeneralStatuses } from '~/utils/financing-flow/Financing'
import { customValidation, getHardwareListLabels, validateBatteries, validateInverters, validateModules } from './utils'

const props = defineProps<{
  hardwareInformation: HardwareCombosModel
  refetchForm: () => Promise<void>
}>()

const { t } = useI18n()

const route = useRoute()
const projectId = computed(() => String(route.params.id))

const {
  refetch: refetchDataStepsStatus,
} = useOpenableSteps(projectId)

const { track } = useMixpanel()

const projectShoppingCartId: any = ref('')

const {
  createErrorToast,
  createSuccessToast,
} = useToast()
const { data: financing, isLoading } = useFinancingV2(projectId)

const { mutateAsync: hardwareValidation, isPending: isValidationPending } = useHardwareValidation()
const { data: project, isLoading: isProjectLoading } = useProject(projectId)

const disableStepsAccordingStatus = computed(() => {
  const hardwareIsPending = financing.value?.section_statuses?.proposal?.equipment === GeneralStatuses.IN_PROGRESS
  const isFinancingCanceledOrExpired = ['canceled', 'expired'].includes(financing.value?.status ?? '')
  return (isFinancingCanceledOrExpired || !hardwareIsPending) && isLoading
})

const hasStoreOrder = props.hardwareInformation.has_store_order

const inverterSelectionFieldCount = ref()
const batterySelectionFieldCount = ref()
const loadingProjectData = ref(false)
const showButtonNext = ref(false)
const loadingBackgroundInfo = computed(() => isValidationPending.value || isProjectLoading.value)

function setQuantityOfInvertersFields() {
  const inverters = props.hardwareInformation?.inverters
  inverterSelectionFieldCount.value = inverters && inverters.length > 0 ? inverters.length : 1
}

function setQuantityOfBatteriesFields() {
  const batteries = props.hardwareInformation?.batteries
  batterySelectionFieldCount.value = batteries && batteries.length > 0 ? batteries.length : 1
}

const radiosInverterType = [
  {
    name: 'inverterType',
    value: 'string',
    label: 'Normal (String)',
  },
  {
    name: 'inverterType',
    value: 'microinverter',
    label: 'Microinversor',
  },
]

const { data: comboHardwareData, error: comboHardwareError } = useCombosHardware(projectId, props.hardwareInformation.system_type)

watch(comboHardwareError, (error) => {
  if (error) {
    createErrorToast('Erro ao carregar as informações do hardware. Por favor, tente novamente.')
  }
})

const optionsSelect = computed<HardwareCombosSelect>(() => {
  if (!comboHardwareData.value) {
    return {} as HardwareCombosSelect
  }

  return Object.keys(comboHardwareData.value).reduce((acc, combo) => {
    if (comboHardwareData.value?.[combo]) {
      acc[combo] = comboHardwareData.value[combo].map((item: OptionsForm) => ({
        name: getHardwareListLabels(item, combo),
        value: Number(item.id),
      }))
    }
    return acc
  }, {} as HardwareCombosSelect)
})

const inverterTypeSelect = ref('')

const isMicroinversor = ref((props.hardwareInformation?.microinverters?.length ?? 0) > 0)

const validationSchema = computed(() => toTypedSchema(
  zod.object({
    distributor: zod.object({
      name: zod.string().min(1, t('form.required')),
      value: zod.number().min(1, t('form.required')),
    }),
    installationPhase: zod.object({
      name: zod.string().min(1, t('form.required')),
      value: zod.number().min(1, t('form.required')),
    }),
    inverterType: zod.enum(['string', 'microinverter']),
    microinversorQuantity: inverterTypeSelect.value === 'microinverter'
      ? zod.union([zod.string()
          .refine(value => /^[1-9]\d*$/.test(value), t('form.required')), zod.number().min(1, t('form.required'))])
      : zod.any().optional(),
    inverters: (inverterTypeSelect.value === 'microinverter')
      ? zod.any().optional()
      : zod.array(zod.object({
          name: zod.string({ required_error: t('form.required') }).min(1, t('form.required')),
          value: zod.string({ required_error: t('form.required') }).min(1, t('form.required')).min(1, t('form.required')),
          quantity: zod.number({ required_error: t('form.required') }).min(1, t('form.required')),
        }, { required_error: t('form.required') })),
    batteries: props.hardwareInformation.system_type === 'with_battery'
      ? zod.array(zod.object({
          name: zod.string({ required_error: t('form.required') }).min(1, t('form.required')),
          value: zod.string({ required_error: t('form.required') }).min(1, t('form.required')),
          quantity: zod.number({ required_error: t('form.required') }).min(1, t('form.required')),
        }))
      : zod.any().optional(),
    microinversors: inverterTypeSelect.value === 'microinverter'
      ? zod.array(zod.object({
          name: zod.string().min(1, t('form.required')),
          value: zod.number().min(1, t('form.required')),
        }), { required_error: t('form.required') })
      : zod.any().optional(),
    modules: zod.object({
      name: zod.string({ required_error: t('form.required') }).min(1, t('form.required')),
      value: zod.string({ required_error: t('form.required') }).min(1, t('form.required')),
      quantity: zod.number({ required_error: t('form.required') }).min(1, t('form.required')),
    }).optional(),
  }),
))

const { values, validate, setFieldValue, errors, setErrors, setFieldError } = useForm({
  validationSchema,
  initialValues: {
    distributor: {
      name: props?.hardwareInformation?.distributor?.slug || props?.hardwareInformation?.distributor?.name || 'Selecione',
      value: props?.hardwareInformation?.distributor?.id || 0,
    },
    installationPhase: {
      name: props?.hardwareInformation?.phase?.name || 'Selecione',
      value: props?.hardwareInformation?.phase?.id || 0,
    },
    inverterType: props?.hardwareInformation?.inverter_type || 'string',
    inverters: props?.hardwareInformation?.inverters !== null && props?.hardwareInformation?.inverters?.length > 0
      ? props.hardwareInformation.inverters.map((item) => { return { name: getHardwareListLabels(item as unknown as OptionsForm, 'inverters'), value: String(item.id), quantity: item.quantity } })
      : [],
    batteries: props?.hardwareInformation?.batteries !== null && props?.hardwareInformation?.batteries?.length > 0
      ? props.hardwareInformation.batteries.map((item) => { return { name: getHardwareListLabels(item as unknown as OptionsForm, 'batteries'), value: String(item.id), quantity: item.quantity } })
      : [],
    microinversors: [{
      name: isMicroinversor.value ? getHardwareListLabels(props.hardwareInformation?.microinverters?.[0] as unknown as OptionsForm, 'microinverters') : 'Selecione',
      value: isMicroinversor.value ? props.hardwareInformation?.microinverters?.[0]?.id : 0,
    }],
    modules: props?.hardwareInformation?.modules !== null && props?.hardwareInformation?.modules?.length > 0
      ? props.hardwareInformation.modules.map((item) => { return { name: getHardwareListLabels(item as unknown as OptionsForm, 'modules'), value: String(item.id), quantity: item.quantity } }).reduce((acc, cur) => Object.assign(acc, cur))
      : undefined,
    microinversorQuantity: props?.hardwareInformation?.microinverters && (props?.hardwareInformation?.microinverters?.length ?? 0) > 0
      ? Number(props?.hardwareInformation?.microinverters?.map((item) => { return item.quantity })?.reduce((acc, cur) => Object.assign(acc, cur)))
      : '',
  },
  validateOnMount: false,
})

const isFormValid = computed(() => {
  const errorKeys = Object.keys(errors.value)

  if (errorKeys.length === 0) {
    return true
  }

  return errorKeys.filter(key => key.match(/(^.[a-z]+)$/)).length === 0
})

watch(values, (newValue) => {
  inverterTypeSelect.value = newValue.inverterType ?? ''
})

function showErrorStringInverter(isUnderExpected: boolean) {
  if (isUnderExpected) {
    values.inverters?.forEach((_, index) => {
      setErrors({
        ...errors.value,
        [`inverters[${index}]`]: t('project_data.errors.string_overload_min'),
      })
    })

    return
  }

  values.inverters?.forEach((_, index) => {
    setErrors({
      ...errors.value,
      [`inverters[${index}]`]: t('project_data.errors.string_overload_max'),
    })
  })
}

function showErrorMicroinverter(isUnderExpected: boolean) {
  if (isUnderExpected) {
    values.microinversors?.forEach((_, index) => {
      setErrors({
        ...errors.value,
        microinversorQuantity: ' ',
        [`microinversors[${index}]`]: t('project_data.errors.microinverter_overload_min'),
      })
    })

    return
  }

  values.microinversors?.forEach((_, index) => {
    setErrors({
      ...errors.value,
      microinversorQuantity: ' ',
      [`microinversors[${index}]`]: t('project_data.errors.microinverter_overload_max'),
    })
  })
}

function showPricePerWattError() {
  setErrors({
    ...errors.value,
    'modules.value': t('project_data.errors.modules_price_per_watt'),
  })
}

async function validateHardwareForm(form: HardwareBodySave) {
  const validation = await hardwareValidation(form)

  if (!validation) {
    return { isValid: false }
  }

  if (validation.is_valid) {
    Object.keys(errors.value).forEach((key) => {
      if (key.startsWith(`inverters`)) {
        setFieldError(key as any, '')
      }
    })
    setErrors({})
  }
  else {
    if (validation.hardware?.modules?.price_per_watt?.is_valid === false) {
      showPricePerWattError()
    }

    if (validation.hardware?.inverters?.overload?.is_valid === false) {
      const { is_under_expected: _isUnderExpected } = validation.hardware.inverters.overload
      const isUnderExpected = Boolean(_isUnderExpected)

      if (values.inverterType === 'string') {
        showErrorStringInverter(isUnderExpected)
      }
      else {
        showErrorMicroinverter(isUnderExpected)
      }
    }
  }

  return { isValid: validation.is_valid }
}

function addInverter() {
  setFieldValue('inverters', [...values.inverters, { name: 'Selecione', value: '0', quantity: 0 }])
  inverterSelectionFieldCount.value = inverterSelectionFieldCount.value + 1
  track('formalizing_equipments_button_add-inverter', { trigger: 'Clique no botão de adicionar inversor' })
}

function addBattery() {
  setFieldValue('batteries', [...values.batteries, { name: 'Selecione', value: '0', quantity: 0 }])
  batterySelectionFieldCount.value = values?.batteries?.length
}

const invertersKey = ref(0)
const batteriesKey = ref(0)

function removeInverter(index: number) {
  const valueToRemove = values.inverters[index]
  const inverterToRemove = values.inverters.filter(item => item !== valueToRemove)

  setFieldValue('inverters', inverterToRemove)

  Object.keys(errors.value).forEach((key) => {
    if (key.startsWith(`inverters[${index}]`)) {
      setFieldError(key as any, '')
    }
  })

  invertersKey.value = invertersKey.value + 1
  inverterSelectionFieldCount.value = inverterSelectionFieldCount.value - 1
}

function removeBattery(index: number) {
  const valueToRemove = values.batteries[index]
  const batteryToRemove = values.batteries.filter(item => item !== valueToRemove)
  setFieldValue('batteries', batteryToRemove)

  Object.keys(errors.value).forEach((key) => {
    if (key.startsWith(`batteries[${index}]`)) {
      setFieldError(key as any, '')
    }
  })

  batteriesKey.value = batteriesKey.value + 1
  batterySelectionFieldCount.value = values?.batteries?.length
}

function resetField() {
  inverterSelectionFieldCount.value = 1
  batterySelectionFieldCount.value = 1
  if (props.hardwareInformation?.distributor !== null && props.hardwareInformation?.distributor?.id === 0)
    setFieldValue('distributor', { name: 'Selecione', value: 0 })

  if (props.hardwareInformation?.phase !== null && props.hardwareInformation.phase.id === 0)
    setFieldValue('installationPhase', { name: 'Selecione', value: 0 })

  if (values.inverterType === 'microinverter') {
    isMicroinversor.value = true
    setFieldValue('microinversors', [{ name: 'Selecione', value: 0 }])
  }
  else {
    isMicroinversor.value = false
    setFieldValue('inverters', [])
  }

  if (props.hardwareInformation.system_type === 'with_battery') {
    setFieldValue('batteries', [])
  }
}

watch(projectShoppingCartId, (newValue) => {
  if (newValue)
    projectShoppingCartId.value = newValue
})

function getHardwareFormPayload() {
  // eslint-disable-next-line style/max-statements-per-line
  const invertersPayload = isMicroinversor.value ? values.microinversors?.map((item) => { return { name: item.name, id: Number(item.value), quantity: Number.parseInt(values.microinversorQuantity) } }) : values.inverters?.map((item) => { return { name: item.name, id: Number(item.value), quantity: item.quantity } })
  // eslint-disable-next-line style/max-statements-per-line
  const batteriesPayload = props.hardwareInformation.system_type === 'with_battery' ? values.batteries?.map((item) => { return { name: item.name, id: Number(item.value), quantity: item.quantity } }) : []

  const hardwareDataForm: HardwareBodySave = {
    is_combo: props.hardwareInformation.is_combo,
    project_id: projectId.value,
    distributor_id: values?.distributor?.value !== undefined ? values?.distributor?.value : 0,
    phase_id: values?.installationPhase?.value !== undefined ? values?.installationPhase?.value : 0,
    inverters: invertersPayload,
    modules: [{ id: Number(values.modules?.value) ?? 0, quantity: Number(values.modules?.quantity) }],
    inverter_type: values.inverterType,
    batteries: batteriesPayload,
  }

  return hardwareDataForm
}

const { mutateAsync: mutateSaveHardware } = useSaveHardware(projectId)

async function saveProject() {
  const { valid } = await validate()

  if (valid) {
    try {
      loadingProjectData.value = true

      const hardwareDataForm = getHardwareFormPayload()

      await mutateSaveHardware(hardwareDataForm)
      await props.refetchForm()
      refetchDataStepsStatus()

      createSuccessToast('Equipamentos salvos com sucesso')
      track('formalizing_equipments_button_save', { trigger: 'Clique no botão de salvar na seção de equipamentos', ...hardwareDataForm })

      showButtonNext.value = true
    }
    catch (error: any) {
      loadingProjectData.value = false
      return createErrorToast(error?.data?.detail ? error.data.detail : 'Erro ao salvar os equipamentos. Tente novamente mais tarde.')
    }
    finally {
      loadingProjectData.value = false
    }
  }
  else {
    createErrorToast(t('form.alert_required_fields'))
  }
}

async function submitTechnicalData() {
  const { valid } = await validate()
  const hasError = customValidation(
    values,
    errors,
    setErrors,
    t,
    props.hardwareInformation.system_type === 'with_battery',
    inverterTypeSelect.value === 'microinverter',
  )

  if (!valid || hasError) {
    return
  }

  const hardwareDataForm = getHardwareFormPayload()

  const { isValid } = await validateHardwareForm(hardwareDataForm)

  if (isValid)
    await saveProject()
}

function disabledFields(value: boolean) {
  return disableStepsAccordingStatus.value || value
}

function handleModuleChange(value: { name?: string, value?: string, quantity?: number }) {
  setFieldValue('modules', value)

  validateModules(values, errors, setErrors, t)
}

function handleEquipmentChange(index: number, key: keyof typeof values, value: { name?: string, value?: string, quantity?: number }) {
  const equipment = [...values[key]]
  equipment[index] = value

  setFieldValue(key, equipment)

  if (key === 'inverters') {
    validateInverters(values, errors, setErrors, t)
  }

  if (props.hardwareInformation.system_type === 'with_battery' && key === 'batteries') {
    validateBatteries(values, errors, setErrors, t)
  }
}

onMounted(async () => {
  setQuantityOfInvertersFields()
  setQuantityOfBatteriesFields()
})
</script>

<template>
  <div class="form-project-data">
    <SolAlert
      v-if="project?.system_power"
      id="hardware-power-alert"
      class="mb-2xs"
      feedback="informative"
    >
      <p>{{ t('project_data.alerts.project_power') }} <strong>{{ project.system_power.toLocaleString('pt-BR') }} kWp.</strong></p>
    </SolAlert>

    <form id="hardware-form">
      <div class="grid grid-cols-1 md:system:grid-cols-2 gap-6 mt-6 mb-6 items-start">
        <SolSelect
          id="energy-concessionaire-field"
          name="distributor"
          :label="`${t('form.energy_concessionaire')}`"
          :use-field="useField"
          :options="optionsSelect.distributor"
          :searchable="true"
          :disabled="disabledFields(Boolean(props.hardwareInformation.distributor)) || hasStoreOrder"
        />
        <SolSelect
          id="installation-phase-field"
          name="installationPhase"
          :label="`${t('form.installation_phase')}`"
          :use-field="useField"
          :options="optionsSelect.phase"
          :disabled="disabledFields(Boolean(props.hardwareInformation.phase)) || hasStoreOrder"
        />

        <!-- Show modules -->
        <EquipmentSelect
          id="inverter-field-module"
          v-model="values.modules as any"
          :select-label="t('form.modules')"
          :counter-label="t('form.module_quantity')"
          :select-error="(!Number(values.modules?.value) || values.modules?.value === '0') && errors.modules || errors['modules.value']"
          :counter-error="errors['modules.quantity']"
          :options="optionsSelect.modules"
          :disabled="Boolean(disableStepsAccordingStatus || hasStoreOrder)"
          :equipments="optionsSelect.modules"
          @update:model-value="handleModuleChange($event)"
        />
      </div>

      <!-- Hardware Form -->
      <template v-if="props.hardwareInformation.system_type === 'without_battery'">
        <label
          :class="{ 'pointer-events-none opacity-60': disableStepsAccordingStatus }"
          for="inverter-type"
          class="fonts-body-medium-bold text-neutral-low-dark"
        >
          {{ t('project_data.form.inverter_type') }}
        </label>
        <div class="grid grid-cols-1 md:system:grid-cols-2 gap-6 mb-6">
          <SolRadioGroup
            id="inverter-type"
            class="mb-2"
            title=""
            name="inverterType"
            :use-field="useField"
            direction="row"
            :radios="radiosInverterType"
            :disabled="disableStepsAccordingStatus || hasStoreOrder"
            @change="resetField()"
          />
        </div>
      </template>

      <!-- Exibe os campos de acordo com o microinverter -->
      <template v-if="values.inverterType === 'microinverter'">
        <div class="grid grid-cols-1 md:system:grid-cols-4 gap-6">
          <SolInputText
            id="microinversor-quantity-field"
            name="microinversorQuantity"
            type="number"
            :use-field="useField"
            :label="`${t('form.microinversor_quantity')}`"
            :disabled="disableStepsAccordingStatus || hasStoreOrder"
          />
          <div class="md:system:col-span-3">
            <SolSelect
              v-for="(_, index) in values.microinversors"
              id="microinversor-field"
              :key="index"
              class="w-full"
              :name="`microinversors[${index}]`"
              :label="`${t('form.microinversors')}`"
              :use-field="useField"
              :options="optionsSelect.microinverters"
              :searchable="true"
              :disabled="disableStepsAccordingStatus || hasStoreOrder"
            />
          </div>
        </div>
      </template>
      <!-- Exibe os campos de acordo com o Normal (String) -->
      <template v-else>
        <div class="mb-6">
          <div class="grid grid-cols-1 gap-6 mb-2">
            <div
              v-for="(_, index) in inverterSelectionFieldCount"
              :key="`${index}-${invertersKey}`"
              class="grid grid-cols-1 md:system:grid-cols-2 gap-6 items-start"
            >
              <EquipmentSelect
                :id="`inverter-field-${index}`"
                v-model="values.inverters[index]"
                :select-label="`${t('form.inverter')}`"
                :counter-label="`${t('form.inverter_quantity')}`"
                :select-error="(!Number(values.inverters?.[index]?.value) || values.inverters[index]?.value === '0') && errors.inverters || errors[`inverters[${index}]`] || errors[`inverters[${index}].value`]"
                :counter-error="errors[`inverters[${index}].quantity`]"
                :options="optionsSelect.inverters"
                :removable="index > 0"
                :disabled="Boolean(disableStepsAccordingStatus || hasStoreOrder)"
                :equipments="optionsSelect.inverters"
                @remove="removeInverter(index)"
                @update:model-value="handleEquipmentChange(index, 'inverters', $event)"
              />
            </div>
          </div>

          <SolButton
            id="addInverter"
            size="large"
            variant="tertiary"
            :disabled="values.inverters.length === 0 || values?.inverters?.some((item) => [String(item.value), String(item.quantity)].includes('0')) || disableStepsAccordingStatus || hasStoreOrder"
            @click="addInverter()"
          >
            <template #icon:left>
              <AddIcon />
            </template>
            {{ t('form.button.add_inverter') }}
          </SolButton>
        </div>
      </template>

      <!-- Battery -->
      <template v-if="props.hardwareInformation.system_type === 'with_battery'">
        <div>
          <div class="grid grid-cols-1 gap-6 mb-2">
            <div
              v-for="(_, index) in batterySelectionFieldCount"
              :key="`${index}-${batteriesKey}`"
              class="grid grid-cols-1 md:system:grid-cols-2 gap-6 items-start"
            >
              <EquipmentSelect
                :id="`battery-field-${index}`"
                v-model="values.batteries[index]"
                :select-label="`${t('form.battery')}`"
                :counter-label="`${t('form.battery_quantity')}`"
                :options="optionsSelect.batteries"
                :removable="index > 0"
                :select-error="(!Number(values.batteries?.[index]?.value) || values.batteries[index]?.value === '0') && errors.batteries || errors[`batteries[${index}].value`]"
                :counter-error="errors[`batteries[${index}].quantity`]"
                :disabled="Boolean(disableStepsAccordingStatus || hasStoreOrder)"
                :equipments="optionsSelect.batteries"
                @remove="removeBattery(index)"
                @update:model-value="handleEquipmentChange(index, 'batteries', $event)"
              />
            </div>
          </div>

          <SolButton
            id="addBattery"
            size="large"
            variant="tertiary"
            :disabled="values?.batteries?.length === 0 || values?.batteries?.some((item) => [String(item.value), String(item.quantity)].includes('0')) || disableStepsAccordingStatus || hasStoreOrder"
            @click="addBattery()"
          >
            <template #icon:left>
              <AddIcon />
            </template>
            {{ t('form.button.add_battery') }}
          </SolButton>
        </div>
      </template>

      <div class="grid grid-cols-1 gap-6 mt-6">
        <SolDivider thickness="x-small" orientation="horizontal" />
      </div>
      <div class="grid grid-cols-1 md:system:grid-cols-4 gap-6 mt-6">
        <div class="md:system:col-start-4 flex justify-end">
          <SolButton
            id="add-equipment-button"
            size="large"
            class="w-full md:system:w-auto"
            :loading="loadingProjectData || loadingBackgroundInfo"
            :disabled="disableStepsAccordingStatus || !isFormValid || hasStoreOrder"
            @click="submitTechnicalData"
          >
            {{ t('app.save') }}
          </SolButton>
        </div>
      </div>
    </form>
  </div>
</template>

<style lang="scss" scoped>
#hardware-form {
  :deep(.sol-alert-core.-informative) {
    @apply block mb-2xs;

    @screen md:system {
      @apply flex;
    }

    .sol-button-core.-secondary {
      @apply ml-sm mt-micro min-w-max;

      @screen md:system {
        @apply ml-0 mt-0;
      }
    }
  }
}

.form-project-data{
  @apply mt-sm mb-0;
}
</style>
