<script setup lang="ts">
import type { ErrorPayload } from './Formalization.types'
import type { CustomerRegistrationInput } from '~/store-v2/registration/useUpdateRegistration'
import type {
  StageFormalization,
} from '~/ui-blocks/Formalization/Formalization.types'
import { useToast } from '@solfacil/girassol'
import { toTypedSchema } from '@vee-validate/zod'
import { useField, useForm } from 'vee-validate'
import * as zod from 'zod'
// Icons
import IconLock from '~icons/material-symbols/lock'
import CancelStep from '~/components/shared/CancelStep.vue'
import { FinancingStatus, type ModelTypes } from '~/services-v2/financial-bff/__generated__/zeus'
import useFinancing from '~/store-v2/financings/useFinancing'
import useProject from '~/store-v2/projects/useProject'
import useRegistration from '~/store-v2/registration/useRegistration'
import useUpdateRegistration from '~/store-v2/registration/useUpdateRegistration'
import { Formalization } from '~/types/enum'
import { convertToISODate } from '~/utils/string'
// blocks
import AddressSectionForm from './blocks/address/Address.form.vue'
import * as addressSchema from './blocks/address/Address.schema'
import CompanyForm from './blocks/company/Company.form.vue'
import * as companySchema from './blocks/company/Company.schema'
import DocumentationUnderAnalysisModal from './blocks/DocumentationUnderAnalysisModal.vue'
import ElectricityBillSectionForm from './blocks/electricityBill/ElectricityBill.form.vue'
import * as electricityBillSchema from './blocks/electricityBill/ElectricityBill.schema'

import PersonSectionForm from './blocks/person/Person.form.vue'
import * as personSchema from './blocks/person/Person.schema'
import { categorizeErrorsPJ, getStatusTag, showErrorsFieldsPJ } from './Formalization.utils'

// Props
const props = defineProps<{
  position: number
  stage: StageFormalization
  stageReproved: string
  triggerRefetch: () => Promise<void>
}>()

// initial base hooks
const { t } = useI18n()
const route = useRoute()
const toast = useToast()

// initial variables
const projectId = computed(() => String(route.params.id))
const blocked = ref(false)
const isValidateCpfMajorityPartner = ref(false)
const isValidateCpfLegalRepresentative = ref(true)
const docUnderAnalysisModal = ref(false)

// domain hooks

const {
  data: projectData,
} = useProject(projectId)

const {
  data: financing,
} = useFinancing(projectId)

const {
  data: registration,
} = useRegistration(projectId)

const {
  data: dataUpdateRegistration,
  mutateAsync: mutateRegistration,
  isPending: isPendingUpdateRegistration,
} = useUpdateRegistration(projectId)

// INITIAL FORMS
const companyForm = useForm({
  initialValues: companySchema.getInitialValues(),
  validationSchema: toTypedSchema(companySchema.getSchema(t)),
  validateOnMount: false,
})

const majorityPartnerDataForm = useForm({
  initialValues: personSchema.getInitialValues(),
  validationSchema: toTypedSchema(personSchema.getSchema(t, isValidateCpfMajorityPartner.value)),
  validateOnMount: false,
})

const majorityPartnerAddressForm = useForm({
  initialValues: addressSchema.getInitialValues(),
  validationSchema: toTypedSchema(addressSchema.getSchema(t)),
  validateOnMount: false,
})

const legalRepresentative1DataForm = useForm({
  initialValues: personSchema.getInitialValues(),
  validationSchema: toTypedSchema(personSchema.getSchema(t, isValidateCpfLegalRepresentative.value)),
  validateOnMount: false,
})

const legalRepresentative1AddressForm = useForm({
  initialValues: addressSchema.getInitialValues(),
  validationSchema: toTypedSchema(addressSchema.getSchema(t)),
  validateOnMount: false,
})

const legalRepresentative2DataForm = useForm({
  initialValues: personSchema.getInitialValues(),
  validationSchema: toTypedSchema(personSchema.getSchema(t, isValidateCpfLegalRepresentative.value)),
  validateOnMount: false,
})

const legalRepresentative2AddressForm = useForm({
  initialValues: addressSchema.getInitialValues(),
  validationSchema: toTypedSchema(addressSchema.getSchema(t)),
  validateOnMount: false,
})

const projectAddressForm = useForm({
  initialValues: addressSchema.getInitialValues(),
  validationSchema: toTypedSchema(addressSchema.getSchema(t)),
  validateOnMount: false,
})

const electricityBillForm = useForm({
  initialValues: electricityBillSchema.getInitialValues(),
  validationSchema: toTypedSchema(electricityBillSchema.getSchema(t)),
  validateOnMount: false,
})

const radiosForm = useForm({
  initialValues: {
    majority_partner_is_legal_representative: registration.value?.company?.majority_partner_is_legal_representative ?? true,
    is_only_one_legal_representative: registration.value?.company?.is_only_one_legal_representative ?? true,
    rural_area: false,
  },
  validationSchema: toTypedSchema(zod.object({
    majority_partner_is_legal_representative: zod.boolean().nullable().or(zod.undefined()),
    is_only_one_legal_representative: zod.boolean().nullable().or(zod.undefined()),
    rural_area: zod.boolean(),
  })),
  validateOnMount: false,
})

watch(registration, (_registration) => {
  if (!_registration)
    return

  radiosForm.setValues({
    majority_partner_is_legal_representative: _registration?.company?.majority_partner_is_legal_representative,
    is_only_one_legal_representative: _registration?.company?.is_only_one_legal_representative,
    rural_area: _registration?.project?.address?.is_rural || false,
  }, false)
}, { immediate: true })

// initial options
const majorityPartnerIsSameRepresentativeLegalOptions = [
  {
    name: 'majorityIsOnlyLegalRepresentative',
    value: true,
    label: `${t('formAddress.yesCountryside')}`,
  },
  {
    name: 'majorityIsOnlyLegalRepresentative',
    value: false,
    label: `${t('formAddress.noCountryside')}`,
  },
]

const hasOnlyOneLegalRepresentativeOptions = [
  {
    name: 'hasOnlyOneLegalRepresentative',
    value: true,
    label: `${t('formAddress.yesCountryside')}`,
  },
  {
    name: 'hasOnlyOneLegalRepresentative',
    value: false,
    label: `${t('formAddress.noCountryside')}`,
  },
]

const radiosRuralArea = [
  {
    name: 'ruralArea',
    value: false,
    label: `${t('formAddress.noCountryside')}`,
  },
  {
    name: 'ruralArea',
    value: true,
    label: `${t('formAddress.yesCountryside')}`,
  },
]

// initial computed

const representativeLegalText = computed(() => {
  return {
    firstLegalRepresentativeTitle: `
      ${t('formalization.legal_representant.documents.title', { count: radiosForm.values.is_only_one_legal_representative
        || radiosForm.values.majority_partner_is_legal_representative
        ? 1
        : 0 })}`,
    firstLegalRepresentativeAddressTitle: `
    ${t('formalization.legal_representant.address.title', { count: radiosForm.values.is_only_one_legal_representative
      || radiosForm.values.majority_partner_is_legal_representative
      ? 1
      : 0 })}`,
  }
})

const hasMoreThanOneLegalRepresentants = computed(() => {
  return radiosForm.values.is_only_one_legal_representative === false
    && radiosForm.values.majority_partner_is_legal_representative === false
})

const hasOneLegalRepresentants = computed(() => {
  return !radiosForm.values.is_only_one_legal_representative
    || !radiosForm.values.majority_partner_is_legal_representative
})

const hasAnyFormErrors = computed(() => {
  const formToValidate = [majorityPartnerDataForm, majorityPartnerAddressForm, electricityBillForm, radiosForm, projectAddressForm]

  if (!radiosForm.values.majority_partner_is_legal_representative || !radiosForm.values.is_only_one_legal_representative) {
    formToValidate.push(legalRepresentative1DataForm, legalRepresentative1AddressForm)
    formToValidate.push()
  }

  if (hasMoreThanOneLegalRepresentants.value) {
    formToValidate.push(legalRepresentative2DataForm)
    formToValidate.push(legalRepresentative2AddressForm)
  }

  return formToValidate.some(form => Object.keys(form.errors.value).length > 0)
})

const isFinancingDisabled = computed(() => {
  // TODO: Migrate this logic to BFF
  if (!financing.value) {
    return false
  }
  if ([FinancingStatus.expired].includes(financing.value.status)) {
    return true
  }
  if (financing.value.section_statuses.proposal?.customer_data === 'approved') {
    return true
  }
  return [
    FinancingStatus.approved,
    FinancingStatus.reproved,
    FinancingStatus.expired,
    FinancingStatus.canceled,
  ].includes(financing.value.status) && financing.value.step === 'proposal'
})

const isRegistrationDisabled = computed(() => {
  if (!financing.value || !registration.value) {
    return false
  }
  return ['under_analysis', 'reproved', 'approved'].includes(registration.value?.status)
})

const isSendDisabled = computed(() => {
  return isPendingUpdateRegistration.value
    || hasAnyFormErrors.value
    || isFinancingDisabled.value
    || isRegistrationDisabled.value
})

const isCancelDisabled = computed(() => {
  if (!financing.value || !registration.value) {
    return false
  }

  return isPendingUpdateRegistration.value
    || isFinancingDisabled.value
    || registration.value?.status !== 'under_analysis'
})

const isFieldsDisabled = computed(() => {
  return isFinancingDisabled.value || isRegistrationDisabled.value
})

// functions get and saves

async function validateAllForms(): Promise<boolean> {
  const forms = [
    companyForm,
    majorityPartnerDataForm,
    majorityPartnerAddressForm,
    projectAddressForm,
    electricityBillForm,
    radiosForm,
  ]

  if (!radiosForm.values.rural_area) {
    forms.push(projectAddressForm)
  }

  if (hasOneLegalRepresentants.value) {
    forms.push(legalRepresentative1DataForm, legalRepresentative1AddressForm)
  }

  if (hasMoreThanOneLegalRepresentants.value) {
    forms.push(legalRepresentative2DataForm, legalRepresentative2AddressForm)
  }

  const results = await Promise.all(forms.map(form => form.validate()))
  const isValid = results.every(result => result.valid)

  return isValid
}

async function isValidForm() {
  const isValid = await validateAllForms()
  if (!isValid) {
    toast.createErrorToast({
      title: t('toast.incomplete.documentation.title'),
      description: t('toast.incomplete.documentation.description'),
      actionText: '',
    })
  }
  return isValid
}

// TODO: MIGRATE THIS LOGIC TO BFF
function createRepresentativeLegalsPayload() {
  type LegalRepresentativeInput = ModelTypes['CustomerRegistrationCompany']['legal_representatives']
  const legalRepresentatives: LegalRepresentativeInput = []

  const majorityData = {
    cpf: majorityPartnerDataForm.values?.cpf || '',
    full_name: majorityPartnerDataForm.values.full_name || '',
    birthdate: convertToISODate(majorityPartnerDataForm.values.birthdate),
    contact: {
      email: majorityPartnerDataForm.values.email || '',
      phone: majorityPartnerDataForm.values.phone || '',
    },
    documents: personSchema.extractDocumentEntries(majorityPartnerDataForm),
    address: {
      zipcode: majorityPartnerAddressForm.values.zipcode || '',
      street: majorityPartnerAddressForm.values.street || '',
      street_number: majorityPartnerAddressForm.values.street_number || '',
      complement: majorityPartnerAddressForm.values.complement || '',
      neighborhood: majorityPartnerAddressForm.values.neighborhood || '',
      city_id: majorityPartnerAddressForm.values.city_id || 0,
      state_id: majorityPartnerAddressForm.values.state_id || 0,
    },
  }
  const legal1Data = {
    kind: 'legal_representative_1',
    cpf: legalRepresentative1DataForm.values?.cpf || '',
    full_name: legalRepresentative1DataForm.values.full_name || '',
    birthdate: convertToISODate(legalRepresentative1DataForm.values.birthdate),
    contact: {
      email: legalRepresentative1DataForm.values.email || '',
      phone: legalRepresentative1DataForm.values.phone || '',
    },
    documents: personSchema.extractDocumentEntries(legalRepresentative1DataForm),
    address: {
      zipcode: legalRepresentative1AddressForm.values.zipcode || '',
      street: legalRepresentative1AddressForm.values.street || '',
      street_number: legalRepresentative1AddressForm.values.street_number || '',
      complement: legalRepresentative1AddressForm.values.complement || '',
      neighborhood: legalRepresentative1AddressForm.values.neighborhood || '',
      city_id: legalRepresentative1AddressForm.values.city_id || 0,
      state_id: legalRepresentative1AddressForm.values.state_id || 0,
      is_rural: false,
    },
  }
  const legal2Data = {
    kind: 'legal_representative_2',
    cpf: legalRepresentative2DataForm.values.cpf || '',
    full_name: legalRepresentative2DataForm.values.full_name || '',
    birthdate: convertToISODate(legalRepresentative2DataForm.values.birthdate),
    contact: {
      email: legalRepresentative2DataForm.values.email || '',
      phone: legalRepresentative2DataForm.values.phone || '',
    },
    documents: personSchema.extractDocumentEntries(legalRepresentative2DataForm),
    address: {
      zipcode: legalRepresentative1AddressForm.values.zipcode || '',
      street: legalRepresentative1AddressForm.values.street || '',
      street_number: legalRepresentative1AddressForm.values.street_number || '',
      complement: legalRepresentative1AddressForm.values.complement || '',
      neighborhood: legalRepresentative1AddressForm.values.neighborhood || '',
      city_id: legalRepresentative1AddressForm.values.city_id || 0,
      state_id: legalRepresentative1AddressForm.values.state_id || 0,
      is_rural: false,
    },
  }

  if (radiosForm.values.majority_partner_is_legal_representative) {
    if (radiosForm.values.is_only_one_legal_representative) {
      legalRepresentatives.push({ ...majorityData, kind: 'legal_representative_1' })
    }
    else {
      legalRepresentatives.push({ ...majorityData, kind: 'legal_representative_1' })
      legalRepresentatives.push({ ...legal1Data, kind: 'legal_representative_2' })
    }
    return legalRepresentatives
  }

  if (!radiosForm.values.majority_partner_is_legal_representative) {
    if (radiosForm.values.is_only_one_legal_representative) {
      legalRepresentatives.push({ ...legal1Data, kind: 'legal_representative_1' })
    }
    else {
      legalRepresentatives.push({ ...legal1Data, kind: 'legal_representative_1' })
      legalRepresentatives.push({ ...legal2Data, kind: 'legal_representative_2' })
    }
    return legalRepresentatives
  }
}

async function createPayloadRegistrationCompany() {
  const majorityPartner = {
    full_name: majorityPartnerDataForm.values.full_name,
    birthdate: convertToISODate(majorityPartnerDataForm.values.birthdate),
    contact: {
      email: majorityPartnerDataForm.values.email,
      phone: majorityPartnerDataForm.values.phone,
    },
    documents: personSchema.extractDocumentEntries(majorityPartnerDataForm),
    address: {
      zipcode: majorityPartnerAddressForm.values.zipcode,
      street: majorityPartnerAddressForm.values.street,
      street_number: majorityPartnerAddressForm.values.street_number,
      complement: majorityPartnerAddressForm.values.complement || '',
      neighborhood: majorityPartnerAddressForm.values.neighborhood,
      city_id: majorityPartnerAddressForm.values.city_id,
      state_id: majorityPartnerAddressForm.values.state_id,
    },
  }

  const installationAddress = {
    zipcode: projectAddressForm.values.zipcode,
    street: projectAddressForm.values.street,
    street_number: projectAddressForm.values.street_number,
    complement: projectAddressForm.values.complement || '',
    neighborhood: projectAddressForm.values.neighborhood,
    city_id: projectAddressForm.values.city_id,
    state_id: projectAddressForm.values.state_id,
    is_rural: radiosForm.values.rural_area,
  }

  const project = {
    address: installationAddress,
    documents: electricityBillSchema.extractDocumentEntries(electricityBillForm),
    comment: electricityBillForm.values.comment,
    electricity_bill: {
      is_under_customer_name: electricityBillForm.values.is_under_customer_name,
      holder_cpf: electricityBillForm.values.holder_cpf,
    },
  }

  const company = {
    company_name: companyForm.values.company_name,
    contact: {
      email: companyForm.values.email,
      phone: companyForm.values.phone,
    },
    documents: companySchema.extractDocumentEntries(companyForm),
    majority_partner: majorityPartner,
    legal_representatives: createRepresentativeLegalsPayload(),
  }
  return {
    project_id: projectId.value,
    person_type: String(projectData.value?.person_type).toLowerCase(),
    customer: null,
    project,
    company,
    guarantor: null,
  } as unknown as CustomerRegistrationInput
}

async function handleSave() {
  const isValidRegistrationCompany = await isValidForm()

  if (!isValidRegistrationCompany) {
    return
  }

  const updateCompanyPayload = await createPayloadRegistrationCompany()

  try {
    const response = await mutateRegistration(updateCompanyPayload)
    const success = response?.errors?.length === 0

    if (success) {
      docUnderAnalysisModal.value = true
    }
    else {
      toast.createErrorToast({
        title: t('toast.error.send.title'),
        description: t('toast.error.send.description'),
      })

      const dataErrors = categorizeErrorsPJ(dataUpdateRegistration.value?.errors as ErrorPayload[] || [])

      showErrorsFieldsPJ(
        dataErrors,
        companyForm,
        majorityPartnerDataForm,
        majorityPartnerAddressForm,
        legalRepresentative1DataForm,
        legalRepresentative1AddressForm,
        legalRepresentative2DataForm,
        legalRepresentative2AddressForm,
        electricityBillForm,
        projectAddressForm,
      )
    }
  }
  catch {
    docUnderAnalysisModal.value = false
    toast.createErrorToast({
      title: t('toast.error.send.title'),
      description: t('toast.error.send.description'),
    })
  }
}

function checkStageReproved() {
  if (props.stageReproved === '')
    blocked.value = false
  else
    blocked.value = props.stageReproved !== Formalization.CUSTOMER_DATA
}

onMounted(() => {
  checkStageReproved()
})
</script>

<template>
  <CustomerElementAccordion
    :id="`accordion_${Formalization.CUSTOMER_DATA}`"
    :title="t('customer.client_data')"
    :position="String(position)"
    :tag="getStatusTag(t, financing?.section_statuses.proposal?.customer_data)"
    :blocked="blocked"
    :open-collapse="!blocked && stage === Formalization.CUSTOMER_DATA"
    state="done"
    class="-mt-6"
  >
    <template #icon>
      <IconLock v-if="blocked" />
    </template>

    <div>
      <p class="mt-2">
        {{ t('formalization.client.title') }}
      </p>
    </div>

    <div class="mt-sm !mb-0">
      <form class="flex flex-col gap-10">
        <CompanyForm
          :form="companyForm"
          :disable-cnpj="true"
          :disabled="isFieldsDisabled"
        />

        <SolDivider thickness="x-small" />

        <PersonSectionForm
          :form="majorityPartnerDataForm"
          :title="`${t('company.majority_section')}`"
          :disable-cpf="true"
          :disabled="isFieldsDisabled"
          kind="majority_partner"
        />

        <div class="flex flex-col gap-6">
          <!--  -->
          <div>
            <span class="font-bold text-3xs text-brand-primary-pure">
              {{ t('company.majority_is_the_same_of_the_legal') }}
            </span>
            <SolRadioGroup
              id="majority_partner_is_legal_representative"
              title=""
              name="majority_partner_is_legal_representative"
              :use-field="useField"
              direction="row"
              :radios="majorityPartnerIsSameRepresentativeLegalOptions"
              :disabled="isFieldsDisabled"
            />
          </div>

          <div>
            <span class="font-bold text-3xs text-brand-primary-pure">
              {{ t('company.has_more_than_one_legal_representative') }}
            </span>
            <SolRadioGroup
              id="is_only_one_legal_representative"
              title=""
              name="is_only_one_legal_representative"
              :use-field="useField"
              direction="row"
              :radios="hasOnlyOneLegalRepresentativeOptions"
              :disabled="isFieldsDisabled"
            />
          </div>
        </div>

        <AddressSectionForm
          :form="majorityPartnerAddressForm"
          :title="`${t('formalization.majority.address')}`"
          kind="majority_partner"
          :disabled="isFieldsDisabled"
        />

        <SolDivider thickness="x-small" />

        <div v-show="hasOneLegalRepresentants" class="flex flex-col gap-6">
          <PersonSectionForm
            :form="legalRepresentative1DataForm"
            :title="representativeLegalText.firstLegalRepresentativeTitle"
            kind="legal_representative_1"
            :disabled="isFieldsDisabled"
          />

          <AddressSectionForm
            :form="legalRepresentative1AddressForm"
            :title="representativeLegalText.firstLegalRepresentativeAddressTitle"
            kind="legal_representative_1"
            :disabled="isFieldsDisabled"
          />
          <SolDivider thickness="x-small" />
        </div>

        <div v-show="hasMoreThanOneLegalRepresentants" class="flex flex-col gap-6">
          <PersonSectionForm
            :form="legalRepresentative2DataForm"
            :title="t('formalization.legal_representant.documents.secundary')"
            kind="legal_representative_2"
            :disabled="isFieldsDisabled"
          />

          <AddressSectionForm
            :form="legalRepresentative2AddressForm"
            :title="t('formalization.legal_representant.address.secundary')"
            kind="legal_representative_2"
            :disabled="isFieldsDisabled"
          />
          <SolDivider thickness="x-small" />
        </div>

        <ElectricityBillSectionForm
          :form="electricityBillForm"
          :disabled="isFieldsDisabled"
        />

        <SolDivider thickness="x-small" />

        <AddressSectionForm
          :form="projectAddressForm"
          title="Endereço de instalação"
          kind="project"
          :disabled="isFieldsDisabled"
        />

        <div>
          <span class="font-bold text-3xs text-brand-primary-pure">{{ t('formAddress.countryside') }}</span>
          <SolRadioGroup
            id="rural_area"
            title=""
            name="rural_area"
            :use-field="useField"
            direction="row"
            :disabled="isFieldsDisabled"
            :radios="radiosRuralArea"
          />
        </div>

        <div class="flex justify-end border-t border-neutral-high-medium pt-6 mt-8 gap-4 flex-col md:system:flex-row">
          <CancelStep
            step-to-cancel="registration"
            :disabled="isCancelDisabled"
            :project-id="projectId"
          />

          <SolButton
            id="customer_confirm"
            class="w-full md:system:w-auto order-1 md:system:order-2"
            :loading="isPendingUpdateRegistration"
            size="large"
            :disabled="isSendDisabled"
            @click="handleSave"
          >
            {{ t('app.send') }}
          </SolButton>
        </div>
      </form>
    </div>

    <DocumentationUnderAnalysisModal
      v-if="docUnderAnalysisModal"
      @close="() => { docUnderAnalysisModal = false }"
    />
  </CustomerElementAccordion>
</template>

<style lang="scss" scoped>
.error-message {
  @apply text-feedback-negative-pure py-nano fonts-subtitle-small;
}

:deep(#input-text-input-phone) {
  @apply pl-xl;
}

[data-tooltip].tooltip:after {
  width: 250px;
  white-space: break-spaces;
}
</style>
