export enum GeneralStatuses {
  IN_PROGRESS = 'in_progress',
  UNDER_ANALYSIS = 'under_analysis',
  APPROVED = 'approved',
  TO_REVIEW = 'to_review',
  WAITING_CUSTOMER = 'waiting_customer',
  REPROVED = 'reproved',
  EXPIRED = 'expired',
  CANCELED = 'canceled',
}

type ProposalSteps = 'biometry_and_contract' | 'equipment' | 'customer_data' | 'payment_model'

export type ProposalStatus = Record<ProposalSteps, GeneralStatuses>
export type FormalizationStatus = Record<'contract' | 'hardware' | 'registration' | 'documentation' | 'receipt_model', GeneralStatuses>

export interface FinancingFlowResponse {
  id: number
  document: string
  section_statuses: {
    formalization: FormalizationStatus
    proposal: ProposalStatus
  }
  step: string
  status: string
  points?: number | string
  person_type: 'PF' | 'PJ'
}

export interface FinancingList {
  data: FinancingListItem[]
  total: number
  total_by_status: TotalByStatus
  total_by_step: TotalByStep
}

export interface TotalByStatus {
  approved: number
  expired: number
  in_progress: number
  waiting_customer: number
  reproved: number
  under_analysis: number
  to_review: number
  canceled: number
}

export interface TotalByStep {
  concluded: number
  expired: number
  formalization: number
  installation: number
}

type FinancingItemSteps = 'simulation' | 'proposal' | 'installation'

export interface FinancingListItem {
  id: number
  project_id: string
  financed_value: number
  customer_name: string
  document?: string
  expires_at: string | null
  created_at: string
  formalized_at: string | null
  installed_at: string | null
  status: GeneralStatuses
  step: FinancingItemSteps
  is_combo: boolean
  payments: FinancingPayment[]
  percentage_paid?: number
  flow_provider: string
  person_type: string
  all_payments_sent: boolean
  section_statuses?: {
    concluded: string
    simulation: string
    installation: string
    formalization: {
      contract: string
      hardware: string
      registration: string
      documentation: string
      receipt_model: string
    }
    proposal: ProposalStatus
  }
  updated_at?: string | null
  is_expired?: boolean
  system_power: number
  reason: string
}

export interface FinancingPayment {
  id: number
  financing_id: number
  value: number
  created_at: string
}

interface Filters {
  id: string
  class: string
  placeholder: string
  size: string
  name: string
  label: string
  options: { name: string, value: string }[]
}

export interface OptionsFilters {
  title: string
  filters: Filters[]
}

export interface FinancingListParams {
  page: number
  page_size: number
  order_by: string
  query?: string
  steps?: string | Array<string>
  status?: string
}

export interface FilterField {
  name: string
  value: string
}

export interface SelectedFilters {
  statusField?: FilterField | string
  stepsField?: FilterField | string
}
