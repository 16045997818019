import type { InputPaymentModelSendBillingLetter } from '~/services-v2/payment-model-api/payment-model-api.types'
import { useMutation } from '@tanstack/vue-query'
import { queryKeys } from '~/queryClient'
import { postPaymentModelSendBillingLetter } from '~/services-v2/payment-model-api/payment-model-api'

export default function usePaymentModelSendBillingLetter() {
  const mutationFn = async (inputs: InputPaymentModelSendBillingLetter) => {
    const response = await postPaymentModelSendBillingLetter(inputs)
    return response
  }

  return useMutation({
    mutationFn,
    mutationKey: queryKeys.PAYMENT_MODEL.SEND_BILLING_LETTER,

    retry: 2,
    retryDelay: 1000,
  })
}
