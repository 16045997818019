import { useQuery } from '@tanstack/vue-query'
import { queryKeys } from '~/queryClient'
import financialBff from '~/services-v2/financial-bff/financial-bff'

export default function useProjectProposalChanges(projectId: Ref<string>) {
  const queryKey = queryKeys.PROJECT.PROPOSAL_CHANGES(projectId.value)

  const queryFn = async () => {
    const res = await financialBff('query')({
      project_proposal_changes: [{
        project_id: projectId.value,
      }, {
        value_changed: true,
      }],
    })
    return res.project_proposal_changes
  }

  return useQuery({
    queryFn,
    queryKey,
  })
}
