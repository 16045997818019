import type { UseRegistrationData } from '~/store-v2/registration/useRegistration'
import z from 'zod'
import * as validations from '~/utils/validations'

export function getSchema(t: any, registration: UseRegistrationData | undefined) {
  if (registration?.person_type === 'pf') {
    const hasGuarantor = !!registration?.guarantor?.cpf
    return z.object({
      email: z
        .string({ message: t('customer.biometry.modal_sending.form.required') })
        .email(t('customer.biometry.modal_sending.form.email_error')),
      phone: z
        .string({ message: t('customer.biometry.modal_sending.form.phone_error') })
        .refine(validations.phoneBrValidation, t('customer.biometry.modal_sending.form.phone_error')),
      emailGuarantor: hasGuarantor
        ? z
            .string({ message: t('customer.biometry.modal_sending.form.required') })
            .email(t('customer.biometry.modal_sending.form.email_error'))
        : z.string().optional(),
      phoneGuarantor: hasGuarantor
        ? z
            .string({ message: t('customer.biometry.modal_sending.form.phone_error') })
            .refine(validations.phoneBrValidation, t('customer.biometry.modal_sending.form.phone_error'))
        : z.string().optional(),
    })
  }
  else {
    const majorityPartnerIsLegalRepresentative = registration?.company?.majority_partner_is_legal_representative
    const legals = registration?.company?.legal_representatives
    const hasFirstLegalRepresentative = legals && !!legals[0]?.cpf && !majorityPartnerIsLegalRepresentative
    const hasSecondLegalRepresentative = legals && !!legals[1]?.cpf

    return z.object({
      majority_partner_person_form_email: z
        .string({ required_error: t('company.biometry.modal_sending.form.required') })
        .email({ message: t('company.biometry.modal_sending.form.email_error') }),
      majority_partner_person_form_phone: z
        .string({ required_error: t('company.biometry.modal_sending.form.phone_error') })
        .refine(validations.phoneBrValidation, { message: t('company.biometry.modal_sending.form.phone_error') }),
      first_legal_representant_email: hasFirstLegalRepresentative
        ? z
            .string({ required_error: t('company.biometry.modal_sending.form.required') })
            .email({ message: t('company.biometry.modal_sending.form.email_error') })
        : z.string().optional(),
      first_legal_representant_phone: hasFirstLegalRepresentative
        ? z
            .string({ required_error: t('company.biometry.modal_sending.form.phone_error') })
            .refine(validations.phoneBrValidation, { message: t('company.biometry.modal_sending.form.phone_error') })
        : z.string().optional(),
      second_legal_representant_email: hasSecondLegalRepresentative
        ? z
            .string({ required_error: t('company.biometry.modal_sending.form.required') })
            .email({ message: t('company.biometry.modal_sending.form.email_error') })
        : z.string().optional(),
      second_legal_representant_phone: hasSecondLegalRepresentative
        ? z
            .string({ required_error: t('company.biometry.modal_sending.form.phone_error') })
            .refine(validations.phoneBrValidation, { message: t('company.biometry.modal_sending.form.phone_error') })
        : z.string().optional(),
    })
  }
}
