<script setup lang="ts">
import type { User } from '~/utils/auth/User'
import { useMoney } from '~/composables/money'
import SimulatorService from '~/services/simulator/Simulator'

interface SelectOptions { name: string, value: number }

const props = defineProps<{
  selectedGracePeriod: SelectOptions
  selectedCommission: SelectOptions
  disableFields: boolean
  loadingDetails: boolean
  maxGracePeriod: number
  projectId: string
  downPayment?: number
  financingValue?: number
  selectGracePeriod: (...args: any) => void
  selectCommission: (...args: any) => void
}>()
const simulatorService = new SimulatorService()
const financingValue = ref(props.financingValue)
const downPayment = ref(props.downPayment)
const { getSelectedInstallment } = useResumeStore()
const commissionOptions: any = ref({})
const { formatMoney } = useMoney()
const user: User = useAppStorage().get('user')

onMounted(async () => {
  const shouldShowCommissionWithMoney = await commissionMoney()
  commissionOptions.value = await mountCommissionList(shouldShowCommissionWithMoney)
})

async function commissionMoney(): Promise<boolean> {
  return await useFlag('show-commission-money', { partner_id: user?.parceiro?.id })
}

function mountGracePeriodList() {
  const grace_period: number[] = []
  let counter = 1

  const grace_in_months = props.maxGracePeriod / 30
  while (counter <= grace_in_months) {
    grace_period.push(counter)
    counter++
  }

  return grace_period.map((grace_period) => {
    return {
      name: `${grace_period} Meses`,
      value: grace_period,
    }
  })
}

async function mountCommissionList(shouldShowCommissionWithMoney: boolean) {
  if (shouldShowCommissionWithMoney) {
    const commissionValues = await simulatorService.get_commissions(props.projectId)
    if (commissionValues.data) {
      return commissionValues.data.map((el) => {
        return {
          name: `${el.commission} % = ${formatMoney(el.commission_raw)} `,
          value: el.commission,
        }
      })
    }
  }

  const defaultCommissions = [0, 1, 2, 3, 4, 5]

  return defaultCommissions.map((el) => {
    return {
      name: `${el} % `,
      value: el,
    }
  })
}

const gracePeriodOptions = mountGracePeriodList()

const { t } = useI18n()
const route = useRoute()
const isEditPage = 'edit' in route.query
</script>

<template>
  <div class="col-span-5">
    <h2 class="card-subtitle">
      {{ t('simulation.financing_options') }}
    </h2>
    <div
      :class="`${!disableFields ? 'disabled' : ''} grid grid-cols-2 gap-4 mt-2xs`"
    >
      <SolSelect
        v-if="gracePeriodOptions?.length"
        id="select-grace-period"
        :selected="selectedGracePeriod"
        label="Carência*"
        class="w-full"
        name="grace-period"
        size="medium"
        :disabled="!disableFields || loadingDetails"
        placeholder="Busque aqui"
        :options="gracePeriodOptions"
        @update:selected="selectGracePeriod"
      />
      <SolSelect
        v-if="commissionOptions?.length"
        id="select-commission"
        label="Sua Comissão*"
        :selected="getSelectedInstallment()?.is_from_suggested ? { name: `${getSelectedInstallment()?.commission || 0} %`, value: getSelectedInstallment()?.commission || 0 } : selectedCommission"
        class="w-full"
        size="medium" name="commission"
        placeholder="Busque aqui"
        :options="commissionOptions"
        :disabled="!disableFields || loadingDetails || getSelectedInstallment()?.is_from_suggested"
        @update:selected="selectCommission"
      />
    </div>

    <div
      v-if="isEditPage"
      class="edit-fields grid grid-cols-2 gap-4 mt-2xs"
    >
      <InputMoneyInput
        id="edit-financing-value"
        v-model="financingValue"
        size="medium"
        required
        name="financingValue"
        label="Valor do projeto"
        placeholder="Digite o valor do projeto*"
        :disabled="true"
      />
      <InputMoneyInput
        id="edit-down-payment"
        v-model="downPayment"
        size="medium"
        name="downPayment"
        label="Entrada"
        placeholder="Digite o valor da entrada*"
        :disabled="true"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.btn-selectable-left{
  @apply border border-brand-primary-light rounded-l-md;
  @apply p-nano cursor-pointer font-bold;
}

.btn-selectable-right{
  @apply border border-brand-primary-light rounded-r-md;
  @apply p-nano cursor-pointer font-bold;
}
.btn-selected{
  @apply bg-brand-secondary-pure border-brand-secondary-pure;

  .title {
    @apply text-neutral-high-pure;
  }

}
.buttons {
  @apply flex;
}

.disabled {
  @apply opacity-75;
}

:deep(.edit-fields) {
  label {
    color: #757575 !important;
    opacity: 75%;
  }
}
</style>
