import { useQuery } from '@tanstack/vue-query'
import { queryKeys } from '~/queryClient'
import financialBff from '~/services-v2/financial-bff/financial-bff'

export default function useRegistration(projectId: Ref<string>) {
  const queryKey = ref(queryKeys.REGISTRATION.DATA(projectId.value))
  const queryFn = async () => {
    const res = await financialBff('query')({
      registration: [{
        project_id: projectId.value,
      }, {
        project_id: true,
        status: true,
        person_type: true,
        remaining_changes_after_confirmation: true,
        customer: {
          cpf: true,
          document_type: true,
          full_name: true,
          birthdate: true,
          documents: {
            id: true,
            filename: true,
            status: true,
            type: true,
            url_download: true,
            url_preview: true,
            mime_type: true,
            docstorage_id: true,
          },
          contact: {
            email: true,
            phone: true,
          },
          address: {
            zipcode: true,
            street: true,
            neighborhood: true,
            complement: true,
            street_number: true,
            city: {
              name: true,
              id: true,
            },
            state: {
              name: true,
              id: true,
            },
          },
        },
        project: {
          address: {
            zipcode: true,
            street: true,
            neighborhood: true,
            complement: true,
            street_number: true,
            is_rural: true,
            city: {
              name: true,
              id: true,
            },
            state: {
              name: true,
              id: true,
            },
          },
          documents: {
            id: true,
            filename: true,
            status: true,
            type: true,
            url_download: true,
            url_preview: true,
            mime_type: true,
            docstorage_id: true,
          },
          electricity_bill: {
            is_under_customer_name: true,
            holder_cpf: true,
          },
          residencial_is_the_same_of_plant: true,
          comment: true,
        },
        company: {
          company_name: true,
          cnpj: true,
          is_only_one_legal_representative: true,
          majority_partner_is_legal_representative: true,
          contact: {
            phone: true,
            email: true,
          },
          legal_representatives: {
            cpf: true,
            document_type: true,
            full_name: true,
            birthdate: true,
            kind: true,
            contact: {
              phone: true,
              email: true,
            },
            documents: {
              id: true,
              filename: true,
              status: true,
              type: true,
              url_download: true,
              url_preview: true,
              mime_type: true,
              docstorage_id: true,
            },
            address: {
              zipcode: true,
              street: true,
              neighborhood: true,
              complement: true,
              street_number: true,
              city: {
                name: true,
                id: true,
              },
              state: {
                name: true,
                id: true,
              },
            },
          },
          majority_partner: {
            cpf: true,
            document_type: true,
            full_name: true,
            birthdate: true,
            contact: {
              phone: true,
              email: true,
            },
            documents: {
              id: true,
              filename: true,
              status: true,
              type: true,
              url_download: true,
              url_preview: true,
              mime_type: true,
              docstorage_id: true,
            },
            address: {
              zipcode: true,
              street: true,
              neighborhood: true,
              complement: true,
              street_number: true,
              city: {
                name: true,
                id: true,
              },
              state: {
                name: true,
                id: true,
              },
            },
          },
          documents: {
            id: true,
            filename: true,
            status: true,
            type: true,
            url_download: true,
            url_preview: true,
            mime_type: true,
            docstorage_id: true,
          },
        },
        errors: {
          field: true,
          title: true,
          type: true,
          description: true,
        },
        guarantor: {
          cpf: true,
          document_type: true,
          full_name: true,
          birthdate: true,
          documents: {
            id: true,
            filename: true,
            status: true,
            type: true,
            url_download: true,
            url_preview: true,
            mime_type: true,
            docstorage_id: true,
          },
          contact: {
            email: true,
            phone: true,
          },
          address: {
            zipcode: true,
            street: true,
            neighborhood: true,
            complement: true,
            street_number: true,
            city: {
              name: true,
              id: true,
            },
            state: {
              name: true,
              id: true,
            },
          },
        },
      }],
    })

    return res.registration!
  }

  return useQuery({
    queryFn,
    queryKey,
    staleTime: 1000 * 60 * 1, // 1 minutes
  })
}

export type UseRegistrationData = ReturnType<typeof useRegistration>['data']['value']

export type UseRegistrationError = ReturnType<typeof useRegistration>['error']['value']

export type UseRegistrationParams = Parameters<typeof useRegistration>
