import type { InstallationService } from './installation-api.types'
import type { ResponseModel } from '~/utils/connections/rest/RestConnections'
import type { HardwareBodySave, HardwareCombos, HardwareMirrorUpload, ResponseHardwareSave, ResponseInstallation } from '~/utils/installation/Installation'
import http from './installation-api.http'

export async function getHardwareForm(projectId: string) {
  const response = await http.get<InstallationService.HardwareFormResponse>(`/hardware/form/${projectId}`)
  return response.data
}

export async function saveHardware(hardwareBodySave: HardwareBodySave) {
  const response = await http.post<ResponseModel<ResponseHardwareSave>>('/hardware/save', hardwareBodySave)
  return response.data
}

export async function getCombosHardware(projectId: string, systemType: string | null) {
  const response = await http.get<ResponseModel<HardwareCombos>>(`/hardware/combos?microinverters=true&inverters=true&modules=true&phase=true&project_id=${projectId}&batteries=${systemType === 'with_battery'}&system_type=${systemType}`)
  return response.data.data
}

export async function uploadInstallationHardware(id: string, files: FormData, comment: string) {
  const response = await http.post<ResponseInstallation>(`/installations/upload?id=${id}&comment=${comment}`, files)

  return response.data
  // return ''
}

export async function saveMirror({ projectId, mirror }: { projectId: string, mirror: File }) {
  const formData = new FormData()
  formData.append('mirror', mirror)

  const response = await http.post<HardwareMirrorUpload>(`/v1/mirror/upload/${projectId}`, formData)

  return response.data
}
