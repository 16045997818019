<script setup lang="ts">
import IconPlus from '~icons/material-symbols/add'
import IconMinus from '~icons/material-symbols/remove'

const props = withDefaults(defineProps<{
  modelValue: number
  min: number
  max: number
  error: string | null
  label: string | null
  disabled: boolean
  class: string
}>(), {
  max: Infinity,
  min: -Infinity,
  modelValue: 0,
  error: null,
  label: null,
  disabled: false,
  class: '',
})

const emit = defineEmits<{
  (e: 'update:modelValue', value: number): void
}>()

const errorClass = computed(() => props.error ? 'border-feedback-negative-pure' : 'border-neutral-high-medium')

function increase() {
  emit('update:modelValue', props.modelValue + 1)
}

function decrease() {
  emit('update:modelValue', props.modelValue - 1)
}

function onChange(target: HTMLInputElement) {
  const { min, max } = props
  const value = Number.parseInt(target.value, 10)

  if (!value) {
    emit('update:modelValue', 0)
    target.value = '0'

    return
  }

  if (value < props.min) {
    emit('update:modelValue', min)
    target.value = min.toString()

    return
  }

  if (value > props.max) {
    emit('update:modelValue', max)
    target.value = max.toString()

    return
  }

  emit('update:modelValue', value)
}
</script>

<template>
  <div :class="props.class">
    <label v-if="label" class="block fonts-body-medium-bold mb-1" :class="disabled ? 'text-neutral-low-light' : 'text-neutral-low-dark'" for="counter">
      {{ label }}
    </label>
    <div class="w-fit-content h-12 border border-neutral-high-medium rounded-md flex items-center" :class="[errorClass, disabled && 'bg-neutral-high-light']">
      <button
        class="p-micro fonts-body-x-large-regular"
        :class="modelValue <= min || props.disabled ? 'text-neutral-low-light' : 'text-neutral-low-dark'"
        type="button"
        :disabled="modelValue <= min || disabled"
        @click="decrease"
      >
        <IconMinus />
      </button>
      <input
        id="counter"
        class="input-text w-12 outline-none"
        :class="modelValue <= min || modelValue >= max || props.disabled ? 'text-neutral-low-light' : 'text-neutral-low-dark'"
        type="number"
        :value="modelValue"
        :min="min"
        :max="max"
        :disabled="props.disabled"
        @change="(event) => onChange(event.target as HTMLInputElement)"
      >
      <button
        class="p-micro fonts-body-x-large-regular"
        :class="modelValue >= max || props.disabled ? 'text-neutral-low-light' : 'text-neutral-low-dark'"
        type="button"
        :disabled="modelValue >= max || props.disabled"
        @click="increase"
      >
        <IconPlus />
      </button>
    </div>
    <span v-if="error" class="text-feedback-negative-pure fonts-body-small-regular">
      {{ error }}
    </span>
  </div>
</template>

<style lang="scss" scoped>
.input-text {
  text-align: center;

  /* Chrome, Safari, Edge, Opera */
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  -moz-appearance: textfield;
  appearance: textfield;
}
</style>
