<script setup lang="ts">
import type { Field, Form, FormSchema } from './Address.schema'
import { SolInputText } from '@solfacil/girassol'
import { isEqual } from 'lodash'
import IconInfo from '~icons/material-symbols/info-outline'
import useCep from '~/store-v2/address/useCep'
import useRegistration from '~/store-v2/registration/useRegistration'
import { maskCep, onlyNumbers } from '~/utils/string'
import { extractErrorsByField } from './Address.schema'

// Props
const props = defineProps<{
  form: Form
  kind: 'customer' | 'majority_partner' | 'legal_representative_1' | 'legal_representative_2' | 'project' | 'guarantor'
  title?: string
  disabled?: boolean
}>()

// initial base hooks
const { t } = useI18n()
const route = useRoute()

// initial variables and computed
const projectId = computed(() => route.params.id as string)

// fields
const [zipCodeModel, zipCodeProps] = props.form.defineField('zipcode')
const [streetModel, streetProps] = props.form.defineField('street')
const [streetNumberModel, streetNumberProps] = props.form.defineField('street_number')
const [complementModel, complementProps] = props.form.defineField('complement')
const [neighborhoodModel, neighborhoodProps] = props.form.defineField('neighborhood')
const [cityModel, cityProps] = props.form.defineField('city')
const [stateModel, stateProps] = props.form.defineField('state')

// domain hooks

const {
  data: registration,
} = useRegistration(projectId)

const {
  data: cepData,
  isLoading: isLoadingCep,
  isSuccess: isSuccessCep,
  isError: isErrorCep,
  isFetching: isFetchingCep,
} = useCep(zipCodeModel)

// computeds
const isFieldsDisabled = computed(() => props.disabled || false)

const isStatusToReview = computed(() => (registration.value?.status === 'to_review') || false)

const data = computed(() => {
  const data = {
    customer: registration.value?.customer,
    guarantor: registration.value?.guarantor,
    majority_partner: registration.value?.company?.majority_partner,
    legal_representative_1: registration.value?.company?.legal_representatives?.find(rep => rep.kind === 'legal_representative_1'),
    legal_representative_2: registration.value?.company?.legal_representatives?.find(rep => rep.kind === 'legal_representative_2'),
    project: registration.value?.project,
  }[props.kind]

  return {
    ...data!,
    errors: extractErrorsByField(registration.value?.errors, props.kind),
  }
})

const firstError = computed(() => Object.values(data.value.errors)[0])
const zipCodeError = computed(() => {
  if (props.form.errors.value.zipcode) {
    return props.form.errors.value.zipcode
  }

  if (isErrorCep.value && props.form.values.zipcode && !isLoadingCep.value)
    return t('form.invalidCep')

  return ''
})

// watch for cep data and compare with the zip code on database
watch([zipCodeModel, cepData, data], () => {
  if (onlyNumbers(zipCodeModel.value) === onlyNumbers(data.value.address?.zipcode)) {
    props.form.setValues({
      city: data.value.address?.city?.name,
      state: data.value.address?.state?.name,
      state_id: data.value.address?.city?.id,
      city_id: data.value.address?.city?.id,
      zipcode: maskCep(data.value.address?.zipcode),
      street: data.value.address?.street,
      street_number: data.value.address?.street_number,
      neighborhood: data.value.address?.neighborhood,
      complement: data.value.address?.complement,
    } as FormSchema, false)
    return
  }

  if (cepData && !isErrorCep.value) {
    props.form.setValues({
      zipcode: maskCep(zipCodeModel.value),
      street: cepData.value?.street,
      neighborhood: cepData.value?.neighborhood,
      state: cepData.value?.state?.name,
      city: cepData.value?.city?.name,
      street_number: '',
      complement: '',
      state_id: Number(cepData.value?.state?.id),
      city_id: Number(cepData.value?.city?.id),
    }, false)
  }
})

// set initial form data & errors
watch(data, (newState, oldState) => {
  if (isEqual(newState, oldState)) {
    return
  }
  props.form.setValues({
    city: newState.address?.city?.name,
    state: newState.address?.state?.name,
    state_id: newState.address?.city?.id,
    city_id: newState.address?.city?.id,
    zipcode: maskCep(newState.address?.zipcode),
    street: newState.address?.street,
    street_number: newState.address?.street_number,
    neighborhood: newState.address?.neighborhood,
    complement: newState.address?.complement,
  } as FormSchema, false)

  setTimeout(() => { // to avoid concorrency with the form values
    Object.entries(newState.errors).forEach(([key, value]) => {
      props.form.setFieldError(key as Field, value.title)
    })
  }, 1000)
}, { immediate: true })
</script>

<template>
  <!-- alert -->
  <SolAlert
    v-if="firstError && isStatusToReview"
    id="feedback"
    class="mb-4"
    :feedback="firstError.type ?? 'warning'"
    :title="firstError.title"
  >
    {{ firstError.description }}
  </SolAlert>
  <!--  -->

  <span class="font-highlight text-neutral-low-dark text-[16px] md:system:text-[20px]">
    {{ title }}
  </span>

  <div class="grid grid-cols-1 md:system:grid-cols-12 gap-6 mt-6">
    <div class="md:system:col-span-3">
      <div class="ml-9 -mb-4">
        <SimulationTooltip position="right" :text="t('form.cepInfo')" class="tooltip z-100">
          <IconInfo class="cursor-pointer" />
        </SimulationTooltip>
      </div>
      <SolInputText
        id="zipcode"
        v-model="zipCodeModel"
        name="zipcode"
        mask="#####-###"
        :label="t('formalization.address.cep.label')"
        :placeholder="t('formalization.address.cep.placeholder')"
        :is-success="!isFetchingCep && isSuccessCep && !form.errors.value.zipcode && !isErrorCep"
        :success-text="t('form.validatedCep')"
        :is-loading="isLoadingCep || isFetchingCep"
        :disabled="isFieldsDisabled"
        :error="zipCodeError"
        v-bind="zipCodeProps"
      />
      <SharedHelperCep />
    </div>
    <SolInputText
      id="street-field"
      v-bind="streetProps"
      v-model="streetModel"
      class="md:system:col-span-5"
      name="street"
      :label="`${t('formAddress.street')}`"
      :placeholder="`${t('formAddress.street_placeholder')}`"
      :error="form.errors.value.street"
      :disabled="isFieldsDisabled"
    />
    <SolInputText
      id="street_number"
      v-bind="streetNumberProps"
      v-model="streetNumberModel"
      class="md:system:col-span-2"
      name="street_number"
      :label="`${t('formAddress.number')}`"
      :placeholder="`${t('formalization.address.number.placeholder')}`"
      :disabled="isFieldsDisabled"
      :error="form.errors.value.street_number"
    />
    <SolInputText
      id="complement"
      v-model="complementModel"
      class="md:system:col-span-2"
      name="complement"
      :label="`${t('formAddress.complement')}`"
      :placeholder="`${t('form.optional_placeholder')}`"
      :disabled="isFieldsDisabled"
      :error="form.errors.value.complement"
      v-bind="complementProps"
    />
  </div>

  <div class="grid grid-cols-1 md:system:grid-cols-12 gap-6 mt-6">
    <SolInputText
      id="neighborhood"
      v-model="neighborhoodModel"
      class="md:system:col-span-3"
      name="neighborhood"
      :label="`${t('formAddress.neighborhood')}`"
      :placeholder="`${t('formAddress.neighborhood_placeholder')}`"
      :disabled="isFieldsDisabled"
      :error="form.errors.value.neighborhood"
      v-bind="neighborhoodProps"
    />
    <SolInputText
      id="city-field"
      v-bind="cityProps"
      v-model="cityModel"
      class="md:system:col-span-7"
      name="city.name"
      :label="`${t('formAddress.city')}`"
      :placeholder="`${t('formAddress.city_placeholder')}`"
      :disabled="true"
      :error="form.errors.value.city"
    />
    <SolInputText
      id="state-field"
      v-model="stateModel"
      class="md:system:col-span-2"
      name="state.name"
      :label="`${t('formAddress.state')}`"
      placeholder="UF"
      :disabled="true"
      :error="form.errors.value.state"
      v-bind="stateProps"
    />
  </div>
</template>
