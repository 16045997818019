import { useMutation } from '@tanstack/vue-query'
import { queryKeys } from '~/queryClient'
import { postPaymentModelUpload } from '~/services-v2/payment-model-api/payment-model-api'

export default function usePaymentModelUploadFile() {
  const mutationFn = async (body: any) => {
    const response = await postPaymentModelUpload(body)
    return response
  }

  return useMutation({
    mutationFn,
    mutationKey: queryKeys.PAYMENT_MODEL.UPLOAD_FILE,

    retry: 2,
    retryDelay: 1000,
  })
}
