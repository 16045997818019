<script setup lang="ts">
import type { Field, Form } from './Company.schema'
import type { ModelTypes } from '~/services-v2/financial-bff/__generated__/zeus'
import { isEqual } from 'lodash'
import { computed, defineProps, ref, watch } from 'vue'
import FileStorageInput from '~/components/input/FileStorageInput.vue'
import { RegistrationDocumentTypes } from '~/services-v2/financial-bff/__generated__/zeus'
import useRegistration from '~/store-v2/registration/useRegistration'
import { convertToBrPhone, convertToCNPJ } from '~/utils/string'
import { extractErrorsByField } from './Company.schema'

const props = defineProps<{
  form: Form
  disabled?: boolean
}>()

// initial base hooks
const { t } = useI18n()
const route = useRoute()

const projectId = computed(() => route.params.id as string)

const {
  data: registration,
} = useRegistration(projectId)

// initial variables and computed
const currentCompanyOfficialDocument = ref<ModelTypes['RegistrationDocument']>()
const ACCEPTED_FILE_TYPES = ['image/jpeg', 'image/jpg', 'image/png', 'image/webp', 'application/pdf']

// computed
const isFieldsDisabled = computed(() => props.disabled || false)

const isStatusToReview = computed(() => (registration.value?.status === 'to_review') || false)
const errors = computed(() => extractErrorsByField(registration.value?.errors))
const firstError = computed(() => Object.values(errors.value)[0])

// fields
const [cnpjModel, cnpjProps] = props.form.defineField('cnpj')
const [companyNameModel, companyNameProps] = props.form.defineField('company_name')
const [emailModel, emailProps] = props.form.defineField('email')
const [phoneModel, phoneProps] = props.form.defineField('phone')
const [companyOfficialDocumentModel, companyOfficialDocumentProps] = props.form.defineField('company_official_document')

// watchers
watch(registration, async (newValue, oldValue) => {
  if (isEqual(newValue, oldValue)) {
    return
  }
  // set initial form data
  if (newValue && newValue.person_type === 'pj' && newValue.company) {
    const data = newValue.company
    const companyOfficialDocument = data.documents.find(d => d.type === RegistrationDocumentTypes.company_official_document)

    currentCompanyOfficialDocument.value = companyOfficialDocument

    props.form.setValues({
      company_name: data.company_name,
      cnpj: convertToCNPJ(data.cnpj),
      email: data?.contact?.email,
      phone: convertToBrPhone(data?.contact?.phone),
      company_official_document: companyOfficialDocument?.docstorage_id,
    }, false)

    const errs = extractErrorsByField(newValue.errors)

    setTimeout(() => { // to avoid concorrency with the form values
      Object.entries(errs).forEach(([key, value]) => {
        props.form.setFieldError(key as Field, value.title)
      })
    }, 1000)
  }
}, { immediate: true })
</script>

<template>
  <div v-if="registration">
    <!--  -->
    <SolAlert
      v-if="firstError && isStatusToReview"
      id="feedback"
      class="mb-4"
      :feedback="firstError.type ?? 'warning'"
      :title="firstError.title"
    >
      {{ firstError.description }}
    </SolAlert>
    <!--  -->

    <div class="flex flex-col items-start gap-4">
      <h3 class="font-highlight text-neutral-low-dark text-[16px] md:system:text-[20px]">
        {{ t('company.information') }}
      </h3>

      <div class="flex flex-col items-start gap-6 w-full">
        <div class="flex flex-col md:system:flex-row items-start gap-6 w-full">
          <SolInputText
            id="name"
            v-model="companyNameModel"
            class="w-full md:system:w-2/3"
            name="company_name"
            :label="`${t('company.name')}*`"
            v-bind="companyNameProps"
            :placeholder="`${t('company.name_placeholder')}`"
            :disabled="isFieldsDisabled"
            :error="props.form.errors.value.company_name"
          />
          <SolInputText
            id="cnpj"
            v-model="cnpjModel"
            class="w-full md:system:w-1/3"
            name="cnpj"
            :label="`${t('company.document')}*`"
            v-bind="cnpjProps"
            :mask="{ preset: 'CNPJ' }"
            :disabled="true"
            :placeholder="`${t('company.document_placeholder')}`"
            :error="props.form.errors.value.cnpj"
          />
        </div>
        <div class="flex flex-col md:system:flex-row items-start gap-6 w-full">
          <SolInputText
            id="email-field"
            v-model="emailModel"
            class="w-full md:system:w-2/3"
            name="email"
            type="email"
            :label="`${t('company.email')}*`"
            v-bind="emailProps"
            :placeholder="`${t('company.email_placeholder')}`"
            :disabled="isFieldsDisabled"
            :error="props.form.errors.value.email"
          />

          <SolInputText
            id="phone"
            v-model="phoneModel"
            class="w-full md:system:w-1/3"
            name="phone"
            :mask="['(##) # ####-####', '(##) ####-####']"
            :label="`${t('company.phone')}*`"
            v-bind="phoneProps"
            :placeholder="`${t('company.phone_placeholder')}`"
            :disabled="isFieldsDisabled"
            :error="props.form.errors.value.phone"
          >
            <template #icon:left>
              <span>+55</span>
            </template>
          </SolInputText>
        </div>

        <div class="flex items-start gap-6 w-full">
          <FileStorageInput
            id="company_official_document"
            v-model="companyOfficialDocumentModel"
            name="company_official_document"
            :label="`${t('company.official_document')}*`"
            class="w-full"
            v-bind="companyOfficialDocumentProps"
            :accept="ACCEPTED_FILE_TYPES.join(',')"
            :placeholder="t('formDocument.placeholder')"
            :disabled="isFieldsDisabled || (currentCompanyOfficialDocument && currentCompanyOfficialDocument.status !== 'pending')"
            :error="props.form.errors.value.company_official_document"
            :project-id="registration.project_id"
            :enable-delete="isStatusToReview"
            :uploaded-file="currentCompanyOfficialDocument"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
:deep(.sol-input-core){
    &.-left-icon {
      @apply pl-xl;
    }
  }

.error-document {
  :deep(.sol-download) {
    @apply border-feedback-negative-pure;
  }
}
</style>
