<script setup lang="ts">
import { SolLink } from '@solfacil/girassol'
import { ref } from 'vue'
import { useRoute } from 'vue-router'

// icons
import CloseIcon from '~icons/material-symbols/close-rounded'
import EditIcon from '~icons/material-symbols/edit'
import InfoIcon from '~icons/material-symbols/info-outline'
import VisibilityIcon from '~icons/material-symbols/visibility'

import { I18N_SINGULAR } from '~/constants'
import useProject from '~/store-v2/projects/useProject'
import useProjectContractSigned from '~/store-v2/projects/useProjectContractSigned'
import currency from '~/utils/currency'
import Oportunities from './blocks/Oportunities.vue'

// initial base hooks
const route = useRoute()
const { t } = useI18n()

// initial variables and computed
const projectId = computed(() => route.params.id as string)

// domain hooks
const {
  data: project,
  isLoading: isLoadingProject,
  isError: isErrorProject,
} = useProject(projectId)

const {
  data: isProjectContractSigned,
} = useProjectContractSigned(projectId)

// states
const isOpen = ref(false)

// functions
function toggleModal() {
  isOpen.value = !isOpen.value
}

function getAddonLabel(type: string) {
  if (type === 'insurance')
    return t('resumeWidget.insurance')

  if (type === 'combo')
    return t('resumeWidget.combo')

  if (type === 'ampera')
    return t('resumeWidget.ampera')
  return ''
}

// computeds
const gracePeriod = computed(() => project?.value?.selected_installments?.grace_period === 1
  ? `${project?.value?.selected_installments?.grace_period} ${t('resumeWidget.month', I18N_SINGULAR)} `
  : `${project?.value?.selected_installments?.grace_period} ${t('resumeWidget.month', I18N_SINGULAR)}`)
</script>

<template>
  <!-- ! loading state -->
  <div v-if="!project && isLoadingProject" />

  <!-- ! error state -->
  <div v-else-if="!project && isErrorProject" />

  <!-- ! success state -->
  <div v-else-if="project" class="z-99999">
    <button class="fixed-button" @click="toggleModal">
      <span class="text-neutral-high-pure flex gap-2">
        <img src="/icons/money-rounded.svg" alt="money">
        {{ $t('resumeWidget.simulationSummary') }}
      </span>
    </button>

    <div
      v-if="isOpen"
      class="fixed inset-0 bg-brand-primary-pure bg-opacity-50 transition-opacity duration-300 z-10"
      :class="{ 'opacity-0': !isOpen }"
      @click="toggleModal"
    />

    <div
      class="right-0 fixed top-1/2 -translate-y-1/2 bg-neutral-high-pure shadow-lg transform duration-300 ease-out resume-modal"
      :class="{ 'translate-x-0': isOpen, 'translate-x-full': !isOpen }"
    >
      <div class="flex items-center justify-between py-6 mx-6 border-b border-b-neutral-high-medium">
        <h2 class="fonts-body-x-large-bold">
          {{ $t('resumeWidget.summary') }}
        </h2>
        <button
          class="text-gray-400 hover:text-gray-500"
          @click="toggleModal"
        >
          <span class="sr-only">{{ $t('resumeWidget.close') }}</span>
          <CloseIcon class="w-6 h-6" />
        </button>
      </div>

      <div class="resume-card-container">
        <div>
          <!-- Parcela -->
          <div>
            <div class="fonts-body-large-bold mb-3xs">
              {{ $t('resumeWidget.installment') }}
            </div>
            <div class="bg-gray-50 rounded-lg">
              <div class="resume-card">
                <span v-if="project?.selected_installments?.aggregated_addons?.length" class="discount">
                  {{ currency.formatBRL(project?.original_installment_value) }}
                </span>
                <div class="resume-value" :class="{ 'text-brand-primary-dark': !project?.selected_installments?.aggregated_addons?.length }">
                  <span class="fonts-body-small-regular">{{ project?.selected_installments?.installments }}x </span>
                  <span class="text-money-title">
                    {{ currency.formatBRL(project?.selected_installments?.installment_value!) }}
                  </span>
                </div>
              </div>
              <ul class="flex flex-col my-4xs">
                <li v-for="item in project?.selected_installments?.aggregated_addons" :key="item.id" class="benefits-container">
                  <span class="text-neutral-low-light font-base fonts-body-large-regular">{{ getAddonLabel(item.type) }}</span>
                  <span
                    class="text-brand-secondary-pure font-bold"
                    :class="{ 'text-brand-primary-medium': item.installment_price >= 0 }"
                  >
                    {{ currency.formatBRL(item.installment_price) }}
                  </span>
                </li>
              </ul>
            </div>
          </div>

          <h3 class="fonts-body-large-bold mt-4xs mb-nano">
            {{ $t('resumeWidget.financing') }}
          </h3>
          <!-- Financiamento -->
          <div class="container-resume-details">
            <ul class="flex flex-col fonts-body-large-regular space-y-2">
              <li class="details">
                <span class="text-neutral-low-light">{{ $t('resumeWidget.interestRate') }}</span>
                <span class="text-neutral-low-light font-bold flex gap-1">
                  {{ project?.selected_installments?.interest_rate.toLocaleString('pt-BR') }}% a.m.
                  <SimulationTooltip :text="t('resumeWidget.interestRateTooltip')" position="left" class="text-micro">
                    <InfoIcon class="w-4 h-4" />
                  </SimulationTooltip>
                </span>
              </li>
              <li class="details">
                <span class="text-neutral-low-light">{{ $t('resumeWidget.cet') }}</span>
                <span class="text-neutral-low-light font-bold flex gap-1 ">
                  {{ project?.selected_installments?.cet_percentage.toLocaleString('pt-BR') }}% a.m.
                  <SimulationTooltip :text="t('resumeWidget.cetTooltip')" position="left" class="text-micro">
                    <InfoIcon class="w-4 h-4" />
                  </SimulationTooltip>
                </span>
              </li>
              <li class="details flex flex-col">
                <div class="flex justify-between py-quark ">
                  <span class="text-neutral-low-light">{{ $t('resumeWidget.totalProjectValue') }}</span>
                  <span class="text-neutral-low-light">{{ currency.formatBRL(project?.selected_installments?.financing_value) }}</span>
                </div>
                <div class="flex justify-between py-quark">
                  <span class="text-neutral-low-light">{{ $t('resumeWidget.downPayment') }}</span>
                  <span class="text-neutral-low-light">{{ currency.formatBRL(project?.selected_installments?.down_payment) }}</span>
                </div>
                <div class="flex justify-between py-quark">
                  <span class="text-neutral-low-light">{{ $t('resumeWidget.financedValue') }}</span>
                  <span class="text-neutral-low-light">
                    {{ currency.formatBRL(
                      Number(project?.selected_installments?.financing_value) - Number(project?.selected_installments?.down_payment),
                    ) }}
                  </span>
                </div>
              </li>
              <li class="details">
                <span class="text-neutral-low-light">{{ $t('resumeWidget.gracePeriod') }}</span>
                <span class="text-neutral-low-light font-bold">{{ gracePeriod }}</span>
              </li>
            </ul>
          </div>

          <Oportunities
            :project-value="project.project_value"
            :down-payment="project.down_payment"
            :partner-id="project.partner_id"
            :person-type="project.person_type"
            :comission-raw="project?.selected_installments?.commission_raw"
            :insurance-commission="project?.insurance_commission as number"
            :selected-addons="project?.selected_installments?.addons.filter(item => item.applied)"
            :gained-points="project?.gained_points as number"
          />

          <div class="flex justify-center">
            <SolLink v-if="isProjectContractSigned" id="view-simulation" size="large" class="mt-4xs" :to="`/simulation/details/${projectId}`">
              {{ $t('resumeWidget.viewSimulation') }}
              <template #icon>
                <VisibilityIcon />
              </template>
            </SolLink>

            <SolLink v-else id="edit-simulation" size="large" class="mt-4xs" :to="`/simulation/details/${projectId}?edit`">
              {{ $t('resumeWidget.editSimulation') }}
              <template #icon>
                <EditIcon />
              </template>
            </SolLink>
          </div>
          <!--  -->
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.text-money-title {
  font-family: "Lato",sans-serif;
  line-height: 1.15;
  font-size: 1.5rem;
  font-weight: 700;
}

.fixed-button {
  @apply fixed top-1/3 right-10 bg-brand-secondary-pure;
  @apply transform -rotate-90 origin-right;
  @apply p-micro rounded-t-md;
}

.resume-modal {
  @apply p-micro rounded-l-md my-auto z-50 w-80;
}

.benefits-container {
  @apply flex justify-between;
  &:not(:last-of-type) {
    @apply border-b-neutral-high-medium border-b-1
  }
}

.discount {
  @apply text-neutral-low-light fonts-subtitle-small;
  text-decoration: line-through;
}

.card-subtitle {
  @apply fonts-body-large-bold;
}

.container-resume-details {
  @apply w-full flex flex-col;
  .details {
    @apply flex justify-between py-quark text-3xs;

    &:not(:last-of-type) {
      @apply border-b-neutral-high-medium border-b-1 border-dashed
    }
    &:last-of-type {
      @apply border-b-neutral-high-medium border-b-1 pb-3xs mb-3xs
    }
  }
}

.resume-card-container{
  @apply overflow-y-auto p-6 h-[calc(100%)];
}

.resume-card {
  @apply bg-neutral-high-light rounded-lg p-4xs align-middle text-center;
  @apply flex flex-col;
  .card-title {
    @apply fonts-heading-h4 mb-2xs;
  }
  .card-content {
      @apply grid-cols-1 grid-rows-2 grid gap-1;
  }
  .resume-value {
    @apply text-brand-secondary-pure;
  }
}
</style>
