<script setup lang="ts">
import { computed, ref } from 'vue'

const props = withDefaults(defineProps<{
  readonly text?: string
  readonly position?: 'top' | 'right' | 'bottom' | 'left'
  readonly distance?: string | null
  readonly showManually?: boolean
  readonly width?: string
}>(), {
  text: '',
  position: 'top',
  distance: null,
  showManually: false,
  width: undefined,
})

const showTooltip = ref(false)

const tooltipStyles = computed(() => {
  switch (props.position) {
    case 'top':
      return {
        top: props.distance || '-108px',
        left: '50%',
        transform: 'translateX(-50%)',
        width: props.width || 'auto',
      }
    case 'bottom':
      return {
        top: props.distance || '150%',
        left: '50%',
        transform: 'translateX(-50%)',
        width: props.width || 'auto',
      }
    case 'left':
      return {
        right: props.distance || 'calc(120% + 8px)',
        top: '50%',
        transform: 'translateY(-50%)',
        width: props.width || 'auto',
      }
    case 'right':
      return {
        left: props.distance || '120%',
        top: '50%',
        transform: 'translateY(-50%)',
        width: props.width || 'auto',
      }
    default:
      return {}
  }
})

const arrowClass = computed(() => {
  return `${props.position}`
})
</script>

<template>
  <div class="tooltip-container" @mouseenter="showTooltip = true" @mouseleave="showTooltip = false">
    <slot />
    <div v-if="showTooltip || showManually" class="tooltip" :style="tooltipStyles">
      <div class="arrow" :class="arrowClass" />
      <slot name="text">
        <span>{{ text }}</span>
      </slot>
    </div>
  </div>
</template>

<style scoped lang="scss">
.tooltip-container {
  position: relative;
  isolation: isolate;

  .tooltip {
    @apply min-w-xl w-max;
    @apply absolute p-4xs z-50 rounded-md;
    @apply opacity;
    @apply bg-neutral-low-medium fonts-body-medium-regular;
    @apply text-neutral-high-pure shadow-moderate;
    transition: opacity 0.2s;
  }

  &:hover .tooltip {
    opacity: 1;
  }
}

.arrow {
  @apply absolute w-3 h-3;
  @apply bg-neutral-low-medium;

  &.top {
    bottom: -6px;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
  }

  &.bottom {
    top: -6px;
    left: 50%;
    transform: translateX(-50%) rotate(225deg);
  }

  &.left {
    right: -6px;
    top: 50%;
    transform: translateY(-50%) rotate(135deg);
  }

  &.right {
    left: -6px;
    top: 50%;
    transform: translateY(-50%) rotate(-45deg);
  }
}
</style>
