import type { HardwareBodyDraft } from '~/utils/installation/Installation'
import { useMutation } from '@tanstack/vue-query'
import { queryKeys } from '~/queryClient'
import financialBff from '~/services-v2/financial-bff/financial-bff'

export default function useDraftMutation() {
  const mutationKey = queryKeys.HARDWARE.DRAFT

  const mutationFn = async (input: HardwareBodyDraft) => {
    await financialBff('mutation')({
      hardware_form_draft: [
        {
          hardwareFormDraftInput: {
            project_id: input.project_id,
            power: input.power,
            sku: input.sku,
            price: input.price,
            inverters: input.inverters?.map(inverter => ({
              quantity: inverter.quantity,
              power: inverter.power,
              sku: inverter.sku,
              brand: inverter.brand,
              model: inverter.model,
            })) || [],
            modules: input.modules?.map(module => ({
              quantity: module.quantity,
              power: module.power,
              sku: module.sku,
              brand: module.brand,
              model: module.model,
            })) || [],
          },
        },
        true,
      ],
    })
  }

  return useMutation({
    mutationKey,
    mutationFn,
    retry: 3,
  })
}
