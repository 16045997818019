export function onlyNumbers(str: string | null | undefined) {
  if (!str || typeof str !== 'string') {
    return ''
  }
  return str.replace(/\D/g, '')
}

/**
 * This function normalizes a phone numbers parsing DDI-less phone numbers to E.164 format
 * @param phone Any phone number
 * @returns A normalized phone number in E.164 format
 */
export function sanitizeIntPhone(phone: string | undefined | null, defaultDDI = '55') {
  if (!phone) {
    return ''
  }
  // remove all non-numeric characters but keep only + char
  const num = phone.replace(/[^0-9+]/g, '')

  // if the number already has a +, return it
  if (num.startsWith('+'))
    return num

  if (num.startsWith('55')) {
    return sanitizeIntPhone(num.replace('55', ''))
  }

  // if the number has 10 or 11 digits, add the default DDI
  if (num.length === 10 || num.length === 11) {
    return `+${defaultDDI}${num}`
  }

  return `+${num}`
}

/**
 * This function normalizes a phone in E.164 format to a DDI-less phone number in Brazil
 * @param phone Any phone number in E.164 format
 * @returns A normalized phone number in Brazil ex: 11987654321
 */
export function sanitizeAndFormatPhone(phone: string | undefined | null) {
  if (!phone) {
    return ''
  }

  const num = sanitizeIntPhone(phone)

  return num.replace(/\+\d{2}/g, '')
}

/**
 * Normalizes a international date YYYY-MM-DD to DD/MM/YYYY
 * @param date A date in the format YYYY-MM-DD
 * @returns A date in the format DD/MM/YYYY
 * @example
 * normalizeDate('2021-12-31') // 31/12/2021
 * normalizeDate('2021-01-01') // 01/01/2021
 * normalizeDate('2021-01-01T00:00:00.000Z') // 01/01/2021
 */
export function convertToBrDate(date: string | undefined | null) {
  if (!date) {
    return ''
  }

  // check if date already is in the format DD/MM/YYYY
  if (/\d{2}\/\d{2}\/\d{4}/.test(date)) {
    return date
  }

  return date.split('T')[0].split('-').reverse().join('/')
}

/**
 * Converts a Brazilian date DD/MM/YYYY to an international date YYYY-MM-DD
 * @param date A date in the format DD/MM/YYYY
 * @returns A date in the format YYYY-MM-DD
 * @example
 * convertToISODate('31/12/2021') // 2021-12-31
 * convertToISODate('01/01/2021') // 2021-01-01
 */
export function convertToISODate(date: string | undefined | null) {
  if (!date) {
    return ''
  }

  // check if date already is in the format YYYY-MM-DD
  if (/\d{4}-\d{2}-\d{2}/.test(date)) {
    return date
  }

  const [day, month, year] = date.split('/')
  return `${year}-${month}-${day}`
}

/**
 * Converts a Brazilian date format DD/MM/YYYY to international date format YYYY-MM-DD
 * @param date A date in the format DD/MM/YYYY
 * @returns A date in the format YYYY-MM-DD
 * @example
 * convertToIntlDate('31/12/2021') // 2021-12-31
 * convertToIntlDate('01/01/2021') // 2021-01-01
 */
export function convertToIntlDate(date: string | undefined | null): string {
  if (!date) {
    return ''
  }

  const parts = date.split('/')
  if (parts.length !== 3) {
    return ''
  }

  const [day, month, year] = parts
  return `${year}-${month}-${day}`
}

export function convertToCPF(cpf: string | undefined | null) {
  if (!cpf)
    return ''

  // Remove todos os caracteres que não são números
  const cleaned = cpf.replace(/\D/g, '')

  // Verifica se tem exatamente 11 dígitos
  if (cleaned.length !== 11) {
    throw new Error('CPF inválido. Deve conter 11 dígitos numéricos.')
  }

  // Formata o CPF no padrão XXX.XXX.XXX-XX
  return cleaned.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
}

export function convertToBrPhone(phone: string | undefined | null) {
  if (!phone)
    return ''

  const cleaned = onlyNumbers(phone)

  // check if the phone number already has the DDI, if so, remove it
  if (cleaned.startsWith('55') && cleaned.length > 11) {
    return convertToBrPhone(cleaned.replace('55', ''))
  }

  // deal with 11 digits phone numbers like mobile phones
  if (cleaned.length === 11) {
    return cleaned.replace(/(\d{2})(\d)(\d{4})(\d{4})/, '($1) $2 $3-$4')
  }
  // deal with 10 digits phone numbers like fix phones
  if (cleaned.length === 10) {
    return cleaned.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3')
  }

  return ''
}

export function convertToCNPJ(cnpj: string | undefined | null): string {
  if (!cnpj)
    return ''

  // Remove caracteres não numéricos
  const cleaned = cnpj.replace(/\D/g, '')

  // Verifica se tem 14 dígitos
  if (cleaned.length !== 14) {
    return ''
  }

  // Formata no padrão XX.XXX.XXX/XXXX-XX
  return cleaned.replace(
    /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
    '$1.$2.$3/$4-$5',
  )
}

export function maskCep(cep: string | undefined | null): string {
  if (!cep)
    return ''

  // Remove caracteres não numéricos
  const cleaned = cep.replace(/\D/g, '')

  // Verifica se tem 8 dígitos
  if (cleaned.length !== 8) {
    return ''
  }

  // Formata no padrão XXXXX-XXX
  return cleaned.replace(/(\d{5})(\d{3})/, '$1-$2')
}
