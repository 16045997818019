import type { PartialDeep } from 'type-fest'
import type { FormContext } from 'vee-validate'
import * as z from 'zod'

export function getSchema(t: any, financingValue) {
  return z.object({
    hardware_supplier: z.object({
      name: z.string().optional(),
      value: z.number().min(1, { message: t('form.required') }),
    }),
    equipment_value: z.number()
      .max(financingValue, { message: t('receiptModel.response.equipment_value_error') })
      .min(1, { message: t('form.required') })
      .nonnegative({ message: t('form.non_negative') }),
    supplier_email: z.array(
      z.string().refine(
        email => !email || /^[^\s@]+@[^\s@][^\s.@]*\.[^\s@]+$/.test(email),
        { message: 'Email inválido' },
      ),
    ),
  })
}

export type FormSchema = z.infer<ReturnType<typeof getSchema>>
export type Form = FormContext<PartialDeep<FormSchema>>
export type Field = keyof(FormSchema)

export function getInitialValues(): Partial<FormSchema> {
  return {
    hardware_supplier: {
      name: 'Selecione',
      value: 0,
    },
    equipment_value: 0,
    supplier_email: [],
  }
}
