<script lang="ts" setup>
import type { User } from '~/utils/auth/User'
import type { Project } from '~/utils/project/Project'
import { useDownload, useToast } from '@solfacil/girassol'
import useOpenableSteps from '~/store-v2/financings/useOpenableSteps'
import useDraftMutation from '~/store-v2/hardware/useDraftMutation'
import useHardwareForm from '~/store-v2/hardware/useHardwareForm'
import useHardwareValidation from '~/store-v2/hardware/useHardwareValidation'
import useMirrorStatus from '~/store-v2/hardware/useMirrorStatus'
import useShowcaseMutation from '~/store-v2/hardware/useShowcaseMutation'
import useProject from '~/store-v2/projects/useProject'
import useShoppingCartIdMutation from '~/store-v2/simulation/useShoppingCartIdMutation'
import filtersStoreMarketFinancing from '~/utils/filtersStoreMarketFinancing'
import { type HardwareBodyDraft, type HardwareBodySave, HardwareMirrorStatus } from '~/utils/installation/Installation'

const props = withDefaults(defineProps<{
  projectId: string
  paymentStatusFlow: string
  disabled?: boolean
  orderId?: string
  error?: boolean
  isContainer?: boolean
  equipmentOrigin: string | null
  shoppingCartId: string | null
  availableContainer?: boolean
  projectStatus?: string
}>(), {
  disabled: false,
  availableContainer: false,
})

const emit = defineEmits<{
  (e: 'onKitOrigin', origin: KitOrigins): void
  (e: 'onComboSelected', kit: OnComboSelected): void
  (e: 'onShowcaseSelected', selected: boolean): void
  (e: 'mirrorFile', file: File[] | null): void
  (e: 'hasFileAttached', hasFile: boolean): void
  (e: 'onShoppingCartId', id: string): void
}>()

interface OnComboSelected {
  selected: boolean
}

enum KitOrigins {
  MIRROR = 'mirror',
  SHOWCASE = 'showcase',
  CONTAINER = 'container',
}

const kitOrigin = ref<KitOrigins>(KitOrigins.MIRROR)
const project = ref<Project>()
const isSelectedKitOut = ref(false)
const showInvalidKitAlert = ref(false)
const mirrorFile = ref<File[]>()
const mirrorUploadFail = ref<string>()
const isLoadingMirrorUpload = ref(false)
const isLoadingProjectData = ref(false)
const projectId = ref(props.projectId)
const shoppingCartId = ref<string | null>(null)

const { t } = useI18n()
const isLargeScreen = useMediaQuery('(min-width: 1024px)')
const appStore = useAppStorage()
const CHANNEL = 'plugin-financiamento'
const ENV = useEnvironment()
const { rest } = useConnections()
const { createErrorToast, createInformativeToast } = useToast()
const { downloadFile } = useDownload()

const { data: hardwareForm, isLoading: isLoadingHardwareForm, refetch: refetchHardwareForm } = useHardwareForm(projectId)

const skipMirrorPolling = ref(true)

const { mutate: showcaseMutation } = useShowcaseMutation()
const { mutate: updateShoppingCartId } = useShoppingCartIdMutation()
const { mutateAsync: draftMutation } = useDraftMutation()
const { mutateAsync: hardwareValidation, data: hardwareValidationResult } = useHardwareValidation()
const {
  refetch: refetchDataStepsStatus,
} = useOpenableSteps(projectId)

const { data: dataMirrorStatus } = useMirrorStatus(projectId, skipMirrorPolling)

const {
  data: projectData,
} = useProject(projectId)

const user = appStore.get<User>('user')

const kitOriginsOptions = [
  { key: KitOrigins.MIRROR, labelKey: 'project_data.form.mirror' },
  { key: KitOrigins.SHOWCASE, labelKey: 'project_data.form.showcase' },
  { key: KitOrigins.CONTAINER, labelKey: 'project_data.form.container' },
]

const selectKitOrigin = computed(() =>
  kitOriginsOptions
    .filter(option =>
      (props.isContainer || option.key !== KitOrigins.CONTAINER)
      && (hardwareForm.value?.system_type !== 'with_battery' || option.key !== KitOrigins.SHOWCASE),
    )
    .map(option => ({
      name: option.key,
      label: t(option.labelKey),
      value: option.key,
    })),
)

const loading = computed(() => isLoadingHardwareForm.value || isLoadingProjectData.value)
const hasStoreOrder = computed(() => Boolean(hardwareForm.value?.has_store_order))
const isFormBlocked = computed(() => {
  return props.disabled || hasStoreOrder.value
})
const showMirrorUpload = computed(() => {
  const isMirrorNullable = hardwareForm.value?.mirror_metadata === null
  const isMirrorReproved = hardwareForm.value?.mirror_metadata?.status === HardwareMirrorStatus.REPROVED

  return Boolean(isMirrorNullable || isMirrorReproved)
})
const showMirrorUploadedFile = computed(() => {
  const hasDocumentUrl = Boolean(hardwareForm.value?.mirror_metadata?.document_url)
  const isNotReproved = hardwareForm.value?.mirror_metadata?.status !== HardwareMirrorStatus.REPROVED
  const isNotPending = hardwareForm.value?.mirror_metadata?.status !== HardwareMirrorStatus.PENDING

  return hasDocumentUrl && (isNotReproved && isNotPending)
})
const isPaymentModelApproved = computed(() => props.paymentStatusFlow === 'approved')
const showAlertErrorHardware = computed(() => {
  if (isPaymentModelApproved.value || ['approved', 'in_progress', 'under_analysis'].includes(props.projectStatus ?? ''))
    return false

  const isReproved = hardwareForm.value?.mirror_metadata?.status === HardwareMirrorStatus.REPROVED
  const hasReason = Boolean(hardwareForm.value?.mirror_metadata?.reason)

  return isReproved && hasReason
})

async function getProject() {
  try {
    isLoadingProjectData.value = true
    project.value = projectData.value as unknown as Project
  }
  catch (error) {
    console.error(error)
    createErrorToast('Não foi possível carregar as informações do projeto. Por favor, tente novamente.')
  }
  finally {
    isLoadingProjectData.value = false
  }
}

async function postKitDraft(draft: HardwareBodyDraft) {
  try {
    if (!draft)
      return

    await draftMutation(draft)
  }
  catch {
    createErrorToast(t('project_data.errors.failed_to_save_kit_draft'))
  }
}

function transformKitToDraft(kit): HardwareBodyDraft {
  const inverters = kit.inverters.map((inverter) => {
    return {
      quantity: inverter.amount,
      power: inverter.power,
      sku: inverter.sku,
      brand: inverter.brand,
      model: inverter.model,
    }
  })
  const modules = kit.modules.map((module) => {
    return {
      quantity: module.amount,
      power: (kit.power * 1000) / module.amount,
      sku: module.sku,
      brand: module.brand,
      model: module.model,
    }
  })

  return {
    project_id: props.projectId,
    inverters,
    modules,
    price: kit.price,
    sku: kit.sku,
    power: kit.power,
  }
}

function getHardwareFormFromDraft(draft: HardwareBodyDraft) {
  const inverters = draft.inverters?.map(item => ({ id: Number(item.sku), quantity: item.quantity, power: String(item.power) })) || []
  const modules = draft.modules?.map(item => ({ id: Number(item.sku), quantity: item.quantity, power: String(item.power) })) || []

  const hardwareDataForm: HardwareBodySave = {
    is_combo: true,
    project_id: props.projectId,
    distributor_id: 0,
    phase_id: 0,
    inverters,
    modules,
    id: shoppingCartId.value || '',
    combo_price: draft.price,
  }

  return hardwareDataForm
}

async function validateDraft(draft: HardwareBodyDraft) {
  if (!draft) {
    return false
  }

  const body = getHardwareFormFromDraft(draft)
  await hardwareValidation(body)

  return hardwareValidationResult.value?.is_valid
}

async function onAddKit(data: { cartId: any, kit: any }) {
  shoppingCartId.value = String(data.cartId)

  updateShoppingCartId({ projectId: props.projectId, cartId: String(data.cartId) })

  createInformativeToast({
    title: t('toastKitSelected.title'),
    description: t('toastKitSelected.description'),
  })

  const _draft = transformKitToDraft(data.kit)

  await postKitDraft(_draft)

  isSelectedKitOut.value = false

  emit('onShowcaseSelected', true)
  emit('onShoppingCartId', String(data.cartId))

  const isValid = await validateDraft(_draft)

  if (isValid) {
    showcaseMutation({
      price: _draft.price,
      project_id: props.projectId,
      inverters: _draft.inverters,
      modules: _draft.modules,
      shopping_cart_id: shoppingCartId.value || '',
    })

    emit('onComboSelected', { selected: true })
  }

  showInvalidKitAlert.value = !isValid
}

async function onDeleteKit() {
  emit('onShowcaseSelected', false)

  await updateShoppingCartId({ projectId: props.projectId, cartId: '' })

  showInvalidKitAlert.value = false

  const payload = {
    project_id: props.projectId,
  }

  await postKitDraft(payload)

  emit('onShoppingCartId', '')
}

async function onLoadCart(result) {
  if (result?.items?.length > 0) {
    const hasUnavailableKitItem = result.items.some(item => item.status.description === 'unavailable')

    if (hardwareForm.value?.combo_metadata) {
      const isValid = await validateDraft(hardwareForm.value!.combo_metadata!)

      isSelectedKitOut.value = hasUnavailableKitItem
      showInvalidKitAlert.value = !isValid
    }
  }
}

function handleMirrorDownload() {
  if (hardwareForm.value?.mirror_metadata?.document_url)
    downloadFile(hardwareForm.value.mirror_metadata.document_url)
}

function handleRemoveMirror() {
  mirrorFile.value = undefined
}

async function pollingMirrorStatus() {
  const interval = setInterval(async () => {
    if (dataMirrorStatus.value === HardwareMirrorStatus.APPROVED) {
      clearInterval(interval)

      await refetchHardwareForm()

      refetchDataStepsStatus()
      skipMirrorPolling.value = false
    }
  }, 15000) // 15 seconds
}

async function checkComboMetadata(metadata: HardwareBodyDraft) {
  const isValid = await validateDraft(metadata)
  showInvalidKitAlert.value = hardwareForm.value?.mirror_metadata !== null ? false : !isValid
}

function openSolfacilStore() {
  const urlSolfacilStore = 'https://loja.solfacil.com.br/'

  return window.open(urlSolfacilStore, '_blank')
}

function handleWithHardwareFormState() {
  if (!hardwareForm.value)
    return
  if (props.equipmentOrigin === null || props.equipmentOrigin === 'mirror') {
    if (hardwareForm.value.combo_metadata)
      checkComboMetadata(hardwareForm.value.combo_metadata)

    if (hardwareForm.value.is_showcase)
      kitOrigin.value = KitOrigins.SHOWCASE

    const isMirrorReproved = hardwareForm.value.mirror_metadata?.status === HardwareMirrorStatus.REPROVED
    const isMirrorPending = hardwareForm.value.mirror_metadata?.status === HardwareMirrorStatus.PENDING
    const isMirrorApproved = hardwareForm.value.mirror_metadata?.status === HardwareMirrorStatus.APPROVED

    if (isMirrorReproved)
      mirrorUploadFail.value = t('project_data.notifications.mirror_uploaded_reproved')

    if (isMirrorPending) {
      emit('mirrorFile', [])
      pollingMirrorStatus()
    }

    if (isMirrorApproved || hardwareForm.value.is_showcase)
      emit('onComboSelected', { selected: true })
  }
  else {
    const isMirrorReproved = hardwareForm.value.mirror_metadata?.status === HardwareMirrorStatus.REPROVED
    const isMirrorPending = hardwareForm.value.mirror_metadata?.status === HardwareMirrorStatus.PENDING
    const isMirrorApproved = hardwareForm.value.mirror_metadata?.status === HardwareMirrorStatus.APPROVED

    if (isMirrorReproved)
      mirrorUploadFail.value = t('project_data.notifications.mirror_uploaded_reproved')

    if (isMirrorPending) {
      emit('mirrorFile', [])
      pollingMirrorStatus()
    }

    if (isMirrorApproved || hardwareForm.value.is_showcase)
      emit('onComboSelected', { selected: true })
    if (props.isContainer) {
      kitOrigin.value = KitOrigins.CONTAINER
    }
    if (props.equipmentOrigin === 'vitrine') {
      kitOrigin.value = KitOrigins.SHOWCASE
      emit('onComboSelected', { selected: true })
    }
  }
}

watch(() => props.error, () => {
  if (props.error) {
    mirrorUploadFail.value = 'Campo obrigatório'
  }
})

watch(mirrorFile, (newValue) => {
  emit('mirrorFile', newValue ?? null)
  emit('hasFileAttached', !!newValue)
  mirrorUploadFail.value = undefined
})

watch(hardwareForm, () => {
  handleWithHardwareFormState()
})

watch(kitOrigin, () => {
  emit('onKitOrigin', kitOrigin.value)
})

onMounted(() => {
  getProject()
  handleWithHardwareFormState()

  emit('onKitOrigin', kitOrigin.value)

  if (hardwareForm.value?.mirror_metadata?.status && hardwareForm.value?.mirror_metadata?.status === HardwareMirrorStatus.APPROVED) {
    emit('hasFileAttached', true)
  }
})
</script>

<template>
  <div class="title mb-4 pt-2xs">
    <span class="label text-[16px] md:system:text-[20px] pb-0 font-highlight text-neutral-low-dark">
      {{ t('project_data.heading.kit') }}
    </span>
  </div>

  <template v-if="kitOrigin !== KitOrigins.CONTAINER">
    <SolAlert
      v-if="isSelectedKitOut" id="alert-kit-out" class="mb-2" feedback="warning"
      :title="t('project_data.alerts.kit_out.title')" :text="t('project_data.alerts.kit_out.text')"
    />

    <SolAlert
      v-if="showInvalidKitAlert" id="alert-invalid-kit" class="mb-2" feedback="warning"
      :title="t('project_data.alerts.invalid_kit.title')" :text="t('project_data.alerts.invalid_kit.text')"
    />

    <SolAlert v-if="showAlertErrorHardware && kitOrigin === KitOrigins.MIRROR" id="informative-feedback-receipt-model" class="my-3" feedback="error">
      {{ hardwareForm?.mirror_metadata?.reason }}
    </SolAlert>
  </template>
  <div
    class="bg-neutral-high-medium bg-opacity-intense p-4 mb-4 rounded-md"
  >
    <label for="kit-origin" class="fonts-body-medium-bold text-neutral-low-dark">
      {{ t('project_data.form.combo_option.label') }}
    </label>
    <div class="flex items-center justify-between flex-wrap">
      <SolRadioGroup
        id="kit-origin"
        v-model="kitOrigin"
        class="mb-2"
        title=""
        name="mirror"
        :direction="isLargeScreen ? 'row' : 'column'"
        :radios="selectKitOrigin"
        :disabled="isFormBlocked"
      />
      <div v-if="orderId" class="border border-xs border-neutral-low-medium rounded-md px-3 py-2">
        ID Pedido #{{ orderId }}
      </div>
    </div>
  </div>

  <!-- Mirror section -->
  <template v-if="kitOrigin === KitOrigins.MIRROR">
    <template v-if="showMirrorUpload">
      <SolAlert
        id="mirror-store-alert"
        class="mb-2"
        feedback="informative"
        :text="t('project_data.alerts.mirror.info_payment_method_store')"
        :action="t('project_data.alerts.mirror.info_payment_method_store_button')"
        @click="openSolfacilStore()"
      />

      <SolFileUpload
        id="mirror-upload"
        v-model="mirrorFile"
        name="mirror-upload"
        :multiple="false"
        :file-name="mirrorFile?.[0]?.name"
        :label="t('project_data.form.mirror_upload')"
        :error="mirrorUploadFail"
        :loading="isLoadingMirrorUpload"
        :download-menu="['download', 'delete']"
        :class="{ 'pointer-events-none opacity-60': isFormBlocked }"
        :disabled="isFormBlocked"
        @menu:download="handleMirrorDownload"
        @menu:delete="handleRemoveMirror"
      />
    </template>
    <template v-if="showMirrorUpload === false && !showMirrorUploadedFile">
      <div>
        <span class="font-bold text-3xs text-neutral-low-dark">{{ t('project_data.form.mirror_upload') }}</span>
        <SharedMessageStatus
          :file-type-icon="['pdf']"
          :file-name="['Espelho-do-pedido.pdf']"
          :status-type-document="hardwareForm?.mirror_metadata?.status"
        />
      </div>
    </template>
    <UploadedFile
      v-if="showMirrorUploadedFile"
      :text="t('project_data.alerts.mirror_uploaded.text')"
      :action="{ text: t('project_data.alerts.mirror_uploaded.action_text') }"
      :download="{ link: hardwareForm?.mirror_metadata?.document_url || '' }"
    />
  </template>

  <!-- Showcase section -->
  <template v-if="kitOrigin === KitOrigins.SHOWCASE">
    <StoreMarketFinancing
      v-if="!loading && project && hardwareForm?.system_type !== 'with_battery'"
      class="!px-0"
      :class="{ 'pointer-events-none': isFormBlocked }"
      :disable-filters="isFormBlocked"
      :disable-cart="isFormBlocked"
      title="Kits disponíveis no Combo Fácil"
      subtitle="Sugestões da nossa loja de acordo com as dimensões do seu projeto."
      :filters="filtersStoreMarketFinancing.createObject(`${(project.system_power)}`, project.project_state)"
      :channel="CHANNEL"
      :environment="ENV"
      :segmentation-id="project.id"
      :partner-id="user?.parceiro?.id"
      :cart-id="shoppingCartId || props.shoppingCartId"
      mix-panel-env="formalizing-equipments"
      :mix-panel-key="rest.mixpanel.key"
      @on-add-kit="onAddKit"
      @on-delete-kit="onDeleteKit"
      @on-load-cart="onLoadCart"
    />
  </template>
</template>
