<script setup lang="ts">
import type { ReceiptModel } from '~/utils/receipt-model/ReceiptModel'

import Commissions from './Commissions.vue'
import TotalTransfers from './TotalTransfers.vue'
import Transfers from './Transfers.vue'

const props = defineProps<{
  data: ReceiptModel
  combo: boolean
  isOnboardingFlex: boolean
  statusFlow: string
}>()

const { t } = useI18n()
const { formatMoney } = useMoney()
const hasTransfers = props.data.transfers.length > 0 || false
const hasCommission = props.data.roles.invoices.commission
const commissionTransfers = props.data.transfers.filter(item => item.category.includes('commission_'))
</script>

<template>
  <div class="transfer_summary">
    <!-- TITLE -->
    <div>
      <h3 class="fonts-heading-h3">
        {{ t('customer.transfer_summary') }}
      </h3>
    </div>

    <div class="pt-micro">
      <!-- FINANCING VALUE -->
      <div class="flex flex-col md:system:flex-row md:system:justify-between">
        <span class="block break-words md:system:flex md:system:w-1/2">{{ t('customer.financed_value') }}:</span>
        <span class="md:system:pt-0 pt-quark text-brand-primary-dark">
          {{ formatMoney(props.data.financing_value) }}
        </span>
      </div>
      <!-- TRANSFERS STATUS -->
      <Transfers
        :has-transfers="hasTransfers"
        :data="props.data"
        :status-flow="props.statusFlow"
      />
    </div>

    <!-- COMMISSIONS -->
    <template v-if="hasCommission">
      <SolDivider thickness="x-small" orientation="horizontal" class="mt-nano mb-nano" />
      <Commissions
        :data="props.data.commissions"
        :commision-trasnfers="commissionTransfers"
        :status-flow="props.statusFlow"
      />
    </template>

    <!-- TOTAL TRANSFER -->
    <TotalTransfers
      :transfers="props.data.transfers"
      :transfers-paid="props.data.transfers_paid"
      :transfers-pending="props.data.transfers_pending"
      :transfer-after-installation="props.isOnboardingFlex"
      :status-flow="props.statusFlow"
    />
  </div>
</template>

<style lang="scss" scoped>
.transfer_summary {
  @apply my-micro p-micro;
  @apply bg-neutral-high-light rounded-sm;

  @screen md:system {
    @apply my-xs p-xs;
  }

}

.receipt-model .title span {
  @apply font-highlight  text-neutral-low-pure;
}

.method_receipt .subtitle {
  @apply leading-3 mt-quark mb-2xs;
}
</style>
