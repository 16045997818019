import { useQuery } from '@tanstack/vue-query'
import { queryKeys } from '~/queryClient'
import { getPaymentModelSuppliers } from '~/services-v2/payment-model-api/payment-model-api'

export default function useSuppliers() {
  const queryFn = async () => {
    return await getPaymentModelSuppliers()
  }

  return useQuery({
    queryFn,
    queryKey: queryKeys.PAYMENT_MODEL.SUPPLIERS,
    retry: 3,
    staleTime: 1000 * 60 * 1,
  })
}
