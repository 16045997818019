import { useQuery } from '@tanstack/vue-query'
import { decodeJwt } from 'jose'
import { ACCESS_TOKEN_STORAGE_KEY, REFRESH_TOKEN_STORAGE_KEY } from '~/constants'
import KnownError from '~/exceptions/KnownError'
//
import cookie from '~/foundation/storage/cookie'
import localstorage from '~/foundation/storage/localstorage'
//
import { queryKeys } from '~/queryClient'
// apis
import coreApi from '~/services-v2/core-api/core-api'
import hotjar from '~/services-v2/hotjar/hotjar'
import keycloackApi from '~/services-v2/keycloack-api/keycloack-api'
import personApi from '~/services-v2/person-api/person-api'
import * as personUtils from '~/services-v2/person-api/person-api.utils'

export default function useAuth() {
  const queryKey = queryKeys.AUTH.STATUS

  const queryFn = async () => {
    const token = localstorage.get<string>(ACCESS_TOKEN_STORAGE_KEY)
    const refreshToken = localstorage.get<string>(REFRESH_TOKEN_STORAGE_KEY)

    if (!token)
      throw new KnownError('UNAUTHENTICATED')

    const { sub } = decodeJwt(token)
    if (!sub)
      throw new KnownError('UNAUTHENTICATED')

    const [keycloack, me, person] = await Promise.all([
      keycloackApi.getUserInfo(),
      coreApi.me(),
      personApi.getUser(sub),
    ])

    const solfacilplus = {
      level: personUtils.getLevel(person),
      currentScore: personUtils.getCurrentScore(person),
      goScore: personUtils.getGoalScore(person),
      nextTransitionDate: personUtils.getNextTrasitionDate(person),
      hasRisk: personUtils.getHasRisk(person),
    }

    // SYNC USER DATA AND COOKIES
    useAppStorage().set('user', me) // TODO: Replace with a proper store
    cookie.set('access_token', token, { expires: 1 / 24 }) // 1 hour
    cookie.set('refresh_token', refreshToken!, { expires: 1 / 24 }) // 1 hour

    hotjar.identify(person.id, {
      email: person.contact.email,
      phone: person.contact.mobile,
      nome: person.fullname,
      profile: person.profile,
      level: personUtils.getLevel(person),
      document: person.partner?.document,
      state: person.partner?.address.state,
      zipcode: person.partner?.address.zipcode,
      city: person.partner?.address.city,
    })

    return {
      me,
      keycloack,
      person,
      solfacilplus,
    }
  }

  return useQuery({
    queryFn,
    queryKey,
    retry: 2,
    refetchOnWindowFocus: true,
    retryOnMount: true,
    retryDelay: 500,
    refetchInterval: 1000 * 60 * 5, // 5 minute
    staleTime: 1000 * 60 * 5, // 5 minute
  })
}

export type UseAuthData = ReturnType<typeof useAuth>['data']['value']
